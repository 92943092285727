import { CommonService } from './../../common/services/common.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import  { FormBuilder, FormGroup,  FormControl,  Validators}  from  '@angular/forms';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-products-summary',
  templateUrl: './products-summary.component.html',
  styleUrls: ['./products-summary.component.scss']
})

export class ProductsSummaryComponent implements OnInit {

  images = [];
  imagenames = [];
  uploadedImages = [];
  imageupload_name = [];
  public message;
  public messageRefImg;
  public uploadForm: FormGroup;
  public imageForm: FormGroup;

  public user_id = localStorage.getItem('userId');
  public user_type = localStorage.getItem('user_type');
  public prod_detail: any = {}; 
  public error: any = {};
  public duplicate_prod_detail:any = {};
  public cus_prod_id: any = '';
  public imagelink: any = environment.imageUrl;
  public paper_one_colours: any = [];
  public paper_two_colours: any = [];
  public paper_three_colours: any = [];
  public paper_four_colours: any = [];
  public paper_five_colours: any = [];
  public paper_colours: any = [];
  public noOfPlacesForNumbering: any = [];
  public submitted: boolean = false;
  public formSubmit: boolean = false;
  public showError: boolean = false;
  public showErrorRefImg: boolean = false;
  public Number_start: any = [];
  fileToUpload: any;
  uploadimageUrl: any;
  public image_name: any = [];
  public image_path_with_name: any = '';
  public paper_one_carbon: any = '';
  public paper_colours_length: number = 0;
  public prod_status: any = '';
  public taxable_amount: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private Router: Router, 
    private route: ActivatedRoute,
    private commonService: CommonService) {
      
    route.params.forEach(params => {
      if(params.id) {
        this.cus_prod_id = params.id;
        this.prod_detail.product_name = '';
        this.prod_detail.gst = '';
        this.prod_detail.sub_total_amount = '';
        this.prod_detail.category_type = '';
        this.prod_detail.category_name = '';
        this.prod_detail.job_code = '';
        this.prod_detail.description = '';
        this.prod_detail.product_details = '';
        this.prod_detail.image_large_details = '';
        this.prod_detail.paper_one_color_id = '';
        this.prod_detail.paper_two_color_id = '';
        this.prod_detail.paper_three_colour_id = '';
        this.prod_detail.paper_four_colour_id = '';
        this.prod_detail.paper_five_colour_id = '';
        this.prod_detail.paper_colour_id = '';
        this.prod_detail.pnumber_id = ''
        this.prod_detail.quantity = '';
        this.prod_detail.pk_sheet_id ='';
        this.prod_detail.no_of_printing_colour_back = '';
        this.error.paper_one_colour_id = false;
        this.error.paper_two_colour_id = false;
        this.error.paper_three_colour_id = false;
        this.error.paper_four_colour_id = false;
        this.error.paper_five_colour_id = false;
        this.error.pnumber_id = false;
        this.error.quantity = false;
        this.getCustomerProductDetails();
        this.getNoOfPlacesForNumbering();
      }
    });
  }

  get f(){
    return  this.uploadForm.controls;
  }

  handleFileInput(file: FileList) {
    this.fileToUpload = file.item(0);
    this.image_name = this.fileToUpload.name;

    //Show image preview
    let reader = new FileReader();
    reader.onload = (event: any) => {
      const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          this.showErrorRefImg = false;
          this.uploadimageUrl = event.target.result;
          this.imageForm.patchValue({
            imagefileSource: this.uploadimageUrl
          });
        }
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  onFileChange(event) {
    this.message = '';
    this.showError = false;
    if(event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        let checkext = this.validateFile(event.target.files[i].name);
        if(checkext){
          if (event.target.files[i].size/1024/1024 > 25) {
            this.showError = true;
            this.message = 'File size is bigger than 25MB';
            return false;
           }else{
            var reader = new FileReader();
            reader.onload = (event:any) => {
              this.images.push(event.target.result);
              this.uploadForm.patchValue({
                fileSource: this.images
              });
            }
            reader.readAsDataURL(event.target.files[i]);
            this.imagenames.push(event.target.files[i].name);
          }
        }else{
          this.showError = true;
          this.message = 'File extension not matching';
          return false;
        }
      }
    }
  }


  validateFile(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'dxf'   || ext.toLowerCase() == 'dwg' || ext.toLowerCase() == 'eps'   || ext.toLowerCase() == 'PS'
    || ext.toLowerCase() == 'prn'    || ext.toLowerCase() == 'pdf' || ext.toLowerCase() == 'svg/z' || ext.toLowerCase() == 'cpt'
    || ext.toLowerCase() == 'png'    || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'tiff'  || ext.toLowerCase() == 'gif'
    || ext.toLowerCase() == 'bmp'    || ext.toLowerCase() == 'raw' || ext.toLowerCase() == 'arw'   || ext.toLowerCase() ==  'cr2'
    || ext.toLowerCase() == 'crw'    || ext.toLowerCase() == 'dcr' || ext.toLowerCase() == 'dng'   || ext.toLowerCase() == 'kdc'
    || ext.toLowerCase() == 'mos'    || ext.toLowerCase() == 'mrw' || ext.toLowerCase() == 'nef'   || ext.toLowerCase() == 'orf'
    || ext.toLowerCase() == 'pef'    || ext.toLowerCase() == 'raf' || ext.toLowerCase() == 'sr2'   || ext.toLowerCase() == 'rw2' 
    || ext.toLowerCase() == 'srf'    || ext.toLowerCase() == 'srw' || ext.toLowerCase() == 'thm'   || ext.toLowerCase() == 'cdr' 
    || ext.toLowerCase() == 'legacy' || ext.toLowerCase() == 'al'  || ext.toLowerCase() == 'cmx'   || ext.toLowerCase() == 'cdt'
    || ext.toLowerCase() == 'pat'    || ext.toLowerCase() == 'clk' || ext.toLowerCase() == 'cgm'   || ext.toLowerCase() == 'cgz'
    || ext.toLowerCase() == 'cpx'    || ext.toLowerCase() == 'csl' || ext.toLowerCase() == 'des'   || ext.toLowerCase() == 'dsf'
    || ext.toLowerCase() == 'drw'    || ext.toLowerCase() == 'dst' || ext.toLowerCase() == 'mgx'   || ext.toLowerCase() == 'emf'
    || ext.toLowerCase() == 'fh'     || ext.toLowerCase() == 'fmv' || ext.toLowerCase() == 'gem'   || ext.toLowerCase() == 'htm'
    || ext.toLowerCase() == 'met'    || ext.toLowerCase() == 'nap' || ext.toLowerCase() == 'pic'   || ext.toLowerCase() == 'pct'
    || ext.toLowerCase() == 'plt'    || ext.toLowerCase() == 'ppt' || ext.toLowerCase() == 'pub'   || ext.toLowerCase() == 'shw'
    || ext.toLowerCase() == 'vsd'    || ext.toLowerCase() == 'wmf' || ext.toLowerCase() == 'wpg'   || ext.toLowerCase() == 'rtf'
    || ext.toLowerCase() == 'cal'    || ext.toLowerCase() == 'cur' || ext.toLowerCase() == 'docx'  || ext.toLowerCase() == 'exe'
    || ext.toLowerCase() == 'ico'    || ext.toLowerCase() == 'img' || ext.toLowerCase() == 'jp2'   || ext.toLowerCase() == 'mac' 
    || ext.toLowerCase() == 'pcx'    || ext.toLowerCase() == 'pp4' || ext.toLowerCase() == 'psd'   || ext.toLowerCase() == 'riff'
    || ext.toLowerCase() == 'sct'    || ext.toLowerCase() == 'tga' || ext.toLowerCase() == 'txt'   || ext.toLowerCase() == 'wb' 
    || ext.toLowerCase() == 'wq'     || ext.toLowerCase() == 'wk'  || ext.toLowerCase() == 'wp4'   || ext.toLowerCase() == 'wp5'
    || ext.toLowerCase() == 'wpd'    || ext.toLowerCase() == 'wsd' || ext.toLowerCase() == 'xcf'   || ext.toLowerCase() == 'xpm'
    || ext.toLowerCase() == 'xls'    || ext.toLowerCase() == 'pfb' || ext.toLowerCase() == 'doc'   || ext.toLowerCase() == 'swf'
    || ext.toLowerCase() == 'ttf') {
        return true;
    }
    else {
        return false;
    }
  }

  returnNumber(event: any){
    let value = event.target.value;
    value = value.replace(/[^0-9 ]/g,"");
    this.prod_detail.quantity = value.replace(/ /g,";");
    this.getlatestAmount();
  }

  filterColour(){
    if(this.paper_two_colours.length > 1){
      const papertwo = this.paper_two_colours.filter((data: any) => {
        if(data.pcolour_id == this.prod_detail.paper_one_colour_id){
          data.active = false;
        }else{
          data.active = true;
        }
      });
    }
    if(this.paper_three_colours.length > 1){
      const paperthree = this.paper_three_colours.filter((data: any) => {
        if(data.pcolour_id == this.prod_detail.paper_one_colour_id || data.pcolour_id == this.prod_detail.paper_two_colour_id){
          data.active = false;
        }else{
          data.active = true;
        }
      });
    }
    if(this.paper_four_colours.length > 1){
      const paperfour = this.paper_four_colours.filter((data: any) => {
        if(data.pcolour_id == this.prod_detail.paper_one_colour_id || data.pcolour_id == this.prod_detail.paper_two_colour_id || data.pcolour_id == this.prod_detail.paper_three_colour_id){
          data.active = false;
        }else{
          data.active = true;
        }
      });
    }
    if(this.paper_five_colours.length > 1){
      const paperfive = this.paper_five_colours.filter((data: any) => {
        if(data.pcolour_id == this.prod_detail.paper_one_colour_id || data.pcolour_id == this.prod_detail.paper_two_colour_id || data.pcolour_id == this.prod_detail.paper_three_colour_id || data.pcolour_id == this.prod_detail.paper_four_colour_id){
          data.active = false;
        }else{
          data.active = true;
        }
      });
    }
  }

  getlatestAmount(){
    const formData = new FormData();
    formData.append('user_id' , this.user_id);
    formData.append('user_type', this.user_type);
    formData.append('data', JSON.stringify(this.prod_detail));
    this.commonService.getlatestAmount(formData).subscribe((response: any) => {
      if (response.status) {

        this.prod_detail.rate = response.data.rate?response.data.rate:0;
        this.prod_detail.total_amount = response.data.total_amount;
        this.prod_detail.sub_total_amount = response.data.sub_total_amount;
        this.prod_detail.tax_amount = response.data.tax_amount?response.data.tax_amount:0;
        this.prod_detail.taxable_amount = response.data.taxable_amount?response.data.taxable_amount:0;

        
        this.prod_detail.discount_amount = response.data.discount_amount?response.data.discount_amount:0;

        this.prod_detail.original_total_amount = response.data.original_total_amount?response.data.original_total_amount:0;
        this.prod_detail.original_sub_total_amount = response.data.original_sub_total_amount?response.data.original_sub_total_amount:0;
        this.prod_detail.original_tax_amount = response.data.original_tax_amount?response.data.original_tax_amount:0;

        this.duplicate_prod_detail = response.data;
      }
    });
  }

  ngOnInit(): void {
    this.uploadForm = this.formBuilder.group({
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required])
    });
    this.imageForm = this.formBuilder.group({
      imageFile: new FormControl('', [Validators.required]),
      imagefileSource: new FormControl('', [Validators.required])
    });
  }

  getCustomerProductDetails(){
    this.commonService.getCustomerProductDetails(this.cus_prod_id,this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.prod_detail = response.data;
        this.duplicate_prod_detail = response.data;
        this.uploadedImages = this.prod_detail.images;
        this.paper_one_carbon = this.prod_detail.paper_one_carbon;
        if(this.prod_detail.paper_one_carbon == 'Y'){
          if(this.prod_detail.pk_sheet_id == 2){
            this.prod_detail.disablePaper2 = false;
            this.prod_detail.disablePaper3 = false;
            this.prod_detail.disablePaper4 = false;
            this.prod_detail.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 3){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = false;
            this.prod_detail.disablePaper4 = false;
            this.prod_detail.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 4){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = true;
            this.prod_detail.disablePaper4 = false;
            this.prod_detail.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 5){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = true;
            this.prod_detail.disablePaper4 = true;
            this.prod_detail.disablePaper5 = true;
          }
        }else{
          if(this.prod_detail.pk_sheet_id == 2){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = false;
            this.prod_detail.disablePaper4 = false;
            this.prod_detail.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 3){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = true;
            this.prod_detail.disablePaper4 = false;
            this.prod_detail.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 4){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = true;
            this.prod_detail.disablePaper4 = true;
            this.prod_detail.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 5){
            this.prod_detail.disablePaper2 = true;
            this.prod_detail.disablePaper3 = true;
            this.prod_detail.disablePaper4 = true;
            this.prod_detail.disablePaper5 = true;
          }
        }
        this.uploadimageUrl = this.imagelink+this.prod_detail.image_path_with_name;
        this.image_path_with_name = this.prod_detail.image_path_with_name;
        if(this.prod_detail.original_image_name == null || this.prod_detail.original_image_name == ''){
          this.image_name = '';
        }else{
          this.image_name = this.prod_detail.original_image_name;
        }
        this.paper_one_colours = this.prod_detail.paper_one_colours;
        this.paper_two_colours = this.prod_detail.paper_two_colours;
        this.paper_three_colours = this.prod_detail.paper_three_colours;
        this.paper_four_colours = this.prod_detail.paper_four_colours;
        this.paper_five_colours = this.prod_detail.paper_five_colours;
        this.paper_colours = this.prod_detail.paper_colours;
        this.paper_colours_length = this.paper_colours.length;

        if(this.duplicate_prod_detail.paper_color_id){
          this.prod_detail.paper_color_id = this.duplicate_prod_detail.paper_color_id
        }else{
          if(this.paper_colours.length > 0){
            this.prod_detail.paper_colour_id = this.paper_colours[0].pcolour_id;
            // this.filterColour();
          }
        }

        if(this.duplicate_prod_detail.paper_one_color_id){
          this.prod_detail.paper_one_color_id = this.duplicate_prod_detail.paper_one_color_id
        }else{
          if(this.paper_one_colours.length > 0){
            this.prod_detail.paper_one_colour_id = this.paper_one_colours[0].pcolour_id;
            this.filterColour();
          }
        }

        if(this.duplicate_prod_detail.paper_two_colour_id){
          this.prod_detail.paper_two_colour_id = this.duplicate_prod_detail.paper_two_colour_id
        }else{
          if(this.paper_two_colours.length > 0){
            const papertwo = this.paper_two_colours.filter((data: any) => {
              if(data.active){
                this.prod_detail.paper_two_colour_id = data.pcolour_id;
                return false;
              }
            });
            this.filterColour();
          }
        }

        if(this.duplicate_prod_detail.paper_three_colour_id){
          this.prod_detail.paper_three_colour_id = this.duplicate_prod_detail.paper_three_colour_id
        }else{
          if(this.paper_three_colours.length > 0){
            const paperthree = this.paper_three_colours.filter((data: any) => {
              if(data.active){
                this.prod_detail.paper_three_colour_id = data.pcolour_id;
                return false;
              }
            });
            this.filterColour();
          }
        }

        if(this.duplicate_prod_detail.paper_four_colour_id){
          this.prod_detail.paper_four_colour_id = this.duplicate_prod_detail.paper_four_colour_id
        }else{
          if(this.paper_four_colours.length > 0){
            const paperfour = this.paper_four_colours.filter((data: any) => {
              if(data.active){
                this.prod_detail.paper_four_colour_id = data.pcolour_id;
                return false;
              }
            });
            this.filterColour();
          }
        }

        if(this.duplicate_prod_detail.paper_five_colour_id){
          this.prod_detail.paper_five_colour_id = this.duplicate_prod_detail.paper_five_colour_id
        }else{
          if(this.paper_five_colours.length > 0){
            const paperfive = this.paper_five_colours.filter((data: any) => {
              if(data.active){
                this.prod_detail.paper_five_colour_id = data.pcolour_id;
                return false;
              }
            });
          }
        }
        // this.prod_detail.is_from = 'P';
        if((this.prod_detail.category_type == 'BB' || this.prod_detail.category_type == 'BN') && this.prod_detail.numbering != 'N'){
          this.changenumbering();
        }
      this.prod_status = this.prod_detail.status;  
      if(this.prod_status == 'P'){
        this.getlatestAmount();
      }
      }else{
        this.Router.navigate(['/product']);
      }
    });
  }

  getNoOfPlacesForNumbering(){
    this.commonService.getNoOfPlacesForNumbering().subscribe((response: any) => {
      if (response.status) {
        this.noOfPlacesForNumbering = response.data;
        const data = this.noOfPlacesForNumbering.filter((items: any) => {
            if(items.pnumber_id == '1'){
              items.active = false;
            }else{
              items.active = true;
            }
        });
      }
    });
  }

  saveCustomerProducts(){
    this.duplicate_prod_detail.numbering_start_from = this.prod_detail.numbering_start_from;
    this.submitted = true;
    if(this.prod_detail.category_type == 'BB'){
      
      if(this.prod_detail.paper_one_colour_id == '' || this.prod_detail.paper_one_colour_id == null){
        this.error.paper_one_colour_id = true;
      }else{
        this.error.paper_one_colour_id = false;
      }

      if(this.prod_detail.disablePaper2){
        if(this.prod_detail.paper_two_colour_id == '' || this.prod_detail.paper_two_colour_id == null){
          this.error.paper_two_colour_id = true;
        }else{
          this.error.paper_two_colour_id = false;
        }
      }else{
        this.error.paper_two_colour_id = false;
      }
      
      if(this.prod_detail.disablePaper3){
        if(this.prod_detail.paper_three_colour_id == '' || this.prod_detail.paper_three_colour_id == null){
          this.error.paper_three_colour_id = true;
        }else{
          this.error.paper_three_colour_id = false;
        }
      }else{
        this.error.paper_three_colour_id = false;
      }

      if(this.prod_detail.disablePaper4){
        if(this.prod_detail.paper_four_colour_id == '' || this.prod_detail.paper_four_colour_id == null){
          this.error.paper_four_colour_id = true;
        }else{
          this.error.paper_four_colour_id = false;
        }
      }else{
        this.error.paper_four_colour_id = false;
      }

      if(this.prod_detail.disablePaper5){
        if(this.prod_detail.paper_five_colour_id == '' || this.prod_detail.paper_five_colour_id == null){
          this.error.paper_five_colour_id = true;
        }else{
          this.error.paper_five_colour_id = false;
        }
      }else{
        this.error.paper_five_colour_id = false;
      }

    }

    if((this.prod_detail.category_type == 'BN' || this.prod_detail.category_type == 'BB') && this.prod_detail.numbering != 'N'){
      if(this.prod_detail.is_numbering == '' || this.prod_detail.is_numbering == null){
        this.error.is_numbering = true;
      }else{
        this.error.is_numbering = false;
        if(this.prod_detail.numbering_start_from == '' || this.prod_detail.numbering_start_from == null){
          this.error.numbering_start_from = true;
        }else{
          if(this.prod_detail.is_numbering == 'C'){
            let isnum = /^\d+$/.test(this.prod_detail.numbering_start_from);
            if(isnum){
              this.error.numbering_start_from = false;
            }else{
              this.error.numbering_start_from = true;
            }
          }
        }
      }
    }else{
      this.error.is_numbering = false;
      this.error.numbering_start_from = false;
    }

    if(this.prod_detail.quantity == '' || this.prod_detail.quantity == null || this.prod_detail.quantity < 1){
      this.error.quantity = true;
    }else{
      this.error.quantity = false;
    }
    
    if(this.prod_detail.category_type == 'BB'){
      if(!this.error.paper_one_colour_id && !this.error.paper_two_colour_id && 
        !this.error.paper_three_colour_id && !this.error.paper_four_colour_id && !this.error.paper_five_colour_id && 
        !this.error.is_numbering && !this.error.numbering_start_from && !this.error.quantity){
        this.formSubmit = true;
      }else{
        this.formSubmit = false;
      }
    }else if(this.prod_detail.category_type == 'BN'){
      if(!this.error.paper_colour_id && !this.error.numbering_start_from && 
        !this.error.is_numbering && !this.error.quantity){
        this.formSubmit = true;
      }else{
        this.formSubmit = false;
      }
    }else if(this.prod_detail.category_type == 'LH'){
      if(!this.error.paper_colour_id && !this.error.quantity){
        this.formSubmit = true;
      }else{
        this.formSubmit = false;
      }
    }

    if(this.formSubmit){
      
      const formData = new FormData();
      formData.append('files' , JSON.stringify(this.uploadForm.value));
      formData.append('fileToUpload' , JSON.stringify(this.imageForm.value));
      formData.append('imagenames', JSON.stringify(this.imagenames));
      formData.append('image_path_with_name', this.image_path_with_name);
      formData.append('image_name', this.image_name);
      formData.append('user_id' , this.user_id);
      formData.append('data', JSON.stringify(this.duplicate_prod_detail));
      this.commonService.updateCustomerProduct(formData).subscribe((response:any) => {
        if (response.status) {
          this.Router.navigate(['/product-summary-view/'+this.cus_prod_id]);
        }else{
          this.Router.navigate(['/customerproducts']);
        }
      });
    }
  }

  changenumbering(){
    if((this.prod_detail.category_type == 'BB' || this.prod_detail.category_type == 'BN') && this.prod_detail.numbering != 'N'){
      if(this.prod_detail.is_numbering == '' || this.prod_detail.is_numbering == null || this.prod_detail.is_numbering == 'N') {
        this.prod_detail.is_numbering = 'C';
      }else{
        this.error.is_numbering = false;
        if(this.prod_detail.is_numbering == 'C'){
          let isnum = /^\d+$/.test(this.prod_detail.numbering_start_from);
          if(isnum){
            this.error.numbering_start_from = false;
          }else{
            this.error.numbering_start_from = true;
          }
        }else if(this.prod_detail.is_numbering == 'R'){
          let numberRepeated = parseInt(this.prod_detail.no_of_bills) * parseInt(this.prod_detail.no_of_bills_per_page);
          this.prod_detail.numbering_start_from = '001 '+(numberRepeated?' -  '+numberRepeated:'');
        }
      }
    }else{
      this.error.is_numbering = false;
      this.error.numbering_start_from = false;
    }
  }

  removeFromAt(name: any){
    if(name.includes("(")){
      var name = name.substr(0, name.indexOf('('));
    }
    if(name.includes("@")){
      var name = name.substr(0, name.indexOf('@'));
    }
    return name;
  }

  removeFile(id){
    this.imagenames.splice(id, 1);
    this.images.splice(id, 1);
  }

  removeSampleFile(upload_id){
    const formData = new FormData();
      formData.append('upload_id' ,  upload_id);
      formData.append('user_id' , this.user_id);
      this.commonService.removeSampleFile(formData).subscribe((response:any) => {
        if (response.status) {
          const uploadedImages = this.uploadedImages.filter((data: any) => {
            return data.upload_id !== upload_id;
          }); 
          this.uploadedImages = [];
          this.uploadedImages = uploadedImages;
        }
      });
  }

}