import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  public registerForm: FormGroup;
  public error:boolean = false;
  public message:any = '';
  public showAdminBox: boolean = false;
  
  submitted = false;

  constructor(private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute) 
  {
    if (this.authService.isLoggedin()) {
      this.router.navigate(['']);
    }
  }

  navigateToHome(){
    this.router.navigate(['']);
  }

  register() {
    this.showAdminBox = false;
    this.message = "";
    this.error = false;
    this.submitted = true;
    if(this.registerForm.value.password != this.registerForm.value.confirm_password){
      this.error = true;
      this.message = "Password Not Match";
      return false;
    }
    if (this.registerForm.invalid) {
      return false;
    }
      const formData = new FormData();
      formData.append('customer_name', this.registerForm.get('customer_name').value);
      formData.append('customer_email', this.registerForm.get('customer_email').value);
      formData.append('customer_mobile', this.registerForm.get('customer_mobile').value);
      // formData.append('user_type', this.registerForm.get('user_type').value);
      formData.append('password', this.registerForm.get('password').value);
      formData.append('confirm_password', this.registerForm.get('confirm_password').value);
      formData.append('referrer_code', this.registerForm.get('referrer_code').value);
      this.authService.register(formData).subscribe((response: any) => {
          if (response.status) {
              this.submitted = false;
              if(response.data.active_status == 'D'){
                this.showAdminBox = true;
                setTimeout(this.navigateToHome,3000);
              }else{
                this.authService.setSessionInfo(response.data);
                if(localStorage.getItem('userId')){
                  if(localStorage.getItem('db_entry') == 'Y'){
                    if(localStorage.getItem('current_url') == 'product-details'){
                      const formData = new FormData();
                      formData.append('productList' , localStorage.getItem('productList'));
                      formData.append('user_id' , localStorage.getItem('userId'));
                      this.commonService.saveCustomerproducts(formData).subscribe((response: any) => {
                        if (response.status) {
                          localStorage.removeItem('db_entry');
                          localStorage.removeItem('current_url');
                          localStorage.removeItem('productList');
                          this.router.navigate(['/product-summary/'+response.pk_product_id]);
                        }
                      });
                    }
                  }else{
                    this.router.navigate(['/']);
                  }
                }
              }
          } else {
            this.error = true;
            this.message = response.message;
          }
      });
  }

  get g() { return this.registerForm.controls; }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      customer_name: ['', Validators.required],
      customer_email: ['', Validators.required],
      customer_mobile: ['', Validators.required],
      password: ['', Validators.required],
      confirm_password: ['', Validators.required],
      referrer_code: [''],
      // user_type: ['', Validators.required]
    });
  }

}
