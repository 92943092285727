<app-navbar-style-one></app-navbar-style-one>
    <!-- <div class = "inner-banner inner-bg2">
        <div class = "container">
            <div class = "inner-title">
                <h3>Update Additional Details</h3>
                <ul>
                    <li><a routerLink = "/">Home</a></li>
                    <li><i class = 'bx bxs-chevrons-right'></i></li>
                    <li>Update Additional Details</li>
                </ul>
            </div>
        </div>
    </div> -->
    <section class = "product-detls ptb-25">
        <div class = "container">
            <div class="row">
                <div class="col-lg-12 text-center" style="text-transform: capitalize;">
                    <h4>{{prod_detail.job_name}}<span *ngIf="prod_detail.quote_no">( {{prod_detail.quote_no}} )</span></h4>
                    <p class="col-lg-12">{{prod_detail.description}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="product-detls-image text-center">
                        <img class="imageSection" alt="Image" *ngIf ="image_name ==''" src = "{{imagelink+prod_detail.image_large_details}}" >
                        <img class="imageSection" alt="Image" *ngIf="image_name" [src]="uploadimageUrl" >
                    </div>
                    <p>{{prod_detail.product_details}}</p>
                    <div class="row">
                        <label class="col-6"><b>Category</b></label>
                        <label class="col-6">{{prod_detail.category_name}}</label>
                    </div>
                    <div class="row">
                        <label class="col-6"><b>Size</b></label>
                        <label class="col-6">{{prod_detail.size_name}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' ">
                        <label class="col-6"><b>Bill Book Type</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.pk_sheet_id == 1">All Original</span>
                            <span *ngIf="prod_detail.pk_sheet_id == 2">Duplicate</span>
                            <span *ngIf="prod_detail.pk_sheet_id == 3">Triplicate</span>
                            <span *ngIf="prod_detail.pk_sheet_id == 4">Quadriplicate</span>
                            <span *ngIf="prod_detail.pk_sheet_id == 5">Quintuplicate</span>
                        </label>
                      </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' ">
                        <label class="col-6">
                            <b *ngIf="prod_detail.pk_sheet_id == 1">Paper </b>
                            <b *ngIf="prod_detail.pk_sheet_id > 1">Paper 1 </b>
                        </label>
                        <label class="col-6">{{prod_detail.paper_one_name?removeFromAt(prod_detail.paper_one_name):''}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.disablePaper2">
                        <label class="col-6"><b>Paper 2</b></label>
                        <label class="col-6">{{prod_detail.paper_two_name?removeFromAt(prod_detail.paper_two_name):''}}<span *ngIf="prod_detail.checkpaper2 == 'N'" >(No Printing)</span></label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.disablePaper3">
                        <label class="col-6"><b>Paper 3</b></label>
                        <label class="col-6">{{prod_detail.paper_three_name?removeFromAt(prod_detail.paper_three_name):''}}<span *ngIf="prod_detail.checkpaper3 == 'N'" >(No Printing)</span></label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.disablePaper4">
                        <label class="col-6"><b>Paper 4</b></label>
                        <label class="col-6">{{prod_detail.paper_four_name?removeFromAt(prod_detail.paper_four_name):''}}<span *ngIf="prod_detail.checkpaper4 == 'N'" >(No Printing)</span></label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.disablePaper5">
                        <label *ngIf="prod_detail.disablePaper4 && prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-6"><b>Paper 5</b></label>
                        <label *ngIf="!prod_detail.disablePaper4 && prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-6"><b>Paper 4</b></label>
                        <label *ngIf="!prod_detail.disablePaper4 && !prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-6"><b>Paper 3</b></label>
                        <label *ngIf="!prod_detail.disablePaper4 && !prod_detail.disablePaper3 && !prod_detail.disablePaper2" class="col-6"><b>Paper 2</b></label>
                        <label class="col-6">{{prod_detail.paper_five_name?removeFromAt(prod_detail.paper_five_name):''}}<span *ngIf="prod_detail.checkpaper5 == 'N'" >(No Printing)</span></label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type != 'BB'">
                        <label class="col-6"><b>Paper</b></label>
                        <label class="col-6">{{prod_detail.paper_name?removeFromAt(prod_detail.paper_name):''}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.front_colours != ''">
                        <label class="col-6"><b>Printing Color Front</b></label>
                        <label class="col-6">{{prod_detail.front_colours}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.back_colours != ''">
                        <label class="col-6"><b>Printing Color Back</b></label>
                        <label class="col-6">{{prod_detail.back_colours}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.back_colours != '' && prod_detail.front_colours != '' && prod_detail.is_same_content == 'Y'">
                        <label class="col-6"><b>Front & Back Same Printing Content</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.is_same_content == 'Y'">Yes</span>
                            <span *ngIf="prod_detail.is_same_content == 'N'">No</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BN' &&  (prod_detail.lamination_type == 'M' || prod_detail.lamination_type == 'G') ">
                        <label class="col-6"><b>Lamination Type</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.lamination_type == 'M'">Matt</span>
                            <span *ngIf="prod_detail.lamination_type == 'G'">Glossy</span>
                        </label>
                    </div>
                    <!-- <div class="row" *ngIf="prod_detail.category_type == 'BN' && prod_detail.lamination_type != 'N'">
                        <label class="col-6"><b>Lamination Sides</b></label>
                        <label class="col-6">{{prod_detail.lamination_side}}
                        </label>
                    </div> -->
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.numbering != 'N'">
                        <label class="col-6"><b>No of Bills Per Book</b></label>
                        <label class="col-6">{{prod_detail.no_of_bills}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.numbering != 'N'">
                        <label class="col-6"><b>No of Bills Per Page</b></label>
                        <label class="col-6">{{prod_detail.no_of_bills_per_page}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.numbering != 'N'">
                        <label class="col-6"><b>Numbering</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.numbering == 'M'">Manual</span>
                            <span *ngIf="prod_detail.numbering == 'MA'">Machine</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.numbering == 'MA'">
                        <label class="col-6"><b>Numbering Type</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.is_numbering == 'C'">Continoius</span>
                            <span *ngIf="prod_detail.is_numbering == 'R'">Repeated</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.no_of_places_for_numbering > 1 && prod_detail.category_type == 'BB' && prod_detail.pnumber_id != '' && prod_detail.numbering != 'N'">
                        <label class="col-6"><b>No of Places for Numbering in a Bill: </b></label>
                        <label class="col-6">{{prod_detail.no_of_places_for_numbering}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.no_of_places_for_numbering > 1 && prod_detail.category_type == 'BN' && prod_detail.pnumber_id != '' && prod_detail.bitnotice_numbering != 'N'">
                        <label class="col-6"><b>No of Places for Numbering: </b></label>
                        <label class="col-6">{{prod_detail.no_of_places_for_numbering}}</label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BB' && prod_detail.perforation != 'N'">
                        <label class="col-6"><b>Perforation</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.perforation == 'M'">Manual</span>
                            <span *ngIf="prod_detail.perforation == 'MA'">Machine</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BN' && prod_detail.bitnotice_perforation != 'N'">
                        <label class="col-6"><b>Perforation</b></label>
                        <label class="col-6">
                            <span>Micro</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BN' && prod_detail.folding == 'Y'">
                        <label class="col-6"><b>Folding</b></label>
                        <label class="col-6">
                            <span *ngIf="prod_detail.folding == 'Y'">Yes</span>
                            <span *ngIf="prod_detail.folding == 'N'">No</span>
                        </label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'BN'">
                        <label class="col-6"><b>Package</b></label>
                        <label class="col-6"><span>{{prod_detail.bitnoticepacking}}</span></label>
                    </div>
                    <div class="row" *ngIf="prod_detail.category_type == 'LH'">
                        <label class="col-6"><b>Package / Finishing</b></label>
                        <label class="col-6"><span>{{prod_detail.letterheadpacking}}</span></label>
                    </div>
                    <div class="row">
                        <label class="col-6"><b>Quantity</b></label>
                        <label class="col-6"><span>{{prod_detail.quantity}}</span></label>
                    </div>
                    <div class="row" *ngIf="user_type == 'P'">
                        <label class="col-6"><b>Sub Total Amount </b></label>
                        <label class="col-6">{{taxable_amount}}</label>
                    </div>
                    <div class="row" *ngIf="user_type == 'P'">
                        <label class="col-6"><b>Discount Amount</b></label>
                        <label class="col-6">{{discount_amount}}</label>
                    </div>
                    <!-- <div class="row">
                        <label class="col-6"><b>GST : </b></label>
                        <label class="col-6">{{prod_detail.gst?prod_detail.gst:0.00}} %</label>
                    </div> -->
                    <div class="row">
                        <label class="col-6"><b>AMOUNT : </b></label>
                        <label class="col-6"><b>Rs {{prod_detail.sub_total_amount?prod_detail.sub_total_amount:0.00}} + {{prod_detail.gst?prod_detail.gst:0.00}}% GST</b></label>
                    </div>
                </div>
                <div class = "col-lg-6 col-md-12">
                    <h6>Upload Reference Image</h6>
                        <form [formGroup]="imageForm">
                            <div class="form-group row" style="margin-left: 0px;">
                                <input formControlName="imageFile" class="image_upload" id="image_upload" type="file" (change)="handleFileInput($event.target.files)"/>
                                <label class="upload_button" for="image_upload">Upload</label>
                                <span style="padding-left: 10px;">{{image_name?image_name:''}}</span>
                            </div>
                        </form>
                    <div class = "col-md-12" style="padding-left: 1px;">
                        <h6>Upload Files ( pdf, coral draw, illustrator & in-design)</h6>
                        <ol class="pl-15">
                            <li *ngFor="let upimg of uploadedImages;let i=index;" class="pt-10 pb-10">
                                <span class="ml-10 mr-10">{{upimg.original_image_name}}</span>
                                <span class="removeFile" (click)="removeSampleFile(upimg.upload_id)">
                                    <i class="bx bx-trash"></i>
                                </span>
                            </li>
                            <li *ngFor="let url of imagenames;let i=index;" class="pt-10 pb-10">
                                <span class="ml-10 mr-10">{{url}}</span>
                                <span class="removeFile" (click)="removeFile(i)">
                                    <i class="bx bx-trash"></i>
                                </span>
                            </li>
                        </ol>
                        <form [formGroup]="uploadForm">
                            <div class="form-group">
                                <input 
                                    formControlName="file"
                                    id="file" 
                                    type="file" 
                                    class="hidden"
                                    multiple="" 
                                    (change)="onFileChange($event)">
                                <label class="default-btn" for="file">Upload</label>
                                <div *ngIf="f.file.touched && f.file.invalid" class="alert alert-danger">
                                    <div *ngIf="f.file.errors.required">File is required.</div>
                                </div>
                                <div *ngIf="showError" class="alert alert-danger">
                                    <div *ngIf="showError">{{message}}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <br>
                    <div class="row form-group" *ngIf = "prod_detail.category_type != 'BB'">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper Colour: </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_colour_id}" [(ngModel)]="prod_detail.paper_colour_id">
                        <option value="">Select Colour</option>
                        <option value="{{colour.pcolour_id}}" *ngFor="let colour of paper_colours">{{colour.colour_name}}</option>
                        </select>
                    </div>
                    <!-- <div class = "row form-group" *ngIf = "prod_detail.category_type == 'LH'">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">Select Foil Type: </label>
                        <select class = "form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" name = "paper_clr1">
                        <option value = "disabled">GOLD / SILVER</option>
                        <option value = " " *ngFor = " "></option>
                        </select>
                    </div> -->
                    <div class = "row form-group" *ngIf = "prod_detail.category_type == 'BB'">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">
                            <span *ngIf="prod_detail.pk_sheet_id == 1">Paper Colour: </span>
                            <span *ngIf="prod_detail.pk_sheet_id != 1">Paper 1 Colour: </span>
                        </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_one_colour_id}" [(ngModel)]="prod_detail.paper_one_colour_id" (change)="filterColour()">
                        <option value = "disabled">Select colour</option>
                        <option value="{{colour1.pcolour_id}}" *ngFor="let colour1 of paper_one_colours">{{colour1.colour_name}}</option>
                        </select>
                    </div>
                    <div class = "row form-group" *ngIf = "prod_detail.category_type == 'BB' && prod_detail.disablePaper2">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 2 Colour: </label>
                        <select class = "form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_two_colour_id}" [(ngModel)]="prod_detail.paper_two_colour_id" (change)="filterColour()">
                            <option value="">Select Colour</option>
                            <ng-container *ngFor="let colour2 of paper_two_colours">
                                <option value="{{colour2.pcolour_id}}" *ngIf="colour2.active" >{{colour2.colour_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class = "row form-group" *ngIf = "prod_detail.category_type == 'BB' && prod_detail.disablePaper3">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 3 Colour: </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_three_colour_id}" [(ngModel)]="prod_detail.paper_three_colour_id" (change)="filterColour()">
                            <option value="">Select Colour</option>
                            <ng-container *ngFor="let colour3 of paper_three_colours">
                                <option value="{{colour3.pcolour_id}}" *ngIf="colour3.active" >{{colour3.colour_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="row form-group" *ngIf = "prod_detail.category_type == 'BB' && prod_detail.disablePaper4">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 4 Colour : </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_four_colour_id}" [(ngModel)]="prod_detail.paper_four_colour_id" (change)="filterColour()">
                            <option value="">Select Colour</option>
                            <ng-container *ngFor="let colour4 of paper_four_colours">
                                <option value="{{colour4.pcolour_id}}" *ngIf="colour4.active"  >{{colour4.colour_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="row form-group" *ngIf = "prod_detail.category_type == 'BB' && prod_detail.disablePaper5">
                        <label *ngIf="prod_detail.disablePaper4 && prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 5 Colour : </label>
                        <label *ngIf="!prod_detail.disablePaper4 && prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 4 Colour : </label>
                        <label *ngIf="!prod_detail.disablePaper4 && !prod_detail.disablePaper3 && prod_detail.disablePaper2" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 3 Colour : </label>
                        <label *ngIf="!prod_detail.disablePaper4 && !prod_detail.disablePaper3 && !prod_detail.disablePaper2" class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 pt-10">Paper 2 Colour : </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.paper_five_colour_id}" [(ngModel)]="prod_detail.paper_five_colour_id" (change)="filterColour()">
                            <option value="">Select Colour</option>
                            <ng-container *ngFor="let colour5 of paper_five_colours">
                                <option value="{{colour5.pcolour_id}}" *ngIf="colour5.active"  >{{colour5.colour_name}}</option>
                            </ng-container>
                        </select>
                    </div>
                    <div class="row form-group" *ngIf="((prod_detail.category_type == 'BB' || prod_detail.category_type == 'BN') && prod_detail.numbering == 'M')">
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <span class="lableSpan">Numbering Type: </span>
                        </label>
                        <select class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" (change)="changenumbering()" [ngClass]="{ 'is-invalid': submitted && error.is_numbering}" [(ngModel)]="prod_detail.is_numbering">
                            <option value="C">Continuous</option>
                            <option value="R">Repeated</option>
                        </select>
                    </div>
                    <div class="row form-group" *ngIf="(prod_detail.category_type == 'BN' && prod_detail.bitnotice_numbering != 'N') || (prod_detail.category_type == 'BB' && prod_detail.numbering != 'N' && prod_detail.is_numbering == 'C')" >
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <span class="lableSpan">Numbering Starts From: </span>
                        </label>
                        <input class="form-control col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12" [ngClass]="{ 'is-invalid': submitted && error.numbering_start_from }" pattern="^\d+$" [(ngModel)]="prod_detail.numbering_start_from" type="text" required>
                    </div>
                    <div class="row form-group" *ngIf="prod_detail.category_type == 'BB' && prod_detail.numbering != 'N' && prod_detail.is_numbering == 'R'" >
                        <label class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <span class="lableSpan">Numbering Starts From to Numbering: </span>
                        </label>
                        <label class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                            <span class="lableSpan">{{prod_detail.numbering_start_from}}</span>
                        </label> 
                    </div>
                    <br>
                    <div class="row">
                        <div class = "col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center product-add">
                            <button (click)="saveCustomerProducts()"  class="default-btn">Save Summary
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    </section>
<app-footer-style-two></app-footer-style-two>