import {Injectable, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Injectable()
export class Globals implements OnInit {

  constructor() {
  }

  cart_count:number = 0;
  
  ngOnInit() {
  }

}
