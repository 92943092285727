<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title">
            <h3>Services Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Services Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="service-dtls pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="service">
                    <div class="service-dtls-item">
                        <img src="{{imagelink+image_url}}" alt="Images">
                    </div>
                </div>

                <div class="service-dtls-title">
                    <p>{{long_description}}</p>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="service-sidebar">
                    <div class="service-sidebar-widget">
                        <h3>{{category_name}}  {{category_code?'('+category_code+')':''}}</h3>
                        <p>{{description}}</p>
                    </div>

                    <!-- <div class="service-sidebar-tag">
                        <h3>Tags</h3>

                        <ul>
                            <li><a routerLink="/">T-shirt</a></li>
                            <li><a routerLink="/">Cover</a></li>
                            <li><a routerLink="/">Great</a></li>
                            <li><a routerLink="/">Print Media</a></li>
                            <li><a routerLink="/">Hoodie Design</a></li>
                            <li><a routerLink="/">Design</a></li>
                            <li><a routerLink="/">Mug Design</a></li>
                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>