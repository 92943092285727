import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-services-details',
  templateUrl: './services-details.component.html',
  styleUrls: ['./services-details.component.scss']
})
export class ServicesDetailsComponent implements OnInit {

  public user_id = localStorage.getItem('userId');
  public service_id = '';
  public service_detail : any = [];
  public servicejson: any = {};
  public cate_detail: any = {};
  
  public category_name: any = '';
  public category_code: any = '';
  public category_id: any ='';
  public description : any = '';
  public long_description: any='';
  public calculation_route_name: any='';
  public imagelink: any = environment.imageUrl;
  public image_url: any = '';



  constructor(private Router: Router,
     private route: ActivatedRoute,
     private commonService: CommonService) { 
    route.params.forEach(params => {
      if (params.id) {
        this.service_id = params.id;
        this.getServiceDetails();
      }
    });
  }

  ngOnInit(): void {
  }

  getServiceDetails(){
    this.commonService.getServiceDetails(this.service_id).subscribe((response: any) => {
      if (response.status) {
        this.cate_detail = response.data;
        this.category_name = this.cate_detail.category_name;
        this.category_id = this.cate_detail.category_id;
        this.description = this.cate_detail.description;
        this.long_description = this.cate_detail.long_description;
        this.calculation_route_name = this.cate_detail.calculation_route_name;
        this.category_code = this.cate_detail.category_code;
        this.image_url = this.cate_detail.cat_image;
      }
    });
  }

}
