<app-navbar-style-one></app-navbar-style-one>

<section class="product-area pt-70 pb-70">
    <div class="container">
        <div class="row theme-card">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 wallet_details">
                <h1>My Wallet</h1>
            
                <div class="row pt-20 card-body tab2-card" *ngFor="let wallet of wallet_detail">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>Referrerence No</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>{{wallet.referrence_no}}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>Referrer name</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>{{wallet.customer_name}}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>Credited Amount</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30" *ngIf="user_detail.user_type == 'D'">
                        <p>{{wallet.transferred_amount}}</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>Transaction Type</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <span *ngIf="wallet.transaction_type == 'D'">Debit</span>
                        <span *ngIf="wallet.transaction_type == 'C'">Credit</span>
                        <!-- <p >{{wallet.transaction_type}}</p> -->
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>Credited Date</p>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                        <p>{{wallet.credited_date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>