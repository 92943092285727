import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../pages/auth/auth.service';
import { Router } from '@angular/router';
import { CommonService} from '../services/common.service';
import { Globals } from '../providers/global';

@Component({
  selector: 'app-navbar-style-two',
  templateUrl: './navbar-style-two.component.html',
  styleUrls: ['./navbar-style-two.component.scss']
})
export class NavbarStyleTwoComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public user_detail: any = {};
  public user_code: any = '';
  
  constructor(
    private authService: AuthService, 
    private router: Router,
    private commonService: CommonService,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    if(this.user_id){
      this.getUserDetails();
      this.getCartProducts();
    }
  }

  getUserDetails(){
    this.commonService.getUserDetails(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.user_detail = response.data;
        this.user_code = this.user_detail.reference_code;
      }
    });
  }

  logout(){
    this.globals.cart_count = 0;
    this.authService.logout();
    this.router.navigate(['/sign-in']);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  getCartProducts(){
    this.commonService.getCartProducts(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.globals.cart_count = response.data.length;
      }else{
        this.globals.cart_count = 0;
      }
    });
  }

}