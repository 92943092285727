import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { DesignerComponent } from './components/pages/designer/designer.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CreateComponent } from './components/pages/quotes/create/create.component';
import { ListComponent } from './components/pages/quotes/list/list.component';
import { ViewComponent } from './components/pages/quotes/view/view.component';
import { PostJobsComponent } from './components/pages/post-jobs/post-jobs.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { ProductsSummaryComponent } from './components/pages/products-summary/products-summary.component';
import { ProductsSummaryViewComponent } from './components/pages/products-summary-view/products-summary-view.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AccountComponent } from './components/pages/account/account.component';
import { AddressComponent } from './components/pages/address/address.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { CustomerproductsComponent } from './components/pages/customerproducts/customerproducts.component';
import { OrderViewComponent } from './components/pages/order-view/order-view.component';
import { WalletComponent } from './components/pages/wallet/wallet.component';
import { QuoteSummaryComponent } from './components/pages/quote-summary/quote-summary.component';
import { QuoteSummaryViewComponent } from './components/pages/quote-summary-view/quote-summary-view.component';
import { Globals } from './components/common/providers/global';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { GalleryComponent } from './components/pages/gallery/gallery.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HomePageOneComponent,
    HomePageTwoComponent,
    HomePageThreeComponent,
    FooterStyleOneComponent,
    NavbarStyleOneComponent,
    FooterStyleTwoComponent,
    NavbarStyleTwoComponent,
    AboutComponent,
    PricingComponent,
    FaqComponent,
    DesignerComponent,
    TestimonialsComponent,
    ErrorComponent,
    SignInComponent,
    SignUpComponent,
    RecoverPasswordComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    ProductsComponent,
    CartComponent,
    CheckoutComponent,
    ProductsDetailsComponent,
    BlogComponent,
    BlogDetailsComponent,
    ContactComponent,
    CreateComponent,
    ListComponent,
    ViewComponent,
    PostJobsComponent,
    CareersComponent,
    ProductsSummaryComponent,
    ProductsSummaryViewComponent,
    DashboardComponent,
    AccountComponent,
    AddressComponent,
    OrdersComponent,
    CustomerproductsComponent,
    OrderViewComponent,
    WalletComponent,
    QuoteSummaryComponent,
    QuoteSummaryViewComponent,
    GalleryComponent,
    
  ],
  imports: [
    NgSelectModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
  ],
  providers: [
    Globals
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
