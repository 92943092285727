<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title">
            <h3>Blog Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-dtls pt-100 ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8">
                <div class="blog-dtls-content">
                    <div class="blog-dtls-img">
                        <img src="assets/img/blog/blog-dtls.jpg" alt="Blog Images">
                    </div>

                    <div class="blog-text">
                        <h2>Keep Your Print Great</h2>
                        <ul>
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><a routerLink="/"><i class='bx bx-calendar-alt'></i> 18 May 2020</a></li>
                        </ul>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look</p>
                        <blockquote class="boxicon-quote">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Repellendus aliquid praesentium eveniet illum asperiores, quidem, ipsum voluptatum numquam ducimus nisi exercitationem dolorum facilis Repellendus aliquid praesentium eveniet illum asperiores.</p>
                        </blockquote>
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                    </div>

                    <div class="row bolg-gallery">
                        <div class="bolg-gallery-item">
                            <img src="assets/img/blog/blog6.jpg" alt="Blog Images">
                        </div>

                        <div class="bolg-gallery-item">
                            <img src="assets/img/blog/blog4.jpg" alt="Blog Images">
                        </div>

                        <div class="bolg-gallery-item">
                            <img src="assets/img/blog/blog5.jpg" alt="Blog Images">
                        </div>
                    </div>
                    <h3>Top 10 Design Trends 2020</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer vitae commodo justo. In vulputate arcu imperdiet  arcu molestie ultrices ac nec tortor. Sed euismod placerat justo, eget suscipit ex accumsan non. Curabitur id faucibus urna, eu tincidunt diam. Nulla malesuada nibh ligula. Cras semper tristique dolor, vitae ultricies massa eleifend sit amet. Proin id eros nisi.</p>
                    <p>Fusce tristique leo magna, nec bibendum lacus sollicitudin in. Suspendisse augue est, dignissim vel elementum ut, vestibulum eget dui. Sed purus odio, congue sed mi non, viverra commodo magna. Donec at placerat erat. Maecenas vel odio eget urna viverra gravida. Vestibulum risus neque, imperdiet in felis eget, pretium vestibulum nisl. Maecenas ultrices, ipsum eleifend pellentesque pellentesque, velit massa sodales eros, eu faucibus risus justo non est. Aenean non pellentesque ipsum. Mauris varius maximus euismod. Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bx-share-alt'></i></span>
                            <a href="#">Share</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            </ul>
                        </div>
                    </div>
                    
                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details">Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post</a>
                            </div>
                        </div>
                    </div>
                    
                    <div class="comment-area">
                        <h3 class="comment-title">2 Comments:</h3>

                        <div class="comment-card">
                            <div class="comment-author-img">
                                <img src="assets/img/testimonial/testimonial2.jpg" alt="Images">
                            </div>
                            <div class="comment-author-title">
                                <h3>Evanaa</h3>
                                <span>24 June 2020 10:00 PM</span>
                            </div>
                            <div class="comment-body">
                                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                                <a routerLink="/blog-details" class="reply-btn">Reply</a>
                            </div>
                        </div>

                        <div class="comment-card ml-50">
                            <div class="comment-author-img">
                                <img src="assets/img/testimonial/testimonial3.jpg" alt="Images">
                            </div>
                            <div class="comment-author-title">
                                <h3>Knot Doe</h3>
                                <span>24 June 2020 10:30 PM</span>
                            </div>
                            <div class="comment-body">
                                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                                <a routerLink="/blog-details" class="reply-btn">Reply</a>
                            </div>
                        </div>

                        <div class="comment-card">
                            <div class="comment-author-img">
                                <img src="assets/img/testimonial/testimonial4.jpg" alt="Images">
                            </div>
                            <div class="comment-author-title">
                                <h3>John Smith</h3>
                                <span>24 June 2020 10:50 PM</span>
                            </div>
                            <div class="comment-body">
                                <p>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ei usmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo.”</p>
                                <a routerLink="/blog-details" class="reply-btn">Reply</a>
                            </div>
                        </div>

                        <div class="comment-respond">
                            <h3 class="comment-respond-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p>Your email address will not be published. Required fields are marked *</p>

                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Name *</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Email *</label>
                                            <input type="text" class="form-control">
                                        </div>
                                    </div>

                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <label>Comment *</label>
                                            <textarea name="Comment" class="form-control textarea-hight" id="Comment" cols="30" rows="5"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 col-md-12">
                                        <button type="submit" class="post-com-btn">Post A Comment</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4">
                <div class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search Now</h3>
                        <div class="post-wrap">
                            <form class="search-form">
                                <label>
                                    <span class="screen-reader-text">Search for:</span>
                                    <input type="search" class="search-field" placeholder="Search...">
                                </label>
                                <button type="submit"><i class='bx bx-search'></i></button>
                            </form>
                        </div>
                    </div>

                    <section class="widget widget-popular-post">
                        <h3 class="widget-title">Popular Posts</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg1" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">Jun 20, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">How can Design Product Packaging in the Industry</a></h4>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg2" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">Jun 21, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">How Can Choose the Right Color for Cover Package</a></h4>
                                </div>
                            </article>

                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg3" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">Jun  22, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">How Can Choose the Best Color for Product Package</a> </h4>
                                </div>
                            </article>
                            
                            <article class="item">
                                <a routerLink="/blog-details" class="thumb"><span class="fullimage cover bg4" role="img"></span></a>
                                <div class="info">
                                    <time datetime="2020-06-30">Jun 23, 2020</time>
                                    <h4 class="title usmall"><a routerLink="/blog-details">How Can Make Your Product Cover  Most  Attractive</a></h4>
                                </div>
                            </article>
                        </div>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section>
                    
                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">Package Design <span>(10)</span></a></li>
                                <li><a routerLink="/">T-shirt Design <span>(07)</span></a></li>
                                <li><a routerLink="/">Cover Van <span>(20)</span></a></li>
                                <li><a routerLink="/">Mug Design <span>(12)</span></a></li>
                                <li><a routerLink="/">Book Cover Design <span>(15)</span></a></li>
                                <li><a routerLink="/">Hoodie Design <span>(18)</span></a></li>
                            </ul>
                        </div>
                    </section>

                    <section class="widget widget_tag">
                        <h3 class="widget-title">Tags</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">T-shirt</a></li>
                                <li><a routerLink="/">Cover</a></li>
                                <li><a routerLink="/">Great</a></li>
                                <li><a routerLink="/">Print Media</a></li>
                                <li><a routerLink="/">Hoodie Design</a></li>
                                <li><a routerLink="/">Design</a></li>
                                <li><a routerLink="/">Mug Design</a></li>
                            </ul>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>