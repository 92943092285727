import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../auth/auth.service';
import { AbstractControl, ValidationErrors } from '@angular/forms';



@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public user_detail: any = []; 
  public accountinfoForm: FormGroup;
  public submitted: boolean = false;
  public showofferedtittlebox: boolean = false;
  public showWantedtittlebox: boolean = false;
  public showbox: boolean = false;
  public registerForm: FormGroup;
  public error:boolean = false;
  public message:any = '';
  public confirimmsg:any = '';


  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder,
    private router: Router, 
    private route: ActivatedRoute, 
    private commonService: CommonService) {
      this.accountinfoForm = this.fb.group({
        username: ['', Validators.required],
        mobile: [{value: '', disabled: true}, Validators.required],
        email: [{value: '', disabled: true}, Validators.required],
        customer_gstin: [''],
        checkbox: [''],
        current_password: [''],
        new_password: [''],
        confirm_password: [''],
        });
    
   }

   ngOnInit(): void {
    this.getUserDetails();
  }

  checkbox_check(){
    if(this.accountinfoForm.get('checkbox').value){
      this.showbox = false;
    }else{
      this.showbox = true;
    }

  }

  getUserDetails(){
    this.commonService.getUserDetails(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.user_detail = response.data;
        this.accountinfoForm.get('username').setValue(this.user_detail.customer_name);
        this.accountinfoForm.get('mobile').setValue(this.user_detail.customer_mobile);
        this.accountinfoForm.get('email').setValue(this.user_detail.customer_email);
        this.accountinfoForm.get('customer_gstin').setValue(this.user_detail.customer_gstin);
      }
    });
  }

  validateCurrentPassword(){
    this.message = "";
    const formData = new FormData();
    formData.append('user_id' , this.user_id);
    formData.append('current_password' , this.accountinfoForm.value.current_password);
    this.commonService.validateCurrentPassword(formData).subscribe((response: any) => {
        if (response.status) {
          this.error = false;
          this.message = " ";
        }else{
          this.message = 'Password Not Matching';
          this.error = true;
        }
    });
  }


  get p() { return this.accountinfoForm.controls; }


  saveaccountinfo_post() {    
    this.submitted = true;
    this.confirimmsg = "";
    this.error = false;
    if(this.accountinfoForm.value.new_password != this.accountinfoForm.value.confirm_password){
      this.error = true;
      this.confirimmsg = "Confirim Password Is Not Matching";
      return false;
    }
    if (this.accountinfoForm.valid && !this.error) {
      const formData = new FormData();
      formData.append('customer_id', localStorage.getItem('userId'));
      formData.append('customer_name', this.accountinfoForm.get('username').value);
      formData.append('customer_mobile', this.accountinfoForm.get('mobile').value);
      formData.append('customer_email', this.accountinfoForm.get('email').value);
      formData.append('customer_gstin', this.accountinfoForm.get('customer_gstin').value);
      formData.append('checkbox', this.accountinfoForm.get('checkbox').value);
      formData.append('new_password', this.accountinfoForm.get('new_password').value);
      formData.append('confirm_password', this.accountinfoForm.get('confirm_password').value);
      this.commonService.saveaccountinfo_post(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.router.navigate(['/dashboard']);
        } else {
          console.log(response);
        }
      });
    }
    else{
      console.log("form Invalid");
    }
  }

}
