<footer class="footer-area">
    <div class="container">
        <div class="footer-contact">
            <div class="newsleter-area">
                <h2>Subscribe Our Newsletter</h2>
                <form class="newsletter-form">
                    <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL">
                    <button class="subscribe-btn" type="submit">Subscribe</button>
                </form>
            </div>
        </div>
        <div class="footer-top-list pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="footer-list">
                        <h3>Categories</h3>
                        <ul>
                            <ng-container *ngFor="let cat of subCategories">
                                <li *ngIf="cat.value == 'BB' || cat.value == 'BN' || cat.value == 'LH'">
                                    <i class='bx bxs-chevron-right'></i> <a routerLink="/product/{{cat.category_id}}">{{cat.category_name}}</a></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="footer-list">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="">Home</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/services">Categories</a></li>
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/about">About Us</a></li> -->
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/testimonial">Testimonial</a></li> -->
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/blog">Blog</a></li> -->
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4">
                    <div class="footer-side-list">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class='bx bxs-phone'></i> <a href="tel:+919941429634">+91 99414 29634</a></li>
                            <li><i class='bx bxl-telegram'></i> <a href="mailto:achagamonline@gmail.com">achagamonline@gmail.com</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-3">
                    <div class="footer-logo">
                        <img width="150px" src="assets/img/footerlogo.svg" alt="Footer Logo">
                    </div>
                </div>
                <div class="col-lg-8 col-md-9">
                    <div class="bottom-text pt-20">
                        <p>©2020 Achagam</p>
                        <!-- <ul class="social-bottom">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>