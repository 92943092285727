<app-navbar-style-one></app-navbar-style-one>
<section class="register-page section-b-space">
    <div class="container">
        <div class="row mt-50">
            <button class="default-btn-post_jobs" [routerLink]="['/createquote']">Create Quote</button>
        </div>
        <div class="row mt-20">
            <div class="col-sm-8">
                <table class="table">
                    <tbody *ngIf="quote_detail">
                        <tr>
                            <td>Quotation Number</td>
                            <td><b>{{quote_detail.quote_no}}</b></td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type">
                            <td>Category Type</td>
                            <td>{{quote_detail.category_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.job_name">
                            <td>Job Name</td>
                            <td>{{quote_detail.job_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.size_name">
                            <td>Size</td>
                            <td>{{quote_detail.size_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.no_of_bills != '' && quote_detail.category_type == 'BB'">
                            <td>No Of Sets per Book</td>
                            <td>{{quote_detail.no_of_bills}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.pk_sheet_id != '' && quote_detail.category_type == 'BB'">
                            <td>No Of Sheets per Set</td>
                            <td>
                                <span *ngIf="quote_detail.pk_sheet_id == 1">Single</span>
                                <span *ngIf="quote_detail.pk_sheet_id == 2">Duplicate</span>
                                <span *ngIf="quote_detail.pk_sheet_id == 3">Triplicate</span>
                                <span *ngIf="quote_detail.pk_sheet_id == 4">Quadruplicate</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.paper1 != 0 && quote_detail.category_type == 'BB'">
                            <td>
                                <span *ngIf="quote_detail.pk_sheet_id == 1">Paper</span>
                                <span *ngIf="quote_detail.pk_sheet_id > 1">Paper 1</span>
                            </td>
                            <td>{{quote_detail.paper_one_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.paper2 != 0 && quote_detail.category_type == 'BB' && quote_detail.pk_sheet_id > 1">
                            <td>Paper 2</td>
                            <td>{{quote_detail.paper_two_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.paper3 != 0 && quote_detail.category_type == 'BB' && quote_detail.pk_sheet_id > 2">
                            <td>Paper 3</td>
                            <td>{{quote_detail.paper_three_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.paper4 != 0 && quote_detail.category_type == 'BB' && quote_detail.pk_sheet_id > 3">
                            <td>Paper 4</td>
                            <td>{{quote_detail.paper_four_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.paper5 != 0 && quote_detail.category_type == 'BB' && quote_detail.pk_sheet_id > 4">
                            <td>Paper 4</td>
                            <td>{{quote_detail.paper_four_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.paper != 0 && (quote_detail.category_type == 'BN' || quote_detail.category_type == 'LH') ">
                            <td>Paper</td>
                            <td>{{quote_detail.paper_name}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.front_colours">
                            <td>Printing Colours Front Side</td>
                            <td>{{quote_detail.front_colours}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.back_colours">
                            <td>Printing Colours Back Side</td>
                            <td>{{quote_detail.back_colours}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.lamination != '' && quote_detail.category_type == 'BN' ">
                            <td>Lamination</td>
                            <td>
                                <span *ngIf="quote_detail.lamination == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.lamination == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.lamination_type != '' && quote_detail.category_type == 'BN' ">
                            <td>Lamination Type</td>
                            <td>
                                <span *ngIf="quote_detail.lamination_type == 'N'">None</span>
                                <span *ngIf="quote_detail.lamination_type == 'M'">Matt</span>
                                <span *ngIf="quote_detail.lamination_type == 'G'">Glossy</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.lamination_side != '' && quote_detail.lamination_side != 0 && quote_detail.category_type == 'BN' ">
                            <td>Lamination Side</td>
                            <td>{{quote_detail.lamination_side}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.pk_bb_id != '' && quote_detail.category_type == 'BB'" >
                            <td>No Of Bills Per Page</td>
                            <td>{{quote_detail.no_of_bills_per_page}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.numbering != '' && quote_detail.category_type == 'BB' && quote_detail.numbering != 'N'" >
                            <td>Numbering</td>
                            <td>
                                <span *ngIf="quote_detail.numbering == 'M'">Manual</span>
                                <span *ngIf="quote_detail.numbering == 'MA'">Machine</span>
                                <span *ngIf="quote_detail.numbering == 'N'">None</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.bitnotice_numbering != '' && quote_detail.category_type == 'BN' ">
                            <td>Numbering</td>
                            <td>
                                <span *ngIf="quote_detail.bitnotice_numbering == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.bitnotice_numbering == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="(quote_detail.category_type == 'BN' || quote_detail.category_type == 'BB') && quote_detail.pnumber_id != '' && (quote_detail.bitnotice_numbering == 'Y' || quote_detail.numbering != 'N' )">
                            <td>No of Places for Numbering in a Bill</td>
                            <td>{{quote_detail.no_of_places_for_numbering}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.perforation != '' && quote_detail.category_type == 'BB' && quote_detail.perforation != 'N'">
                            <td>Perforation</td>
                            <td>
                                <span *ngIf="quote_detail.perforation == 'M'">Manual</span>
                                <span *ngIf="quote_detail.perforation == 'MA'">Machine</span>
                                <span *ngIf="quote_detail.perforation == 'N'">None</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.bitnotice_perforation != '' && quote_detail.category_type == 'BN' ">
                            <td>Perforation</td>
                            <td>
                                <span *ngIf="quote_detail.bitnotice_perforation == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.bitnotice_perforation == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.folding != '' && quote_detail.category_type == 'BN' ">
                            <td>Folding</td>
                            <td>
                                <span *ngIf="quote_detail.folding == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.folding == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.package_details > 0 && quote_detail.category_type == 'BN' ">
                            <td>Packing Details</td>
                            <td>{{quote_detail.packing}}</td>
                        </tr>

                        
                        <tr *ngIf="quote_detail.binding != '' && quote_detail.category_type == 'BB'" >
                            <td>Binding</td>
                            <td>
                                <span *ngIf="quote_detail.binding == 1">Pad Binding</span>
                                <span *ngIf="quote_detail.binding == 2">Soft Binding</span>
                                <span *ngIf="quote_detail.binding == 3">Hard Binding</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.foil != ''">
                            <td>Gold or Silver Foil</td>
                            <td>
                                <span *ngIf="quote_detail.foil == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.foil == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.foil == 'Y'">
                            <td>Gold or Silver Foil length</td>
                            <td>{{quote_detail.foillength}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.foil == 'Y'">
                            <td>Gold or Silver Foil Width</td>
                            <td>{{quote_detail.foilwidth}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.embossing != ''">
                            <td>Embossing</td>
                            <td>
                                <span *ngIf="quote_detail.embossing == 'Y'">Yes</span>
                                <span *ngIf="quote_detail.embossing == 'N'">No</span>
                            </td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.embossing == 'Y'">
                            <td>Embossing length</td>
                            <td>{{quote_detail.embossinglength}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.category_type == 'LH' && quote_detail.embossing == 'Y'">
                            <td>Embossing Width</td>
                            <td>{{quote_detail.embossingwidth}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.pk_letter_head_pack_id > 0 && quote_detail.category_type == 'LH' ">
                            <td>Packing Details</td>
                            <td>{{quote_detail.letterheadpacking}}</td>
                        </tr>
                        <tr *ngIf="quote_detail.quantity" >
                            <td>Quantity</td>
                            <td>{{quote_detail.quantity}}</td>
                        </tr>
                        <tr>
                            <td><b>Total Amount</b></td>
                            <td><b>Rs. {{quote_detail.total_amount}}</b></td>
                        </tr>
                    </tbody>
                </table>
                <div class="text-center">
                    <button class="btn btn-lg btn-outline-primary" href="javascript:void(0)" routerLink="/quote">Back</button>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-one></app-footer-style-one>