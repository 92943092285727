<app-navbar-style-one></app-navbar-style-one>
<section>
    <div class="container">
        <div class="row">
            <div class="col-4 selectbox">
                <select class="custom-select" name="Job Type" [(ngModel)]="pk_career_id" (change)="filteredpostjobs_get($event.target.value)">
                    <option value="disabled">Select</option>
                    <option value="{{title.pk_career_id}}" *ngFor="let title of careertitlelist">
                        {{title.career_title}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center paddingTop20px" *ngIf="jobwanted == ''">No data Found</div> -->
            <div class="col-12" *ngIf="jobwanted != ''">
                    <h2>Job Wanted</h2>
            </div>
            <div class="col-4" *ngFor="let jobdetails of jobwanted;">
                <div class="col-12 boxShape">
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Career Title</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.career_title}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Name</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.name}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Required Experience</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.requiredexp}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Salary</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.salary}}</p>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span></span>Designation</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.designation}}</p>
                    </div> -->
                    <!-- <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Description</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.description}}</p>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Address</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.address}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>mobilenumber</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.mobile}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Email</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.email_id}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <!-- <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center paddingTop20px" *ngIf="joboffered == ''">No data Found</div> -->
            <div class="col-12" *ngIf="joboffered != ''">
                    <h2>Job Offered</h2>
            </div>
            <div class="col-4" *ngFor="let jobdetails of joboffered;">
                <div class="col-12 boxShape">
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Career Title</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.career_title}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Name</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.name}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Required Experience</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.requiredexp}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Salary</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.salary}}</p>
                    </div>
                    <!-- <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span></span>Designation</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.designation}}</p>
                    </div> -->
                    <!-- <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Description</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.description}}</p>
                    </div> -->
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Address</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.address}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>mobilenumber</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.mobile}}</p>
                    </div>
                    <div class="form-group row">
                        <label class="col-xl-4 col-md-3 paddingTop10px" style="font-weight: 600;"><span> </span>Email</label>
                        <p class="col-xl-8 col-md-9">{{jobdetails.email_id}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section>
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center paddingTop20px" *ngIf="postjoblist == ''">No data Found</div>
        </div>
    </div>
</section>
<app-footer-style-one></app-footer-style-one>
