import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/services/common.service';
@Component({
  selector: 'app-home-page-three',
  templateUrl: './home-page-three.component.html',
  styleUrls: ['./home-page-three.component.scss']
})
export class HomePageThreeComponent implements OnInit {

  public products:any = [];
  public count: any = '';
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts(){
    const formData = new FormData();
    formData.append('cat', '');
    formData.append('pg', '1');
    this.commonService.getProducts(formData).subscribe((response: any) => {
      if (response.status) {
        this.products = response.data;
        this.count = response.count;
      }
    });
  }

}
