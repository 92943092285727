import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonService } from '../../common/services/common.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public contactForm: FormGroup;
  public submitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  get p() { return this.contactForm.controls; }

  sendContact(){
    this.submitted = true;
    if(this.contactForm.valid){
      const formData = new FormData();
      formData.append('name' , this.contactForm.value.name);
      formData.append('email' , this.contactForm.value.email);
      formData.append('phone_number' , this.contactForm.value.phone_number);
      formData.append('msg_subject' , this.contactForm.value.msg_subject);
      formData.append('message' , this.contactForm.value.message);
      this.commonService.sendContactInformation(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.contactForm.get('name').setValue("");
          this.contactForm.get('email').setValue("");
          this.contactForm.get('phone_number').setValue("");
          this.contactForm.get('msg_subject').setValue("");
          this.contactForm.get('message').setValue("");
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        }
      });
    }
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.required],
      phone_number: [null, Validators.required],
      msg_subject: [null, Validators.required],
      message: [null, Validators.required],
    });
  }

}
