<app-navbar-style-one></app-navbar-style-one>
<div class="container border-radius">
    <div class="home-slider owl-carousel owl-theme box_shadow">
        <div class="slider-item cp-item1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="slider-content">
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item5">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="inner-banner inner-bg8">
    <div class="container">
        <div class="inner-title">
            
            <h3>Category</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Category</li>
            </ul>
        </div>
    </div>
</div> -->

<section class="service-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Category</span>
        </div>
        <div class="row pt-45">
            <ng-container *ngFor="let category of subCategories">
                <div class="col-lg-4 col-md-6 p-30 outline_none" routerLink="/product/{{category.category_id}}">
                    <div class="service-card height600px">
                        <div class="container resize">
                            <a class="text-center" >
                                <img class="imageRadius imgHeightwid" *ngIf="category.cat_image == ''" src="{{imagelink+category.cat_image}}" alt="Service Images"><img *ngIf="category.cat_image != ''" src="{{imagelink+category.cat_image}}" alt="Service Images">
                            </a>
                        </div>
                        <div class="service-content height285">
                            <h5>{{category.category_name}} {{category.category_code?'('+category.category_code+')':''}}</h5>
                            <div class="divClass" [innerHtml]="category.long_description"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</section>

<app-footer-style-one></app-footer-style-one>