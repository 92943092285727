import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../../../common/services/common.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public quotes: any = [];
  constructor(private router: Router,private commonService: CommonService) { 
    // if(this.user_type == 'C'){
    //   this.router.navigate(['']);
    // }
  }

  getCustomerQuotes(){
    this.commonService.getCustomerQuotes(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.quotes = response.data;
      }else{
        this.quotes = [];
      }
    });
  }

  deleteQuote(pk_quote_id: any){
    this.commonService.deleteQuote(pk_quote_id).subscribe((response: any) => {
      if (response.status) {
        this.getCustomerQuotes();
      }
    });
  }

  ngOnInit(): void {
    this.getCustomerQuotes();
  }

}