import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
declare var $:any;

@Component({
  selector: 'app-quote-summary-view',
  templateUrl: './quote-summary-view.component.html',
  styleUrls: ['./quote-summary-view.component.scss']
})
export class QuoteSummaryViewComponent implements OnInit {

  public user_id = localStorage.getItem('userId');
  public imagelink: any = environment.imageUrl;
  public images: any = [];
  public cus_quote_id : any = '';
  public product_name: any = '';
  public category_name: any = '';
  public job_name: any = '';
  public prod_cat_id: any = '';
  public product_code: any = '';
  public image_large_details: any ='';
  public description: any = '';
  public brief_details: any = '';
  public size_name: any = '';
  public binding:any = '';
  public binding_type: any = '';
  public paper_name: any = '';
  public paper_one_name: any = '';
  public paper_two_name: any = '';
  public paper_three_name: any = '';
  public paper_four_name: any = '';
  public paper_five_name: any = '';
  public user_type = localStorage.getItem('user_type');

  public paper_colour_name: any = '';
  public paper_one_colour_name: any = '';
  public paper_two_colour_name: any = '';
  public paper_three_colour_name: any = '';
  public paper_four_colour_name: any = '';
  public paper_five_colour_name: any = '';
  public printing_colours_front: any = '';
  public printing_colours_back: any = '';
  public is_same_content: any = '';
  public display_clear_number:any = '';
  public numbering:any = '';
  public is_numbering: any = '';
  public display_perforation:any = '';
  public perforation: any = '';
  public bitnotice_numbering: any = '';
  public bitnotice_perforation: any ='';
  public quantity: any = '';
  public no_of_bills: any = '';
  public no_of_bills_per_page: any = '';
  public front_colours: any = '';
  public back_colours: any = '';
  public prod_status: any = '';
  public frontfileimage: any = '';
  public backimagefile: any = '';
  public pk_sheet_id: any = '';
  public gst: any = '';
  public sub_total_amount: any = '';
  public total_amount: any = '';
  public numbering_start_from: any = '';
  public prod_detail: any = []; 
  public lamination_type: any = '';
  public lamination_side: any = '';
  public folding: any = '';
  public pnumber_id: any = '';
  public no_of_places_for_numbering: any = '';
  public image_path_with_name: any = '';
  public original_image_name: any = '';
  public letterheadpacking: any = '';
  public disablePaper2: boolean = false;
  public disablePaper3: boolean = false;
  public disablePaper4: boolean = false;
  public disablePaper5: boolean = false;
  public bitnoticepacking:any = '';  
  
  public checkpaper1:any = '';
  public checkpaper2:any = '';
  public checkpaper3:any = '';
  public checkpaper4:any = '';
  public checkpaper5:any = '';
  public book_copy_status:any = '';

  public original_sub_total_amount:any = '';
  public original_tax_amount:any = '';
  public original_total_amount:any = '';
  public taxable_amount: any = '';
  public discount_amount: any = '';

  constructor(private Router: Router, private route: ActivatedRoute, private commonService: CommonService) {
    route.params.forEach(params=> {
      if(params.id) {
        this.cus_quote_id = params.id;
        this.getQuoteSummaryDetails();
      }
    });
   }

  ngOnInit(): void {
  }

  getQuoteSummaryDetails(){
    this.commonService.getCustomerQuoteDetails(this.cus_quote_id, this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.prod_detail = response.data;
        this.pk_sheet_id = this.prod_detail.pk_sheet_id;
        this.images = this.prod_detail.images;
        this.bitnoticepacking = this.prod_detail.bitnoticepacking;
        if(this.prod_detail.paper_one_carbon == 'Y'){
          if(this.prod_detail.pk_sheet_id == 2){
            this.disablePaper2 = false;
            this.disablePaper3 = false;
            this.disablePaper4 = false;
            this.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 3){
            this.disablePaper2 = true;
            this.disablePaper3 = false;
            this.disablePaper4 = false;
            this.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 4){
            this.disablePaper2 = true;
            this.disablePaper3 = true;
            this.disablePaper4 = false;
            this.disablePaper5 = true;
          }else if(this.prod_detail.pk_sheet_id == 5){
            this.disablePaper2 = true;
            this.disablePaper3 = true;
            this.disablePaper4 = true;
            this.disablePaper5 = true;
          }
        }else{
          if(this.prod_detail.pk_sheet_id == 2){
            this.disablePaper2 = true;
            this.disablePaper3 = false;
            this.disablePaper4 = false;
            this.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 3){
            this.disablePaper2 = true;
            this.disablePaper3 = true;
            this.disablePaper4 = false;
            this.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 4){
            this.disablePaper2 = true;
            this.disablePaper3 = true;
            this.disablePaper4 = true;
            this.disablePaper5 = false;
          }else if(this.prod_detail.pk_sheet_id == 5){
            this.disablePaper2 = true;
            this.disablePaper3 = true;
            this.disablePaper4 = true;
            this.disablePaper5 = true;
          }
        }

        this.product_name = this.prod_detail.job_name;
        this.category_name = this.prod_detail.category_name;
        this.prod_cat_id = this.prod_detail.category_type;
        this.product_code = this.prod_detail.quote_no;
        this.image_large_details = this.prod_detail.image_large_details;
        this.description = this.prod_detail.description;
        this.brief_details = this.prod_detail.product_details;
        this.size_name = this.prod_detail.size_name;
        this.binding_type = this.prod_detail.binding_type;
        this.paper_one_name = this.prod_detail.paper_one_name;
        this.paper_two_name = this.prod_detail.paper_two_name;
        this.paper_three_name = this.prod_detail.paper_three_name;
        this.paper_four_name = this.prod_detail.paper_four_name;
        this.paper_five_name = this.prod_detail.paper_five_name;
        this.paper_name = this.prod_detail.paper_name;
        this.letterheadpacking = this.prod_detail.letterheadpacking;
        this.paper_one_colour_name = this.prod_detail.paper_one_colour_name;
        this.paper_two_colour_name = this.prod_detail.paper_two_colour_name;
        this.paper_three_colour_name = this.prod_detail.paper_three_colour_name;
        this.paper_four_colour_name = this.prod_detail.paper_four_colour_name;
        this.paper_five_colour_name = this.prod_detail.paper_five_colour_name;
        this.paper_colour_name = this.prod_detail.paper_colour_name;

        this.printing_colours_front = this.prod_detail.printing_colours_front;
        this.printing_colours_back = this.prod_detail.printing_colours_back;
        this.is_same_content = this.prod_detail.is_same_content;
        this.binding = this.prod_detail.binding;
        this.lamination_type = this.prod_detail.lamination_type;
        this.lamination_side = this.prod_detail.lamination_side;
        this.numbering = this.prod_detail.numbering;
        this.is_numbering = this.prod_detail.is_numbering;
        this.pnumber_id = this.prod_detail.pnumber_id;
        this.no_of_places_for_numbering = this.prod_detail.no_of_places_for_numbering;
        this.bitnotice_numbering = this.prod_detail.bitnotice_numbering;
        this.bitnotice_perforation = this.prod_detail.bitnotice_perforation;
        this.display_perforation = this.prod_detail.display_perforation;
        this.perforation = this.prod_detail.perforation;
        this.folding = this.prod_detail.folding;
        this.quantity = this.prod_detail.quantity;
        this.display_clear_number = this.prod_detail.display_clear_number;
        this.no_of_bills = this.prod_detail.no_of_bills;
        this.no_of_bills_per_page = this.prod_detail.no_of_bills_per_page;
        this.front_colours = this.prod_detail.front_colours;
        this.back_colours = this.prod_detail.back_colours;
        this.prod_status = this.prod_detail.verify;
        this.frontfileimage = this.prod_detail.frontfileimage;
        this.backimagefile = this.prod_detail.backimagefile;

        this.sub_total_amount = this.prod_detail.sub_total_amount;
        this.gst = this.prod_detail.gst;
        this.total_amount = this.prod_detail.total_amount;
        this.numbering_start_from = this.prod_detail.numbering_start_from;
        this.image_path_with_name = this.prod_detail.image_path_with_name;
        this.original_image_name = this.prod_detail.original_image_name;

        this.checkpaper1 = this.prod_detail.checkpaper1;
        this.checkpaper2 = this.prod_detail.checkpaper2;
        this.checkpaper3 = this.prod_detail.checkpaper3;
        this.checkpaper4 = this.prod_detail.checkpaper4;
        this.checkpaper5 = this.prod_detail.checkpaper5;
        this.book_copy_status = this.prod_detail.book_copy_status;

        this.original_sub_total_amount = this.prod_detail.original_sub_total_amount;
        this.original_tax_amount = this.prod_detail.original_tax_amount;
        this.original_total_amount = this.prod_detail.original_total_amount;
        this.taxable_amount = this.prod_detail.taxable_amount;
        this.discount_amount = this.prod_detail.discount_amount;

      }
    });
  }

  saveAndSendToWhishlist(){
    const formData = new FormData();
    formData.append('user_id' , this.user_id);
    formData.append('pk_quote_id', this.cus_quote_id);
    $('.preloader').fadeIn('slow');
    this.commonService.confirmQuoteSummary(formData).subscribe((response:any) => {
      if (response.status) {
        this.Router.navigate(['/product-summary-view/'+response.pk_product_id]);
      }else{
        $('.preloader').fadeOut('slow');
      }
    });
    
  }

  saveToCart(){
    const formData = new FormData();
    formData.append('user_id' , this.user_id);
    formData.append('data', JSON.stringify(this.prod_detail));
    $('.preloader').fadeIn('slow');
    this.commonService.addToCartFromQuote(formData).subscribe((response:any) => {
      if (response.status) {
        this.Router.navigate(['/cart']);
      }
    });
  }

  removeFromAt(name: any){
    if(name.includes("(")){
      var name = name.substr(0, name.indexOf('('));
    }
    if(name.includes("@")){
      var name = name.substr(0, name.indexOf('@'));
    }
    return name;
  }

}
