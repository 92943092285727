import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/services/common.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-home-page-one',
  templateUrl: './home-page-one.component.html',
  styleUrls: ['./home-page-one.component.scss']
})
export class HomePageOneComponent implements OnInit {

  public products:any = [];
  public count: any = '';
  public slider: any = [];
  public imagelink: any = environment.imageUrl;
  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getProducts();
    this.getSlider();
  }

  getProducts(){
    const formData = new FormData();
    formData.append('cat', '');
    formData.append('pg', '1');
    this.commonService.getProducts(formData).subscribe((response: any) => {
      if (response.status) {
        this.products = response.data;
        this.count = response.count;
      }
    });
  }
  getSlider(){
      this.commonService.getSlider().subscribe((response: any) => {
        if (response.status) {
          this.slider = response.data;
        }
      })
  }

}
