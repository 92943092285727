<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner inner-bg5">
    <div class="container">
        <div class="inner-title">
            <h3>Checkout</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Checkout</li>
            </ul>
        </div>
    </div>
</div> -->
<section class="register-page pb-20">
    <div class="container">
        <div class="col-lg-12 col-md-12">
            <ng-template #content let-modal >
                <div class="col-lg-12 billing-details">
                    <div class="row marginTop10">
                        <div class="col-6">
                            <h3>Address</h3>
                        </div>
                        <div class="col-6">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="theme-card">
                        <div class="card-body tab2-card">
                            <div class="paddingTop20px">
                                <form [formGroup]="addAddressForm" class="needs-validation" novalidate="" >
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Street 1</label>
                                        <input class="form-control col-xl-8 col-md-5"  [ngClass]="{ 'is-invalid': submitted && p.street_1.errors }" formControlName="street_1"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">    
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Street 2</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.street_2.errors }" formControlName="street_2"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Area</label>
                                        <input class="form-control col-xl-8 col-md-5"[ngClass]="{ 'is-invalid': submitted && p.area.errors }" formControlName="area"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Pincode</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.pincode.errors }" formControlName="pincode"
                                            type="number" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>City</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.city.errors }" formControlName="city" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>State</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.state.errors }" formControlName="state" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Country</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.country.errors }" formControlName="country" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row" *ngIf="showaddresstypebox">
                                        <label class="col-xl-4 col-md-3 label_align">Address Type</label>
                                        <select (change)="showcheckbox($event.target.value)" class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.address_type.errors }" formControlName="address_type">
                                            <option value="disabled">Select Address Type</option>
                                            <option value="B">Billing Address</option>
                                            <option value="S">Shipping Address</option>
                                            <option value="H">Both</option>
                                        </select>
                                    </div>
                                        <div class="show_billing" *ngIf="showbillingaddressbox">
                                            <div class="form-group row">
                                                <input type="checkbox"  name="default_billing" value="" title="Use as My Default Billing Address" class="checkbox" formControlName="is_default_billingaddress">
                                                <label>Use as my default billing address</label>
                                            </div> 
                                        </div>
                                        <div class="show_shipping" *ngIf="showshippingaddressbox">
                                            <div class="form-group row">
                                                <input type="checkbox" name="default_shipping"  value="" title="Use as my default shipping address" class="checkbox" formControlName="is_default_shippingaddress">
                                                <label>Use as my default shipping address</label>
                                            </div>
                                        </div>                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-post_jobs" (click)="saveaddress();">Save</button>
                        <button type="button" class="default-btn-post_jobs" (click)="modal.dismiss('Cross click');">Cancel</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>

<section class="checkout-area pt-10 pb-10">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="order-table table-responsive">
                    <div class="row">
                        <h3 class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">Checkout Details <span class="days_color fontsz"> ({{order.confirm_summary_end_date?" Valid upto "+(order.confirm_summary_end_date | date: 'dd/MM/yyyy'):'' }})</span></h3>
                        <h6 class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 text-right">Expected Delivery Date {{order.shipping_date}}</h6>
                    </div>
                    
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Product Name</th>
                                <th scope="col">Quantity</th>
                                <th scope="col" class="text-right" >Price <br>(Rs)</th>
                                <th scope="col" class="text-right">Total <br>Price<br>(Rs)</th>
                                <th scope="col" class="text-right" *ngIf="user_type == 'P'">Discount <br>Amount<br>({{plan_percentage}}%)</th>
                                <th scope="col" class="text-right" *ngIf="user_type == 'P'">Total <br>Amount <br>(Rs)</th>
                                <th scope="col" class="text-right">Tax <br>(%)</th>
                                <th scope="col" class="text-right">Total Taxable <br>Amount<br>(Rs)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let pro of products">
                                <td class="product-name">
                                    <a routerLink="/product-details/{{pro.product_id}}">{{pro.product_name}} </a>
                                </td>
                                <td class="product-name">
                                    <span>{{pro.quantity}}</span>
                                </td>
                                <td class="product-name text-right">
                                    <span><a>{{pro.rate}}</a></span>
                                </td>
                                <td class="product-name text-right">
                                    <span>{{pro.taxable_amount}}</span>
                                </td>
                                <td class="product-name text-right" *ngIf="user_type == 'P'">
                                    <span>{{pro.discount}}</span>
                                </td>
                                <td class="product-name text-right" *ngIf="user_type == 'P'">
                                    <span>{{calculateTotalAmount(pro.taxable_amount, pro.discount) }}</span>
                                </td>
                                <td class="product-name text-right">
                                    <span>{{pro.gst}}</span>
                                </td>
                                <td class="product-total text-right">
                                    <span class="subtotal-amount">{{pro.total_price}}</span>
                                </td>
                            </tr>
                            <tr >
                                <td class="border_none"></td>
                                <td class="border_none"></td>
                                <td class="border_none" *ngIf="user_type == 'P'"></td>
                                <td class="border_none" *ngIf="user_type == 'P'"></td>  
                                <td class="border_none"></td>
                                <td colspan="2" class="order-subtotal text-right">
                                    <span>Order total</span>
                                </td>
                                <td class="order-subtotal-price text-right">
                                    <span class="order-subtotal-amount">Rs {{order.total_amount}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            
            <div class="col-xl-5 col-lg-5 col-md-4 col-sm-4 col-12">
                <div class="billing-details">
                    <!-- <button class="default-btn-post_jobs" (click)="open(content)">Add New Address</button> -->
                    <h5 class="title padding_top_20px">Billing Details</h5>
                    <label class="cursor_pointer" (click)="open(content)"> + Add Billing Address</label>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Billing Address <span class="required">*</span></label>
                                <div class="select-box">
                                    <select (change)="showbillingoption($event.target.value)"  class="form-control" [(ngModel)]="order.billing_address" [ngClass]="{ 'is-invalid': ordersubmitted && error.billingaddress}" >
                                        <option value="">Select Billing Address</option>
                                        <ng-container *ngFor="let billing of billingAddress">
                                            <option value="{{billing.address_id}}">{{billing.street_1}} {{billing.street_2}} {{billing.area}} {{billing.city}} {{billing.pincode}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row" *ngIf="showBillingDetailsBox">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 styleshow">{{order.customer_name}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{billingAddressshow.street_1}}, {{billingAddressshow.street_2}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{billingAddressshow.area}}, {{billingAddressshow.city}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{billingAddressshow.state}}, {{billingAddressshow.country}}, {{billingAddressshow.pincode}}</div>
                            </div>
                            <div class="form-group">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="billing-details">
                    <h5 class="title">Shipping Details</h5>
                    <label class="cursor_pointer" (click)="open(content)"> + Add Shipping Address</label>
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <label>Shipping Address <span class="required">*</span></label>
                                <div class="select-box">
                                    <select (change)="showshippingoption($event.target.value)" class="form-control" [(ngModel)]="order.shipping_address" [ngClass]="{ 'is-invalid': ordersubmitted &&  error.shippingaddress}" >
                                        <option value="">Select Shipping Address</option>
                                        <ng-container *ngFor="let shipping of shippingAddress">
                                            <option value="{{shipping.address_id}}">{{shipping.street_1}} {{shipping.street_2}} {{shipping.area}} {{shipping.city}} {{shipping.pincode}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row" *ngIf="showShippingDetailsBox">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 styleshow">{{order.customer_name}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{shippingAddressshow.street_1}}, {{shippingAddressshow.street_2}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{shippingAddressshow.area}}, {{shippingAddressshow.city}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 weight">{{shippingAddressshow.state}}, {{shippingAddressshow.country}}, {{shippingAddressshow.pincode}}</div>
                            </div>
                            <div class="form-group">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-xl-7 col-lg-7 col-md-8 col-sm-8 col-12">
                <div class="order-details">
                    <div class="payment-box">
                        <h5 class="title padding_top_20px">Payment Method</h5>
                        <div class="payment-method">
                            <p class="col-xl-12 float_left">
                                <input type="checkbox" (click)="checkbox_show($event.target.checked)" style="width:4%;">
                                <label>Pay From My Wallet<span class="required"></span></label>
                            </p>
                            <p *ngIf="showwalletbox" class="col-xl-12 float_left">
                                <input [(ngModel)]="paid_wallet_amt" [ngClass]="{ 'is-invalid': ordersubmitted &&  error.paid_wallet_amt}" id="wallet_enter_amt" (keyup)="returnNumber()" type="number" min="0" step="0.01" placeholder="Enter Your Wallet Amount" class="form-control" />
                            </p>
                            <!-- <p *ngIf="showwalletbox">Your Balance Wallet Amount: {{user_wallet_amt.wallet_amount}}</p> -->
                           
                            <p *ngIf="user_wallet_amt" class="col-xl-12 float_left">
                                <input type="checkbox" (click)="checkbox_credit_acc($event.target.checked)" style="width:4%;">
                                <label>Pay From My Credit Account<span class="required"></span></label>
                            </p>
                            <p *ngIf="showcreditbox" class="col-xl-12 float_left">
                                <input [(ngModel)]="paid_credit_amt" [ngClass]="{ 'is-invalid': ordersubmitted &&  error.paid_credit_amt}" id="credit_enter_amt" (keyup)="returnNum()" type="number" min="0" step="0.01" placeholder="Enter Your Credit Amount" class="form-control" />
                            </p>
                            <!-- <p *ngIf="showcreditbox">Your Balance Credit Amount: {{user_wallet_amt.credit_limit}}</p> -->

                            <p class="col-xl-12 float_left">
                                <input type="radio" id="direct-bank-transfer" name="radio-group" (change)="checkpaymentmethod()" [(ngModel)]="payment_meth" value='D' [checked]="true">
                                <label for="direct-bank-transfer">Direct Bank Transfer</label>
                                <span class="padding_left_30px float_left">
                                    Make your payment directly into our bank account. Please use your Order
                                ID as the payment reference. Your order will not be shipped until the funds have cleared in our account.<br>
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <td>Account Name</td>
                                                <td>SVG PRINTS</td>
                                            </tr>
                                            <tr>
                                                <td>Account No</td>
                                                <td>65257728524</td>
                                            </tr>
                                            <tr>
                                                <td>IFSC Code</td>
                                                <td>SBIN0030284</td>
                                            </tr>
                                            <tr>
                                                <td>Bank Name</td>
                                                <td>STATE BANK OF INDIA</td>
                                            </tr>
                                            <tr>
                                                <td>Branch Name</td>
                                                <td>Royapettah</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </p>
                            <p class="col-xl-12 float_left">
                                <input type="radio" id="cash-on-delivery" name="radio-group" (change)="checkpaymentmethod()" [(ngModel)]="payment_meth" value='C'>
                                <label for="cash-on-delivery">Cash On Delivery - <span style="font-style: 13px;color: #8d8d90;font-weight: normal;">*condition apply</span></label>
                            </p>
                            <small class="text-danger">{{confirimmsg}}</small>
                            <small class="text-danger">{{creditmmsg}}</small>
                        </div>
                        <a href="javascript:void(0)" (click)="placeorder()" class="default-btn three">Place to Order</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>