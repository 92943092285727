import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})

export class AddressComponent implements OnInit {

  closeResult = '';
  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public address_id: any = "";
  public address_detail: any = [];
  public defaultBillingAddress: any = [];
  public defaultShippingAddress: any = [];
  public defaultAdditionalAddress: any = [];
  public editBillingAddress: any = [];
  public editShippingAddress: any = [];

  public submitted: boolean = false;
  public addAddressForm: FormGroup;
  public showmodal: boolean = false;
  public closemodal: boolean = false;

  public showaddresstypebox: boolean = true;
  public showbillingaddressbox: boolean = false;
  public showshippingaddressbox: boolean = false;
  



  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router) { this.addAddressForm = this.fb.group({
      street_1: ['', Validators.required],
      street_2: ['', Validators.required],
      area: ['', Validators.required],
      pincode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      is_default_billingaddress: [''],
      is_default_shippingaddress: [''],
      address_type: [''],
      address_id: [''],
    }) }

  showcheckbox(showbox) {
        if (showbox == "H") {
          this.showbillingaddressbox = true;
          this.showshippingaddressbox = true;
        } else if(showbox == "B") {
          this.showbillingaddressbox = true;
          this.showshippingaddressbox = false;
        } else if(showbox == "S") {
          this.showbillingaddressbox = false;
          this.showshippingaddressbox = true;
        } 

      } 
      
  

  ngOnInit(): void {
    this.getUserAddress();
  }

  getUserAddress(){
    this.defaultBillingAddress = [];
    this.defaultShippingAddress = [];
    this.editBillingAddress = [];
    this.editShippingAddress = [];

    this.commonService.getUserAddress(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.address_detail = response.data;
        const lastBackCOlours = this.address_detail.filter((data) => {
          if (data.is_default_address == 'Y' && data.address_type == 'B') {
            this.defaultBillingAddress.push(data);
          }
          else if (data.is_default_address == 'Y' && data.address_type == 'S') {
            this.defaultShippingAddress.push(data);
          }else if(data.is_default_address != 'Y' && data.address_type == 'B'){
            this.editBillingAddress.push(data);
          }else if(data.is_default_address != 'Y' && data.address_type == 'S'){
            this.editShippingAddress.push(data);
          }        
        });
      }
    });
  }

  get p() { return this.addAddressForm.controls; }


  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  saveaddress() {    
    this.submitted = true;
    if (this.addAddressForm.valid) {
      const formData = new FormData();
      formData.append('address_id', this.addAddressForm.get('address_id').value);
      formData.append('street_1', this.addAddressForm.get('street_1').value);
      formData.append('street_2', this.addAddressForm.get('street_2').value);
      formData.append('area', this.addAddressForm.get('area').value);
      formData.append('pincode', this.addAddressForm.get('pincode').value);
      formData.append('city', this.addAddressForm.get('city').value);
      formData.append('state', this.addAddressForm.get('state').value);
      formData.append('country', this.addAddressForm.get('country').value);
      formData.append('address_type', this.addAddressForm.get('address_type').value);
      formData.append('is_default_billingaddress',this.addAddressForm.get('is_default_billingaddress').value?'Y':'N') ;
      formData.append('is_default_shippingaddress', this.addAddressForm.get('is_default_shippingaddress').value?'Y':'N');
      formData.append('customer_id', localStorage.getItem('userId'));

      this.commonService.saveaddress(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.addAddressForm.get('address_id').setValue("");
          this.addAddressForm.get('street_1').setValue("");
          this.addAddressForm.get('street_2').setValue("");
          this.addAddressForm.get('area').setValue("");
          this.addAddressForm.get('pincode').setValue("");
          this.addAddressForm.get('city').setValue("");
          this.addAddressForm.get('state').patchValue("");
          this.addAddressForm.get('country').setValue("");
          this.addAddressForm.get('address_type').setValue("");
          this.addAddressForm.get('is_default_billingaddress').setValue("");
          this.addAddressForm.get('is_default_shippingaddress').setValue("");

          this.showaddresstypebox = true;
          this.showbillingaddressbox = false;
          this.showshippingaddressbox = false;
          this.getUserAddress();
          this.modalService.dismissAll("click");
        } else {
          console.log(response);
        }
      });
    }
    else{
      console.log("form Invalid");
    }
  }

  editaddress(address_id){
    this.commonService.editaddress(address_id).subscribe((response: any) => {
      if (response.status) {
          let getaddress: any;
          if(response.status) {
            getaddress = response['data'];
            this.addAddressForm.get('address_id').setValue(getaddress.address_id);
            this.addAddressForm.get('street_1').setValue(getaddress.street_1);
            this.addAddressForm.get('street_2').setValue(getaddress.street_2);
            this.addAddressForm.get('area').setValue(getaddress.area);
            this.addAddressForm.get('pincode').setValue(getaddress.pincode);
            this.addAddressForm.get('city').setValue(getaddress.city);
            this.addAddressForm.get('state').setValue(getaddress.state);
            this.addAddressForm.get('country').setValue(getaddress.country);
            this.addAddressForm.get('address_type').setValue(getaddress.address_type);
            if(getaddress.is_default_address == 'Y'){
              this.showaddresstypebox = false;
              this.showbillingaddressbox = false;
              this.showshippingaddressbox = false;
                if(getaddress.address_type == 'B'){
                  this.addAddressForm.get('is_default_billingaddress').setValue(true);
                }else{
                  this.addAddressForm.get('is_default_shippingaddress').setValue(true);
                }
            }else{
              this.showcheckbox(getaddress.address_type);
              this.showaddresstypebox = false;
            }
          }
        }
    });
  }

  private getDismissReason(reason: any): string {
    this.address_id = '';
    this.addAddressForm.get('street_1').setValue("");
    this.addAddressForm.get('street_2').setValue("");
    this.addAddressForm.get('area').setValue("");
    this.addAddressForm.get('pincode').setValue("");
    this.addAddressForm.get('city').setValue("");
    this.addAddressForm.get('state').setValue("");
    this.addAddressForm.get('country').setValue("");
    this.addAddressForm.get('address_type').setValue("");
    this.showaddresstypebox = true;
    this.showbillingaddressbox = false;
    this.showshippingaddressbox = false;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.submitted = false;
      return `with: ${reason}`;
    }
  }

  deleteaddress(address_id){
    this.commonService.deleteaddress(address_id).subscribe((response: any) => {
        if (response.status) {
          this.getUserAddress();
        }
    });
  }

}
