import { CommonService } from './../../common/services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit, Injectable, PLATFORM_ID, Inject, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
// import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';

@Component({
  selector: 'app-post-jobs',
  templateUrl: './post-jobs.component.html',
  styleUrls: ['./post-jobs.component.scss']
})

export class PostJobsComponent implements OnInit {

  closeResult = '';
  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type') ? localStorage.getItem('user_type') : '';
  public jobCareerForm: FormGroup;
  public jobWantedForm: FormGroup;
  public job_id: any = "";
  public careertitlelist: any = [];
  public jobwantedList: any = [];
  public joboffered: any = [];
  public jobwanted: any = [];
  public showmodal: boolean = false;
  public closemodal: boolean = false;
  public submitted: boolean = false;
  public postjoblist : any = [];
  public jobwantedlist: any = [];
  public showofferedtittlebox: boolean = false;
  public showWantedtittlebox: boolean = false;
  // public uploadInput: EventEmitter<UploadInput>;
  

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router) {
      // if(this.user_type == 'C'){
      //   this.router.navigate(['']);
      // }
      // this.uploadInput = new EventEmitter<UploadInput>();
    this.jobCareerForm = this.fb.group({
      job_type: ['', Validators.required],
      companyname: ['', Validators.required],
      designation: ['', Validators.required],
      mobile: ['', Validators.required],
      email_id: ['', Validators.required],
      address: ['', Validators.required],
      req_exp: ['', Validators.required],
      sal_off:['', Validators.required],
      titlename:[''],
      job_id: [''],
    })
    this.jobWantedForm = this.fb.group({
      job_type: ['', Validators.required],
      name: ['',Validators.required],
      experience: ['', Validators.required],
      address: ['', Validators.required],
      mobile: ['', Validators.required],
      email_id: ['', Validators.required],
      sal_exp: ['', Validators.required],
      titlename: [''],
      job_id: [''],
    })
  }
  

  ngOnInit(): void {
    this.getCareerList_get();
    this.getPostjobs();

  }

  titleshow_get(event: any){
    if(event.target.value == 0){
      this.showofferedtittlebox = true;
    }
    else{
      this.showofferedtittlebox = false;
    }
  }

  wantedtitleshow_get(event: any){
    if(event.target.value == 0){
      this.showWantedtittlebox = true;
    }
    else{
      this.showWantedtittlebox = false;
    }
  }

  getCareerList_get() {
    this.commonService.getCareerList_get().subscribe((response: any) => {
      if (response.status) {
        this.careertitlelist = response.data;
      }
    });
  }

  get p() { return this.jobCareerForm.controls; }

  get j() { return this.jobWantedForm.controls; }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  open_popup(wanted) {
    this.modalService.open(wanted, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReasonJob(reason)}`;
    });
  }

  getPostjobs() {
    this.commonService.getPostjobs(this.user_id, this.user_type).subscribe((response: any) => {
        if (response.status) {
          this.postjoblist = response.data;
          this.joboffered = [];
          this.jobwanted = [];
          const jobdetails = this.postjoblist.filter((data: any) => {
            if( data.new_job == 'O' )
            {
              this.joboffered.push(data);
            } 
            if( data.new_job == 'W' )
            {
              this.jobwanted.push(data);
            }
        });
    }
  });
}

checkValue(event) {
  if (event.target.value < 0) {
    event.target.value = '';
  }
}

  saveJob_post() {    
    this.submitted = true;
    
    if (this.jobCareerForm.valid) {
      const formData = new FormData();
      formData.append('job_id', this.jobCareerForm.get('job_id').value);
      formData.append('job_type', this.jobCareerForm.get('job_type').value);
      formData.append('titlename', this.jobCareerForm.get('titlename').value);
      formData.append('companyname', this.jobCareerForm.get('companyname').value);
      formData.append('designation', this.jobCareerForm.get('designation').value);
      formData.append('address', this.jobCareerForm.get('address').value);
      formData.append('mobile', this.jobCareerForm.get('mobile').value);
      formData.append('email_id', this.jobCareerForm.get('email_id').value);
      formData.append('req_exp', this.jobCareerForm.get('req_exp').value);

      formData.append('sal_off', this.jobCareerForm.get('sal_off').value);
      formData.append('user_id', localStorage.getItem('userId'));
      this.commonService.saveJob_post(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.jobCareerForm.get('job_id').setValue("");
          this.jobCareerForm.get('companyname').setValue("");
          this.jobCareerForm.get('titlename').setValue("");
          this.jobCareerForm.get('job_type').setValue("");
          this.jobCareerForm.get('designation').setValue("");
          this.jobCareerForm.get('address').setValue("");
          this.jobCareerForm.get('mobile').patchValue("");
          this.jobCareerForm.get('email_id').setValue("");
          this.jobCareerForm.get('req_exp').setValue("");
          this.jobCareerForm.get('sal_off').setValue("");
          this.getPostjobs();
          this.showofferedtittlebox = false;
          this.showWantedtittlebox = false;
          this.modalService.dismissAll("click");
        } else {
          console.log(response);
        }
      });
    }
    else{
      console.log("form Invalid");
    }
  }

  editpostjob(job_id){
    this.commonService.editpostjob(job_id).subscribe((response: any) => {
      if (response.status) {
          let getjob: any;
          if(response.status) {
            getjob = response['data'];
            this.jobCareerForm.get('job_id').setValue(getjob.job_id);
            this.jobCareerForm.get('job_type').setValue(getjob.job_type);
            this.jobCareerForm.get('companyname').setValue(getjob.name);
            this.jobCareerForm.get('titlename').setValue(getjob.titlename);
            this.jobCareerForm.get('designation').setValue(getjob.designation);
            this.jobCareerForm.get('address').setValue(getjob.address);
            this.jobCareerForm.get('mobile').patchValue(getjob.mobile);
            this.jobCareerForm.get('email_id').setValue(getjob.email_id);
            this.jobCareerForm.get('req_exp').setValue(getjob.requiredexp);
            this.jobCareerForm.get('sal_off').setValue(getjob.salary);
            if(getjob.job_type == 0){
              this.showofferedtittlebox = true;
            }else{
              this.showofferedtittlebox = false;
            }
            
          }
        }
    });
  }

  deletejob(job_id){
    this.commonService.deletejob(job_id).subscribe((response: any) => {
        if (response.status) {
          this.getPostjobs();
        }
    });
  }

  saveJobwanted_post() {
    this.submitted = true;
    if (this.jobWantedForm.valid) {
      const formData = new FormData();
      formData.append('job_id', this.jobWantedForm.get('job_id').value);
      formData.append('job_type', this.jobWantedForm.get('job_type').value);
      formData.append('name', this.jobWantedForm.get('name').value);
      formData.append('titlename', this.jobWantedForm.get('titlename').value);
      formData.append('address', this.jobWantedForm.get('address').value);
      formData.append('mobile', this.jobWantedForm.get('mobile').value);
      formData.append('email_id', this.jobWantedForm.get('email_id').value);
      formData.append('experience', this.jobWantedForm.get('experience').value);
      formData.append('sal_exp', this.jobWantedForm.get('sal_exp').value);
      formData.append('user_id', localStorage.getItem('userId'));
      this.commonService.saveJobwanted_post(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.jobWantedForm.get('job_id').setValue("");
          this.jobWantedForm.get('name').setValue("");
          this.jobWantedForm.get('titlename').setValue("");
          this.jobWantedForm.get('job_type').setValue("");
          this.jobWantedForm.get('address').setValue("");
          this.jobWantedForm.get('mobile').patchValue("");
          this.jobWantedForm.get('email_id').setValue("");
          this.jobWantedForm.get('experience').setValue("");
          this.jobWantedForm.get('sal_exp').setValue("");
          this.getPostjobs();
          this.showofferedtittlebox = false;
          this.showWantedtittlebox = false;
          this.modalService.dismissAll("click");
        } else {
          console.log(response);
        }
      });
    }
    else{
      console.log("form Invalid");
    }
  }

  editjobwanted(job_id){
    this.commonService.editjobwanted(job_id).subscribe((response: any) => {
      if (response.status) {
          let getjob: any;
          if(response.status) {
            getjob = response['data'];
            this.jobWantedForm.get('job_id').setValue(getjob.job_id);
            this.jobWantedForm.get('job_type').setValue(getjob.job_type);
            this.jobWantedForm.get('name').setValue(getjob.name);
            this.jobWantedForm.get('titlename').setValue(getjob.titlename);
            this.jobWantedForm.get('address').setValue(getjob.address);
            this.jobWantedForm.get('mobile').patchValue(getjob.mobile);
            this.jobWantedForm.get('email_id').setValue(getjob.email_id);
            this.jobWantedForm.get('experience').setValue(getjob.requiredexp);
            this.jobWantedForm.get('sal_exp').setValue(getjob.salary);
            if(getjob.job_type == 0){
              this.showWantedtittlebox = true;
            }else{
              this.showWantedtittlebox = false;
            }
          }
        }
    });
  }


  private getDismissReason(reason: any): string {
    this.showofferedtittlebox = false;
    this.showWantedtittlebox = false;
    this.jobCareerForm.get('job_id').setValue("");
    this.jobCareerForm.get('job_type').setValue("");
    this.jobCareerForm.get('companyname').setValue("");
    this.jobCareerForm.get('titlename').setValue("");
    this.jobCareerForm.get('designation').setValue("");
    this.jobCareerForm.get('address').setValue("");
    this.jobCareerForm.get('mobile').patchValue("");
    this.jobCareerForm.get('email_id').setValue("");
    this.jobCareerForm.get('req_exp').setValue("");
    this.jobCareerForm.get('sal_off').setValue("");
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.submitted = false;
      return `with: ${reason}`;
    }
  }

  private getDismissReasonJob(reason: any): string {
    this.showofferedtittlebox = false;
    this.showWantedtittlebox = false;
    this.jobWantedForm.get('job_id').setValue("");
    this.jobWantedForm.get('job_type').setValue("");
    this.jobWantedForm.get('name').setValue("");
    this.jobWantedForm.get('titlename').setValue("");
    this.jobWantedForm.get('experience').setValue("");
    this.jobWantedForm.get('address').setValue("");
    this.jobWantedForm.get('mobile').patchValue("");
    this.jobWantedForm.get('email_id').setValue("");
    this.jobWantedForm.get('sal_exp').setValue("");
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.submitted = false;
      return `with: ${reason}`;
    }
  }

  openModal() {
    this.showmodal = true;
  }

  cancelModal() {
    this.showmodal = false;
  }

}