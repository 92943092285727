<app-navbar-style-one></app-navbar-style-one>
<section class="product-area pt-30 pb-70 body mobviv">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 topmob">
                <h1>My Orders</h1>
            </div>
        </div>
    </div>
    <div class="container pt-10" *ngFor = "let order of getOrderlist">
        <div class="row a-box-inner background-color pt-10">
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <h6>ORDERS PLACED</h6>
                <p>{{order.date}}</p>
                <ng-container *ngIf="user_type == 'P' && order.total_discount > 0">
                    <h6>SUB TOTAL</h6>
                    <p>Rs {{getOriginalAmount(order.total_amount,order.total_discount)}}</p>
                    <h6>DISCOUNT {{plan_percentage}} % :</h6>
                    <p>Rs {{converttodecimal(order.total_discount)}}</p>
                    <h6>TOTAL</h6>
                    <p>Rs {{order.total_amount}}</p>
                </ng-container>
                <ng-container *ngIf="user_type == 'P' && order.total_discount == 0">
                    <h6>TOTAL</h6>
                    <p>Rs {{order.total_amount}}</p>
                </ng-container>
                <ng-container *ngIf="user_type != 'P'">
                    <h6>TOTAL</h6>
                    <p>Rs {{order.total_amount}}</p>
                </ng-container>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                <h6>SHIP TO</h6>
                <div>{{order.customer_name}}</div>
                <div>{{order.shipping_street_1}} , {{order.shipping_street_2}}, {{order.shipping_area }} </div>
                <div> {{order.shipping_city}} , {{order.shipping_state}} , {{order.shipping_country }}</div>
                <div>{{order.shipping_pincode }}</div>
                <div>{{order.shipping_date?'Excepted Delivery Date: '+order.shipping_date:'' }}</div>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 font_status"  *ngIf="order.status == '2' || order.status == '3'">
                <h6>ORDER STATUS</h6>
                <div *ngIf="order.invoice_status == '1'" [ngClass]="{ 'active': order.invoice_status == '1' }">PENDING</div>
                <div *ngIf="order.invoice_status == '2'" [ngClass]="{ 'active': order.invoice_status == '2' }">ORDER CONFIRMED BY ACHAGAM</div>
                <div *ngIf="order.invoice_status == '3'" [ngClass]="{ 'active': order.invoice_status == '3' }">RAW MATERIAL READY</div>
                <div *ngIf="order.invoice_status == '4'" [ngClass]="{ 'active': order.invoice_status == '4' }">WORK UNDER PROCESS</div>
                <div *ngIf="order.invoice_status == '5'" [ngClass]="{ 'active': order.invoice_status == '5' }">MATERIAL READY FOR DESPATCH</div>
                <div *ngIf="order.invoice_status == '6'" [ngClass]="{ 'active': order.invoice_status == '6' }">MATERIAL DESPATCHED THROUGH</div>
                <div *ngIf="order.invoice_status == '7'" [ngClass]="{ 'active': order.invoice_status == '7' }">MATERIAL DELIVERED & BILL COPY SENT TO MAIL</div>
                <div *ngIf="order.invoice_status == '8'" [ngClass]="{ 'active': order.invoice_status == '8' }">ORDER CANCELLED</div>
                <div *ngIf="order.invoice_status == '9'" [ngClass]="{ 'active': order.invoice_status == '9' }">MATERIAL RETURNED</div>
                <div *ngIf="order.invoice_status == '10'" [ngClass]="{ 'active': order.invoice_status == '10' }">JOB ON HOLD</div>
            </div>
            <div class="text-right" [ngClass]="order.status == '2' || order.status == '3' ? 'col-3' : 'col-6'">
                <h6>ORDER DETAILS</h6>
                <p>
                    <span class="optionBtndelete" *ngIf="order.status == '1'">Pending</span>
                    <span class="optionBtnedit" *ngIf="order.status == '2' || order.status == '3'">Order Confirmed</span>
                    <span class="optionBtndelete" *ngIf="order.status == '4'">Canceled</span>
                </p>
                <p><b>#{{order.order}}</b></p>
                <p>
                    <a class="mr-15px" href="javascript:void(0)" routerLink="/ordersView/{{order.order_id}}">View Order</a>
                    <!-- <a class="mr-15px" href="javascript:void(0)" (click)="reorderToCart(order.order_id)">Reorder</a> -->
                    <a *ngIf="order.status == '2'" class="mr-15px" href="javascript:void(0)" (click)="downloadInvoice(order.order_id)">Download Invoice</a>
                    <a *ngIf="order.status == '1' || order.status == '3' || order.status == '0'" href="javascript:void(0)" (click)="cancelOrder(order.order_id)" >Cancel</a>
                </p>
            </div> 
        </div>
        <div class="row pad" style="border: 1px solid antiquewhite;" *ngFor="let prod of order.products">
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12 pad">
                <div class="row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-12">
                        <div>
                            <a href="{{imagelink+prod.image_path_with_name}}" target="_blank"><span><img src="{{imagelink+prod.image_path_with_name}}" alt=""></span></a>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <a href="javascript:void(0)">
                                <span *ngIf = "prod.category_type == 'NC'" >{{prod.pd_name}}</span>
                                <span *ngIf = "prod.category_type != 'NC'" >{{prod.product_name}}</span>
                            </a>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <span class="a-size-small a-color-secondary">
                                Part Code : {{prod.part_code}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <span class="a-size-small a-color-secondary">
                                Quantity : {{prod.quantity}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <span class="a-size-small a-color-secondary">
                                Rate per unit : {{prod.rate}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12" *ngIf="user_type == 'P'">
                            <span class="a-size-small a-color-secondary">
                                Sub Total Amount (Rs) : {{prod.taxable_amount}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12" *ngIf="user_type == 'P'">
                            <span class="a-size-small a-color-secondary">
                                Discount Amt({{plan_percentage}} %) : {{prod.discount}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <span class="a-size-small a-color-secondary">
                                Amount : {{prod.sub_total_amount}}
                            </span>
                        </div>
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <span class="a-size-small a-color-secondary">
                                Tax : {{prod.gst?prod.gst:0}} %
                            </span>
                        </div>
                        
                        <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-12">
                            <div class="row pl-15">
                                <span>Total Price : Rs {{prod.total_price}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        <ul *ngIf="prod.images != ''">
                            <label for="Images">uploaded File(s) :</label>
                            <li *ngFor="let upimg of prod.images;let i=index;">
                                <a href="{{imagelink+upimg.image_path_with_name}}" target="_blank"><span>{{upimg.original_image_name}}</span></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-3 col-12 pad">
                <div class="row pl-15 pt-10">
                    <button class="default-btn-post_jobs" routerLink="/product">Buy it Again</button>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>