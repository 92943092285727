import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/services/common.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  public sub_category_id: any = '';
  public subCategories: any = [];
  public imagelink: any = environment.imageUrl;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {      
    this.getproductCategory();
  }

  getproductCategory(){
    this.commonService.getSubCategories().subscribe((response: any) => {
      if (response.status) {
        this.subCategories = response.data;
      }
    });
  }

}
