<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title">
            <h3>About Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/about/about1.png" alt="About Images">

                    <div class="about-bg-shape">
                        <img src="assets/img/about/bg-shape.png" alt="About Shape">
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="about-content">
                    <span>About Us</span>
                    <h2>We Are Leading Our Agency for 15 Years</h2>
                    <p class="text-justify">When it comes to your customised printing needs, you to longer have to run around. At Achagam, we bring the entire range of your printing services to your fingertips. We have made the process so simple that you will save time, money and effort.
                        All you need to do is select the kind of product you are looking for from our wide range of categories. Select the number of copies and you will get an instant estimate. Once approved, make your payment and get it delivered. We will also offer expert suggestions on the optimum number of copies that you can order to get the best price. 
                        We have built rich expertise in the field for 25 years and have also been running our own press in achagam. The expertise gained over these years help us anticipate and develop unique, customised printing experiences for clients. The best part is the high quality at a low price.
                        From printing business cards, marketing collaterals, voucher books, bill books, stickers, corrugated boxes, labels, ledgers to large banners and posters, we can do it all for you. 
                        Wish it, we’ll print it!.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="choose-area ptb-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Why Choose Us</span>
            <h2>Customised printing at the best prices!</h2>
            <p>Achagam is your friendly online printing shop! We make your printing work quick, easy and cost-effective.  Enjoy great quality at the lowest price.  From creating office stationery such as business cards, letter heads, notepads, bill books, packaging material, invitations, labels, books, corrugated boxes to advertising and marketing collaterals, we offer you customized options. The sky is the limit!.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-box'></i>
                    <h3>Trusted Company</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bxs-coin-stack'></i>
                    <h3>Budget Oriented</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bxs-truck'></i>
                    <h3>Fast Service</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bxl-creative-commons'></i>
                    <h3>Quality Work</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-brush' ></i>
                    <h3>Emergency Print</h3>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="choose-card">
                    <i class='bx bx-like'></i>
                    <h3>Satisfaction Revisions</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="service-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Services</span>
            <h2>Achagam Provide Good Quality Printing Services</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service1.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>Brand Identy</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service2.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>Package Design</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service3.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>Pattern Design</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service4.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>Book Cover</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service5.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>T-shirt Design</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-card">
                    <a routerLink="/services"><img src="assets/img/service/service6.jpg" alt="Service Images"></a>

                    <div class="service-content">
                        <a routerLink="/services"><h3>Brochure Design</h3></a>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit, sed do eiusmo gd  tempor incididunt ut labore.</p>
                        <a routerLink="/services" class="more-btn"><i class='bx bx-plus'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<div class="testimonial-area-two ptb-100">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Testimonial</span>
            <h2 class="text-white">People Says About Us</h2>
            <p class="text-white">What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="testimonial-slider-two owl-carousel owl-theme pt-45">
            <div class="testimonial-card">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="testimonial-card-content">
                            <h3>Evana Yas</h3>
                            <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="testimonial-img-2">
                            <img src="assets/img/testimonial/testimonial2.jpg" alt="Testimonial Images">
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-card">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="testimonial-card-content">
                            <h3>John Smith</h3>
                            <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="testimonial-img-2">
                            <img src="assets/img/testimonial/testimonial4.jpg" alt="Testimonial Images">
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-card">
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <div class="testimonial-card-content">
                            <h3>Annie Smith</h3>
                            <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                        </div>
                    </div>

                    <div class="col-lg-5">
                        <div class="testimonial-img-2">
                            <img src="assets/img/testimonial/testimonial3.jpg" alt="Testimonial Images">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="testimonial-icon">
        <i class='bx bxs-quote-alt-right'></i>
    </div>
</div>

<div class="designer-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Our Designer</span>
            <h2>Our Company Leads by Professional Designer</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer1.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Doe</h3></a>
                        <span>Director</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer2.jpg" alt="Designer Images"></a>
                    </div>
                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Smith</h3></a>
                        <span>Product Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer3.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Evanaa</h3></a>
                        <span>llustrator Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer4.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Knot Doe</h3></a>
                        <span>Mockup Specialist</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>