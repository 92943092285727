<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg3">
    <div class="container">
        <div class="inner-title">
            <h3>Recover Password</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Recover Password</li>
            </ul>
        </div>
    </div>
</div>
<section class="sign-up-area ptb-100">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center" *ngIf="token == ''">
                <span>Reset Password</span>
                <h2>Reset Your Password!</h2>
                <p class="recover-text">Enter the email of your account to reset the password. Then you will receive a link to email to reset the password. If you have any issue about reset password <a routerLink="/contact">contact us.</a> </p>
            </div>
            <form [formGroup]="recoverpasswordForm" class="needs-validation" *ngIf="token == ''">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input class="form-control" type="email" name="email" id="email" [ngClass]="{ 'is-invalid': submitted && p.email.errors }" formControlName="email" placeholder="Enter Your Email" required="" autocomplete="off">
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <small *ngIf="error" class="text-danger">{{commonerror}}</small>
                        <small *ngIf="trueerror" class="text-success">{{commonerror}}</small>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a class="recover-login" routerLink="/sign-in">Sign In in your account</a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <p class="recover-register">Not a member? <a routerLink="/sign-in">Register</a></p>
                    </div>
                    <div class="col-12 text-center">
                        <button class="default-btn btn-two" (click)="resetPassword()">Reset Password</button>
                    </div>
                </div>
            </form>
            <div class="scetion-title text-center" *ngIf="token != ''">
                <span>Reset Password</span>
                <h2>Reset Your Password!</h2>
                <p class="recover-text">Please change the password for the email {{customer_email}}. If you have any issue about reset password <a routerLink="/contact">contact us.</a> </p>
            </div>
            <form [formGroup]="changePasswordForm" class="needs-validation" *ngIf="token != ''">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && g.password.errors }" formControlName="password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="Password" required="" autocomplete="off">
                            <div *ngIf="(g.password.invalid && g.password.touched) || g.password.dirty">
                                <small *ngIf="g.password.errors?.required" class="text-danger">password is required</small>
                                <small *ngIf="g.password.errors?.pattern" class="text-danger">Password must contain at least 8 characters!<br>Atleast one Caps letter<br>
                                    Atleast one numeric<br>
                                    Atleast one special character(eg.&,*.@,#,$,%)
                                </small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="form-group">
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && g.confirm_password.errors }" formControlName="confirm_password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="Confirm Password" required="" autocomplete="off">
                            <div *ngIf="(g.confirm_password.invalid && g.confirm_password.touched) || g.confirm_password.dirty">
                                <small *ngIf="g.confirm_password.errors?.required" class="text-danger">confirm password is required</small>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <small *ngIf="error" class="text-danger">{{message}}</small>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <a class="recover-login" routerLink="/sign-in">Sign In in your account</a>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <p class="recover-register">Not a member? <a routerLink="/sign-in">Register</a></p>
                    </div>
                    <div class="col-12 text-center">
                        <button class="default-btn btn-two" (click)="changePassword()">Change Password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>