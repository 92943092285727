import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-order-view',
  templateUrl: './order-view.component.html',
  styleUrls: ['./order-view.component.scss']
})
export class OrderViewComponent implements OnInit {

  public user_id = localStorage.getItem('userId');
  public plan_tag = localStorage.getItem('plan_tag');
  public plan_percentage = localStorage.getItem('pre_cus_percentage');
  public user_type = localStorage.getItem('user_type');
  public imagelink: any = environment.imageUrl;
  public order_id: any = '';
  public order: any = {};
  public products: any = [];

  constructor(
    private Router: Router, 
    private route: ActivatedRoute, 
    private commonService: CommonService
  ) {
    route.params.forEach(params => {
      if(params.id) {
        this.order_id = params.id;
        this.order.date = '';
        this.order.total_amount = '';
        this.order.customer_name = '';
        this.order.details = '';
        this.order.shipping_street_1 = '';
        this.order.shipping_street_2 = '';
        this.order.shipping_area = '';
        this.order.shipping_city = '';
        this.order.shipping_state = '';
        this.order.shipping_country = '';
        this.order.shipping_pincode = '';
        this.order.status = '';
        this.getOrderDetails();
      }
    });
   }

  ngOnInit(): void {
  }

  getOriginalAmount(value1: any, value2: any){
    return (parseFloat(value1) + parseFloat(value2)).toFixed(2);
  }

  converttodecimal(value1: any){
    return (parseFloat(value1)).toFixed(2);
  }
  
  getOrderDetails(){
    this.commonService.getOrderDetails(this.order_id,this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.order = response.data;
        this.products = this.order.products;
      }
    });
  }

  downloadInvoice(order_id){
    this.commonService.downloadInvoice(order_id).subscribe((response: any) => {
      if(response.status){
        window.open(this.imagelink+response.url, "_blank");
      }else{
        console.log("Downlaod Failed");
      }
    });
  }

  removeFromAt(name: any){
    if(name.includes("(")){
      var name = name.substr(0, name.indexOf('('));
    }
    if(name.includes("@")){
      var name = name.substr(0, name.indexOf('@'));
    }
    return name;
  }

}
