import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { formatDate } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ThrowStmt } from '@angular/compiler';
declare let $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  public user_id = localStorage.getItem('userId');
  public plan_tag = localStorage.getItem('plan_tag');
  public plan_percentage = localStorage.getItem('pre_cus_percentage');
  public user_type = localStorage.getItem('user_type');
  public order_id: any = '';
  public order: any = {};
  public error: any = {};
  public products: any = [];
  public address_detail: any = [];
  public billingAddress = [];
  public shippingAddress = [];
  public submitted: boolean = false;
  public ordersubmitted: boolean = false;
  //add address//
  public addAddressForm: FormGroup;
  closeResult = '';
  public address_id: any = "";
  public showaddresstypebox: boolean = true;
  public showbillingaddressbox: boolean = false;
  public showshippingaddressbox: boolean = false;
  public billingAddressshow : any = {};
  public shippingAddressshow : any = {};
  public defaultBillingAddress: any = [];
  public defaultShippingAddress: any = [];
  public showBillingDetailsBox: boolean = false;
  public showShippingDetailsBox: boolean = false;
  public showwalletbox: boolean = false;
  public user_wallet_amt: any = {}; 
  public paid_wallet_amt: any = '';
  public table_wallet_amt: any = '';
  public wallet_checkbox: any = '';
  public diswalletamt: any = '';
  public confirimmsg:any = '';
  public wallet_amt_exit: boolean = true;
  public payment_meth: any = '';
  public showcash: boolean = false;
  public showbank: boolean = false;
  public showcreditbox: boolean = false;
  public credit_checkbox: any = '';
  public paid_credit_amt: any = '';
  public discreditamt: any = '';
  public creditmmsg:any = '';
  public credit_amt_exit: boolean = true;
  public added_wall_credit:any = '';
  public subtotal: any = 0;
  public discount: any = 0;

  
  constructor(
    private modalService: NgbModal,
    private Router: Router, 
    private route: ActivatedRoute, 
    private fb: FormBuilder,
    private commonService: CommonService
  ) {
    route.params.forEach(params => {
      if(params.id) {
        this.order_id = params.id;
      }
    });
    this.order.billing_address = '';
    this.order.shipping_address = '';
    this.order.total_amount = '';
    this.error.billingaddress = false;
    this.error.shippingaddress = false;
    this.getCheckoutDetails();
    this.getUserAddress();
    //add address
    this.addAddressForm = this.fb.group({
      street_1: ['', Validators.required],
      street_2: ['', Validators.required],
      area: ['', Validators.required],
      pincode: ['', Validators.required],
      city: ['', Validators.required],
      state: ['', Validators.required],
      country: ['', Validators.required],
      is_default_billingaddress: [''],
      is_default_shippingaddress: [''],
      address_type: [''],
      address_id: [''],
    })
   }

  ngOnInit(): void {
    this.getUserAddress();
    this.getwalletamount();
  }

  getUserAddress(){
    this.commonService.getUserAddress(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.billingAddress = [];
        this.shippingAddress = [];

        this.address_detail = response.data;
        const address = this.address_detail.filter((data) => {
          if (data.address_type == 'B') {
            this.billingAddress.push(data);
            if(data.is_default_address == 'Y'){
              this.order.billing_address = data.address_id;
              this.showbillingoption(data.address_id)
            }
          }else if (data.address_type == 'S') {
            this.shippingAddress.push(data);
            if(data.is_default_address == 'Y'){
              this.order.shipping_address = data.address_id;
              this.showshippingoption(data.address_id)
            }
          }      
        });
      }
    });
  }

  getCheckoutDetails(){
    this.commonService.getCheckoutDetails(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.order = response.data;
        this.subtotal = (parseFloat(this.order.total_amount) + parseFloat(this.order.order_discount)).toFixed(2);
        this.discount = (parseFloat(this.order.total_discount)).toFixed(2);
        this.order.total_amount = (parseFloat(this.order.total_amount)).toFixed(2);
        this.products = this.order.products;
        if(this.order == ''){
          this.Router.navigate(['/cart']);
        }
      }else{
        this.Router.navigate(['/cart']);
      }
    });
  }

  placeorder(){

    this.order.total_amount = ((parseFloat(this.order.total_amount)).toFixed(2));
    this.paid_wallet_amt = this.paid_wallet_amt?((parseFloat(this.paid_wallet_amt)).toFixed(2)):'';
    this.paid_credit_amt = this.paid_credit_amt?((parseFloat(this.paid_credit_amt)).toFixed(2)):'';
    this.added_wall_credit = ((parseFloat(this.paid_wallet_amt) + parseFloat(this.paid_credit_amt)).toFixed(2));

    this.ordersubmitted = true;
    if(this.order.shipping_address == ''  || this.order.shipping_address == null || this.order.shipping_address == 'null'){
      this.error.shippingaddress = true;
      return false;
    }else{
      this.error.shippingaddress = false;
    }

    if(this.order.billing_address == '' || this.order.billing_address == null || this.order.billing_address == 'null'){
      this.error.billingaddress = true;
      return false;
    }else{
      this.error.billingaddress = false;
    }

    //wallet and credit//
    if(this.wallet_checkbox == 'Y' && this.credit_checkbox == 'Y'){
      if(this.paid_wallet_amt == '' || this.paid_credit_amt == '' || this.paid_wallet_amt == null || this.paid_credit_amt == null || this.paid_wallet_amt == 'null' || this.paid_credit_amt == 'null'|| this.paid_wallet_amt == undefined || this.paid_credit_amt == undefined ){
        this.error.paid_wallet_amt = true;
        this.error.paid_credit_amt = true;
        return false;
      }else{
        this.error.paid_wallet_amt = false;
        this.error.paid_credit_amt = false;
        if(this.order.total_amount < this.added_wall_credit){
          this.confirimmsg = "Entered amount is greater then Order amount";
          this.wallet_amt_exit = false;
          this.credit_amt_exit = false;
          return false;
        }else if(this.order.total_amount > this.added_wall_credit){
          if(this.payment_meth == '' || this.payment_meth == 'null' || this.payment_meth == null || this.payment_meth == undefined){
            this.confirimmsg = "Please choose other payment options";
            this.wallet_amt_exit = false;
            this.credit_amt_exit = false;
            return false;
          }else{
            this.confirimmsg = '';
            this.wallet_amt_exit = true;
            this.credit_amt_exit = true;
          }
        }else{
          this.confirimmsg = "";
          this.wallet_amt_exit = true;
          this.credit_amt_exit = true;
        }
      }
    }else if(this.wallet_checkbox == 'Y' && this.credit_checkbox !='Y'){
      if(this.paid_wallet_amt == '' || this.paid_wallet_amt == null || this.paid_wallet_amt == 'null' || this.paid_wallet_amt == undefined){
        this.error.paid_wallet_amt = true;
        return false;
      }else{
        this.error.paid_wallet_amt = false;
        if(this.order.total_amount != this.paid_wallet_amt){
          if(this.payment_meth == '' || this.payment_meth == 'null' || this.payment_meth == null || this.payment_meth == undefined){
            this.confirimmsg = "Please choose other payment options";
            this.wallet_amt_exit = false;
            return false;
          }else{
            this.confirimmsg = '';
            this.wallet_amt_exit = true;
          }
        }
      }
    }else if(this.credit_checkbox == 'Y' && this.wallet_checkbox != 'Y'){
      if(this.paid_credit_amt == '' || this.paid_credit_amt == null || this.paid_credit_amt == 'null' || this.paid_credit_amt == undefined){
        this.error.paid_credit_amt = true;
        return false;
      }else{
        this.error.paid_credit_amt = false;
        if(this.order.total_amount != this.paid_credit_amt){
          if(this.payment_meth == '' || this.payment_meth == 'null' || this.payment_meth == null || this.payment_meth == undefined){
            this.creditmmsg = "Please choose other payment options";
            this.credit_amt_exit = false;
            return false;
          }else{
            this.creditmmsg = '';
            this.credit_amt_exit = true;
          }
        }
      }
    }else{
      this.error.paid_wallet_amt = false;
      this.error.paid_credit_amt = false;
      if(this.payment_meth == '' || this.payment_meth == 'null' || this.payment_meth == null || this.payment_meth == undefined){
        this.confirimmsg = "Please choose other payment options";
        this.wallet_amt_exit = false;
        this.credit_amt_exit = false;
        return false;
      }else{
        this.confirimmsg = '';
        this.wallet_amt_exit = true;
        this.credit_amt_exit = true;

      }
    }
    if(this.wallet_checkbox == 'Y'){
      this.checkwallet_amt();
    }
    if(this.credit_checkbox == 'Y'){
    this.checkcredit_amt();
    }
   
    if(!this.error.shippingaddress && !this.error.billingaddress && !this.error.paid_wallet_amt && this.wallet_amt_exit && !this.error.paid_credit_amt && this.credit_amt_exit){
      const formData = new FormData();
      formData.append('order', JSON.stringify(this.order));
      formData.append('wallet_amt_save',this.diswalletamt);
      formData.append('wallet_amt',this.user_wallet_amt.wallet_amount);  
      formData.append('wallet_amt_paid',this.paid_wallet_amt ? this.paid_wallet_amt :0);
      formData.append('wallet_checkbox',this.wallet_checkbox);
      formData.append('payment_meth',this.payment_meth);
      formData.append('user_id',this.user_id);
      formData.append('credit_amt_save',this.discreditamt);
      formData.append('credit_limit',this.user_wallet_amt.credit_limit);
      formData.append('credit_amt_paid',this.paid_credit_amt ? this.paid_credit_amt :0);
      formData.append('credit_checkbox',this.credit_checkbox);
      $('.preloader').fadeIn('slow');
      this.commonService.placeOrder(formData).subscribe((response: any) => {
        if (response.status) {
          this.ordersubmitted = false;
          this.Router.navigate(['/orders']);
        }else{
          $('.preloader').fadeOut('slow');
        }
      });
    }else{
      if(this.error.shippingaddress || this.error.billingaddress){
        alert("Please choose address");
      }
    }
  }

  //add address//
  get p() { return this.addAddressForm.controls; }


  open(content) {
    this.submitted = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    this.address_id = '';
    this.addAddressForm.get('street_1').setValue("");
    this.addAddressForm.get('street_2').setValue("");
    this.addAddressForm.get('area').setValue("");
    this.addAddressForm.get('pincode').setValue("");
    this.addAddressForm.get('city').setValue("");
    this.addAddressForm.get('state').setValue("");
    this.addAddressForm.get('country').setValue("");
    this.addAddressForm.get('address_type').setValue("");
    this.showaddresstypebox = true;
    this.showbillingaddressbox = false;
    this.showshippingaddressbox = false;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      this.submitted = false;
      return `with: ${reason}`;
    }
  }

  //address form
  showcheckbox(showbox) {
    if (showbox == "H") {
      this.showbillingaddressbox = true;
      this.showshippingaddressbox = true;
    } else if(showbox == "B") {
      this.showbillingaddressbox = true;
      this.showshippingaddressbox = false;
    } else if(showbox == "S") {
      this.showbillingaddressbox = false;
      this.showshippingaddressbox = true;
    }

  } 

  showbillingoption(address_id){
    if(address_id){
      this.showBillingDetailsBox = true;
      this.billingAddressshow = '';
      const shwbilladdress = this.billingAddress.filter((data) => {
        if(data.address_id == address_id){
          this.billingAddressshow = data;
        }
      });
    }else{
      this.showBillingDetailsBox = false;
    }
   
  }

  calculateTotalAmount(valueA:any, valueB:any){
    return parseFloat(valueA) - parseFloat(valueB);
  }

  //checkbox show
  checkbox_show(check){
    if(check){
      this.showwalletbox = true;
      this.wallet_checkbox = 'Y';
    }else{
      this.showwalletbox = false;
      this.wallet_checkbox = 'N';
    }
  }
  //credit_acc checkbox show
  checkbox_credit_acc(check){
    if(check){
      this.showcreditbox = true;
      this.credit_checkbox = 'Y';
    }else{
      this.showcreditbox = false;
      this.credit_checkbox = 'N';
    }
  }

  getwalletamount(){
    this.commonService.getwalletamount(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.user_wallet_amt = response.data;
      }
    });
  }

  returnNumber(){
    if(this.paid_wallet_amt){
      this.error.paid_wallet_amt = false;
    }
    this.checkwallet_amt();
  }
  returnNum(){
    if(this.paid_credit_amt){
      this.error.paid_credit_amt = false;
    }
    this.checkcredit_amt();
  }
  
  checkpaymentmethod(){
    if(this.payment_meth){
      this.confirimmsg = '';
      this.creditmmsg = "";
      this.wallet_amt_exit = true;
      this.credit_amt_exit = true;
    }
  }

  checkwallet_amt(){
    this.confirimmsg = "";
    this.wallet_amt_exit = true;
    this.diswalletamt = parseFloat(this.user_wallet_amt.wallet_amount) - parseFloat(this.paid_wallet_amt);
    if(parseFloat(this.paid_wallet_amt) > parseFloat(this.user_wallet_amt.wallet_amount)){
      this.confirimmsg = "Entered amount is greater then wallet amount";
      this.wallet_amt_exit = false;
      return false;
    }else{
      this.confirimmsg = '';
      this.wallet_amt_exit = true;
    }
    if(parseFloat(this.paid_wallet_amt) > parseFloat(this.order.total_amount)){
      this.confirimmsg = "Entered amount is greater then Order amount";
      this.wallet_amt_exit = false;
      return false;
    }else{
      this.confirimmsg = '';
      this.wallet_amt_exit = true;
    }
  }


  checkcredit_amt(){
    this.creditmmsg = "";
    this.credit_amt_exit = true;
    this.discreditamt = parseFloat(this.user_wallet_amt.credit_limit) - parseFloat(this.paid_credit_amt);
    if(parseFloat(this.paid_credit_amt) > parseFloat(this.user_wallet_amt.credit_limit)){
      this.creditmmsg = "Entered amount is greater then credit amount";
      this.credit_amt_exit = false;
      return false;
    }else{
      this.creditmmsg = '';
      this.credit_amt_exit = true;
    }
    if(parseFloat(this.paid_credit_amt) > parseFloat(this.order.total_amount)){
      this.creditmmsg = "Entered amount is greater then Order amount";
      this.credit_amt_exit = false;
      return false;
    }else{
      this.creditmmsg = '';
      this.credit_amt_exit = true;
    }
  }

  showshippingoption(address_id){
    if(address_id){
      this.showShippingDetailsBox = true;
      this.shippingAddressshow = '';
      const showshipaddress = this.shippingAddress.filter((data) => {
        if(data.address_id == address_id ){
          this.shippingAddressshow = data;
        } 
      });
    }else{
      this.showShippingDetailsBox = false;
    }
  }

  saveaddress() {    
    this.submitted = true;
    if (this.addAddressForm.valid) {
      const formData = new FormData();
      formData.append('address_id', this.addAddressForm.get('address_id').value);
      formData.append('street_1', this.addAddressForm.get('street_1').value);
      formData.append('street_2', this.addAddressForm.get('street_2').value);
      formData.append('area', this.addAddressForm.get('area').value);
      formData.append('pincode', this.addAddressForm.get('pincode').value);
      formData.append('city', this.addAddressForm.get('city').value);
      formData.append('state', this.addAddressForm.get('state').value);
      formData.append('country', this.addAddressForm.get('country').value);
      formData.append('address_type', this.addAddressForm.get('address_type').value);
      formData.append('is_default_billingaddress',this.addAddressForm.get('is_default_billingaddress').value?'Y':'N') ;
      formData.append('is_default_shippingaddress', this.addAddressForm.get('is_default_shippingaddress').value?'Y':'N');
      formData.append('customer_id', localStorage.getItem('userId'));

      this.commonService.saveaddress(formData).subscribe((response: any) => {
        if (response.status) {
          this.submitted = false;
          this.ordersubmitted = false;
          this.addAddressForm.get('address_id').setValue("");
          this.addAddressForm.get('street_1').setValue("");
          this.addAddressForm.get('street_2').setValue("");
          this.addAddressForm.get('area').setValue("");
          this.addAddressForm.get('pincode').setValue("");
          this.addAddressForm.get('city').setValue("");
          this.addAddressForm.get('state').patchValue("");
          this.addAddressForm.get('country').setValue("");
          this.addAddressForm.get('address_type').setValue("");
          this.addAddressForm.get('is_default_billingaddress').setValue("");
          this.addAddressForm.get('is_default_shippingaddress').setValue("");
          this.showaddresstypebox = true;
          this.showbillingaddressbox = false;
          this.showshippingaddressbox = false;
          this.getUserAddress();
          this.modalService.dismissAll("click");
          this.order.billing_address = response.billing_address;
          this.order.shipping_address = response.shipping_address;
          this.showbillingoption(this.order.billing_address);
          this.showshippingoption(this.order.shipping_address);
        } else {
          console.log(response);
        }
      });
    }
    else{
      console.log("form Invalid");
    }
  }

}