<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title">
            <h3>Testimonials</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Testimonials</li>
            </ul>
        </div>
    </div>
</div>

<div class="client-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Testimonial</span>
            <h2>People Says About Us</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>
        
        <div class="row pt-45">
            <div class="col-lg-6">
                <div class="client-card">
                    <img src="assets/img/testimonial/testimonial2.jpg" alt="Images">
                    <h3>Evana Yas</h3>
                    <span>Product Designer</span>
                    <ul>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="client-card">
                    <img src="assets/img/testimonial/testimonial3.jpg" alt="Images">
                    <h3>John Smith</h3>
                    <span>T-shirt Designer</span>
                    <ul>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="client-card">
                    <img src="assets/img/testimonial/testimonial5.jpg" alt="Images">
                    <h3>Annie Smith</h3>
                    <span>Mockup Specialist</span>
                    <ul>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="client-card">
                    <img src="assets/img/testimonial/testimonial4.jpg" alt="Images">
                    <h3>Knot Doe</h3>
                    <span>llustrator Designer</span>
                    <ul>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                        <li><i class='bx bxs-star'></i></li>
                    </ul>
                    <p>“Adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas.”</p>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/testimonial"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item current"><a class="page-link" routerLink="/testimonial">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/testimonial">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/testimonial">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/testimonial"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>