<app-navbar-style-one></app-navbar-style-one>
<section class="register-page section-b-space">
    <div class="container">
        <div class="row mt-50">
            <button class="default-btn-post_jobs" [routerLink]="['/quote']">Quotes</button>
        </div>
        <div class="row billing-details mt-20">
            <div class="col-lg-3">
                <div class="col-xl-12 col-lg-12 col-12 p-0 text-center">
                    <span *ngIf="category_image != ''">
                        <img src="{{imagelink+category_image}}" width="100%">
                    </span>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding0px">
                    <section class="mb-4 padding0px">
                        <h3>Categories</h3>
                        <div class="form-check padding0px" *ngFor="let category of subCategories">
                            <label *ngIf="(category.value == 'BB' || category.value == 'BN' || category.value == 'LH') && category.value != 'NC'" [ngClass]="{ 'is-active': category.active}" (click)="getSizes(category.value, category.category_id)"  class="text-uppercase cursor">{{category.category_name}}</label>
                        </div>
                    </section> 
                </div>
            </div>
            <div class="col-lg-9 bl-1">
                <div class="row p-0" >
                    <h3 class="col-xl-8 col-lg-8 col-12">Generate Quote <span *ngIf="category_name != ''">{{" - "+category_name}}</span></h3>
                </div>
                <div class="theme-card">
                    <div class="card-body tab2-card">
                        <!-- <div class="form-group row" *ngIf="user_type == 'SA'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Customer Name<span class="error">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.customer_id}" [(ngModel)]="quotejson.customer_id">
                                <option value="">Select a customer</option>
                                <option value="{{customer.customer_id}}"
                                    *ngFor="let customer of customerList">{{customer.customer_name}}</option>
                            </select>
                        </div> -->
                        <div class="form-group padding0px" *ngIf="user_type == 'SA'">
                            <div id="customerSearch" class="form-group row">
                                <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="!quotejson.showCustomerForm">Customer name
                                    <span class="error">*</span>
                                </label>
                                <ng-select *ngIf="!quotejson.showCustomerForm" [(ngModel)]="quotejson.customer_id" [searchable]="true"
                                            placeholder="" (change)="checkValidation()" class="ng-select-quote col-xl-4 col-md-5"
                                            [ngClass]="{'ng-select-error':submitted && quotejson.error.customer_id}">
                                    <ng-option [value]="">Select Customer</ng-option>
                                    <ng-option *ngFor="let customer of customerList" [value]="customer.customer_id">{{ customer.customer_mobile }},{{customer.customer_name}}</ng-option>
                                </ng-select>
                                <button *ngIf="!quotejson.showCustomerForm" class="createBtn col-xl-3 col-md-3" (click)="showCreateForm()">
                                    Create Customer
                                </button>
                            </div>
                            <div *ngIf="quotejson.showCustomerForm" class="form-group row">
                                <label class="col-xl-3 col-md-3 paddingTop10px">Customer Name<span class="error">*</span></label>
                                <input class="form-control col-xl-4 col-md-5" [ngClass]="{'is-invalid':submitted && quotejson.error.customer_name}" [(ngModel)]="quotejson.customer_name" (change)="checkValidation()" />
                                <button *ngIf="quotejson.showCustomerForm" class="createBtn col-xl-3 col-md-3" (click)="closeCreateForm()">
                                    cancel create Customer
                                </button>
                            </div>
                            <div *ngIf="quotejson.showCustomerForm" class="form-group row">
                                <label class="col-xl-3 col-md-3 paddingTop10px">Customer Mobile<span class="error">*</span></label>
                                <input class="form-control col-xl-4 col-md-5" [ngClass]="{'is-invalid':submitted && quotejson.error.customer_mobile }" [(ngModel)]="quotejson.customer_mobile" (change)="checkValidation()" />
                            </div>
                            <div *ngIf="quotejson.showCustomerForm" class="form-group row" >
                                <label class="col-xl-3 col-md-3 paddingTop10px">Customer Email<span class="error">*</span></label>
                                <input class="form-control col-xl-4 col-md-5" type="text" [ngClass]="{'is-invalid': submitted && quotejson.error.customer_email}" [(ngModel)]="quotejson.customer_email" (change)="checkValidation()" autocomplete="off" />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Job Name <span style="color: red; font-size: 15px;">*</span></label>
                            <input class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.job_name}" [(ngModel)]="quotejson.job_name" (keyup)="checkValidation()" type="text" autocomplete="off" />
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BB' || quotejson.category_type == 'LH'"> Size <span style="color: red; font-size: 15px;">*</span></label>
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BN'"> Size Without Folding <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.psize_id}" [(ngModel)]="quotejson.psize_id" autocomplete="off" (change)="checkSizeWithbokType()">
                                <option value="">Select a size</option>
                                <option value="{{billsize.psize_id}}" *ngFor="let billsize of Sizes">{{billsize.size_name}} - ( {{removeNonZeros(billsize.size_cms_left)}} * {{removeNonZeros(billsize.size_cms_right)}} cm )</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.showBookType && billpaper1 != ''">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Book type <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pk_sheet_id}" [(ngModel)]="quotejson.pk_sheet_id" (change)="changepaperCheckBoxConditions($event.target.value)">
                                <ng-container *ngFor="let book of bookTypes">
                                    <option value="{{book.pk_sheet_id}}" *ngIf="book.active && book.book" >{{book.lable}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">No of Bills Per Book <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pk_bb_id}" [(ngModel)]="quotejson.pk_bb_id">
                                <option value="">Select No. of Sets</option>
                                <ng-container *ngFor="let billperbook of noOfBillsPerBook">
                                    <option value="{{billperbook.pk_bb_id}}" *ngIf="billperbook.active">{{billperbook.no_of_bills}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && billpaper1 != ''">
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.pk_sheet_id == 1">Paper</label>
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.pk_sheet_id != 1">Paper 1</label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper1}" [(ngModel)]="quotejson.paper1" (change)="checkPaperIsMulticolour()">
                                <option value="">Select Paper</option>
                                <ng-container *ngFor="let paper1 of billpaper1">
                                    <option [disabled]="paper1.isDisabled" value="{{paper1.paper_id}}" >{{removeFromAt(paper1.paper_name)}}</option>
                                </ng-container>
                            </select>
                            <div class="col-xl-1 col-md-1 paddingTop10px">
                                <input type="checkbox" [checked]="quotejson.checkpaper1" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.checkpaper1" class="col-xl-12 col-md-12">
                            </div>
                            <div *ngIf="quotejson.checkpaper1" class="col-xl-1 col-md-1 paddingTop10px" ngbTooltip="variable printing">
                                <input type="checkbox" [checked]="quotejson.variablePaper1" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.variablePaper1" class="col-xl-12 col-md-12">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.disablePaper2">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Paper 2</label>
                            <select class="form-control col-xl-4 col-md-5"  [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper2}" [(ngModel)]="quotejson.paper2" (change)="checkPaperIsMulticolour()">
                                <option value="">Select Paper</option>
                                <ng-container *ngFor="let paper2 of billpaper2">
                                    <option value="{{paper2.paper_id}}" [disabled]="paper2.isDisabled" >{{removeFromAt(paper2.paper_name)}}</option>
                                </ng-container>
                            </select>
                            <div class="col-xl-1 col-md-1 paddingTop10px">
                                <input type="checkbox" [disabled]="quotejson.isCorbanDisablePaper2" [(ngModel)]="quotejson.checkpaper2" class="col-xl-12 col-md-12" (click)="checkpaperValidation($event , '2')" (change)="checkValidation()">
                                <!-- <input type="checkbox" [checked]="quotejson.checkpaper2" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.checkpaper2" class="col-xl-12 col-md-12" > -->
                            </div>
                            <div *ngIf="quotejson.checkpaper2" class="col-xl-1 col-md-1 paddingTop10px" ngbTooltip="variable printing" (click)="checkpaperValidation($event , '2')" (change)="checkValidation()">
                                <input type="checkbox"   [(ngModel)]="quotejson.variablePaper2" class="col-xl-12 col-md-12" (click)="checkVariablePaper($event , '2')">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.disablePaper3">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Paper 3</label>
                            <select class="form-control col-xl-4 col-md-5" [disabled]="quotejson.isCorbanDisablePaper3" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper3}" [(ngModel)]="quotejson.paper3" (change)="checkPaperIsMulticolour()">
                                <option value="">Select Paper</option>
                                <ng-container *ngFor="let paper3 of billpaper3">
                                    <option value="{{paper3.paper_id}}" [disabled]="paper3.isDisabled">{{removeFromAt(paper3.paper_name)}}</option>
                                </ng-container>
                            </select>
                            <div class="col-xl-1 col-md-1 paddingTop10px">
                                <input type="checkbox" [disabled]="quotejson.isCorbanDisablePaper3" [(ngModel)]="quotejson.checkpaper3" class="col-xl-12 col-md-12" (click)="checkpaperValidation($event , '3')" (change)="checkValidation()">
                                <!-- <input type="checkbox" [checked]="quotejson.checkpaper3" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.checkpaper3" class="col-xl-12 col-md-12"> -->
                            </div>
                            <div *ngIf="quotejson.checkpaper3" class="col-xl-1 col-md-1 paddingTop10px" ngbTooltip="variable printing" (click)="checkpaperValidation($event , '3')" (change)="checkValidation()">
                                <input type="checkbox"  [(ngModel)]="quotejson.variablePaper3" class="col-xl-12 col-md-12" (click)="checkVariablePaper($event , '3')">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.disablePaper4">
                            <label *ngIf="quotejson.disablePaper3 && quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 4</label>
                            <label *ngIf="!quotejson.disablePaper3 && quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 3</label>
                            <label *ngIf="!quotejson.disablePaper2 && !quotejson.disablePaper3" class="col-xl-3 col-md-3 paddingTop10px">Paper 2</label>
                            <select class="form-control col-xl-4 col-md-5" [disabled]="quotejson.isCorbanDisablePaper4" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper4}" [(ngModel)]="quotejson.paper4" (change)="checkPaperIsMulticolour()">
                                <option value="">Select Paper</option>
                                <option value="{{paper.paper_id}}" [disabled]="paper.isDisabled" *ngFor="let paper of billpaper4">{{removeFromAt(paper.paper_name)}}</option>
                            </select>
                            <div class="col-xl-1 col-md-1 paddingTop10px">
                                <input type="checkbox" [disabled]="quotejson.isCorbanDisablePaper4" [(ngModel)]="quotejson.checkpaper4" class="col-xl-12 col-md-12" (click)="checkpaperValidation($event , '4')" (change)="checkValidation()">
                                <!-- <input type="checkbox" [checked]="quotejson.checkpaper4" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.checkpaper4" class="col-xl-12 col-md-12" > -->
                            </div>
                            <div *ngIf="quotejson.checkpaper4" class="col-xl-1 col-md-1 paddingTop10px" ngbTooltip="variable printing" (click)="checkpaperValidation($event , '4')" (change)="checkValidation()">
                                <input type="checkbox"  [(ngModel)]="quotejson.variablePaper4" class="col-xl-12 col-md-12" (click)="checkVariablePaper($event , '4')">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.disablePaper5">
                            <label *ngIf="quotejson.disablePaper4 && quotejson.disablePaper3 && quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 5</label>
                            <label *ngIf="!quotejson.disablePaper4 && quotejson.disablePaper3 && quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 4</label>
                            <label *ngIf="!quotejson.disablePaper4 && !quotejson.disablePaper3 && quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 3</label>
                            <label *ngIf="!quotejson.disablePaper4 && !quotejson.disablePaper3 && !quotejson.disablePaper2" class="col-xl-3 col-md-3 paddingTop10px">Paper 2</label>
                            <select class="form-control col-xl-4 col-md-5" [disabled]="quotejson.isCorbanDisablePaper5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper5}" [(ngModel)]="quotejson.paper5" (change)="checkPaperIsMulticolour()">
                                <option value="">Select Paper</option>
                                <option value="{{paper.paper_id}}" [disabled]="paper.isDisabled" *ngFor="let paper of billpaper5">{{removeFromAt(paper.paper_name)}}</option>
                            </select>
                            <div class="col-xl-1 col-md-1 paddingTop10px">
                                <input type="checkbox" [disabled]="quotejson.isCorbanDisablePaper5" [(ngModel)]="quotejson.checkpaper5" class="col-xl-12 col-md-12" (click)="checkpaperValidation($event , '5')" (change)="checkValidation()">
                                <!-- <input type="checkbox" [checked]="quotejson.checkpaper5" [disabled]="quotejson.pk_sheet_id > 0" [(ngModel)]="quotejson.checkpaper5" class="col-xl-12 col-md-12"> -->
                            </div>
                            <div *ngIf="quotejson.checkpaper5" class="col-xl-1 col-md-1 paddingTop10px" ngbTooltip="variable printing" (click)="checkpaperValidation($event , '5')" (change)="checkValidation()">
                                <input type="checkbox"  [(ngModel)]="quotejson.variablePaper5" class="col-xl-12 col-md-12" (click)="checkVariablePaper($event , '5')">
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Paper</label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper}" [(ngModel)]="quotejson.paper" (change)="checkPaperIsMulticolour()">
                                <option value="">Select paper</option>
                                <option value="{{paper.paper_id}}" *ngFor="let paper of duplicateBitNoticePapers">{{removeFromAt(paper.paper_name)}}</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'LH'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Paper</label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.paper}" [(ngModel)]="quotejson.paper" (change)="checkPaperIsMulticolour()">
                                <option value="">Select paper</option>
                                <option value="{{paper.paper_id}}" *ngFor="let paper of duplicateLetterPadPapers">{{removeFromAt(paper.paper_name)}}</option>
                            </select>
                        </div>
                        <div class="form-group row">
                            <label class="col-xl-2 col-md-3 paddingTop10px"><span> </span>Please Select Printing Colour<span style="color: red; font-size: 15px;">*</span></label>
                            <label class="col-xl-2 col-md-1 paddingTop10px">Front</label>
                            <div class="col-xl-3 col-md-3">
                                <div class="paddingTop10px displayFlex" *ngFor="let front of printingFrontColours">
                                    <input class="marginTop5px" type="checkbox" [checked]="front.isChecked == 'Y'" [disabled]="front.isDisabled" value="{{front.pcolour_id}}" (change)="updateFrontColourChanges($event , front.pcolour_id)">
                                    <label class="paddingLeft10px">{{front.colour_name}}</label>
                                </div>
                                <div class="error" *ngIf="quotejson.error.frontcolour && submitted">Please Choose Atleast One Front Colour Checkbox</div>
                            </div>
                            <label class="col-xl-2 col-md-1 paddingTop10px">Back</label>
                            <div class="col-xl-3 col-md-3">
                                <div class="paddingTop10px displayFlex" *ngFor="let back of printingBackColours">
                                    <input class="marginTop5px" type="checkbox" [checked]="back.isChecked == 'Y'" [disabled]="back.isDisabled" value="{{back.pcolour_id}}" (change)="updateBackColourChanges($event , back.pcolour_id)">
                                    <label class="paddingLeft10px">{{back.colour_name}}</label>
                                </div>
                                <div class="error" *ngIf="quotejson.error.backcolour && submitted">Please Choose Atleast One Back Colour Checkbox</div>
                            </div>
                        </div>
                        <div class="form-group row" *ngIf="contentBox && quotejson.category_type == 'BB'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Front Back Same Content</label>
                            <select class="form-control col-xl-4 col-md-5" [(ngModel)]="quotejson.sameContent" (change)="checkValidation()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN' && quotejson.bitnoticelamination">
                            <label class="col-xl-3 col-md-2 paddingTop10px">Lamination <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-2 col-md-2"  [ngClass]="{ 'is-invalid': submitted && quotejson.error.lamination}" [(ngModel)]="quotejson.lamination" (change)="changeBitNoticelamination()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                            <select *ngIf="quotejson.lamination == 'Y'" [ngClass]="{ 'is-invalid': submitted && quotejson.error.lamination_type}" class="form-control col-xl-2 col-md-2" [(ngModel)]="quotejson.lamination_type" (change)="checkValidation()">
                                <option value="G">Gloss</option>
                                <option value="M">Matt</option>
                            </select>
                            <label *ngIf="quotejson.lamination == 'Y'" class="col-xl-1 col-md-2 paddingTop10px">Side <span style="color: red; font-size: 15px;">*</span></label>
                            <select *ngIf="quotejson.lamination == 'Y'" [ngClass]="{ 'is-invalid': submitted && quotejson.error.lamination_side}" class="form-control col-xl-2 col-md-2" [(ngModel)]="quotejson.lamination_side" (change)="checkValidation()">
                                <option value="1" >1</option>
                                <option value="2" >2</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Numbering <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.bitnotice_numbering}" [(ngModel)]="quotejson.bitnotice_numbering" (change)="checkBitNoticeNumbering()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Numbering <span style="color: red; font-size: 15px;">*</span></label>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="numbering" value="M" (change)="checkSizeWithbokTypenumber()" [(ngModel)]="quotejson.numbering" [checked]="quotejson.numbering == 'M'" >
                                <label for="manual" class="paddingLeft10px"> Manual</label>
                            </div>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="numbering" value="MA" (change)="checkSizeWithbokTypenumber()" [(ngModel)]="quotejson.numbering" [checked]="quotejson.numbering == 'MA'">
                                <label for="machine" class="paddingLeft10px"> Machine</label>
                            </div>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="numbering" value="N" (change)="checkSizeWithbokTypenumber()" [(ngModel)]="quotejson.numbering" [checked]="quotejson.numbering == 'N'">
                                <label for="none" class="paddingLeft10px"> None</label>
                            </div>
                            <div class="col-xl-12 col-md-12" style="color: red; font-size: 14px;" *ngIf="submitted && quotejson.error.numbering">Please choose any radio button</div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">No of Bills Per Page <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pk_bp_id}" [(ngModel)]="quotejson.pk_bp_id" (change)="billsPerchange()">
                                <option value="{{billspage.pk_bp_id}}" *ngFor="let billspage of noOfBillsPerPageDuplicate">{{billspage.no_of_bills_per_page}}</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="(quotejson.category_type == 'BB' && quotejson.numbering != 'N' ) || (quotejson.category_type == 'BN' && quotejson.bitnotice_numbering == 'Y')">
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BB'">No of Places for Numbering in a Bill <span style="color: red; font-size: 15px;">*</span></label>
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BN'">No of Places for Numbering in a Copy <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pnumber_id}"  [(ngModel)]="quotejson.pnumber_id" required="" (change)="changeNumbering($event)">
                                <ng-container *ngFor="let place of noOfPlacesOfNumbering">
                                    <option value="{{place.pnumber_id}}" *ngIf="place.active" >{{place.no_of_places_for_numbering}}</option>
                                </ng-container>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB' && quotejson.numbering == 'MA'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">is Numbering <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.is_numbering}" [(ngModel)]="quotejson.is_numbering" (change)="checkValidation()">
                                <option value="C">Continuous</option>
                                <option value="R">Repeated</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BB'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Perforation <span style="color: red; font-size: 15px;">*</span></label>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="perforation" value="M" [(ngModel)]="quotejson.perforation" [checked]="quotejson.perforation == 'M'" (change)="perforationChange()">
                                <label for="permanual" class="paddingLeft10px"> Manual</label>
                            </div>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="perforation" value="MA" [(ngModel)]="quotejson.perforation" [checked]="quotejson.perforation == 'MA'" (change)="perforationChange()">
                                <label for="permachine" class="paddingLeft10px"> Machine</label>
                            </div>
                            <div class="col-xl-2 col-md-3 paddingTop10px displayFlex">
                                <input type="radio" class="marginTop5px" name="perforation" value="N" [(ngModel)]="quotejson.perforation" [disabled]="quotejson.pk_bp_id > 1 || quotejson.pnumber_id > 2" [checked]="quotejson.perforation == 'N'" (change)="perforationChange()">
                                <label for="pernone" class="paddingLeft10px"> None</label>
                            </div>
                            <div class="col-xl-12 col-md-12" style="color: red; font-size: 14px;" *ngIf="submitted && quotejson.error.perforation">Please choose any radio button</div>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN' && quotejson.bitnoticeperforation">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Perforation <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.bitnotice_perforation}" [(ngModel)]="quotejson.bitnotice_perforation" (change)="checkValidation()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN' && quotejson.bitnoticefolding">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Folding <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.folding}" [(ngModel)]="quotejson.folding" (change)="checkValidation()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'LH' && quotejson.letterpadfoil">
                            <label class="col-xl-3 col-md-2 paddingTop10px">Foil Printing <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-2 col-md-2"  [ngClass]="{ 'is-invalid': submitted && quotejson.error.foil}" [(ngModel)]="quotejson.foil" (change)="checkValidation()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                            <label *ngIf="quotejson.category_type == 'LH' && quotejson.foil == 'Y'" class="col-xl-1 col-md-2 paddingTop10px">Length <span style="color: red; font-size: 15px;">*</span></label>
                            <input *ngIf="quotejson.category_type == 'LH' && quotejson.foil == 'Y'" class="form-control col-xl-2 col-md-2" [ngClass]="{ 'is-invalid': submitted && quotejson.error.foillength}" [(ngModel)]="quotejson.foillength" placeholder="Foil Length in CMS" (keyup)="checkValidation()" type="text" autocomplete="off">
                            <label *ngIf="quotejson.category_type == 'LH' && quotejson.foil == 'Y'" class="col-xl-1 col-md-2 paddingTop10px">Width <span style="color: red; font-size: 15px;">*</span></label>
                            <input *ngIf="quotejson.category_type == 'LH' && quotejson.foil == 'Y'" class="form-control col-xl-2 col-md-2" [ngClass]="{ 'is-invalid': submitted && quotejson.error.foilwidth}" [(ngModel)]="quotejson.foilwidth" placeholder="Foil Width in CMS" (keyup)="checkValidation()" type="text" autocomplete="off">
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'LH' && quotejson.letterpadembossing">
                            <label class="col-xl-3 col-md-2 paddingTop10px">Embossing <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-2 col-md-2"  [ngClass]="{ 'is-invalid': submitted && quotejson.error.embossing}" [(ngModel)]="quotejson.embossing" (change)="checkValidation()">
                                <option value="Y">Yes</option>
                                <option value="N">No</option>
                            </select>
                            <label *ngIf="quotejson.category_type == 'LH' && quotejson.embossing == 'Y'" class="col-xl-1 col-md-2 paddingTop10px">length <span style="color: red; font-size: 15px;">*</span></label>
                            <input *ngIf="quotejson.category_type == 'LH' && quotejson.embossing == 'Y'" class="form-control col-xl-2 col-md-2" [ngClass]="{ 'is-invalid': submitted && quotejson.error.embossinglength}" [(ngModel)]="quotejson.embossinglength" placeholder="Length in CMS" (keyup)="checkValidation()" type="text" autocomplete="off">
                            <label *ngIf="quotejson.category_type == 'LH' && quotejson.embossing == 'Y'" class="col-xl-1 col-md-2 paddingTop10px">Width <span style="color: red; font-size: 15px;">*</span></label>
                            <input *ngIf="quotejson.category_type == 'LH' && quotejson.embossing == 'Y'" class="form-control col-xl-2 col-md-2" [ngClass]="{ 'is-invalid': submitted && quotejson.error.embossingwidth}" [(ngModel)]="quotejson.embossingwidth" placeholder="Width in CMS" (keyup)="checkValidation()" type="text" autocomplete="off">
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'BN'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Packing Quantity <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pk_bitnotice_cal_id}" [(ngModel)]="quotejson.pk_bitnotice_cal_id" (change)="checkValidation()">
                                <option value="">Select packing Quantity</option>
                                <option value="{{bitPacking.pk_bit_notice_pack_id}}" *ngFor="let bitPacking of bitNoticePacking">{{bitPacking.packing}}</option>
                            </select>
                        </div>
                        <div class="form-group row" *ngIf="quotejson.category_type == 'LH'">
                            <label class="col-xl-3 col-md-3 paddingTop10px">Packing / Finishing <span style="color: red; font-size: 15px;">*</span></label>
                            <select class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.pk_letter_cal_id}" [(ngModel)]="quotejson.pk_letter_cal_id" (change)="checkValidation()">
                                <option value="">Select packing Quantity</option>
                                <option value="{{bitPacking.pk_letter_cal_id}}" *ngFor="let bitPacking of letterHeadPacking">{{bitPacking.packing}}</option>
                            </select>
                        </div>
                        <div class="form-group row" >
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BB'">No of Books <span style="color: red; font-size: 15px;">*</span></label>
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'BN'">No of Copies you want <span style="color: red; font-size: 15px;">*</span></label>
                            <label class="col-xl-3 col-md-3 paddingTop10px" *ngIf="quotejson.category_type == 'LH'">No of Letter Heads <span style="color: red; font-size: 15px;">*</span></label>
                            <input class="form-control col-xl-4 col-md-5" [ngClass]="{ 'is-invalid': submitted && quotejson.error.quantity}" (keyup)="returnNumber()" type="text" [(ngModel)]="quotejson.quantity" (change)="checkValidation()">
                        </div>
                        <div class="form-group row submitBtn">
                            <button class="default-btn three marginRight20px" (click)="saveQuote()">Get a Quote</button>
                            <button class="optionBtndelete three marginLeft20px" (click)="resetAll()">Reset</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-one></app-footer-style-one>