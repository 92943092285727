<app-navbar-style-one></app-navbar-style-one>
    <section class="pt-50">
        <div class="container">
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="customerproducts != '' && user_type != 'SA'">
                    <div class="row">
                        <h4 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Search Jobs</h4>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                            <label><b>Job Name</b></label>
                            <input class="form-control" [(ngModel)]="job_name" (keyup)="getCustomerproducts()">
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                        </div>
                        <div class="pt-25 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 text-right">
                            <button (click)="deleteSelectedProducts()" class="btn default-btn">Delete</button>
                        </div>
                    </div>
                    <table class="table table_res">
                        <thead>
                            <th><input type="checkbox" class="cusor_pointer" [checked]="selectAll" (change)="selectAllProducts($event.target.checked)"></th>
                            <th>Image</th>
                            <th>Product Name (Job Code)</th>
                            <th>Product Quality</th>
                            <th class="text-center">Quantity</th>
                            <th class="text-right">Total Amount (Rs)</th>
                            <th class="text-right">Actions</th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cust of customerproducts">
                                <th><input [checked]="cust.checked" [disabled]="cust.lock_status === 'Y'" type="checkbox" (change)="toggleCheckBox($event.target.checked, cust.pk_product_id)" value="{{cust.checked}}" class="cusor_pointer"></th>
                                <td>
                                    <a *ngIf="cust.image_path_with_name == '' || cust.image_path_with_name == 'null'" href="{{imagelink+cust.image_thumb}}" target="_blank"><img src="{{imagelink+cust.image_thumb}}" width="80px" height="80px"></a>
                                    <a *ngIf="cust.image_path_with_name != '' && cust.image_path_with_name != 'null'" href="{{imagelink+cust.image_path_with_name}}" target="_blank"><span><img src="{{imagelink+cust.image_path_with_name}}" width="80px" height="80px"></span></a>
                                </td>
                                <td>{{cust.product_name}}{{ cust.job_code?' - '+cust.job_code:'' }}</td>
                                <td>{{cust.quality_name}}</td>
                                <td class="text-center">{{cust.quantity?cust.quantity:0}}</td>
                                <td class="text-right">Rs {{cust.sub_total_amount?cust.sub_total_amount:0}} +{{cust.gst?cust.gst:0}} % GST <br> <span class="days_color" *ngIf="cust.status == 'A'" >{{cust.confirm_summary_end_date?" Valid upto "+(cust.confirm_summary_end_date | date: 'dd/MM/yyyy'):'' }}</span> </td>
                                <td align="right" class="text-right cart-area">
                                    <p class="pl-15">
                                        <a *ngIf="cust.lock_status === 'Y'" class="mr-10" href="javascript:void(0)" ngbTooltip="Locked" (click)="unLockProduct(cust.pk_product_id)"><i class='bx bx-lock'></i></a>
                                        <a *ngIf="cust.lock_status === 'N'" class="mr-10" href="javascript:void(0)" ngbTooltip="Un-Locked" (click)="lockProduct(cust.pk_product_id)"><i class='bx bx-lock-open'></i></a>
                                        <a class="mr-10" ngbTooltip="View Summary" routerLink="/product-summary/{{cust.pk_product_id}}"><i class='bx bx-show-alt'></i></a>
                                        <a class="mr-10" ngbTooltip="Add to Cart" *ngIf="cust.status == 'A' && user_type != 'SA'" href="javascript:void(0)" (click)="addToCartFromCustomerProducts(cust.pk_product_id)" ><i class='bx bx-cart'></i></a>
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="customerproducts == ''" class="pt-50 pb-50 col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center">
                    No products Found
                </div>
            </div>
        </div>
    </section>
<app-footer-style-two></app-footer-style-two>