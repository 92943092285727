<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner inner-bg6">
    <div class="container">
        <div class="inner-title">
            <h3>Cart</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Cart</li>
            </ul>
        </div>
    </div>
</div> -->
<div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
    <h3>Cart</h3>
    <br><br>
</div>
<section class="cart-wraps-area">
    <div class="container">
        <div *ngIf="carts != '' && user_type != 'SA'">
            <div class="cart-wraps">
                <div class="cart-table table-responsive">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Item No</th>
                                <th scope="col">Image</th>
                                <th scope="col">Name</th>
                                <th scope="col" class="text-center">Quantity</th>
                                <th scope="col" class="text-right toright">Unit <br>Price<br>(Rs)</th>
                                <th scope="col" class="text-right toright" *ngIf="user_type == 'P'">Total <br>Price<br>(Rs)</th>
                                <th scope="col" class="text-right toright" *ngIf="user_type == 'P'">Discount <br>Amount<br>({{plan_percentage}}%)</th>
                                <th scope="col" class="text-right toright">Total (Rs)  <br>+<br>GST</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let cart of carts">
                                <td>
                                    {{cart.job_code}}
                                </td>
                                <td class="product-thumbnail position-relative">
                                    <a class="position-relative" *ngIf="cart.category_type == 'NC'" href="{{imagelink + cart.image_large_details}}" target="_blank">
                                        <span><img *ngIf="cart.image_large_details" class="position-relative" src="{{imagelink + cart.image_large_details}}" alt="Image"></span>
                                    </a>
                                    <a class="position-relative" *ngIf="cart.category_type != 'NC'">
                                        <img *ngIf="cart.image_path_with_name == ''" class="position-relative" src="{{imagelink + cart.image_large_details}}" alt="Image">
                                        <a href="{{imagelink + cart.image_path_with_name}}" target="_blank"><span><img *ngIf="cart.image_path_with_name != ''" width="60px" height="60px" src="{{imagelink + cart.image_path_with_name}}" alt="Image"></span></a>
                                    </a>
                                </td>
                                <td class="product-name">
                                    <span *ngIf="cart.is_from == 'Q'"><a routerLink="/viewquote/{{cart.pk_quote_id}}">{{cart.job_name}}</a></span>
                                    <span *ngIf="cart.category_type == 'NC'"><a routerLink="/product-details/{{cart.product_id}}">{{cart.pd_name}}</a></span>
                                    <span *ngIf="cart.category_type != 'NC'"><a routerLink="/product-summary-view/{{cart.pk_product_id}}">{{cart.product_name}}</a></span>
                                </td>
                                <td class="product-quantity text-center">
                                    <div class="input-counter">
                                        <span class="left" (click)="getLatestAmount(cart, 'minus')">
                                            <i class='bx bx-minus'></i>
                                        </span>
                                        <input type="number" min="1" step="1" value="{{cart.quantity}}" [(ngModel)]="cart.quantity" (keyup)="getLatestAmount(cart , 'input')">
                                        <span class="right" (click)="getLatestAmount(cart , 'plus')">
                                            <i class='bx bx-plus'></i>
                                        </span>
                                    </div>
                                </td>
                                <td class="product-subtotal text-right">
                                    <span class="subtotal-amount">Rs {{cart.rate?cart.rate:0.00}}</span>
                                </td>
                                <td class="product-subtotal text-right" *ngIf="user_type == 'P'">
                                    <span class="subtotal-amount">{{cart.taxable_amount?cart.taxable_amount:0.00}}</span>
                                </td>
                                <td class="product-subtotal text-right" *ngIf="user_type == 'P'">
                                    <span class="subtotal-amount">{{cart.discount_amount?cart.discount_amount:0.00}}</span>
                                </td>
                                <td class="product-subtotal text-right">
                                    <span class="subtotal-amount">Rs {{cart.sub_total_amount?cart.sub_total_amount:0.00}}</span> 
                                    <span> +{{cart.gst?cart.gst:0.00}}% GST </span><br><span class="days_color">{{cart.confirm_summary_end_date?" Valid upto "+(cart.confirm_summary_end_date | date: 'dd/MM/yyyy'):'' }}</span>
                                </td>
                                <td class="product-subtotal text-center">
                                    <span> <a href="javascript:void(0)" (click)="removeCart(cart.cart_id)" class="remove"> <i class='bx bx-trash'></i></a></span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="cart-buttons">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-sm-7 col-md-7">
                            <div class="continue-shopping-box">
                                <a routerLink="/product" class="default-btn">Continue Shopping</a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-sm-5 col-md-5 text-right">
                            <a href="javascript:void(0)" (click)="clearCart()" class="mr-15 default-btn">Clear Cart</a>
                            <a *ngIf="isShown" href="javascript:void(0)" (click)="updateCart()" class="default-btn">Update Cart</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <!-- <div class="cart-calc">
                        <div class="cart-wraps-form"> 
                            <h3>Calculate Shipping</h3>

                            <div class="row">
                                <div class="col-lg-6">
                                    <div class="form-group">
                                        <select>
                                            <option value="">Credit Card Type</option>
                                            <option value="">Another option</option>
                                            <option value="">A option</option>
                                        </select>	
                                    </div>
                                </div>

                                <div class="form-group col-lg-6">
                                    <input type="text" class="form-control" placeholder="Credit Card Number">
                                </div>

                                <div class="form-group col-12">
                                    <input type="text" class="form-control" placeholder="Card Verification Number">
                                </div>
                            </div>

                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Coupon Code">
                            </div>

                            <a class="default-btn">Apply Coupon</a>
                        </div>
                    </div> -->
                </div>
                
                <div class="col-lg-6">
                    <div class="cart-totals">
                        <h3>Cart Totals</h3>
                        <ul>
                            <ng-container *ngIf="user_type == 'P'">
                                <li>Total <span><b>Rs {{total_amount}} </b></span></li>
                            </ng-container>
                            
                            <li *ngIf="user_type != 'P'">Total <span><b>Rs {{total_amount}} </b></span></li>
                        </ul>
                        <a href="javascript:void(0)" (click)="addToOrder()" class="default-btn">Proceed To Checkout</a>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="carts == ''" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            Cart Empty
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>