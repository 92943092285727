<app-navbar-style-one></app-navbar-style-one>
<!-- 
<div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title">
            <h3>Sign In</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Sign In</li>
            </ul>
        </div>
    </div>
</div> -->

<section class="sign-in-area ptb-50">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center">
                <span>Sign In</span>
                <h2>Sign In to your account!</h2>
                <!-- <p>with your social network</p> -->
                <ul class="sign-social-icon">
                   <!--  <li><a href="#"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#"><i class='bx bxl-twitter' ></i></a></li>
                    <li><a href="#"><i class='bx bxl-google-plus'></i></a></li>-->
                </ul> 
            </div>

            <form [formGroup]="loginForm" class="form-horizontal theme-form" novalidate="">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" placeholder="Email" required="" [ngClass]="{ 'is-invalid': submitted && f.userLogin.errors }" formControlName="userLogin" name="userLogin" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="off" class="form-control">
                            <div *ngIf="(f.userLogin.invalid && f.userLogin.touched) || f.userLogin.dirty">
                                <small *ngIf="f.userLogin.errors?.required" class="text-danger">Email is required</small>
                                <small *ngIf="f.userLogin.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && f.userPwd.errors }" required="" formControlName="userPwd" name="userPwd" placeholder="Password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" autocomplete="off" class="form-control">
                            <div *ngIf="(f.userPwd.invalid && f.userPwd.touched) || f.userPwd.dirty">
                                <small *ngIf="f.userPwd.errors?.required" class="text-danger">Email is required</small>
                                <small *ngIf="f.userPwd.errors?.pattern" class="text-danger">Please provide a valid Password</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6 form-condition">
                        <div class="agree-label">
                            <input type="checkbox" id="chb1">
                            <label for="chb1">Remember Me</label>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <a class="forget" routerLink="/recover-password">Forgot my password?</a>
                    </div>
                    <div class="form-terms">
                        <small *ngIf="loginerror" class="text-danger">{{loginmessage}}</small>
                    </div>
                    <div class="col-12 text-center mt-20px" *ngIf="showAdminBox">
                        <p class="message">Your Account has not activated please contact our admin.</p>
                    </div>
                    <div class="col-12 text-center">
                        <button class="default-btn btn-two sign-btn" (click)="login()" >Sign In Now</button>
                    </div>

                    <div class="col-12">
                        <p class="account-desc">Not a member? <a routerLink="/sign-up">Register</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>