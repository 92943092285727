import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../common/services/common.service';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
declare var $:any;
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  public products:any = [];
  // public productslength: number = 0;
  public subCategories: any = [];
  public sub_category_id: any = '';
  public count: any = '';
  public productsmessage: any = '';
  public searchproducttext: any = '';
  public imagelink: any = environment.imageUrl;
  public category_image: any = '';
  public name: any = '';
  public email: any = '';
  public phone_number: any = '';
  public msg_subject: any = '';
  public message: any = '';
  public submitted: boolean = false;
  public formSubmit:boolean = false;
  public error: any = {};
  public user_id = localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public user_name = localStorage.getItem('');
  public user_details: any = {};
  public productslength: boolean = false;
  public iconClasses: object;
  
  constructor(
    private commonService: CommonService, 
    private router: Router, 
    private route: ActivatedRoute,
    private authenticationService: AuthService,
    private toastr: ToastrService
    ) {
    route.params.forEach(params => {
      if(params.id) {
        this.sub_category_id = params.id;
        this.getProductsByCategory(this.sub_category_id);
      }
    });
    this.error = {};
   }

  ngOnInit(): void {
    this.getProducts();
    this.searchproducttext = '';
    this.getproductCategory();
    this.iconClasses = {
      error: 'toast-error',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning',
    };
  }

  getProductsByCategory(sub_category_id){
    if(sub_category_id){
      this.sub_category_id = sub_category_id;
      const bookstype = this.subCategories.filter((data: any) => {
        if(data.category_id == sub_category_id){
          if(data.active){
            data.active = false;
          }else{
            data.active = true;
          }
          this.category_image = data.cat_image;
        }else{
          data.active = false;
        }
      });
    }else{
      this.sub_category_id = '';
    }
    this.getProducts();
  }

  getProducts(){
    const formData = new FormData();
    formData.append('searchproducttext', this.searchproducttext);
    formData.append('sub_category_id', this.sub_category_id);
    formData.append('pg', '1');
    this.commonService.getProducts(formData).subscribe((response: any) => {
      if (response.status) {
        $('.preloader').fadeOut('slow');
        this.products = response.data;
        this.productslength = false;
        this.count = response.count;
      }else{
        this.products = [];
        this.productsmessage = response.message;
        this.productslength = true;
        this.count = response.count;
        this.getUserdetails();
      }
    });
  }

  getproductCategory(){
    this.commonService.getSubCategories().subscribe((response: any) => {
      if (response.status) {
        this.subCategories = response.data;
        if(this.sub_category_id){
          this.getProductsByCategory(this.sub_category_id);
        }
      }
    });
  }

  sendContact(){
    this.submitted = true;
    this.checkValildation();
    if(this.formSubmit){
      const formData = new FormData();
      formData.append('name' , this.name);
      formData.append('email' , this.email);
      formData.append('phone_number' , this.phone_number);
      formData.append('msg_subject' , this.msg_subject);
      formData.append('message' , this.message);
      $('.preloader').fadeIn('slow');
      this.commonService.sendProductRequirement(formData).subscribe((response: any) => {
        if (response.status) {
          $('.preloader').fadeOut('slow');
          this.showSuccess();
          this.name = "";
          this.email = "";
          this.phone_number = "";
          this.msg_subject = "";
          this.message = "";
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        }
      });
    }
      
  }

  checkValildation(){
    this.error.name = false;
    this.error.email = false;
    this.error.phone_number = false;
    this.error.msg_subject = false;
    this.error.message = false;
    
    if(this.name == '' || this.name == null || this.name == undefined){
      this.error.name = true;
    }
    if(this.email == '' || this.email == null || this.email == undefined){
      this.error.email = true;
    }else{
      let isnum = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
        if(isnum){
          this.error.email = false;
        }else{
          this.error.email = true;
        }
    }
    if(this.phone_number == '' || this.phone_number == null || this.phone_number == undefined){
      this.error.phone_number = true;
    }
    if(this.msg_subject == '' || this.msg_subject == null || this.msg_subject == undefined){
      this.error.msg_subject = true;
    }
    if(this.message == '' || this.message == null || this.message == undefined){
      this.error.message = true;
    }

    if( !this.error.name && !this.error.email &&
        !this.error.phone_number && !this.error.msg_subject &&
        !this.error.message){
      this.formSubmit = true;
    }else{
      this.formSubmit = false;
    }
  }

  getUserdetails(){
    if(this.authenticationService.isLoggedin()){
        this.commonService.getUserDetails(this.user_id, this.user_type).subscribe((response: any) => {
          if (response.status) {
            this.user_details = response.data;
            this.name = this.user_details.customer_name;
            this.email = this.user_details.customer_email;
            this.phone_number = this.user_details.customer_mobile;
          }
        });
  
    }
  }

  showSuccess() {
    this.toastr.success('Email has been sent successfully!');
  }
  showerror(){
    this.toastr.warning('Email fail!');
  }
  

}