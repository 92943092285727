import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../../common/services/common.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  constructor(private commonService: CommonService) { }

  public images:any = [];
  public imagelink: any = environment.imageUrl;

  ngOnInit(): void {
    this.getGalleryImages();
  }

  getGalleryImages(){
    this.commonService.getGalleryImages().subscribe((response: any) => {
      if (response.status) {
        this.images = response.data;
      }
    });
  }

}
