<app-navbar-style-one></app-navbar-style-one>
<section class="product-area pt-50 pb-70 body">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Orders Details</h1>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <span>Ordered on {{order.date}}</span> | <span>Order# {{order.order}}</span> | 
                <span *ngIf="order.status == '2'" ><a class="mr-15px" href="javascript:void(0)" (click)="downloadInvoice(order.order_id)">Download Invoice</a></span> 
                <span class="optionBtndelete float_right" *ngIf="order.status == '1'">Pending</span>
                <span class="optionBtnedit float_right" *ngIf="order.status == '2' || order.status == '3'">Order Confirmed</span>
            </div>
        </div>
        <div class="row a-box-inner background-color pt-10 borderColour padding10px mt-10">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 padding10px">
                <h6 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Shipping Address</h6>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {{order.shipping_street_1}} , {{order.shipping_street_2}}
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {{order.shipping_area }}, {{order.shipping_city}}
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {{order.shipping_state}}, {{order.shipping_country }}
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {{order.shipping_pincode }}
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    {{order.shipping_date?'Excepted Delivery Date: '+order.shipping_date:'' }}
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 padding10px font_status"  *ngIf="order.status == '2' || order.status == '3'">
                <h6>ORDER STATUS</h6>
                <div *ngIf="order.invoice_status == '1'" [ngClass]="{ 'active': order.invoice_status == '1' }">PENDING</div>
                <div *ngIf="order.invoice_status == '2'" [ngClass]="{ 'active': order.invoice_status == '2' }">ORDER CONFIRMED BY ACHAGAM</div>
                <div *ngIf="order.invoice_status == '3'" [ngClass]="{ 'active': order.invoice_status == '3' }">RAW MATERIAL READY</div>
                <div *ngIf="order.invoice_status == '4'" [ngClass]="{ 'active': order.invoice_status == '4' }">WORK UNDER PROCESS</div>
                <div *ngIf="order.invoice_status == '5'" [ngClass]="{ 'active': order.invoice_status == '5' }">MATERIAL READY FOR DESPATCH</div>
                <div *ngIf="order.invoice_status == '6'" [ngClass]="{ 'active': order.invoice_status == '6' }">MATERIAL DESPATCHED THROUGH</div>
                <div *ngIf="order.invoice_status == '7'" [ngClass]="{ 'active': order.invoice_status == '7' }">MATERIAL DELIVERED & BILL COPY SENT TO MAIL</div>
                <div *ngIf="order.invoice_status == '8'" [ngClass]="{ 'active': order.invoice_status == '8' }">ORDER CANCELLED</div>
                <div *ngIf="order.invoice_status == '9'" [ngClass]="{ 'active': order.invoice_status == '9' }">MATERIAL RETURNED</div>
                <div *ngIf="order.invoice_status == '10'" [ngClass]="{ 'active': order.invoice_status == '10' }">JOB ON HOLD</div>
            </div>
            <div [ngClass]="order.status == '2' || order.status == '3' ? 'col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12' : 'col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12'" class="padding10px">
                <h5 class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Order Summary</h5>
                <label class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">items(s) Subtotal: {{order.total_amount}}</label>
                <label class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">shipping: Rs 0.00</label>                
                <label class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Grand Total: Rs {{order.total_amount}}</label>
            </div>
        </div>
        <div class="row padding10px borderColour" *ngFor="let prod of order.products">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padding10px">
                <div class="row">
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div>
                            <a href="{{imagelink+prod.image_path_with_name}}" target="_blank"><span><img src="{{imagelink+prod.image_path_with_name}}" alt=""></span></a>
                        </div>
                        <ul *ngIf="prod.images != ''">
                            <label for="Images">Sample Files :</label>
                            <li *ngFor="let upimg of prod.images;let i=index;">
                                <a href="{{imagelink+upimg.image_path_with_name}}" target="_blank"><span>{{upimg.original_image_name}}</span></a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12" >
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left" *ngIf = "prod.category_type == 'NC'">
                            <a href="javascript:void(0)" routerLink="/product-details/{{prod.product_id}}">
                                <span class="a-size-small a-color-secondary">{{prod.pd_name}}</span></a>
                                {{prod.description}}
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left" *ngIf = "prod.category_type != 'NC'">
                            <a href="javascript:void(0)">
                                <span class="a-size-small a-color-secondary">{{prod.product_name}}</span></a>
                                {{prod.description}}
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left">
                            <span class="a-size-small a-color-secondary">
                                <b>Quantity</b> : {{prod.quantity}}
                            </span>
                        </div>
                        <div class="col-10">
                            <span class="a-size-small a-color-secondary">
                                Rate per unit : {{prod.rate}}
                            </span>
                        </div>
                        <div class="col-10" *ngIf="user_type == 'P'">
                            <span class="a-size-small a-color-secondary">
                                Sub Total Amount (Rs) : {{prod.taxable_amount}}
                            </span>
                        </div>
                        <div class="col-10" *ngIf="user_type == 'P'">
                            <span class="a-size-small a-color-secondary">
                                Discount Amt({{plan_percentage}} %) : {{prod.discount}}
                            </span>
                        </div>
                        <div class="col-10">
                            <span class="a-size-small a-color-secondary">
                                Amount : {{prod.sub_total_amount}}
                            </span>
                        </div>
                        <div class="col-10">
                            <span class="a-size-small a-color-secondary">
                                Tax : {{prod.gst?prod.gst:0}} %
                            </span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left">
                            <div class="a-size-small a-color-secondary">
                                <span><b>Total Price</b> : Rs {{prod.total_price}}</span>
                            </div>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-left">
                            <span *ngIf="prod.quality_name" class="a-size-small a-color-secondary">
                                <b>Quality</b> : {{prod.quality_name}}
                            </span>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12 ">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <span class="a-size-small a-color-secondary"><b>Part Number</b> : {{prod.part_code}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <span class="a-size-small a-color-secondary"><b>Category Name</b> : {{prod.category_name}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <span class="a-size-small a-color-secondary"><b>Size Name</b> : {{prod.size_name}}-({{prod.size_cms_left}}*{{prod.size_cms_right}})</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB'">
                            <span class="a-size-small a-color-secondary"><b>No of bills per book</b> : {{prod.no_of_bills}}</span>
                        </div>
                       
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB'">
                            <span class="a-size-small a-color-secondary"><b>No of bills per page</b> : {{prod.no_of_bills_per_page}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.front_colours">
                            <span class="a-size-small a-color-secondary"><b>Front Colours</b> : {{prod.front_colours}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.back_colours">
                            <span class="a-size-small a-color-secondary"><b>Back Colours</b> : {{prod.back_colours}}</span>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                        <div *ngIf="prod.is_from == 'P'">
                            <ng-container *ngIf="prod.paper_one_carbon == 'Y' && prod.category_type == 'BB'">
                                <ng-container *ngIf="prod.pk_sheet_id == 1 || prod.pk_sheet_id == '1'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper </b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 2 || prod.pk_sheet_id == '2'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '2' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 3 || prod.pk_sheet_id == '3'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '2' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '3' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 4 || prod.pk_sheet_id == '4'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '2' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '3' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '4' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 5 || prod.pk_sheet_id == '5'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '2' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '3' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_four_name?removeFromAt(prod.paper_four_name):''}}{{prod.paper_four_colour_name?' ( '+prod.paper_four_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '4' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Five</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '5' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="prod.paper_one_carbon != 'Y' && prod.category_type == 'BB'">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB'">
                                    <span class="a-size-small a-color-secondary"><b *ngIf="prod.pk_sheet_id > 1">Paper One</b> <b *ngIf="prod.pk_sheet_id == 1">Paper </b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 1">
                                    <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '2' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 2">
                                    <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '3' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 3">
                                    <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_four_name?removeFromAt(prod.paper_four_name):''}}{{prod.paper_four_colour_name?' ( '+prod.paper_four_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '4' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 4">
                                    <span class="a-size-small a-color-secondary"><b>Paper Five</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.pk_sheet_id == '5' && prod.book_copy_status == 'Y'" >(No Printing)</span></span>
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="prod.is_from == 'Q'">
                            <ng-container *ngIf="prod.paper_one_carbon == 'Y' && prod.category_type == 'BB'">
                                <ng-container *ngIf="prod.pk_sheet_id == 1 || prod.pk_sheet_id == '1'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper </b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 2 || prod.pk_sheet_id == '2'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper5 == 'N'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 3 || prod.pk_sheet_id == '3'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper2 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper5 == 'N'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 4 || prod.pk_sheet_id == '4'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper2 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper3 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper5 == 'N'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="prod.pk_sheet_id == 5 || prod.pk_sheet_id == '5'">
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper One</b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper2 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper3 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_four_name?removeFromAt(prod.paper_four_name):''}}{{prod.paper_four_colour_name?' ( '+prod.paper_four_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper4 == 'N'" >(No Printing)</span></span>
                                    </div>
                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                        <span class="a-size-small a-color-secondary"><b>Paper Five</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper5 == 'N'" >(No Printing)</span></span>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="prod.paper_one_carbon != 'Y' && prod.category_type == 'BB'">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB'">
                                    <span class="a-size-small a-color-secondary"><b *ngIf="prod.pk_sheet_id > 1">Paper One</b> <b *ngIf="prod.pk_sheet_id == 1">Paper </b>  : {{prod.paper_one_name?removeFromAt(prod.paper_one_name):''}}{{prod.paper_one_colour_name?' ( '+prod.paper_one_colour_name+' ) ':''}}</span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 1">
                                    <span class="a-size-small a-color-secondary"><b>Paper Two</b> : {{prod.paper_two_name?removeFromAt(prod.paper_two_name):''}}{{prod.paper_two_colour_name?' ( '+prod.paper_two_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper2 == 'N'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 2">
                                    <span class="a-size-small a-color-secondary"><b>Paper Three</b> : {{prod.paper_three_name?removeFromAt(prod.paper_three_name):''}}{{prod.paper_three_colour_name?' ( '+prod.paper_three_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper3 == 'N'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 3">
                                    <span class="a-size-small a-color-secondary"><b>Paper Four</b> : {{prod.paper_four_name?removeFromAt(prod.paper_four_name):''}}{{prod.paper_four_colour_name?' ( '+prod.paper_four_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper4 == 'N'" >(No Printing)</span></span>
                                </div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BB' && prod.pk_sheet_id > 4">
                                    <span class="a-size-small a-color-secondary"><b>Paper Five</b> : {{prod.paper_five_name?removeFromAt(prod.paper_five_name):''}}{{prod.paper_five_colour_name?' ( '+prod.paper_five_colour_name+' ) ':''}}<span *ngIf="prod.checkpaper5 == 'N'" >(No Printing)</span></span>
                                </div>
                            </ng-container>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type != 'BB'">
                            <span class="a-size-small a-color-secondary"><b>Paper Name</b> : {{prod.paper_name?removeFromAt(prod.paper_name):''}}{{prod.paper_colour_name?' ( '+prod.paper_colour_name+' ) ':''}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.category_type == 'BN' && ( prod.lamination_type == 'M' || prod.lamination_type == 'G' ) ">
                            <span class="a-size-small a-color-secondary" *ngIf="prod.lamination_type == 'M'"><b>Lamination Type</b>: Matt</span>
                            <span class="a-size-small a-color-secondary" *ngIf="prod.lamination_type == 'G'"><b>Lamination Type</b>: Glossy</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="prod.lamination_side && prod.category_type == 'BN'">
                            <span class="a-size-small a-color-secondary"><b>Lamination Side</b> : {{prod.lamination_side}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="(prod.category_type == 'BB' || prod.category_type == 'BN') && prod.numbering != 'N'">
                            <span class="a-size-small a-color-secondary" *ngIf="prod.numbering == 'M'"><b>Numbering</b> : Manual</span>
                            <span class="a-size-small a-color-secondary" *ngIf="prod.numbering == 'MA'"><b>Numbering</b> : Machine</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="(prod.category_type == 'BB' || prod.category_type == 'BN') && prod.numbering != 'N'">
                            <span class="a-size-small a-color-secondary" *ngIf="prod.is_numbering == 'C'"><b>is Numbering</b> : Continuous</span>
                            <span class="a-size-small a-color-secondary" *ngIf="prod.is_numbering == 'R'"><b>is Numbering</b> : Repeate</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="(prod.category_type == 'BB' || prod.category_type == 'BN') && prod.is_numbering !='' && prod.numbering != 'N' && prod.numbering_start_from != '' && prod.numbering_start_from != null ">
                            <span class="a-size-small a-color-secondary"><b>Numbering Starts From</b> : {{prod.numbering_start_from}}</span>
                        </div>
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="(prod.category_type == 'BB' || prod.category_type == 'BN') &&  prod.perforation != 'N'">
                            <span class="a-size-small a-color-secondary" *ngIf="prod.perforation == 'M'"><b>Perforation</b> : Manual</span>
                            <span class="a-size-small a-color-secondary" *ngIf="prod.perforation == 'MA'"><b>Perforation</b> : Machine</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row text-center pt-20">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <button class="default-btn-post_jobs" routerLink="/orders">Back</button>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>