<app-navbar-style-one></app-navbar-style-one>
<section class="register-page pt-50">
    <div class="container">
        <div class="row paddingBottom10px">
            <button class="default-btn-post_jobs" (click)="open(content)">Add New Address</button>
            <ng-template #content let-modal >
                <div class="col-lg-12 billing-details">
                    <div class="row marginTop10">
                        <div class="col-6">
                            <h3>Address</h3>
                        </div>
                        <div class="col-6">
                            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="theme-card">
                        <div class="card-body tab2-card">
                            <div class="paddingTop20px">
                                <form [formGroup]="addAddressForm" class="needs-validation" novalidate="" >
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Street 1</label>
                                        <input class="form-control col-xl-8 col-md-5"  [ngClass]="{ 'is-invalid': submitted && p.street_1.errors }" formControlName="street_1"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">    
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Street 2</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.street_2.errors }" formControlName="street_2"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Area</label>
                                        <input class="form-control col-xl-8 col-md-5"[ngClass]="{ 'is-invalid': submitted && p.area.errors }" formControlName="area"
                                            type="text" autocomplete="off" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Pincode</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.pincode.errors }" formControlName="pincode"
                                            type="number" autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>City</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.city.errors }" formControlName="city" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>State</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.state.errors }" formControlName="state" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-xl-4 col-md-3 label_align"><span> </span>Country</label>
                                        <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.country.errors }" formControlName="country" type="text"
                                            autocomplete="off">
                                    </div>
                                    <div class="form-group row" *ngIf="showaddresstypebox">
                                        <label class="col-xl-4 col-md-3 label_align">Address Type</label>
                                        <select (change)="showcheckbox($event.target.value)" class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.address_type.errors }" formControlName="address_type">
                                            <option value="disabled">Select Address Type</option>
                                            <option value="B">Billing Address</option>
                                            <option value="S">Shipping Address</option>
                                            <option value="H">Both</option>
                                        </select>
                                    </div>
                                        <div class="show_billing" *ngIf="showbillingaddressbox">
                                            <div class="form-group row">
                                                <input type="checkbox"  name="default_billing" value="" title="Use as My Default Billing Address" class="checkbox" formControlName="is_default_billingaddress">
                                                <label>Use as my default billing address</label>
                                            </div> 
                                        </div>
                                        <div class="show_shipping" *ngIf="showshippingaddressbox">
                                            <div class="form-group row">
                                                <input type="checkbox" name="default_shipping"  value="" title="Use as my default shipping address" class="checkbox" formControlName="is_default_shippingaddress">
                                                <label>Use as my default shipping address</label>
                                            </div>
                                        </div>                                    
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="default-btn-post_jobs" (click)="saveaddress();">Save</button>
                        <button type="button" class="default-btn-post_jobs" (click)="modal.dismiss('Cross click');">Cancel</button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</section>
<section class="product-area pt-30 pb-70">
    <div class="container">
            <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-12 col-12">
                    <div class="billing_address">
                        <h6 class="header">DEFAULT BILLING ADDRESS</h6>
                        <div class="row" *ngIf="defaultBillingAddress != ''">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress[0].street_1}},{{defaultBillingAddress[0].street_2}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress[0].area}},{{defaultBillingAddress[0].city}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress[0].state}},{{defaultBillingAddress[0].country}},{{defaultBillingAddress[0].pincode}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"><a style="color: blue;cursor: pointer;"><span (click)="editaddress(defaultBillingAddress[0].address_id);open(content);">Change Billing Address</span></a></div>
                        </div>
                        <p *ngIf="defaultBillingAddress == ''">
                            You have not set a default billing address.
                        </p>
                    </div>
                    <br>
                    <div class="shipping_address">
                        <h6 class="header">DEFAULT SHIPPING ADDRESS</h6>
                        <div class="row" *ngIf="defaultShippingAddress != ''">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress[0].street_1}},{{defaultShippingAddress[0].street_2}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress[0].area}},{{defaultShippingAddress[0].city}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress[0].state}},{{defaultShippingAddress[0].country}},{{defaultShippingAddress[0].pincode}}</div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"><a style="color: blue;cursor: pointer;"><span (click)="editaddress(defaultShippingAddress[0].address_id);open(content);">Change Shipping Address</span></a></div>
                        </div>
                        <p *ngIf="defaultShippingAddress == ''">
                            You have not set a default shipping address.
                        </p>
                    </div>
                </div>
                <div class="col-xl-8 col-lg-7 col-md-7 col-sm-12 col-12">
                    <h6 class="header">ADDITIONAL ADDRESS ENTRIES</h6>
                    <div class="row col-lg-6 col-md-12 edit" *ngIf="editBillingAddress != ''">
                        <h6>Billing Address</h6>
                        <div class="row" *ngFor="let editBilling of editBillingAddress">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editBilling.street_1}},{{editBilling.street_2}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editBilling.area}},{{editBilling.city}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editBilling.state}},{{editBilling.country}},{{editBilling.pincode}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <a style="color: blue;cursor: pointer;"><span (click)="editaddress(editBilling.address_id);open(content);">Edit Address</span></a> | 
                                <a style="color: blue;cursor: pointer;"><span (click)="deleteaddress(editBilling.address_id);">Delete</span></a>
                                </div>
                        </div>
                    </div>
                    <div class="row col-lg-6 col-md-12 edit" *ngIf="editShippingAddress != ''">
                        <h6>Shipping Address</h6>
                        <div class="row" *ngFor="let editShipping of editShippingAddress">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editShipping.street_1}},{{editShipping.street_2}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editShipping.area}},{{editShipping.city}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{editShipping.state}},{{editShipping.country}},{{editShipping.pincode}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <a style="color: blue;cursor: pointer;"><span (click)="editaddress(editShipping.address_id);open(content);">Edit Address</span></a> | 
                                <a style="color: blue;cursor: pointer;"><span (click)="deleteaddress(editShipping.address_id);">Delete</span></a>
                                </div>
                        </div>
                    </div>
                </div>     
            </div>        
    </div>
</section>
<app-footer-style-two></app-footer-style-two>