<app-navbar-style-one></app-navbar-style-one>
<section class="register-page section-b-space">
    <div class="container">
        <div class="row mt-50">
            <button class="default-btn three" [routerLink]="['/createquote']">Create Quote</button>
        </div>
        <div class="row">
            <div class="col-sm-12 pt-20">
                <table class="table myClassTable">
                    <thead>
                        <tr>
                            <th scope="col">Job Name</th>
                            <th scope="col">Quote No</th>
                            <th scope="col">Quote Date</th>
                            <th scope="col">Category</th>
                            <th scope="col">Quantity</th>
                            <th scope="col">Amount</th>
                            <th scope="col" class="paddingleft30px text-center">Options</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let quote of quotes">
                            <td scope="col">{{quote.job_name}}</td>
                            <td scope="col"><b>{{quote.quote_no}}</b></td>
                            <td scope="col">{{quote.created_on}}</td>
                            <td scope="col">
                                <span *ngIf="quote.category_type == 'BB'">Bill Book</span>
                                <span *ngIf="quote.category_type == 'BN'">Bit Notice</span>
                                <span *ngIf="quote.category_type == 'LH'">Letter Pad</span>
                            </td>
                            <td scope="col">{{quote.quantity}}</td>
                            <td scope="col">{{quote.total_amount}}</td>
                            <td class="text-right cart-area">
                                <p>
                                    <a class="mr-10" ngbTooltip="View Summary" routerLink="/quote-summary-view/{{quote.pk_quote_id}}"><i class='bx bx-show-alt'></i></a>
                                    <a class="mr-10 arrowbutton" ngbTooltip="Upload Additional Details" *ngIf="quote.verify != 'C'" href="javascript:void(0)" routerLink="/quote-summary/{{quote.pk_quote_id}}" ><i class='bx bxs-right-arrow-circle'></i></a>
                                    <a class="mr-10" *ngIf="user_type != 'SA'" ngbTooltip="Delete" href="javascript:void(0)" (click)="deleteQuote(quote.pk_quote_id)"><i class='bx bx-trash'></i></a>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
<app-footer-style-one></app-footer-style-one>