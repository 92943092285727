import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-customerproducts',
  templateUrl: './customerproducts.component.html',
  styleUrls: ['./customerproducts.component.scss'],
  providers: [DatePipe]

})

export class CustomerproductsComponent implements OnInit {

  public user_id = localStorage.getItem('userId');
  public user_type = localStorage.getItem('user_type');
  public customerproducts: any = [];
  public imagelink: any = environment.imageUrl;
  public image_url: any = '';
  public job_name: any = '';
  public final_day: any = '';
  public selectAll: any = '';

  constructor(
    private Router: Router, 
    private route: ActivatedRoute, 
    private authenticationService: AuthService,
    private commonService: CommonService,
    private datePipe: DatePipe
  ){

  }

  ngOnInit(): void {
    this.getCustomerproducts();
  }

  selectAllProducts(e: any){
    if(e === true){
      this.selectAll = true;
    }else{
      this.selectAll = false;
    }
    this.customerproducts.filter((data: any) => {
      if(data.lock_status !== 'Y'){
        if(e === true){
          data.checked = true;
        }else{
          data.checked = false;
        }
      }
    });
  }

  toggleCheckBox(e:any, id: any){
    this.customerproducts.filter((data: any) => {
      if(data.pk_product_id === id){
        if(e === true){
          data.checked = true;
        }else{
          data.checked = false;
        }
      }
      if(data.checked === false){
        this.selectAll = false;
      }
    });
  }

  getCustomerproducts(){
    const formData = new FormData();
    formData.append('job_name' , this.job_name);
    formData.append('user_id' , this.user_id);
    formData.append('user_type' , this.user_type);
    this.commonService.getCustomerWishlist(formData).subscribe((response: any) => {
      if (response.status) {
        this.customerproducts = response.data;
        this.customerproducts.filter((data: any) => {
          data.checked = false;
        });
      }else{
        this.customerproducts = [];
        if(response.logged_out){
          this.authenticationService.logout();
          this.Router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        }
      }
    });
  }

  dayFinder(datefrom: any,dateto: any){
        const myDate = new Date();
        this.final_day = this.datePipe.transform(myDate, 'yyyy-MM-dd');
        return Math.floor(( Date.parse(dateto) - Date.parse(this.final_day) ) / 86400000); 
  }

  addToCartFromCustomerProducts(pk_product_id: any){
    const formData = new FormData();
    formData.append('pk_product_id' , pk_product_id);
    formData.append('user_id' , this.user_id);
    this.commonService.addToCartFromCustomerProducts(formData).subscribe((response: any) => {
      if (response.status) {
        this.Router.navigate(['/cart']);
      }
    });
  }

  deleteCustomerproduct(pk_product_id: any){
    const formData = new FormData();
    formData.append('pk_product_id' , pk_product_id);
    formData.append('user_id' , this.user_id);
    this.commonService.deleteCustomerProducts(formData).subscribe((response: any) => {
      if (response.status) {
        const duplicateCustomerProducts = this.customerproducts.filter((item: any) => { return item.pk_product_id !== pk_product_id });
        this.customerproducts = duplicateCustomerProducts;
      }
    });
  }

  deleteSelectedProducts(){
    let custProdIds = [];
    this.customerproducts.forEach((element: any) => {
      if(element.checked === true){
        custProdIds.push(element.pk_product_id);
      }
    });
    if(custProdIds.length > 0){
      const formData = new FormData();
      formData.append('pk_product_ids' , JSON.stringify(custProdIds));
      formData.append('user_id' , this.user_id);
      this.commonService.deleteGroupCustomerProducts(formData).subscribe((response: any) => {
        if (response.status) {
          this.getCustomerproducts();
        }
      });
    }else{
      alert("please select atleast one product");
    }
  }

  lockProduct(id: any){
    const formData = new FormData();
    formData.append('pk_product_id' , id);
    formData.append('user_id' , this.user_id);
    this.commonService.lockProduct(formData).subscribe((response: any) => {
      if (response.status) {
        this.customerproducts.filter((item: any) => { 
          if(item.pk_product_id === id){
            item.lock_status = 'Y';
          }
        });
      }
    });
  }

  unLockProduct(id: any){
    const formData = new FormData();
    formData.append('pk_product_id' , id);
    formData.append('user_id' , this.user_id);
    this.commonService.unLockProduct(formData).subscribe((response: any) => {
      if (response.status) {
        this.customerproducts.filter((item: any) => { 
          if(item.pk_product_id === id){
            item.lock_status = 'N';
          }
        });
      }
    });
  }
}