import { CommonService } from './../../common/services/common.service';
import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public plan_tag = localStorage.getItem('plan_tag');
  public plan_percentage = localStorage.getItem('pre_cus_percentage');
  public user_type = localStorage.getItem('user_type');
  public getOrderlist: any = [];
  public imagelink: any = environment.imageUrl;

  constructor(private Router: Router,private commonService: CommonService) { }

  ngOnInit(): void {
    this.getOrders();
  }

  getOrders(){
    this.commonService.getOrders(this.user_id, this.user_type).subscribe((response: any) => {
        if (response.status) {
          this.getOrderlist = response.data;
        }
    });
  }

  getOriginalAmount(value1: any, value2: any){
    return (parseFloat(value1) + parseFloat(value2)).toFixed(2);
  }

  converttodecimal(value1: any){
    return (parseFloat(value1)).toFixed(2);
  }

  cancelOrder(order_id: any){
    this.commonService.cancelOrder(order_id).subscribe((response: any) => {
      if (response.status) {
        this.getOrders();
      }
    });
  }

  downloadInvoice(order_id){
    this.commonService.downloadInvoice(order_id).subscribe((response: any) => {
      if(response.status){
        window.open(this.imagelink+response.url, "_blank");
      }else{
        console.log("Downlaod Failed");
      }
    });
  }

  reorderToCart(order_id: any){
    this.commonService.reorderToCart(order_id).subscribe((response: any) => {
      if (response.status) {
        this.Router.navigate(['/cart']);
      }
    });
  }

}
