<app-navbar-style-one></app-navbar-style-one>
<section class="product-area pt-70 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>My Dashboard</h1>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Hello {{user_detail.customer_name}}</h5>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <p>From your My Account Dashboard you have the ability to view a snapshot of your recent account activity and update your account information. Select a link below to view or edit information</p>
            </div>
        </div>
        <div class="row pt-30">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h3>ACCOUNT INFORMATION </h3>
            </div>
            <div class="row pt-20">
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <p>Reference Code</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <p>{{user_detail.reference_code}}</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <p>Wallet Amount</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <p>{{user_detail.wallet_amount}}</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <p>Credit Limit</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30" *ngIf="user_detail.user_type == 'D'">
                    <p>{{user_detail.credit_limit}}</p>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    
                </div>
                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                    <a routerLink="/wallet">View Transactions</a>
                </div>
            </div>
        </div>
        <div class="row pt-20">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left"><h5 class="mb-0">CONTACT INFORMATION</h5></div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-right">
                        <a routerLink="/account">Edit</a>
                        </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{user_detail.customer_name}}</div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{user_detail.customer_mobile}}</div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{user_detail.customer_email}}</div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{user_detail.customer_gstin}}</div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Change Password</div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 pt-30">
                <div class="row">
                    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 text-left"><h5 class="mb-0">NEWSLETTERS</h5></div>
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 text-right">Edit</div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p>You are currently subscribed to 'General Subscription'.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-50">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <div class="row">
                    <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 text-left"><h5>ADDRESS BOOK</h5></div>
                    <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 text-right"><a routerLink="/address">Manage Address</a></div>
                   

                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <b>Default Billing Address</b>
                            <div class="row" *ngIf="defaultBillingAddress">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress.street_1?defaultBillingAddress.street_1+' ,':''}}{{defaultBillingAddress.street_2?defaultBillingAddress.street_2+' , ':''}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress.area?defaultBillingAddress.area+' , ':''}}{{defaultBillingAddress.city?defaultBillingAddress.city+' , ':''}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultBillingAddress.state?defaultBillingAddress.state+' , ':''}}{{defaultBillingAddress.country?defaultBillingAddress.country+', ':''}}{{defaultBillingAddress.pincode?defaultBillingAddress.pincode+' , ':''}}</div>
                            </div>
                            <p *ngIf="defaultBillingAddress == ''">
                                You have not set a default billing address.
                            </p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <b>Default Shipping Address</b>
                            <div class="row" *ngIf="defaultShippingAddress">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress.street_1?defaultShippingAddress.street_1+' , ':''}}{{defaultShippingAddress.street_2?defaultShippingAddress.street_2+' , ':''}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress.area?defaultShippingAddress.area+',':''}}{{defaultShippingAddress.city?defaultShippingAddress.city+' , ':''}}</div>
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">{{defaultShippingAddress.state?defaultShippingAddress.state+',':''}}{{defaultShippingAddress.country?defaultShippingAddress.country+' , ':''}}{{defaultShippingAddress.pincode?defaultShippingAddress.pincode+' , ':''}}</div>
                            </div>
                            <p *ngIf="defaultShippingAddress == ''">
                                You have not set a default shipping address.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>