<app-navbar-style-one></app-navbar-style-one>

<!-- <div class="inner-banner inner-bg10">
    <div class="container">
        <div class="inner-title">
            <h3>Designer</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Designer</li>
            </ul>
        </div>
    </div>
</div>

<div class="designer-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Our Designer</span>
            <h2>Our Company Leads by Professional Designer</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer1.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Doe</h3></a>
                        <span>Director</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer2.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Smith</h3></a>
                        <span>Product Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer3.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Evanaa</h3></a>
                        <span>llustrator Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer4.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Knot Doe</h3></a>
                        <span>Mockup Specialist</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer5.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Emily Doe</h3></a>
                        <span>T-shirt Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer6.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Elena Smith</h3></a>
                        <span>Package Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer8.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Steven Smith</h3></a>
                        <span>Mockup Specialist</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer7.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Katelyn</h3></a>
                        <span>Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-two></app-footer-style-two>