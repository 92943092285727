<app-navbar-style-one></app-navbar-style-one>
<section class="product-area pt-30 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h5>Edit Account Information</h5>
            </div>
        </div>
            <div class="row pt-20" *ngIf="user_detail.customer_id">
            <div class="col-xl-4 col-lg-6 col-md-8 col-sm-10 col-12">
            <form [formGroup]="accountinfoForm" class="needs-validation" novalidate="" >
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>User Name <span class="required">*</span></label>
                        <input type="text" class="form-control" formControlName="username" [ngClass]="{ 'is-invalid': submitted && p.username.errors }">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>Mobile Number</label>
                        <input type="text" class="form-control" formControlName="mobile">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>Email Address <span class="required">*</span></label>
                        <input type="email" class="form-control" formControlName="email">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>GSTIN</label>
                        <input type="text" class="form-control" formControlName="customer_gstin">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="form-group">
                        <label>Change Password<span class="required">*</span></label>
                        <input type="checkbox" value="Y" (click)="checkbox_check()" formControlName="checkbox">
                    </div>
                </div>
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12" *ngIf="showbox">
                    <div class="form-group">
                        <input formControlName="current_password" (change) ="validateCurrentPassword()" id="current_password" type="password" placeholder="Current Password" class="form-control" />
                        <div class="alert alert-danger" *ngIf="p.current_password.touched && p.current_password.invalid">
                        </div>
                        <small *ngIf="error" class="text-danger">{{message}}</small>
                    </div>

                    <div class="form-group">
                        <input type="password" [ngClass]="{ 'is-invalid': submitted && p.new_password.errors }" formControlName="new_password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="New Password" required="" autocomplete="off">
                        <div *ngIf="(p.new_password.invalid && p.new_password.touched) || p.new_password.dirty">
                            <small *ngIf="p.new_password.errors?.required" class="text-danger">password is required</small>
                            <small *ngIf="p.new_password.errors?.pattern" class="text-danger">Password must contain at least 8 characters!<br>Atleast one Caps letter<br>
                                Atleast one numeric<br>
                                Atleast one special character(eg.&,*.@,#,$,%)
                            </small>
                        </div>
                    </div>

                    <div class="form-group">
                        <input type="password" [ngClass]="{ 'is-invalid': submitted && p.confirm_password.errors }" formControlName="confirm_password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="Confirm Password" required="" autocomplete="off">
                        <div *ngIf="(p.confirm_password.invalid && p.confirm_password.touched) || p.confirm_password.dirty">
                            <small *ngIf="p.confirm_password.errors?.required" class="text-danger">confirm password is required</small>
                        </div>
                        <small *ngIf="error" class="text-danger">{{confirimmsg}}</small>
                    </div>
                
                </div>
                
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <button class="default-btn three" (click)="saveaccountinfo_post();">Save</button>
                </div>
            </form>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>