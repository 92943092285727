import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public user_name: any = '';
  public user_detail: any = []; 
  public address_detail: any = [];
  public defaultBillingAddress: any = {};
  public defaultShippingAddress: any = {};
  
  constructor(
    private Router: Router, 
    private route: ActivatedRoute, 
    private commonService: CommonService) {
    
   }

   ngOnInit(): void {
    this.getUserDetails();
    this.getUserAddress();
  }

  getUserDetails(){
    this.commonService.getUserDetails(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.user_detail = response.data;
        this.user_name = this.user_detail.user_name;
      }
    });
  }

  getUserAddress(){
    this.commonService.getUserAddress(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.address_detail = response.data;
        const lastBackCOlours = this.address_detail.filter((data) => {
          if (data.is_default_address == 'Y' && data.address_type == 'B') {
            this.defaultBillingAddress = data;
          }
          if (data.is_default_address == 'Y' && data.address_type == 'S') {
            this.defaultShippingAddress = data;
          }
        });
      }
    });
  }

}
