<app-navbar-style-one></app-navbar-style-one>
<!-- <div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title">
            <h3>Job Summary</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Job Summary</li>
            </ul>
        </div>
    </div>
</div> -->
<section class="product-detls">
    <div class="container">  
        <div>
            <button class="default-btn three movn" [routerLink]="['/createquote']">Create Quote</button>
        </div>
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h3>Quote Summary</h3>
                <h6 class="header">{{product_name}}{{product_code? ' ( '+product_code+' ) ':''}}</h6>
                <br><br>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="product-detls-image text-center">
                    <img class="imageSection" alt="Image" *ngIf ="image_path_with_name ==''" src = "{{imagelink+image_large_details}}" >
                    <img class="imageSection" alt="Image" *ngIf="image_path_with_name !=''" src="{{imagelink+image_path_with_name}}" >
                </div>
                <div class="product-detls-image" *ngIf="images != ''">
                    <h6>Uploaded File(s)</h6>
                    <ol class="pl-15">
                        <li *ngFor="let upimg of images;let i=index;" class="pt-10 pb-10">
                            <span class="ml-10 mr-10">{{upimg.original_image_name}}</span>
                        </li>
                    </ol>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="product-desc">
                    <section>
                        <div class="container">
                            <div class="col-md-12">
                                <div class="padding">
                                    <div class="row">
                                        <label class="col-6"><b>Category</b></label>
                                        <label class="col-6">{{category_name}}</label>
                                    </div>
                                    <div class="row">
                                        <label class="col-6"><b>Size</b></label>
                                        <label class="col-6">{{size_name}}</label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB'" >
                                        <label class="col-6"><b>Bill Book Type</b></label>
                                        <label class="col-6">
                                            <span *ngIf="pk_sheet_id == 1">All Original</span>
                                            <span *ngIf="pk_sheet_id == 2">Duplicate</span>
                                            <span *ngIf="pk_sheet_id == 3">Triplicate</span>
                                            <span *ngIf="pk_sheet_id == 4">Quadriplicate</span>
                                            <span *ngIf="pk_sheet_id == 5">Quintuplicate</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB'">
                                        <label class="col-6">
                                            <b *ngIf="pk_sheet_id == 1">Paper </b>
                                            <b *ngIf="pk_sheet_id > 1">Paper 1 </b>
                                        </label>
                                        <label class="col-6">{{paper_one_name?removeFromAt(paper_one_name):''}}{{paper_one_colour_name?' ( '+paper_one_colour_name+' ) ':''}}</label>
                                    </div>
                                    <div class="row" *ngIf="disablePaper2 && prod_cat_id == 'BB'">
                                        <label class="col-6"><b>Paper 2</b></label>
                                        <label class="col-6">{{paper_two_name?removeFromAt(paper_two_name):''}}{{paper_two_colour_name?' ( '+paper_two_colour_name+' ) ':''}}<span *ngIf="checkpaper2 == 'N'" >(No Printing)</span></label>
                                    </div>
                                    <div class="row" *ngIf="disablePaper3 && prod_cat_id == 'BB'">
                                        <label class="col-6"><b>Paper 3</b></label>
                                        <label class="col-6">{{paper_three_name?removeFromAt(paper_three_name):''}}{{paper_three_colour_name?' ( '+paper_three_colour_name+' ) ':''}}<span *ngIf="checkpaper3 == 'N'" >(No Printing)</span></label>
                                    </div>
                                    <div class="row" *ngIf="disablePaper4 && prod_cat_id == 'BB'">
                                        <label class="col-6"><b>Paper 4</b></label>
                                        <label class="col-6">{{paper_four_name?removeFromAt(paper_four_name):''}}{{paper_four_colour_name?' ( '+paper_four_colour_name+' ) ':''}}<span *ngIf="checkpaper4 == 'N'" >(No Printing)</span></label>
                                    </div>
                                    <div class="row" *ngIf="disablePaper5 && prod_cat_id == 'BB'">
                                        <label *ngIf="disablePaper4 && disablePaper3 && disablePaper2" class="col-6"><b>Paper 5</b></label>
                                        <label *ngIf="!disablePaper4 && disablePaper3 && disablePaper2" class="col-6"><b>Paper 4</b></label>
                                        <label *ngIf="!disablePaper4 && !disablePaper3 && disablePaper2" class="col-6"><b>Paper 3</b></label>
                                        <label *ngIf="!disablePaper4 && !disablePaper3 && !disablePaper2" class="col-6"><b>Paper 2</b></label>
                                        <label class="col-6">{{paper_five_name?removeFromAt(paper_five_name):''}}{{paper_five_colour_name?' ( '+paper_five_colour_name+' ) ':''}}<span *ngIf="checkpaper5 == 'N'" >(No Printing)</span></label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id != 'BB'">
                                        <label class="col-6"><b>Paper</b></label>
                                        <label class="col-6">{{paper_name?removeFromAt(paper_name):''}}{{paper_colour_name?' ( '+paper_colour_name+' ) ':''}}</label>
                                    </div>
                                    <div class="row" *ngIf="front_colours != ''">
                                        <label class="col-6"><b>Printing Color Front</b></label>
                                        <label class="col-6">{{front_colours}}</label>
                                    </div>
                                    <div class="row" *ngIf="back_colours != ''">
                                        <label class="col-6"><b>Printing Color Back</b></label>
                                        <label class="col-6">{{back_colours}}</label>
                                    </div>
                                    <div class="row" *ngIf="back_colours != '' && front_colours != '' && is_same_content == 'Y'">
                                        <label class="col-6"><b>Front & Back Same Printing Content</b></label>
                                        <label class="col-6">
                                            <span *ngIf="is_same_content == 'Y'">Yes</span>
                                            <span *ngIf="is_same_content == 'N'">No</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB'">
                                        <label class="col-6"><b>No of Bills Per Book</b></label>
                                        <label class="col-6">{{no_of_bills}}</label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB'">
                                        <label class="col-6"><b>No of Bills Per Page</b></label>
                                        <label class="col-6">{{no_of_bills_per_page}}</label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BN' &&  (lamination_type == 'M' || lamination_type == 'G') ">
                                        <label class="col-6"><b>Lamination Type</b></label>
                                        <label class="col-6">
                                            <span *ngIf="lamination_type == 'M'">Matt</span>
                                            <span *ngIf="lamination_type == 'G'">Glossy</span>
                                        </label>
                                    </div>
                                    <!-- <div class="row" *ngIf="prod_cat_id == 'BN' && lamination_type != 'N'">
                                        <label class="col-6"><b>Lamination Sides</b></label>
                                        <label class="col-6">{{lamination_side}}
                                        </label>
                                    </div> -->
                                    <div class="row" *ngIf="prod_cat_id == 'BB' && numbering != 'N'">
                                        <label class="col-6"><b>Numbering</b></label>
                                        <label class="col-6">
                                            <span *ngIf="numbering == 'M'">Manual</span>
                                            <span *ngIf="numbering == 'MA'">Machine</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB' && numbering != 'N' && is_numbering != ''">
                                        <label class="col-6"><b>Numbering Type</b></label>
                                        <label class="col-6">
                                            <span *ngIf="is_numbering == 'C'">Continous</span>
                                            <span *ngIf="is_numbering == 'R'">Repeated</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BN' && bitnotice_numbering != 'N'">
                                        <label class="col-6"><b>Numbering Type</b></label>
                                        <label class="col-6">
                                            <span>Machine</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="no_of_places_for_numbering > 1 && prod_cat_id == 'BB' && pnumber_id != '' && numbering != 'N'">
                                        <label class="col-6"><b>No of Places for Numbering in a Bill: </b></label>
                                        <label class="col-6">{{no_of_places_for_numbering}}</label>
                                    </div>
                                    <div *ngIf="no_of_places_for_numbering > 1 && prod_cat_id == 'BN' && pnumber_id != '' && bitnotice_numbering != 'N'">
                                        <label class="col-6"><b>No of Places for Numbering: </b></label>
                                        <label class="col-6">{{no_of_places_for_numbering}}</label>
                                    </div>
                                    <div class="row" *ngIf=" ( prod_cat_id == 'BN' || prod_cat_id == 'BB' ) && numbering != 'N' && numbering_start_from != '' &&  numbering_start_from != null ">
                                        <label class="col-6"><b>Numbering Starts From: </b></label>
                                        <label class="col-6">{{numbering_start_from}}</label>
                                    </div>

                                    <div class="row" *ngIf="prod_cat_id == 'BB' && perforation != 'N'">
                                        <label class="col-6"><b>Perforation</b></label>
                                        <label class="col-6">
                                            <span *ngIf="perforation == 'M'">Manual</span>
                                            <span *ngIf="perforation == 'MA'">Machine</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BN' && bitnotice_perforation != 'N'">
                                        <label class="col-6"><b>Perforation</b></label>
                                        <label class="col-6">
                                            <span>Micro</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BN' && prod_detail.folding == 'Y'">
                                        <label class="col-6"><b>Folding</b></label>
                                        <label class="col-6">
                                            <span *ngIf="prod_detail.folding == 'Y'">Yes</span>
                                            <span *ngIf="prod_detail.folding == 'N'">No</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BB'">
                                        <label class="col-6"><b>Binding</b></label>
                                        <label class="col-6">
                                            <span *ngIf="binding == 1">Pad Binding</span>
                                            <span *ngIf="binding == 2">Soft Binding</span>
                                            <span *ngIf="binding == 3">Hard Binding</span>
                                        </label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'BN'">
                                        <label class="col-6"><b>Package</b></label>
                                        <label class="col-6"><span>{{bitnoticepacking}}</span></label>
                                    </div>
                                    <div class="row" *ngIf="prod_cat_id == 'LH'">
                                        <label class="col-6"><b>Package / Finishing</b></label>
                                        <label class="col-6"><span>{{letterheadpacking}}</span></label>
                                    </div>
                                    <div class="row">
                                        <label class="col-6"><b>Quantity</b></label>
                                        <label class="col-6">{{quantity}}</label>
                                    </div>
                                    <div class="row" *ngIf="user_type == 'P'">
                                        <label class="col-6"><b>Sub Total Amount </b></label>
                                        <label class="col-6">{{taxable_amount}}</label>
                                    </div>
                                    <div class="row" *ngIf="user_type == 'P'">
                                        <label class="col-6"><b>Discount Amount</b></label>
                                        <label class="col-6">{{discount_amount}}</label>
                                    </div>
<!--                                 
                                    <div class="row">
                                        <label class="col-6"><b>GST : </b></label>
                                        <label class="col-6">{{gst?gst:0.00}} %</label>
                                    </div> -->
                                    <div class="row">
                                        <label class="col-6"><b>AMOUNT : </b></label>
                                        <label class="col-6">Rs {{sub_total_amount?sub_total_amount:0.00}} +{{gst?gst:0.00}} % GST</label>
                                    </div>
                                </div>
                                <div class="product-add row text-center">
                                    <button type="submit" routerLink="/quote-summary/{{cus_quote_id}}" class="default-btn">
                                        <i class="fas fa-cart-plus"></i> Edit
                                    </button>
                                    <button *ngIf="prod_status == 'P'" class="default-btn" (click)="saveAndSendToWhishlist()">
                                        <i class="fas fa-cart-plus"></i> Confirm Summary
                                    </button>
                                    <button *ngIf="prod_status == 'C'" class="default-btn" (click)="saveToCart()">
                                        <i class="fas fa-cart-plus"></i>Add to Cart
                                    </button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
</section>
<br><br>
<app-footer-style-two></app-footer-style-two>