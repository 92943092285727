<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg9">
    <div class="container">
        <div class="inner-title">
            <h3>Sign Up</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Sign Up</li>
            </ul>
        </div>
    </div>
</div>

<section class="sign-up-area ptb-100">
    <div class="container">
        <div class="contact-wrap-form sign-in-width">
            <div class="scetion-title text-center">
                <span>Sign Up</span>
                <h2>Create An Account!</h2>
                <!-- <p>with your social network</p> -->
                <ul class="sign-social-icon">
                   <!--   <li><a href="#"><i class='bx bxl-facebook'></i></a></li>
                    <li><a href="#"><i class='bx bxl-twitter' ></i></a></li>
                    <li><a href="#"><i class='bx bxl-google-plus'></i></a></li> -->
                </ul> 
            </div>

            <form [formGroup]="registerForm" class="form-horizontal auth-form theme-form" novalidate="">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && g.customer_name.errors }" formControlName="customer_name" class="form-control" id="customer_name" placeholder="Username" required="" autocomplete="off" >
                            <div *ngIf="(g.customer_name.invalid && g.customer_name.touched) || g.customer_name.dirty">
                                <small *ngIf="g.customer_name.errors?.required" class="text-danger">Name is required</small>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && g.customer_mobile.errors }" formControlName="customer_mobile" class="form-control" id="customer_mobile" placeholder="Mobile" required="" autocomplete="off">
                            <div *ngIf="(g.customer_mobile.invalid && g.customer_mobile.touched) || g.customer_mobile.dirty">
                                <small *ngIf="g.customer_mobile.errors?.required" class="text-danger">Mombile No is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" [ngClass]="{ 'is-invalid': submitted && g.customer_email.errors }" formControlName="customer_email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" class="form-control" id="customer_email" placeholder="Email" required="" autocomplete="off">
                            <div *ngIf="(g.customer_email.invalid && g.customer_email.touched) || g.customer_email.dirty">
                                <small *ngIf="g.customer_email.errors?.required" class="text-danger">Email is required</small>
                                <small *ngIf="g.customer_email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && g.password.errors }" formControlName="password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="Password" required="" autocomplete="off">
                            <div *ngIf="(g.password.invalid && g.password.touched) || g.password.dirty">
                                <small *ngIf="g.password.errors?.required" class="text-danger">password is required</small>
                                <small *ngIf="g.password.errors?.pattern" class="text-danger">Password must contain at least 8 characters!<br>Atleast one Caps letter<br>
                                    Atleast one numeric<br>
                                    Atleast one special character(eg.&,*.@,#,$,%)
                                </small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input type="password" [ngClass]="{ 'is-invalid': submitted && g.confirm_password.errors }" formControlName="confirm_password" pattern="^(?=[^a-z]*[a-z])(?=\D*\d)[^:&.~\s]{5,20}$" class="form-control" placeholder="Confirm Password" required="" autocomplete="off">
                            <div *ngIf="(g.confirm_password.invalid && g.confirm_password.touched) || g.confirm_password.dirty">
                                <small *ngIf="g.confirm_password.errors?.required" class="text-danger">confirm password is required</small>
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="form-group">
                            <input type="text" formControlName="referrer_code" class="form-control" id="referrer_code" placeholder="Referrer Code" autocomplete="off" >
                        </div>
                    </div>
                    <!-- <div class="col-12">
                        <div class="form-group">
                            <select formControlName="user_type" [ngClass]="{ 'is-invalid': submitted && g.user_type.errors }" class="form-control" required="" autocomplete="off">
                                <option value="">Select</option>
                                <option value="C">Customer</option>
                                <option value="P">Partner</option>
                            </select>
                        </div>
                    </div> -->
                    <div class="col-12 text-center">
                        <small *ngIf="error" class="text-danger">{{message}}</small>
                    </div>
                    <div class="col-12 text-center mt-20px">
                        <button class="default-btn btn-two sign-btn" (click)="register()">Sign Up</button>
                    </div>
                    <div class="col-12 text-center mt-20px" *ngIf="showAdminBox">
                        <p class="message">Your account is under review. Our admin will contact you soon…!</p>
                    </div>

                    <div class="col-12">
                        <p class="account-desc">Already have an account? <a routerLink="/sign-in">Sign In</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<app-footer-style-two></app-footer-style-two>