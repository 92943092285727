<app-navbar-style-one></app-navbar-style-one>
<section class="product-area pb-70 margin_top_30px">
    <div class="container">
        <div class="row pt-45">
            <div class="col-lg-4 col-md-6 p-30" *ngFor="let img of images">
                <div class="product-card height350px">
                    <img class="imageRadius imgHeightwid" src="{{imagelink+img.image_url}}" alt="Products Images">
                </div>
            </div>
        </div>
    </div>
</section>
