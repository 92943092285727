import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "../../../../environments/environment";
@Injectable({
    providedIn: "root",
})
export class CommonService {
    constructor(private httpClient: HttpClient) {}
    getCareerTitles() {
        return this.httpClient.get(
            environment.apiUrl + "common/getCareerTitleList"
        );
    }

    getCareerList_get() {
        return this.httpClient.get(
            environment.apiUrl + "common/getCareerTitleList"
        );
    }
    getCronjoblist_get() {
        return this.httpClient.get(
            environment.apiUrl + "common/getCronjoblist"
        );
    }

    getPlantype_get() {
        return this.httpClient.get(environment.apiUrl + "common/getPlantype");
    }

    getJobwanted_get() {
        return this.httpClient.get(
            environment.apiUrl + "common/getCareerTitleList"
        );
    }

    getPostjobs(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getPostjobs/" +
                user_id +
                "/" +
                user_type
        );
    }

    postWantedjobs_get() {
        return this.httpClient.get(environment.apiUrl + "common/getPostjobs/");
    }

    filteredpostjobs_get(job_type: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getfilteredjobs/" + job_type
        );
    }

    saveJob_post(params: any) {
        return this.httpClient.post(environment.apiUrl + "common/save", params);
    }

    saveaccountinfo_post(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/accountinfo",
            params
        );
    }

    saveJobwanted_post(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/savewantedJob",
            params
        );
    }

    editpostjob(sid: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/editjobdetails/" + sid
        );
    }

    editjobwanted(sid: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/editjobwanted/" + sid
        );
    }

    deletejob(job_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/deletepostjob/" + job_id
        );
    }

    getSizes(cat_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getSizes/" + cat_id
        );
    }

    getSizesForProduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getSizesForProduct",
            params
        );
    }

    getNoOfBillPerBook() {
        return this.httpClient.get(
            environment.apiUrl + "common/getNoOfBillPerBooks"
        );
    }

    getNoOfBillPerBookForproduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getNoOfBillPerBookForproduct",
            params
        );
    }

    getQuality(cat_type: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getQuality/" + cat_type
        );
    }

    getPapers(cat_type: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getPapers/" + cat_type
        );
    }

    getNoOfBillsPerPage() {
        return this.httpClient.get(
            environment.apiUrl + "common/getNoOfBillsPerPage"
        );
    }

    getNoOfPlacesForNumbering() {
        return this.httpClient.get(
            environment.apiUrl + "common/getNoOfPlacesForNumbering"
        );
    }

    getCustomerList() {
        return this.httpClient.get(
            environment.apiUrl + "common/getCustomerList"
        );
    }

    getPrintingColours() {
        return this.httpClient.get(
            environment.apiUrl + "common/getPrintingColours"
        );
    }

    getBitNoticePacking(size_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getBitNoticePacking/" + size_id
        );
    }

    getLetterHeadPacking(size_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getLetterHeadPacking/" + size_id
        );
    }

    saveBillBookQuote(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveQuote",
            params
        );
    }

    saveBitNoticeQuote(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveQuote",
            params
        );
    }

    saveLetterPadQuote(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveQuote",
            params
        );
    }

    getQuoteDetails(quote_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getQuoteDetails/" + quote_id
        );
    }

    getCustomerQuotes(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getCustomerQuotes/" +
                user_id +
                "/" +
                user_type
        );
    }

    deleteQuote(pk_quote_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/deleteQuote/" + pk_quote_id
        );
    }

    getUserDetails(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getUserDetails/" +
                user_id +
                "/" +
                user_type
        );
    }

    getUserDetailsWithToken(token: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getUserDetailsWithToken/" + token
        );
    }

    getWalletDetails(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getWalletDetails/" +
                user_id +
                "/" +
                user_type
        );
    }

    getSlider() {
        return this.httpClient.get(environment.apiUrl + "common/getSlider");
    }

    validateCurrentPassword(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/validateCurrentPassword",
            params
        );
    }

    getUserAddress(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getUserAddress/" +
                user_id +
                "/" +
                user_type
        );
    }

    getQualityPapers(quality_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getQualityDetails/" + quality_id
        );
    }

    getProducts(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getproducts",
            params
        );
    }

    getGallery(product_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getGallery/" + product_id
        );
    }

    getGalleryImages() {
        return this.httpClient.get(
            environment.apiUrl + "common/getGalleryImages"
        );
    }

    getProductDetails(prod_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getProductDetails/" + prod_id
        );
    }

    getServiceDetails(cate_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getServiceDetails/" + cate_id
        );
    }

    getOrders(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getOrders/" + user_id + "/" + user_type
        );
    }

    getAmount(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getAmount",
            params
        );
    }

    saveaddress(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveaddress",
            params
        );
    }

    editaddress(address_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/editaddress/" + address_id
        );
    }

    getPostaddress() {
        return this.httpClient.get(
            environment.apiUrl + "common/getPostaddress/"
        );
    }

    getlatestAmount(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getlatestAmount",
            params
        );
    }

    upload(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/upload",
            params
        );
    }

    deleteaddress(address_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/deleteaddress/" + address_id
        );
    }

    getSubCategories() {
        return this.httpClient.get(
            environment.apiUrl + "common/getSubCategories"
        );
    }

    saveCustomerproducts(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveCustomerProducts",
            params
        );
    }

    getCustomerProducts(customer_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/getCustomerProducts/" + customer_id
        );
    }

    getCustomerWishlist(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/getCustomerWishlist/",
            params
        );
    }

    addToCartFromCustomerProducts(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/addToCartFromCustomerProducts",
            params
        );
    }

    deleteCustomerProducts(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/deleteCustomerProducts",
            params
        );
    }

    deleteGroupCustomerProducts(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/deleteGroupCustomerProducts",
            params
        );
    }

    lockProduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/lockProduct",
            params
        );
    }

    unLockProduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/unLockProduct",
            params
        );
    }

    getCustomerProductDetails(pk_prod_id: any, user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getCustomerProductDetails/" +
                pk_prod_id +
                "/" +
                user_id +
                "/" +
                user_type
        );
    }

    getCustomerQuoteDetails(pk_prod_id: any, user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getCustomerQuoteDetails/" +
                pk_prod_id +
                "/" +
                user_id +
                "/" +
                user_type
        );
    }

    updateCustomerProduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/updateCustomerProduct",
            params
        );
    }

    removeSampleFile(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/removeSampleFile",
            params
        );
    }

    updateCustomerQuote(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/updateCustomerQuote",
            params
        );
    }

    saveToCart(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/addToCart",
            params
        );
    }

    addToCartFromQuote(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/addToCartFromQuote",
            params
        );
    }

    saveToCartProduct(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/saveToCartProduct",
            params
        );
    }

    confirmSummary(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/confirmSummary",
            params
        );
    }

    confirmQuoteSummary(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/confirmQuoteSummary",
            params
        );
    }

    clearCart(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/clearCart",
            params
        );
    }

    updateCart(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/updateCart",
            params
        );
    }

    removeUpdateCart(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/removeUpdateCart",
            params
        );
    }

    getCartProducts(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getCartProducts/" +
                user_id +
                "/" +
                user_type
        );
    }

    addToOrder(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/addToOrder",
            params
        );
    }

    getCheckoutDetails(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getCheckoutDetails/" +
                user_id +
                "/" +
                user_type
        );
    }

    getOrderDetails(order_id: any, user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/getOrderDetails/" +
                order_id +
                "/" +
                user_id +
                "/" +
                user_type
        );
    }

    placeOrder(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/placeOrder",
            params
        );
    }

    cancelOrder(order_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/cancelOrder/" + order_id
        );
    }

    downloadInvoice(order_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/downloadinvoice/" + order_id
        );
    }

    reorderToCart(order_id: any) {
        return this.httpClient.get(
            environment.apiUrl + "common/reordertocart/" + order_id
        );
    }

    getwalletamount(user_id: any, user_type: any) {
        return this.httpClient.get(
            environment.apiUrl +
                "common/walletamount/" +
                user_id +
                "/" +
                user_type
        );
    }

    sendContactInformation(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/sendContactInformation",
            params
        );
    }

    sendProductRequirement(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/sendProductRequirement",
            params
        );
    }

    resetPassword(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/resetPassword",
            params
        );
    }

    updatePassword(params: any) {
        return this.httpClient.post(
            environment.apiUrl + "common/updatePassword",
            params
        );
    }
}
