import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray, ValidatorFn } from '@angular/forms';
import { CommonService } from '../../common/services/common.service';
import { AuthService } from '../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Globals } from '../../common/providers/global';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  public loginForm: FormGroup;
  public error:boolean = false;
  public message:any = '';
  public loginerror:boolean = false;
  public loginmessage:any = '';
  public showAdminBox: boolean = false;
  
  submitted = false;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder, 
    private router: Router,
    private route: ActivatedRoute,
    public globals: Globals
  ) {
    if (this.authService.isLoggedin()) {
      this.globals.cart_count = 0;
      this.authService.logout();
      this.router.navigate(['/sign-in']);
    }
  }

  login() {

    this.loginmessage = "";
    this.loginerror = false;
    this.submitted = true;
    if (this.loginForm.invalid) {
        return;
    }
    const formData = new FormData();
    formData.append('userLogin', this.loginForm.get('userLogin').value);
    formData.append('userPwd', this.loginForm.get('userPwd').value);

    this.authService.login(formData).subscribe((response: any) => {
        if (response.status) {
          // this.notifierService.notify( 'success', 'logged in successfully!' );
          this.submitted = false;
          if(response.data.active_status == 'D'){
            this.showAdminBox = true;
          }else{
            this.authService.setSessionInfo(response.data);
            if(localStorage.getItem('userId')){
              if(localStorage.getItem('db_entry') == 'Y'){
                if(localStorage.getItem('current_url') == 'product-details'){
                  const formData = new FormData();
                  formData.append('productList' , localStorage.getItem('productList'));
                  formData.append('user_id' , localStorage.getItem('userId'));
                  this.commonService.saveCustomerproducts(formData).subscribe((response: any) => {
                    if (response.status) {
                      localStorage.removeItem('db_entry');
                      localStorage.removeItem('current_url');
                      localStorage.removeItem('productList');
                      this.router.navigate(['/product-summary/'+response.pk_product_id]);
                    }
                  });
                }
              }else{
                this.router.navigate(['/']);
              }
            }
          }
        } else {
          this.loginerror = true;
          // this.notifierService.notify( 'error', response.message);
          this.loginmessage = response.message;
        }
    });
  }

  get f() { return this.loginForm.controls; }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      userLogin: ['', Validators.required],
      userPwd: ['', Validators.required],
      rememberme: ['']
    });
  }

}