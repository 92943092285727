<app-navbar-style-one></app-navbar-style-one>
<div class="container border-radius">
    <div class="home-slider owl-carousel owl-theme box_shadow">
        <div class="slider-item cp-item1">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container-fluid">
                        <div class="slider-content">
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item2">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item3">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item4">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider-item cp-item5">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="slider-content">
                            <p></p>
                            <div class="slider-btn">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-area pt-100 pb-70">
    <div class="container-fluid padding_50px">
        <div class="scetion-title text-center">
            <span>Product</span>
            <h2>We Have Some Pre-ready Demo Product</h2>
            <p class="text-justify">What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>
        <div class="row pt-45">
                <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 padding0px">
                        <h5 class="pt-2 mb-4 padding0px">Filters</h5>
                        <section class="mb-4 padding0px">
                            <div class="md-form md-outline mt-0 d-flex justify-content-between align-items-center">
                                <input type="text" class="form-control mb-0" (keyup)="getProducts()" [(ngModel)]="searchproducttext" placeholder="Search...">
                            </div>
                            <div class="col-xl-12 col-lg-12 col-12 p-0 text-center">
                                <span *ngIf="category_image != ''">
                                    <img class="pd_tp15" src="{{imagelink+category_image}}" width="100%">
                                </span>
                            </div>
                        </section>
                        <section class="mb-4 padding0px">
                            <h6 class="font-weight-bold mb-3">Categories</h6>
                            <div class="form-check padding0px" *ngFor="let category of subCategories">
                                <label [ngClass]="{ 'is-active': category.active}" (click)="getProductsByCategory(category.category_id)" class="small text-uppercase cursor">{{category.category_name}}</label>
                            </div>
                        </section>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-12 paddingTop40px scrollable-element">
                    <div class="row pd-r15">
                        <div class="col-lg-4 col-md-6" *ngFor="let prod of products">
                            <div class="service-card height425px">
                                <div class="container resize">
                                    <a routerLink="/product-details/{{prod.product_id}}"><img class="imageRadius imgHeightwid" src="{{imagelink+prod.image_large_details}}" alt="Products Images"></a>
                                </div>
                                <div class="service-content height200">
                                    <h5 class="product_name">{{prod.product_name}} {{prod.product_code?'('+prod.product_code+')':''}}</h5>
                                    <div class="divClass" [innerHtml]="prod.product_details"></div>
                                </div>
                            </div>
                            <!-- <div class="product-card height300px">
                                <a routerLink="/product-details/{{prod.product_id}}"><img class="imageRadius imgHeightwid" src="{{imagelink+prod.image_large_details}}" alt="Products Images"></a>
                                <div class="product-content">
                                    <a routerLink="/product-details/{{prod.product_id}}">
                                        <h3>{{prod.product_name}} {{prod.product_code?'('+prod.product_code+')':''}}</h3>
                                    </a>
                                    <div class="product-cart">
                                        <ul>
                                            <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                            <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div *ngIf="productslength" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            <div class="container">
                                <div class="contact-wrap pt-45">
                                    <H2>Describe your product requirement:</H2>
                                    <div class="contact-wrap-form">
                                         <form id="contactForm">
                                             <div class="row">
                                                 <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" name="name" id="name" [ngClass]="{ 'is-invalid': submitted && error.name }" [(ngModel)]="name" class="form-control" (change)="checkValildation()" required placeholder="Your Name">
                                                    </div>
                                                 </div>
                        
                                                 <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <input type="email" name="email" id="email" [ngClass]="{ 'is-invalid': submitted && error.email }" [(ngModel)]="email" class="form-control" (change)="checkValildation()" required
                                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"  placeholder="Your Email">
                                                            <small *ngIf="error.email?.required" class="text-danger">Email is required</small>
                                                            <small *ngIf="error.email?.pattern" class="text-danger">Please provide a valid email address</small>
                                                    </div>
                                                 </div>
                        
                                                 <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" name="phone_number" id="phone_number" [ngClass]="{ 'is-invalid': submitted && error.phone_number }" [(ngModel)]="phone_number"  class="form-control" required (change)="checkValildation()" placeholder="Your Phone">
                                                    </div>
                                                 </div>
                        
                                                 <div class="col-lg-6 col-sm-6">
                                                    <div class="form-group">
                                                        <input type="text" name="msg_subject" id="msg_subject" [ngClass]="{ 'is-invalid': submitted && error.msg_subject }" [(ngModel)]="msg_subject" class="form-control" required (change)="checkValildation()"  placeholder="Your Subject">
                                                    </div>
                                                 </div>
                        
                                                 <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea name="message" class="form-control" id="message" [ngClass]="{ 'is-invalid': submitted && error.message }" [(ngModel)]="message" cols="30" rows="8" required (change)="checkValildation()"  placeholder="Product Description"></textarea>
                                                    </div>
                                                 </div>
                        
                                                 <div class="col-lg-12 col-md-12 text-center">
                                                    <button (click)="sendContact()" class="default-btn page-btn text-center">Send Email</button>
                                                 </div>
                                             </div>
                                         </form>
                                    </div>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>