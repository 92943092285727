<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg4">
    <div class="container">
        <div class="inner-title">
            <h3>Blog</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Blog</li>
            </ul>
        </div>
    </div>
</div>

<div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Blogs</span>
            <h2>Our Regular Blog Post</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Work Once Print 100+</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i>Admin</a></li>
                            <li><i class='bx bx-calendar-alt'></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Keep Your Print Great</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Digital Print is Science</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog4.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Print Banner in Industry</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog5.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Choose Color in Package</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog6.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>T-shirt Design Trends 2020 </h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt'></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="pagination-area">
                    <nav aria-label="Page navigation example text-center">
                        <ul class="pagination">
                            <li class="page-item"><a class="page-link page-links" routerLink="/blog"><i class='bx bx-chevrons-left'></i></a></li>
                            <li class="page-item current"><a class="page-link" routerLink="/blog">1</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">2</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog">3</a></li>
                            <li class="page-item"><a class="page-link" routerLink="/blog"><i class='bx bx-chevrons-right'></i></a></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>