<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg11">
    <div class="container">
        <div class="inner-title">
            <h3>404 Error</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>404 Error</li>
            </ul>
        </div>
    </div>
</div>

<div class="error-area ptb-100">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-content">
                <h1>4 <span>0</span> 4</h1>
                <h3>Oops! Page Not Found</h3>
                <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                <a routerLink="/" class="default-btn page-btn">Return To Home Page</a>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>