<app-navbar-style-one></app-navbar-style-one>

<div class="home-slider owl-carousel owl-theme">
    <div class="slider-item bg-item1">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>WELCOME TO SVG PRINTERS</h1>
                        <p>Achagam is your friendly online printing shop! We make your printing work quick, easy and cost-effective.  Enjoy great quality at the lowest price.  From creating office stationery such as business cards, letter heads, notepads, bill books, packaging material, invitations, labels, books, corrugated boxes to advertising and marketing collaterals, we offer you customized options. The sky is the limit!.</p>
                        <div class="slider-btn">
                            <!-- <a routerLink="/" class="default-btn">Know More</a> -->
                            <a routerLink="/product" class="default-btn active">Our Products</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="slider-item bg-item2">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-content">
                        <h1>Lubricant &amp; Oils</h1>
                        <p>Buy Online Lubricant Oil, Greases @ Best Prices</p>
                        <div class="slider-btn">
                            <a routerLink="/" class="default-btn">Know More</a>
                            <a routerLink="/product" class="default-btn active">Our Products</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="about-area pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5">
                <div class="about-img">
                    <img src="assets/img/about/about1.png" alt="About Images">

                    <div class="about-bg-shape">
                        <img src="assets/img/about/bg-shape.png" alt="About Shape">
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="about-content">
                    <span>About Us</span>                    
                    <p class="text-justify">Our company, a gradually growing company started with small investment. For the past fifteen years, providing better service to its customers. Now, we are doing high Quality mini offset printing service. Our goal is to provide low-cost, quality printing service. Most Customers does not know the Actual cost of printing products they buy. So, the current practice is when you give an order you have to investigate the four or five printers. As a result we will get 4 or 5 different prices from the market. The price difference is too high and it is time consuming effort. To eliminate this we have created this website.

                        First, we have created software to know the price of products such as Bill book Categories, All Type of Bit Notice & letter heads Items. (except multi colour / 4 colour). All items have been working to develop gradually. We urge you to support the website. Any suggestions about this website please provide. Thank you for visiting.</p>
                    <div class="about-btn">
                        <a routerLink="/" class="know-more-btn">Know More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <img src="assets/img/about/shape1.png" alt="About Shape">
    </div>
</div>
<section class="product-area pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Product</span>
            <h2>We Have Some Pre-ready Demo Product</h2>
            <p class="text-justify">What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6" *ngFor="let prod of products">
                <div class="product-card">
                    <a routerLink="/product-details/{{prod.product_id}}"><img src="assets/img/products/products1.jpg" alt="Products Images"></a>
                    <div class="product-content">
                        <a routerLink="/product-details/{{prod.product_id}}"><h3>{{prod.product_name}}</h3></a>
                        <div class="product-cart">
                            <ul>
                                <li><a routerLink="/"><i class='bx bx-heart'></i></a></li>
                                <li><a routerLink="/cart"><i class='bx bx-cart'></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="product-shape">
        <img src="assets/img/products/shape.png" alt="Products Shape">
    </div>
</section>
<!-- 
<div class="price-area pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Pricing Table</span>
            <h2>We Have Pre-ready Pricing Plan for Our Services</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>

        <div class="row">
            <div class="col-lg-12 pt-45">
                <div class="tabs-item-list">
                    <ul id="tabs-item" class="text-center">
                        <li class="active"><a href="#monthly" class="prices-tab">Monthly</a></li> 
                        <li><a href="#yearly" class="prices-tab">Yearly</a></li> 
                    </ul> 
                </div>
            </div>
        </div>

        <div id="prices-conten">
            <div id="monthly" class="active prices-conten-area animated">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-price">
                            <span>Basic Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>30<sub>/month</sub></h2>
                            </div>

                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li class="color-gray"><del>Tshirt Design</del></li>
                                <li class="color-gray"><del>Vector Art</del></li>
                                <li class="color-gray"><del>Print Ready Source</del></li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="single-price current">
                            <span>Standard Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>60<sub>/month</sub></h2>
                            </div>

                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li>Tshirt Design</li>
                                <li class="color-gray"><del>Vector Art</del></li>
                                <li class="color-gray"><del>Print Ready Source</del></li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-price">
                            <span>Premium Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>90<sub>/month</sub></h2>
                            </div>

                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li>Tshirt Design</li>
                                <li>Vector Art</li>
                                <li> Print Ready Source</li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>
                </div>
            </div>

            <div id="yearly" class="animated prices-conten-area">
                <div class="row">
                    <div class="col-lg-4 col-sm-6">
                        <div class="single-price">
                            <span>Basic Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>70<sub>/Year</sub></h2>
                            </div>

                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li class="color-gray"><del>Tshirt Design</del></li>
                                <li class="color-gray"><del>Vector Art</del></li>
                                <li class="color-gray"><del>Print Ready Source</del></li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6">
                        <div class="single-price current">
                            <span>Standard Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>120<sub>/Year</sub></h2>
                            </div>

                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li>Tshirt Design</li>
                                <li class="color-gray"><del>Vector Art</del></li>
                                <li class="color-gray"><del>Print Ready Source</del></li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                        <div class="single-price">
                            <span>Premium Plan</span>

                            <div class="single-price-title">
                                <h2><sup>$</sup>170<sub>/Year</sub></h2>
                            </div>
                            
                            <ul>
                                <li>Brand Identy</li>
                                <li>Package Design</li>
                                <li>Web Application</li>
                                <li>Bill Board</li>
                                <li>Tshirt Design</li>
                                <li>Vector Art</li>
                                <li> Print Ready Source</li>
                            </ul>

                            <a routerLink="/" class="get-btn">Get Stated</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <div class="designer-area pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Our Designer</span>
            <h2>Our Company Leads by Professional Designer</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer1.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Doe</h3></a>
                        <span>Director</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer2.jpg" alt="Designer Images"></a>
                    </div>
                    <div class="designer-content">
                        <a routerLink="/designer"><h3>John Smith</h3></a>
                        <span>Product Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer3.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Evanaa</h3></a>
                        <span>llustrator Designer</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="designer-card">
                    <div class="designer-img">
                        <a routerLink="/designer"><img src="assets/img/designer/designer4.jpg" alt="Designer Images"></a>
                    </div>

                    <div class="designer-content">
                        <a routerLink="/designer"><h3>Knot Doe</h3></a>
                        <span>Mockup Specialist</span>
                        <div class="social-icon">
                            <ul>
                                <li><a href="#" target="_blank" ><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-twitter' ></i></a></li>
                                <li><a href="#" target="_blank" ><i class='bx bxl-instagram' ></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="testimonial-area pb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-7">
                <div class="testimonial-content">
                    <div class="scetion-title text-center">
                        <span>Testimonial</span>
                        <h2>People Says About Us</h2>
                        <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
                    </div>

                    <div class="testimonial-slider owl-carousel owl-theme pt-45">
                        <div class="testimonial-item">
                            <h3>John Smith</h3>
                            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt with of as he this.</p>
                        </div>

                        <div class="testimonial-item">
                            <h3>John Doe</h3>
                            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt with of as he this.</p>
                        </div>

                        <div class="testimonial-item">
                            <h3>Evanaa</h3>
                            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt with of as he this.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-5">
                <div class="testimonial-img">
                    <div class="testimonial-bg-shape">
                        <img src="assets/img/testimonial/bg-shape.png" alt="Testimonial Images">
                    </div>

                    <div class="testominail-man">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Testimonial Shape">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="blog-area pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Blogs</span>
            <h2>Our Regular Blog Post</h2>
            <p>What indication best sick be project proposal in attempt, train of the showed some a forth. That homeless, won't many of goals thoughts volumes felt.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog1.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Work Once Print 100+</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog2.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Keep Your Print Great</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="blog-card">
                    <a routerLink="/blog-details"><img src="assets/img/blog/blog3.jpg" alt="Blog Images"></a>
                    
                    <div class="blog-content">
                        <a routerLink="/blog-details"><h3>Digital Print is Science</h3></a>
                        <ul class="blog-admin">
                            <li><a routerLink="/"><i class='bx bxs-user'></i> Admin</a></li>
                            <li><i class='bx bx-calendar-alt' ></i> 18 May 2020</li>
                        </ul>
                        <p>Lorem ipsum dolor sit amet, consect is etur adipiscing elit.</p>
                        <a routerLink="/blog-details" class="read-more-btn">Read More <i class='bx bxs-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->

<app-footer-style-one></app-footer-style-one>