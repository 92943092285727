import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../common/services/common.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-footer-style-two',
  templateUrl: './footer-style-two.component.html',
  styleUrls: ['./footer-style-two.component.scss']
})
export class FooterStyleTwoComponent implements OnInit {

  public subCategories: any = [];
  public imagelink: any = environment.imageUrl;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.getproductCategory();
  }

  getproductCategory(){
    this.commonService.getSubCategories().subscribe((response: any) => {
      if (response.status) {
        this.subCategories = response.data;
      }
    });
  }

}