import { Component, OnInit } from "@angular/core";
import { CommonService } from "./../../../common/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../../../environments/environment";
declare let $: any;

@Component({
    selector: "app-create",
    templateUrl: "./create.component.html",
    styleUrls: ["./create.component.scss"],
})
export class CreateComponent implements OnInit {
    public noOfBooksPattern = "/[0-9]/g";
    public user_id = localStorage.getItem("userId");
    public user_type = localStorage.getItem("user_type");
    public user_details: any = {};
    public billBookBox: boolean = true;
    public bitNoticeBox: boolean = false;
    public letterPadBox: boolean = false;
    public category_type: any = "";
    public category_name: any = "";
    public category_image: any = "";
    public quotejson: any = {};
    public contentBox: boolean = false;
    public gst: number = 0.0;

    public Sizes: any = [];
    public noOfBillsPerBook: any = [];
    public billBookQuality: any = [];
    public billpapers: any = [];
    public billpaper1: any = [];
    public billpaper2: any = [];
    public billpaper3: any = [];
    public billpaper4: any = [];
    public billpaper5: any = [];
    public bitNoticePapers: any = [];
    public duplicateBitNoticePapers: any = [];
    public letterPadPapers: any = [];
    public duplicateLetterPadPapers: any = [];
    public noOfBillsPerPage: any = [];
    public noOfBillsPerPageDuplicate: any = [];
    public noOfPlacesOfNumbering: any = [];
    public printingColours: any = [];
    public printingFrontColours: any = [];
    public printingBackColours: any = [];
    public customerList: any = [];
    public bitNoticePacking: any = [];
    public letterHeadPacking: any = [];
    public bookTypes: any = [];
    public submitted: boolean = false;
    public formError: Boolean = true;
    public subCategories: any = [];
    public sub_category_id: any = "";
    public selectedcategory_id: any = "";
    public selectedCat: any = "";

    public imagelink: any = environment.imageUrl;

    constructor(
        private commonService: CommonService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        // this.quotejson.category_type = 'BB';
        this.quotejson.customer_id = "";
        this.quotejson.job_name = "";
        this.quotejson.psize_id = "";
        this.quotejson.pk_bb_id = "2";
        this.quotejson.showBookType = true;
        this.quotejson.pk_sheet_id = "1";
        this.quotejson.paper1 = "";
        this.quotejson.paper2 = "";
        this.quotejson.paper3 = "";
        this.quotejson.paper4 = "";
        this.quotejson.paper5 = "";
        this.quotejson.paper = "";
        this.quotejson.checkpaper1 = true;
        this.quotejson.checkpaper2 = false;
        this.quotejson.checkpaper3 = false;
        this.quotejson.checkpaper4 = false;
        this.quotejson.checkpaper5 = false;
        this.quotejson.variablePaper1 = true;
        this.quotejson.variablePaper2 = false;
        this.quotejson.variablePaper3 = false;
        this.quotejson.variablePaper4 = false;
        this.quotejson.variablePaper5 = false;
        this.quotejson.disablePaper2 = false;
        this.quotejson.disablePaper3 = false;
        this.quotejson.disablePaper4 = false;
        this.quotejson.disablePaper5 = false;
        this.quotejson.disbalePadBinding = false;
        this.quotejson.disbaleSoftBinding = false;
        this.quotejson.disbaleHardBinding = false;
        this.quotejson.is_variable_printing = true;
        this.quotejson.pk_bp_id = "1";
        this.quotejson.pnumber_id = "2";
        this.quotejson.sameContent = "N";
        this.quotejson.bitnoticelamination = false;
        this.quotejson.bitnoticeperforation = false;
        this.quotejson.bitnoticefolding = false;
        this.quotejson.numbering = "M";
        this.quotejson.is_numbering = "";
        this.quotejson.perforation = "M";
        this.quotejson.binding = "";
        this.quotejson.quantity = "";
        this.quotejson.isFrontMultiColour = "N";
        this.quotejson.isBackMultiColour = "N";
        this.quotejson.isBackColourNone = "Y";
        this.quotejson.error = {};
        this.quotejson.error.no_front_colors = false;
        this.quotejson.error.no_back_colors = false;
        this.quotejson.error.frontcolour = false;
        this.quotejson.error.backcolour = false;
        this.quotejson.isCorbanDisablePaper2 = false;
        this.quotejson.isCorbanDisablePaper3 = false;
        this.quotejson.isCorbanDisablePaper4 = false;
        this.quotejson.isCorbanDisablePaper5 = false;
        this.quotejson.showCustomerForm = false;
        this.bookTypes = [
            {
                pk_sheet_id: "1",
                id: "A",
                lable: "All original",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "2",
                id: "D",
                lable: "Duplicate (1 + 1)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "3",
                id: "T",
                lable: "Triplicate (1 + 2)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "4",
                id: "Q",
                lable: "Quadriplicate (1 + 3)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "5",
                id: "U",
                lable: "Quintuplicate (1 + 4)",
                active: true,
                book: true,
            },
        ];
    }

    checkSizeWithbokType() {
        if (this.quotejson.category_type == "BB") {
            const sizes = this.Sizes.filter((data: any) => {
                if (data.psize_id == this.quotejson.psize_id) {
                    if (this.sub_category_id) {
                        $(".preloader").fadeIn("slow");
                        this.commonService
                            .getPapers(this.sub_category_id)
                            .subscribe((response: any) => {
                                if (response.status) {
                                    this.billpapers = response.data;
                                    this.billpaper1 = [];
                                    this.billpaper2 = [];
                                    this.billpaper3 = [];
                                    this.billpaper4 = [];
                                    this.billpaper5 = [];
                                    const papers = this.billpapers.filter(
                                        (datas: any) => {
                                            if (data.size_origin == "D") {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    (datas.size_name == "D" ||
                                                        datas.size_name == "DD")
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    (datas.size_name == "D" ||
                                                        datas.size_name == "DD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    (datas.size_name == "D" ||
                                                        datas.size_name == "DD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    (datas.size_name == "D" ||
                                                        datas.size_name == "DD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    (datas.size_name == "D" ||
                                                        datas.size_name == "DD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            } else if (
                                                data.size_origin == "ID"
                                            ) {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    (datas.size_name == "ID" ||
                                                        datas.size_name ==
                                                            "IDD")
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    (datas.size_name == "ID" ||
                                                        datas.size_name ==
                                                            "IDD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    (datas.size_name == "ID" ||
                                                        datas.size_name ==
                                                            "IDD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    (datas.size_name == "ID" ||
                                                        datas.size_name ==
                                                            "IDD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    (datas.size_name == "ID" ||
                                                        datas.size_name ==
                                                            "IDD")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            } else if (
                                                data.size_origin == "C"
                                            ) {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    (datas.size_name == "DC" ||
                                                        datas.size_name == "QC")
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    (datas.size_name == "DC" ||
                                                        datas.size_name == "QC")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    (datas.size_name == "DC" ||
                                                        datas.size_name == "QC")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    (datas.size_name == "DC" ||
                                                        datas.size_name == "QC")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    (datas.size_name == "DC" ||
                                                        datas.size_name == "QC")
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            } else if (
                                                data.size_origin == "B"
                                            ) {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    datas.size_name == "B"
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    datas.size_name == "B"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    datas.size_name == "B"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    datas.size_name == "B"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    datas.size_name == "B"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            } else if (
                                                data.size_origin == "BP"
                                            ) {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    datas.size_name == "BP"
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    datas.size_name == "BP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    datas.size_name == "BP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    datas.size_name == "BP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    datas.size_name == "BP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            } else if (
                                                data.size_origin == "DDP"
                                            ) {
                                                if (
                                                    datas.bb_paper_1 == "Y" &&
                                                    datas.size_name == "DDP"
                                                ) {
                                                    if (
                                                        this.quotejson
                                                            .pk_sheet_id == 1 &&
                                                        datas.is_carbon == "Y"
                                                    ) {
                                                        datas.isDisabled = true;
                                                    } else {
                                                        datas.isDisabled =
                                                            false;
                                                    }
                                                    this.billpaper1.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_2 == "Y" &&
                                                    datas.size_name == "DDP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper2.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_3 == "Y" &&
                                                    datas.size_name == "DDP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper3.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_4 == "Y" &&
                                                    datas.size_name == "DDP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper4.push(datas);
                                                }
                                                if (
                                                    datas.bb_paper_5 == "Y" &&
                                                    datas.size_name == "DDP"
                                                ) {
                                                    datas.isDisabled = false;
                                                    this.billpaper5.push(datas);
                                                }
                                            }
                                        }
                                    );
                                    if (this.billpaper1.length == 0) {
                                        this.quotejson.pk_sheet_id = "";
                                    } else if (this.billpaper2.length == 0) {
                                        this.quotejson.pk_sheet_id = 1;
                                        this.changepaperCheckBoxConditions(
                                            this.quotejson.pk_sheet_id
                                        );
                                        const bookstype = this.bookTypes.filter(
                                            (data: any) => {
                                                if (data.pk_sheet_id > 1) {
                                                    data.active = false;
                                                    data.book = false;
                                                } else {
                                                    data.active = true;
                                                    data.book = true;
                                                }
                                            }
                                        );
                                    } else if (this.billpaper3.length == 0) {
                                        this.quotejson.pk_sheet_id = 1;
                                        this.quotejson.showBookType = true;
                                        this.changepaperCheckBoxConditions(
                                            this.quotejson.pk_sheet_id
                                        );
                                        const bookstype = this.bookTypes.filter(
                                            (data: any) => {
                                                if (data.pk_sheet_id > 2) {
                                                    data.active = false;
                                                    data.book = false;
                                                } else {
                                                    data.active = true;
                                                    data.book = true;
                                                }
                                            }
                                        );
                                    } else if (this.billpaper4.length == 0) {
                                        this.quotejson.pk_sheet_id = 1;
                                        this.quotejson.showBookType = true;
                                        this.changepaperCheckBoxConditions(
                                            this.quotejson.pk_sheet_id
                                        );
                                        const bookstype = this.bookTypes.filter(
                                            (data: any) => {
                                                if (data.pk_sheet_id > 3) {
                                                    data.active = false;
                                                    data.book = false;
                                                } else {
                                                    data.active = true;
                                                    data.book = true;
                                                }
                                            }
                                        );
                                    } else if (this.billpaper5.length == 0) {
                                        this.quotejson.pk_sheet_id = 1;
                                        this.quotejson.showBookType = true;
                                        this.changepaperCheckBoxConditions(
                                            this.quotejson.pk_sheet_id
                                        );
                                        const bookstype = this.bookTypes.filter(
                                            (data: any) => {
                                                if (data.pk_sheet_id > 3) {
                                                    data.active = false;
                                                    data.book = false;
                                                } else {
                                                    data.active = true;
                                                    data.book = true;
                                                }
                                            }
                                        );
                                    } else {
                                        this.quotejson.pk_sheet_id = 1;
                                        this.quotejson.showBookType = true;
                                        this.changepaperCheckBoxConditions(
                                            this.quotejson.pk_sheet_id
                                        );
                                        const bookstype = this.bookTypes.filter(
                                            (data: any) => {
                                                data.active = true;
                                                data.book = true;
                                            }
                                        );
                                    }
                                    // this.changeBookType(this.quotejson.pk_bb_id);
                                }
                                $(".preloader").fadeOut("slow");
                            });
                    }
                }
            });
        } else if (this.quotejson.category_type == "BN") {
            const sizes = this.Sizes.filter((data: any) => {
                if (data.psize_id == this.quotejson.psize_id) {
                    if (this.sub_category_id) {
                        $(".preloader").fadeIn("slow");
                        this.commonService
                            .getPapers(this.sub_category_id)
                            .subscribe((response: any) => {
                                if (response.status) {
                                    this.bitNoticePapers = response.data;
                                    this.duplicateBitNoticePapers = [];
                                    const paper = this.bitNoticePapers.filter(
                                        (bitdata) => {
                                            if (data.size_origin == "D") {
                                                if (
                                                    bitdata.size_name == "D" ||
                                                    bitdata.size_name == "DD"
                                                ) {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "ID"
                                            ) {
                                                if (
                                                    bitdata.size_name == "ID" ||
                                                    bitdata.size_name == "IDD"
                                                ) {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "C"
                                            ) {
                                                if (
                                                    bitdata.size_name == "DC" ||
                                                    bitdata.size_name == "QC"
                                                ) {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "B"
                                            ) {
                                                if (bitdata.size_name == "B") {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "BP"
                                            ) {
                                                if (bitdata.size_name == "BP") {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "DDP"
                                            ) {
                                                if (
                                                    bitdata.size_name == "DDP"
                                                ) {
                                                    this.duplicateBitNoticePapers.push(
                                                        bitdata
                                                    );
                                                }
                                            }
                                        }
                                    );
                                }
                                $(".preloader").fadeOut("slow");
                            });
                    }
                }
            });
        } else if (this.quotejson.category_type == "LH") {
            const sizes = this.Sizes.filter((data: any) => {
                if (data.psize_id == this.quotejson.psize_id) {
                    if (this.sub_category_id) {
                        $(".preloader").fadeIn("slow");
                        this.commonService
                            .getPapers(this.sub_category_id)
                            .subscribe((response: any) => {
                                if (response.status) {
                                    this.letterPadPapers = response.data;
                                    this.duplicateLetterPadPapers = [];
                                    const paper = this.letterPadPapers.filter(
                                        (letdata) => {
                                            if (data.size_origin == "D") {
                                                if (
                                                    letdata.size_name == "D" ||
                                                    letdata.size_name == "DD"
                                                ) {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "ID"
                                            ) {
                                                if (
                                                    letdata.size_name == "ID" ||
                                                    letdata.size_name == "IDD"
                                                ) {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "C"
                                            ) {
                                                if (
                                                    letdata.size_name == "DC" ||
                                                    letdata.size_name == "QC"
                                                ) {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "B"
                                            ) {
                                                if (letdata.size_name == "B") {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "BP"
                                            ) {
                                                if (letdata.size_name == "BP") {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            } else if (
                                                data.size_origin == "DDP"
                                            ) {
                                                if (
                                                    letdata.size_name == "DDP"
                                                ) {
                                                    this.duplicateLetterPadPapers.push(
                                                        letdata
                                                    );
                                                }
                                            }
                                        }
                                    );
                                }
                            });
                    }
                    $(".preloader").fadeOut("slow");
                }
            });
        }
        this.checkSizeWithbokTypenumber();
        if (this.submitted) {
            this.checkValidation();
        }
    }
    checkSizeWithbokTypenumber() {
        if (this.quotejson.category_type == "BB") {
            this.noOfBillsPerPageDuplicate = [];
            const sizes = this.Sizes.filter((data: any) => {
                if (data.psize_id == this.quotejson.psize_id) {
                    const billPage = this.noOfBillsPerPage.filter(
                        (datass: any) => {
                            if (
                                parseInt(datass.pk_bp_id) <=
                                parseInt(data.pk_bp_id)
                            ) {
                                this.noOfBillsPerPageDuplicate.push(datass);
                            }
                        }
                    );
                }
            });
            if (this.quotejson.numbering == "MA") {
                let duplicatemachinenumbers = [];
                this.quotejson.is_numbering = "C";
                const data = this.noOfBillsPerPageDuplicate.filter(
                    (data: any) => {
                        if (data.is_machine_no == "Y") {
                            duplicatemachinenumbers.push(data);
                        }
                    }
                );
                this.noOfBillsPerPageDuplicate = [];
                this.noOfBillsPerPageDuplicate = duplicatemachinenumbers;
                if (
                    this.quotejson.pnumber_id == 1 ||
                    this.quotejson.pk_bp_id == "1"
                ) {
                    this.quotejson.pnumber_id = "2";
                }
            } else if (this.quotejson.numbering == "N") {
                this.quotejson.pnumber_id == 1;
            } else {
                this.quotejson.is_numbering = "";
                if (this.quotejson.pnumber_id == 1) {
                    this.quotejson.pnumber_id = 2;
                }
            }
        } else if (this.quotejson.category_type == "BN") {
            this.getBitNoticePacking();
        } else if (this.quotejson.category_type == "LH") {
            this.getLetterHeadPacking();
        }
        if (this.submitted) {
            this.checkValidation();
        }
    }

    //  changeBookType(value: any){
    //   const data = this.noOfBillsPerBook.filter((datas: any) => {
    //     if( datas.pk_bb_id == value )
    //     {
    //       let sampleArray: any = [];
    //       sampleArray = datas.book_type.split(',');
    //         if(sampleArray.includes("Q"))
    //         {
    //           this.quotejson.showBookType = true;
    //           const bookstype = this.bookTypes.filter((data: any) => {
    //               data.book = true;
    //           });
    //         }else if(sampleArray.includes("T")){
    //           if(this.quotejson.pk_sheet_id == 4){
    //             this.quotejson.pk_sheet_id = 3;
    //           }
    //           this.quotejson.showBookType = true;
    //           const bookstype = this.bookTypes.filter((data: any) => {
    //             if(data.pk_sheet_id <= 3){
    //               data.book = true;
    //             }else{
    //               data.book = false;
    //             }
    //           });
    //         }else if(sampleArray.includes("D")){
    //           if(this.quotejson.pk_sheet_id == 4 || this.quotejson.pk_sheet_id == 3){
    //             this.quotejson.pk_sheet_id = 2;
    //           }
    //           this.quotejson.showBookType = true;
    //           const bookstype = this.bookTypes.filter((data: any) => {
    //             if(data.pk_sheet_id <= 2){
    //               data.book = true;
    //             }else{
    //               data.book = false;
    //             }
    //           });
    //         }else if(sampleArray.includes("A")){
    //           this.quotejson.pk_sheet_id = 1;
    //           const bookstype = this.bookTypes.filter((data: any) => {
    //             if(data.pk_sheet_id == 1){
    //               data.book = true;
    //             }else{
    //               data.book = false;
    //             }
    //           });
    //         }
    //         if(this.billpaper2.length == 0){
    //           this.quotejson.pk_sheet_id = 1;
    //         }
    //     }
    //   });
    //   this.changepaperCheckBoxConditions(this.quotejson.pk_sheet_id);
    //   if(this.submitted){
    //     this.checkValidation();
    //   }
    //  }

    checkpaperValidation(event: any, id: any) {
        if (!event.target.checked) {
            if (id == 2) {
                this.quotejson.checkpaper2 = false;
                this.quotejson.checkpaper3 = false;
                this.quotejson.checkpaper4 = false;
                this.quotejson.checkpaper5 = false;
            } else if (id == 3) {
                this.quotejson.checkpaper3 = false;
                this.quotejson.checkpaper4 = false;
                this.quotejson.checkpaper5 = false;
            } else if (id == 4) {
                this.quotejson.checkpaper4 = false;
                this.quotejson.checkpaper5 = false;
            } else if (id == 5) {
                this.quotejson.checkpaper5 = false;
            }
        } else {
            if (id == 2) {
                this.quotejson.checkpaper2 = true;
            } else if (id == 3) {
                this.quotejson.checkpaper2 = true;
                this.quotejson.checkpaper3 = true;
            } else if (id == 4) {
                this.quotejson.checkpaper2 = true;
                this.quotejson.checkpaper3 = true;
                this.quotejson.checkpaper4 = true;
            } else if (id == 5) {
                this.quotejson.checkpaper2 = true;
                this.quotejson.checkpaper3 = true;
                this.quotejson.checkpaper4 = true;
                this.quotejson.checkpaper5 = true;
            }
        }
        this.checkPaperIsMulticolour();
    }

    checkVariablePaper(event: any, id: any) {
        if (!event.target.checked) {
            if (id == 1) {
                this.quotejson.variablePaper1 = false;
            }
            if (id == 2) {
                this.quotejson.variablePaper2 = false;
            } else if (id == 3) {
                this.quotejson.variablePaper3 = false;
            } else if (id == 4) {
                this.quotejson.variablePaper4 = false;
            } else if (id == 5) {
                this.quotejson.variablePaper5 = false;
            }
        } else {
            if (id == 1) {
                this.quotejson.variablePaper1 = true;
            }
            if (id == 2) {
                this.quotejson.variablePaper2 = true;
            } else if (id == 3) {
                this.quotejson.variablePaper3 = true;
            } else if (id == 4) {
                this.quotejson.variablePaper4 = true;
            } else if (id == 5) {
                this.quotejson.variablePaper5 = true;
            }
        }
        if (
            this.quotejson.variablePaper2 == true ||
            this.quotejson.variablePaper3 == true ||
            this.quotejson.variablePaper4 == true ||
            this.quotejson.variablePaper5 == true
        ) {
            const frontcolor = this.printingFrontColours.filter((data: any) => {
                if (data.pcolour_id == 11) {
                    data.isChecked = "N";
                    data.isDisabled = true;
                }
            });
            const backcolor = this.printingBackColours.filter((data: any) => {
                if (data.pcolour_id == 11) {
                    data.isChecked = "N";
                    data.isDisabled = true;
                }
            });
        } else {
            const frontcolor = this.printingFrontColours.filter((data: any) => {
                if (data.pcolour_id == 11) {
                    data.isChecked = "N";
                    data.isDisabled = false;
                }
            });
            const backcolor = this.printingBackColours.filter((data: any) => {
                if (data.pcolour_id == 11) {
                    data.isChecked = "N";
                    data.isDisabled = false;
                }
            });
        }
    }

    changepaperCheckBoxConditions(value: any) {
        const bookTypes = this.bookTypes.filter((data: any) => {
            if (data.pk_sheet_id == this.quotejson.pk_sheet_id) {
                const noOfBillsPerBook = this.noOfBillsPerBook.filter(
                    (item: any) => {
                        let sampleArray: any = [];
                        sampleArray = item.book_type.split(",");
                        if (sampleArray.includes(data.id)) {
                            item.active = true;
                        } else {
                            item.active = false;
                        }
                    }
                );
            }
        });
        if (value == 1) {
            this.quotejson.disablePaper2 = false;
            this.quotejson.disablePaper3 = false;
            this.quotejson.disablePaper4 = false;
            this.quotejson.disablePaper5 = false;
            this.quotejson.checkpaper2 = false;
            this.quotejson.checkpaper3 = false;
            this.quotejson.checkpaper4 = false;
            this.quotejson.checkpaper5 = false;
            this.quotejson.variablePaper2 = false;
            this.quotejson.variablePaper3 = false;
            this.quotejson.variablePaper4 = false;
            this.quotejson.variablePaper5 = false;
            this.quotejson.paper2 = "";
            this.quotejson.paper3 = "";
            this.quotejson.paper4 = "";
            this.quotejson.paper5 = "";
        } else if (value == 2) {
            this.quotejson.disablePaper2 = true;
            this.quotejson.disablePaper3 = false;
            this.quotejson.disablePaper4 = false;
            this.quotejson.disablePaper5 = false;
            this.quotejson.checkpaper2 = true;
            this.quotejson.checkpaper3 = false;
            this.quotejson.checkpaper4 = false;
            this.quotejson.checkpaper5 = false;
            this.quotejson.variablePaper3 = false;
            this.quotejson.variablePaper4 = false;
            this.quotejson.variablePaper5 = false;
            this.quotejson.paper3 = "";
            this.quotejson.paper4 = "";
            this.quotejson.paper5 = "";
        } else if (value == 3) {
            this.quotejson.disablePaper2 = true;
            this.quotejson.disablePaper3 = true;
            this.quotejson.disablePaper4 = false;
            this.quotejson.disablePaper5 = false;
            this.quotejson.checkpaper2 = true;
            this.quotejson.checkpaper3 = true;
            this.quotejson.checkpaper4 = false;
            this.quotejson.checkpaper5 = false;
            this.quotejson.variablePaper4 = false;
            this.quotejson.variablePaper5 = false;
            this.quotejson.paper4 = "";
            this.quotejson.paper5 = "";
        } else if (value == 4) {
            this.quotejson.disablePaper2 = true;
            this.quotejson.disablePaper3 = true;
            this.quotejson.disablePaper4 = true;
            this.quotejson.disablePaper5 = false;
            this.quotejson.checkpaper2 = true;
            this.quotejson.checkpaper3 = true;
            this.quotejson.checkpaper4 = true;
            this.quotejson.checkpaper5 = false;
            this.quotejson.variablePaper5 = false;
            this.quotejson.paper5 = "";
        } else if (value == 5) {
            this.quotejson.disablePaper2 = true;
            this.quotejson.disablePaper3 = true;
            this.quotejson.disablePaper4 = true;
            this.quotejson.disablePaper5 = true;
            this.quotejson.checkpaper2 = true;
            this.quotejson.checkpaper3 = true;
            this.quotejson.checkpaper4 = true;
            this.quotejson.checkpaper5 = true;
        }
        if (value == 1) {
            const paper1 = this.billpaper1.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = true;
                    if (this.quotejson.paper1 == data.paper_id) {
                        this.quotejson.paper1 = "";
                    }
                }
            });
            const paper2 = this.billpaper2.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = true;
                    if (this.quotejson.paper2 == data.paper_id) {
                        this.quotejson.paper2 = "";
                    }
                }
            });
            const paper3 = this.billpaper3.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = true;
                    if (this.quotejson.paper3 == data.paper_id) {
                        this.quotejson.paper3 = "";
                    }
                }
            });
            const paper4 = this.billpaper4.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = true;
                    if (this.quotejson.paper4 == data.paper_id) {
                        this.quotejson.paper4 = "";
                    }
                }
            });
            const paper5 = this.billpaper5.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = true;
                    if (this.quotejson.paper5 == data.paper_id) {
                        this.quotejson.paper5 = "";
                    }
                }
            });
        } else {
            const paper1 = this.billpaper1.filter((data: any) => {
                if (data.is_carbon == "Y") {
                    data.isDisabled = false;
                }
            });
        }
        this.perforationChange();
        this.checkSameColour();
        this.checkPaperIsMulticolour();
        if (this.submitted) {
            this.checkValidation();
        }
    }

    perforationChange() {
        if (
            this.quotejson.pk_sheet_id != 1 &&
            this.quotejson.perforation == "N"
        ) {
            this.quotejson.disbalePadBinding = false;
            this.quotejson.disbaleSoftBinding = true;
            this.quotejson.disbaleHardBinding = true;
        } else if (
            this.quotejson.pk_sheet_id != 1 &&
            (this.quotejson.pk_bp_id == 1 || this.quotejson.pk_bp_id == "1") &&
            this.quotejson.pnumber_id > 2
        ) {
            this.quotejson.disbalePadBinding = true;
            this.quotejson.disbaleSoftBinding = false;
            this.quotejson.disbaleHardBinding = false;
        } else {
            this.quotejson.disbalePadBinding = false;
            this.quotejson.disbaleSoftBinding = false;
            this.quotejson.disbaleHardBinding = false;
        }
    }

    updateFrontColourChanges(event: any, colour_id: any) {
        if (colour_id == 11) {
            if (event.target.checked) {
                const frontColourArray = this.printingFrontColours.filter(
                    (data: any) => {
                        if (data.pcolour_id != 11) {
                            data.isChecked = "N";
                        } else {
                            data.isChecked = "Y";
                        }
                    }
                );
                this.quotejson.isFrontMultiColour = "Y";
            } else {
                this.quotejson.isFrontMultiColour = "N";
            }
        } else {
            this.quotejson.isFrontMultiColour = "N";
            if (event.target.checked) {
                const frontColourArray = this.printingFrontColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == colour_id) {
                            data.isChecked = "Y";
                        } else if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                        }
                    }
                );
            } else {
                const frontColourArray = this.printingFrontColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == colour_id) {
                            data.isChecked = "N";
                        }
                    }
                );
            }
        }
        this.checkSameColour();
        if (this.submitted) {
            this.checkValidation();
        }
    }

    updateBackColourChanges(event: any, colour_id: any) {
        if (colour_id == 0) {
            if (event.target.checked) {
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id != 0) {
                            data.isChecked = "N";
                        } else {
                            data.isChecked = "Y";
                        }
                    }
                );
                this.quotejson.isBackColourNone = "Y";
            } else {
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == 0) {
                            data.isChecked = "N";
                        }
                    }
                );
                this.quotejson.isBackColourNone = "N";
            }
        } else if (colour_id == 11) {
            this.quotejson.isBackColourNone = "N";
            if (event.target.checked) {
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id != 11) {
                            data.isChecked = "N";
                        } else {
                            data.isChecked = "Y";
                        }
                    }
                );
                this.quotejson.isBackMultiColour = "Y";
            } else {
                this.quotejson.isBackMultiColour = "N";
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id != 0) {
                            data.isChecked = "N";
                        } else {
                            data.isChecked = "Y";
                        }
                    }
                );
            }
        } else {
            this.quotejson.isBackColourNone = "N";
            this.quotejson.isBackMultiColour = "N";
            if (event.target.checked) {
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == colour_id) {
                            data.isChecked = "Y";
                        } else if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                        } else if (data.pcolour_id == 0) {
                            data.isChecked = "N";
                        }
                    }
                );
            } else {
                const backColourArray = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == colour_id) {
                            data.isChecked = "N";
                        }
                    }
                );
            }
        }
        this.checkSameColour();
        this.changeBitNoticelamination();
        if (this.submitted) {
            this.checkValidation();
        }
    }

    checkSameColour() {
        let frontPrintingCOloursLists: any = [];
        const lastFrontCOlours = this.printingFrontColours.filter((data) => {
            if (data.isChecked == "Y" && data.pcolour_id != 11) {
                frontPrintingCOloursLists.push(data.pcolour_id);
            }
        });

        let backPrintingCOloursLists: any = [];
        const lastBackCOlours = this.printingBackColours.filter((data) => {
            if (
                data.isChecked == "Y" &&
                data.pcolour_id != 0 &&
                data.pcolour_id != 11
            ) {
                backPrintingCOloursLists.push(data.pcolour_id);
            }
        });

        let common = []; // Array to contain common elements
        for (var i = 0; i < frontPrintingCOloursLists.length; ++i) {
            for (var j = 0; j < backPrintingCOloursLists.length; ++j) {
                if (
                    frontPrintingCOloursLists[i] == backPrintingCOloursLists[j]
                ) {
                    common.push(frontPrintingCOloursLists[i]);
                }
            }
        }

        if (
            common.length != 0 &&
            frontPrintingCOloursLists.length == common.length &&
            backPrintingCOloursLists.length == common.length &&
            this.quotejson.pk_sheet_id == 1
        ) {
            this.contentBox = true;
        } else {
            this.contentBox = false;
        }
    }

    billsPerchange() {
        if (this.quotejson.pnumber_id > 2 || this.quotejson.pk_bp_id > 1) {
            this.quotejson.perforation = "M";
        }
        this.perforationChange();
    }

    changeNumbering(event: any) {
        if (event.target.value == 1) {
            this.quotejson.numbering = "N";
        }
        this.billsPerchange();
        if (this.submitted) {
            this.checkValidation();
        }
    }

    returnNumber() {
        this.quotejson.quantity = this.quotejson.quantity.replace(
            /[^0-9 ]/g,
            ""
        );
        this.quotejson.quantity = this.quotejson.quantity.replace(/ /g, ";");
        if (this.submitted) {
            this.checkValidation();
        }
    }

    checkPaperIsMulticolour() {
        let ismulticolourpaper1: boolean = true;
        let ismulticolourpaper2: boolean = true;
        let ismulticolourpaper3: boolean = true;
        let ismulticolourpaper4: boolean = true;
        let ismulticolourpaper5: boolean = true;
        if (this.quotejson.category_type == "BB") {
            if (this.quotejson.paper1 != "") {
                const paper1 = this.billpaper1.filter((data: any) => {
                    if (data.paper_id == this.quotejson.paper1) {
                        if (data.multi_colour_printing == "Y") {
                            ismulticolourpaper1 = true;
                        } else {
                            ismulticolourpaper1 = false;
                        }
                        if (data.is_carbon == "Y") {
                            if (this.quotejson.pk_sheet_id == 2) {
                                this.quotejson.disablePaper2 = false;
                                this.quotejson.disablePaper3 = false;
                                this.quotejson.disablePaper4 = false;
                                this.quotejson.disablePaper5 = true;
                                this.quotejson.checkpaper2 = false;
                                this.quotejson.checkpaper3 = false;
                                this.quotejson.checkpaper4 = false;
                                this.quotejson.checkpaper5 = true;
                                const paper5 = this.billpaper5.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper5 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                this.quotejson.paper2 = "";
                                this.quotejson.paper3 = "";
                                this.quotejson.paper4 = "";
                            } else if (this.quotejson.pk_sheet_id == 3) {
                                this.quotejson.disablePaper2 = true;
                                this.quotejson.disablePaper3 = false;
                                this.quotejson.disablePaper4 = false;
                                this.quotejson.disablePaper5 = true;
                                this.quotejson.checkpaper2 = true;
                                this.quotejson.checkpaper3 = false;
                                this.quotejson.checkpaper4 = false;
                                this.quotejson.checkpaper5 = true;
                                const paper2 = this.billpaper2.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper2 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper4 = this.billpaper5.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper5 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                this.quotejson.paper3 = "";
                                this.quotejson.paper4 = "";
                            } else if (this.quotejson.pk_sheet_id == 4) {
                                this.quotejson.disablePaper2 = true;
                                this.quotejson.disablePaper3 = true;
                                this.quotejson.disablePaper4 = false;
                                this.quotejson.disablePaper5 = true;
                                this.quotejson.checkpaper2 = true;
                                this.quotejson.checkpaper3 = true;
                                this.quotejson.checkpaper4 = false;
                                this.quotejson.checkpaper5 = true;
                                const paper2 = this.billpaper2.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper2 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper3 = this.billpaper3.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper3 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper5 = this.billpaper5.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper5 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                this.quotejson.paper4 = "";
                            } else if (this.quotejson.pk_sheet_id == 5) {
                                this.quotejson.disablePaper2 = true;
                                this.quotejson.disablePaper3 = true;
                                this.quotejson.disablePaper4 = true;
                                this.quotejson.disablePaper5 = true;
                                this.quotejson.checkpaper2 = true;
                                this.quotejson.checkpaper3 = true;
                                this.quotejson.checkpaper4 = true;
                                this.quotejson.checkpaper5 = true;
                                const paper2 = this.billpaper2.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper2 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper3 = this.billpaper3.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper3 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper4 = this.billpaper4.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper4 =
                                                data.paper_id;
                                        }
                                    }
                                );
                                const paper5 = this.billpaper5.filter(
                                    (data: any) => {
                                        if (data.is_carbon == "Y") {
                                            data.isDisabled = false;
                                            this.quotejson.paper5 =
                                                data.paper_id;
                                        }
                                    }
                                );
                            }
                            this.quotejson.isCorbanDisablePaper2 = true;
                            this.quotejson.isCorbanDisablePaper3 = true;
                            this.quotejson.isCorbanDisablePaper4 = true;
                            this.quotejson.isCorbanDisablePaper5 = true;
                        } else {
                            this.quotejson.isCorbanDisablePaper2 = false;
                            this.quotejson.isCorbanDisablePaper3 = false;
                            this.quotejson.isCorbanDisablePaper4 = false;
                            this.quotejson.isCorbanDisablePaper5 = false;
                            const paper2 = this.billpaper2.filter(
                                (data: any) => {
                                    if (data.is_carbon == "Y") {
                                        data.isDisabled = true;
                                        if (
                                            data.paper_id ==
                                            this.quotejson.paper2
                                        ) {
                                            this.quotejson.paper2 = "";
                                        }
                                    }
                                }
                            );
                            const paper3 = this.billpaper3.filter(
                                (data: any) => {
                                    if (data.is_carbon == "Y") {
                                        data.isDisabled = true;
                                        if (
                                            data.paper_id ==
                                            this.quotejson.paper3
                                        ) {
                                            this.quotejson.paper3 = "";
                                        }
                                    }
                                }
                            );
                            const paper4 = this.billpaper4.filter(
                                (data: any) => {
                                    if (data.is_carbon == "Y") {
                                        data.isDisabled = true;
                                        if (
                                            data.paper_id ==
                                            this.quotejson.paper4
                                        ) {
                                            this.quotejson.paper4 = "";
                                        }
                                    }
                                }
                            );
                            const paper5 = this.billpaper5.filter(
                                (data: any) => {
                                    if (data.is_carbon == "Y") {
                                        data.isDisabled = true;
                                        if (
                                            data.paper_id ==
                                            this.quotejson.paper5
                                        ) {
                                            this.quotejson.paper5 = "";
                                        }
                                    }
                                }
                            );
                        }
                    }
                });
            }
            if (this.quotejson.paper2 != "" && this.quotejson.checkpaper2) {
                const paper2 = this.billpaper2.filter((data: any) => {
                    if (data.paper_id == this.quotejson.paper2) {
                        if (data.multi_colour_printing == "Y") {
                            ismulticolourpaper2 = true;
                        } else {
                            ismulticolourpaper2 = false;
                        }
                    }
                });
            } else {
                ismulticolourpaper2 = true;
            }

            if (this.quotejson.paper3 != "" && this.quotejson.checkpaper3) {
                const paper3 = this.billpaper3.filter((data: any) => {
                    if (data.paper_id == this.quotejson.paper3) {
                        if (data.multi_colour_printing == "Y") {
                            ismulticolourpaper3 = true;
                        } else {
                            ismulticolourpaper3 = false;
                        }
                    }
                });
            } else {
                ismulticolourpaper3 = true;
            }

            if (this.quotejson.paper4 != "" && this.quotejson.checkpaper4) {
                const paper4 = this.billpaper4.filter((data: any) => {
                    if (data.paper_id == this.quotejson.paper4) {
                        if (data.multi_colour_printing == "Y") {
                            ismulticolourpaper4 = true;
                        } else {
                            ismulticolourpaper4 = false;
                        }
                    }
                });
            } else {
                ismulticolourpaper4 = true;
            }

            if (this.quotejson.paper5 != "" && this.quotejson.checkpaper5) {
                const paper5 = this.billpaper5.filter((data: any) => {
                    if (data.paper_id == this.quotejson.paper5) {
                        if (data.multi_colour_printing == "Y") {
                            ismulticolourpaper5 = true;
                        } else {
                            ismulticolourpaper5 = false;
                        }
                    }
                });
            } else {
                ismulticolourpaper5 = true;
            }

            if (
                ismulticolourpaper1 == true &&
                ismulticolourpaper2 == true &&
                ismulticolourpaper3 == true &&
                ismulticolourpaper4 == true &&
                ismulticolourpaper5 == true
            ) {
                const frontcolor = this.printingFrontColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                            data.isDisabled = false;
                        }
                    }
                );
                const backcolor = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                            data.isDisabled = false;
                        }
                    }
                );
            } else {
                const frontcolor = this.printingFrontColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                            data.isDisabled = true;
                        }
                    }
                );
                const backcolor = this.printingBackColours.filter(
                    (data: any) => {
                        if (data.pcolour_id == 11) {
                            data.isChecked = "N";
                            data.isDisabled = true;
                        }
                    }
                );
            }
        } else if (this.quotejson.category_type == "BN") {
            const paper = this.duplicateBitNoticePapers.filter((data: any) => {
                if (data.paper_id == this.quotejson.paper) {
                    if (data.bt_lamination_status == "Y") {
                        this.quotejson.bitnoticelamination = true;
                        this.quotejson.lamination = "N";
                    } else {
                        this.quotejson.bitnoticelamination = false;
                        this.quotejson.lamination = "N";
                    }
                    if (data.bt_perforation_status == "Y") {
                        this.quotejson.bitnoticeperforation = true;
                        this.quotejson.bitnotice_perforation = "N";
                    } else {
                        this.quotejson.bitnoticeperforation = false;
                        this.quotejson.bitnotice_perforation = "N";
                    }
                    if (data.bt_folding_status == "Y") {
                        this.quotejson.bitnoticefolding = true;
                        this.quotejson.folding = "N";
                    } else {
                        this.quotejson.bitnoticefolding = false;
                        this.quotejson.folding = "N";
                    }
                    if (data.multi_colour_printing != "Y") {
                        const frontcolor = this.printingFrontColours.filter(
                            (data: any) => {
                                if (data.pcolour_id == 11) {
                                    data.isChecked = "N";
                                    data.isDisabled = true;
                                }
                            }
                        );
                        const backcolor = this.printingBackColours.filter(
                            (data: any) => {
                                if (data.pcolour_id == 11) {
                                    data.isChecked = "N";
                                    data.isDisabled = true;
                                }
                            }
                        );
                    } else {
                        const frontcolor = this.printingFrontColours.filter(
                            (data: any) => {
                                if (data.pcolour_id == 11) {
                                    data.isChecked = "N";
                                    data.isDisabled = false;
                                }
                            }
                        );
                        const backcolor = this.printingBackColours.filter(
                            (data: any) => {
                                if (data.pcolour_id == 11) {
                                    data.isChecked = "N";
                                    data.isDisabled = false;
                                }
                            }
                        );
                    }
                }
            });
        } else if (this.quotejson.category_type == "LH") {
            const paper = this.duplicateLetterPadPapers.filter((data: any) => {
                if (data.paper_id == this.quotejson.paper) {
                    if (data.lp_goldsilver_foil_status == "Y") {
                        this.quotejson.letterpadfoil = true;
                        this.quotejson.foil = "N";
                    } else {
                        this.quotejson.letterpadfoil = false;
                        this.quotejson.foil = "N";
                    }
                    if (data.lp_embossing_status == "Y") {
                        this.quotejson.letterpadembossing = true;
                        this.quotejson.embossing = "N";
                    } else {
                        this.quotejson.letterpadembossing = false;
                        this.quotejson.embossing = "N";
                    }
                }
            });
        }
        this.checkValidation();
    }

    removeFromAt(name: any) {
        if (name.includes("(")) {
            var name = name.substr(0, name.indexOf("("));
        }
        if (name.includes("@")) {
            var name = name.substr(0, name.indexOf("@"));
        }
        return name;
    }

    removeNonZeros(n: any) {
        var nonzeros = parseFloat(n);
        return nonzeros;
    }

    changeBitNoticelamination() {
        if (this.quotejson.lamination == "Y") {
            this.quotejson.lamination_type = "G";
            let backPrintingCOloursList: any = [];
            const lastBackCOlours = this.printingBackColours.filter(
                (data: any) => {
                    if (data.isChecked == "Y" && data.pcolour_id != 0) {
                        backPrintingCOloursList.push(data.pcolour_id);
                    }
                }
            );
            if (backPrintingCOloursList.length > 0) {
                this.quotejson.lamination_side = 2;
            } else {
                this.quotejson.lamination_side = 1;
            }
        } else {
            this.quotejson.lamination_type = "N";
            this.quotejson.lamination_side = "0";
        }

        this.checkValidation();
    }

    checkBitNoticeNumbering() {
        if (this.quotejson.bitnotice_numbering == "Y") {
            this.quotejson.pnumber_id = "2";
        } else {
            this.quotejson.pnumber_id = "";
        }
    }

    getCustomerList() {
        this.commonService.getCustomerList().subscribe((response: any) => {
            if (response.status) {
                this.customerList = response.data;
            }
        });
    }

    ngOnInit(): void {
        if (this.user_type === "SA") {
            this.getCustomerList();
        }
        this.getUserDetails();
        this.getNoOfBillPerBook();
        this.getNoOfBillsPerPage();
        this.getNoOfPlacesForNumbering();
        this.getPrintingFrontColours();
        this.getPrintingBackColours();
        this.getproductCategory();
    }

    getproductCategory() {
        this.commonService.getSubCategories().subscribe((response: any) => {
            if (response.status) {
                this.subCategories = response.data;
                this.selectedcategory_id = this.subCategories[0].category_id;
                const bookstype = this.subCategories.filter((data: any) => {
                    if (data.category_id == this.selectedcategory_id) {
                        this.getSizes(data.value, data.category_id);
                        data.active = true;
                    } else {
                        data.active = false;
                    }
                });
            }
        });
    }

    getUserDetails() {
        this.commonService
            .getUserDetails(this.user_id, this.user_type)
            .subscribe((response: any) => {
                if (response.status) {
                    this.user_details = response.data;
                }
            });
    }

    getSizes(value: any, sub_category_id: any) {
        $(".preloader").fadeIn("slow");
        this.quotejson.category_type = value;
        if (sub_category_id) {
            this.sub_category_id = sub_category_id;
            const bookstype = this.subCategories.filter((data: any) => {
                if (data.category_id == sub_category_id) {
                    data.active = true;
                    if (data.category_code == "HB") {
                        this.quotejson.binding = 3;
                    } else if (data.category_code == "SB") {
                        this.quotejson.binding = 2;
                    } else if (data.category_code == "PB") {
                        this.quotejson.binding = 1;
                    } else {
                        this.quotejson.binding = "";
                    }
                    this.category_name = data.category_name;
                    this.category_image = data.cat_image;
                    this.gst = parseFloat(data.gst);
                } else {
                    data.active = false;
                }
            });
        } else {
            this.sub_category_id = "";
        }

        this.quotejson.bitnotice_numbering = "";
        if (this.quotejson.category_type == "BB") {
            const noOfPlacesOfNumbering = this.noOfPlacesOfNumbering.filter(
                (data: any) => {
                    data.active = true;
                }
            );
        } else if (this.quotejson.category_type == "BN") {
            this.quotejson.numbering = "N";
            this.quotejson.perforation = "N";
            this.quotejson.is_numbering = "N";
            const noOfPlacesOfNumbering = this.noOfPlacesOfNumbering.filter(
                (data: any) => {
                    if (data.pnumber_id == 1) {
                        data.active = false;
                    } else {
                        data.active = true;
                    }
                }
            );
            this.quotejson.bitnotice_numbering = "N";
        }

        this.commonService
            .getSizes(sub_category_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.Sizes = response.data;
                    this.quotejson.psize_id = "";
                }
            });

        this.submitted = false;
        if (this.submitted) {
            this.checkValidation();
        }
        $(".preloader").fadeOut("slow");
    }

    getNoOfBillPerBook() {
        this.commonService.getNoOfBillPerBook().subscribe((response: any) => {
            if (response.status) {
                this.noOfBillsPerBook = response.data;
                const noOfBills = this.noOfBillsPerBook.filter((data: any) => {
                    data.active = true;
                });
            }
        });
    }

    getBillBookPapers() {
        if (this.sub_category_id) {
            this.commonService
                .getPapers(this.sub_category_id)
                .subscribe((response: any) => {
                    if (response.status) {
                        this.billpapers = response.data;
                    }
                });
        }
    }

    getBitNoticePapers() {
        if (this.sub_category_id) {
            this.commonService
                .getPapers(this.sub_category_id)
                .subscribe((response: any) => {
                    if (response.status) {
                        this.bitNoticePapers = response.data;
                        this.duplicateBitNoticePapers = response.data;
                    }
                });
        }
    }

    getLetterPadPapers() {
        if (this.sub_category_id) {
            this.commonService
                .getPapers(this.sub_category_id)
                .subscribe((response: any) => {
                    if (response.status) {
                        this.letterPadPapers = response.data;
                        this.duplicateLetterPadPapers = response.data;
                    }
                });
        }
    }

    getNoOfBillsPerPage() {
        this.commonService.getNoOfBillsPerPage().subscribe((response: any) => {
            if (response.status) {
                this.noOfBillsPerPage = response.data;
                this.noOfBillsPerPageDuplicate = response.data;
            }
        });
    }

    getNoOfPlacesForNumbering() {
        this.commonService
            .getNoOfPlacesForNumbering()
            .subscribe((response: any) => {
                if (response.status) {
                    this.noOfPlacesOfNumbering = response.data;
                    const noOfPlacesOfNumbering =
                        this.noOfPlacesOfNumbering.filter((data: any) => {
                            if (data.pnumber_id == "1") {
                                data.active = false;
                            } else {
                                data.active = true;
                            }
                        });
                }
            });
    }

    getPrintingFrontColours() {
        this.commonService.getPrintingColours().subscribe((response: any) => {
            if (response.status) {
                this.printingFrontColours = [];
                const frontColourArray = response.data.filter((data: any) => {
                    if (data.pcolour_id == 1) {
                        data.isChecked = "Y";
                        data.isDisabled = false;
                    } else {
                        data.isChecked = "N";
                        data.isDisabled = false;
                    }
                    this.printingFrontColours.push(data);
                });
            }
        });
    }

    getPrintingBackColours() {
        this.commonService.getPrintingColours().subscribe((response: any) => {
            if (response.status) {
                this.printingBackColours = [];
                this.printingBackColours = [
                    { pcolour_id: "0", colour_name: "None", isChecked: "Y" },
                ];
                const backColourArray = response.data.filter((data: any) => {
                    data.isChecked = "N";
                    data.isDisabled = false;
                    this.printingBackColours.push(data);
                });
            }
        });
    }

    getBitNoticePacking() {
        this.commonService
            .getBitNoticePacking(this.quotejson.psize_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.bitNoticePacking = response.data;
                }
            });
    }

    getLetterHeadPacking() {
        this.commonService
            .getLetterHeadPacking(this.quotejson.psize_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.letterHeadPacking = response.data;
                }
            });
    }

    checkValidation() {
        this.quotejson.error.customer_id = false;
        this.quotejson.error.customer_name = false;
        this.quotejson.error.customer_mobile = false;
        this.quotejson.error.customer_email = false;
        this.quotejson.error.category_type = false;
        this.quotejson.error.job_name = false;
        this.quotejson.error.psize_id = false;
        this.quotejson.error.frontcolour = true;
        this.quotejson.error.backcolour = true;

        if (this.user_type === "SA") {
            if (this.quotejson.showCustomerForm) {
                if (
                    this.quotejson.customer_name == "" ||
                    this.quotejson.customer_name == null ||
                    this.quotejson.customer_name == undefined
                ) {
                    this.quotejson.error.customer_name = true;
                }
                if (
                    this.quotejson.customer_mobile == "" ||
                    this.quotejson.customer_mobile == null ||
                    this.quotejson.customer_mobile == undefined
                ) {
                    this.quotejson.error.customer_mobile = true;
                }
                if (
                    this.quotejson.customer_email == "" ||
                    this.quotejson.customer_email == null ||
                    this.quotejson.customer_email == undefined
                ) {
                    this.quotejson.error.customer_email = true;
                } else {
                    const valid =
                        /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                            this.quotejson.customer_email
                        );
                    if (valid) {
                        this.quotejson.error.customer_email = false;
                    } else {
                        this.quotejson.error.customer_email = true;
                    }
                }
            } else {
                if (
                    this.quotejson.customer_id == "" ||
                    this.quotejson.customer_id == null ||
                    this.quotejson.customer_id == undefined
                ) {
                    this.quotejson.error.customer_id = true;
                }
            }
        }

        if (
            this.quotejson.category_type == "" ||
            this.quotejson.category_type == null ||
            this.quotejson.category_type == " " ||
            this.quotejson.category_type == undefined
        ) {
            this.quotejson.error.category_type = true;
        }

        if (
            this.quotejson.job_name == "" ||
            this.quotejson.job_name == null ||
            this.quotejson.job_name == " " ||
            this.quotejson.job_name == undefined
        ) {
            this.quotejson.error.job_name = true;
        }

        if (
            this.quotejson.psize_id == "" ||
            this.quotejson.psize_id == null ||
            this.quotejson.psize_id == " " ||
            this.quotejson.psize_id == undefined
        ) {
            this.quotejson.error.psize_id = true;
        }

        let frontPrintingCOloursLists: any = [];
        const lastFrontCOlours = this.printingFrontColours.filter((data) => {
            if (data.isChecked == "Y") {
                frontPrintingCOloursLists.push(data.pcolour_id);
            }
        });

        let backPrintingCOloursLists: any = [];
        const lastBackCOlours = this.printingBackColours.filter((data) => {
            if (data.isChecked == "Y") {
                backPrintingCOloursLists.push(data.pcolour_id);
            }
        });

        if (frontPrintingCOloursLists.length > 0) {
            this.quotejson.error.frontcolour = false;
        }

        if (backPrintingCOloursLists.length > 0) {
            this.quotejson.error.backcolour = false;
        }

        if (this.quotejson.numbering == "MA") {
            if (
                this.quotejson.is_numbering == "" ||
                this.quotejson.is_numbering == null ||
                this.quotejson.is_numbering == " " ||
                this.quotejson.is_numbering == undefined
            ) {
                this.quotejson.error.is_numbering = true;
            } else {
                this.quotejson.error.is_numbering = false;
            }
        } else {
            this.quotejson.error.is_numbering = false;
        }

        if (
            this.quotejson.pk_bb_id == "" ||
            this.quotejson.pk_bb_id == null ||
            this.quotejson.pk_bb_id == " " ||
            this.quotejson.pk_bb_id == undefined
        ) {
            this.quotejson.error.pk_bb_id = true;
        } else {
            this.quotejson.error.pk_bb_id = false;
        }

        if (
            this.quotejson.pk_sheet_id == "" ||
            this.quotejson.pk_sheet_id == null ||
            this.quotejson.pk_sheet_id == " " ||
            this.quotejson.pk_sheet_id == undefined
        ) {
            this.quotejson.error.pk_sheet_id = true;
        } else {
            this.quotejson.error.pk_sheet_id = false;
        }

        if (
            this.quotejson.paper1 == "" ||
            this.quotejson.paper1 == null ||
            this.quotejson.paper1 == " " ||
            this.quotejson.paper1 == undefined
        ) {
            this.quotejson.error.paper1 = true;
        } else {
            this.quotejson.error.paper1 = false;
        }

        if (
            this.quotejson.pk_bp_id == "" ||
            this.quotejson.pk_bp_id == null ||
            this.quotejson.pk_bp_id == " " ||
            this.quotejson.pk_bp_id == undefined
        ) {
            this.quotejson.error.pk_bp_id = true;
        } else {
            this.quotejson.error.pk_bp_id = false;
        }

        if (
            this.quotejson.bitnotice_numbering == "Y" ||
            this.quotejson.numbering != "N"
        ) {
            if (
                this.quotejson.pnumber_id == "" ||
                this.quotejson.pnumber_id == null ||
                this.quotejson.pnumber_id == " " ||
                this.quotejson.pnumber_id == undefined
            ) {
                this.quotejson.error.pnumber_id = true;
            } else {
                this.quotejson.error.pnumber_id = false;
            }
        } else {
            this.quotejson.error.pnumber_id = false;
        }

        if (
            this.quotejson.quantity == "" ||
            this.quotejson.quantity == null ||
            this.quotejson.quantity == " " ||
            this.quotejson.quantity == undefined
        ) {
            this.quotejson.error.quantity = true;
        } else {
            if (this.quotejson.quantity < 0) {
                this.quotejson.error.quantity = true;
            } else {
                this.quotejson.error.quantity = false;
            }
        }

        if (
            this.quotejson.pk_sheet_id == 2 ||
            this.quotejson.pk_sheet_id == "2"
        ) {
            if (
                this.quotejson.paper2 == "" ||
                this.quotejson.paper2 == null ||
                this.quotejson.paper2 == " " ||
                this.quotejson.paper2 == undefined
            ) {
                this.quotejson.error.paper2 = true;
            } else {
                this.quotejson.error.paper2 = false;
            }
        } else if (
            this.quotejson.pk_sheet_id == 3 ||
            this.quotejson.pk_sheet_id == "3"
        ) {
            if (
                this.quotejson.paper2 == "" ||
                this.quotejson.paper2 == null ||
                this.quotejson.paper2 == " " ||
                this.quotejson.paper2 == undefined
            ) {
                this.quotejson.error.paper2 = true;
            } else {
                this.quotejson.error.paper2 = false;
            }
            if (
                this.quotejson.paper3 == "" ||
                this.quotejson.paper3 == null ||
                this.quotejson.paper3 == " " ||
                this.quotejson.paper3 == undefined
            ) {
                this.quotejson.error.paper3 = true;
            } else {
                this.quotejson.error.paper3 = false;
            }
        } else if (
            this.quotejson.pk_sheet_id == 4 ||
            this.quotejson.pk_sheet_id == "4"
        ) {
            if (
                this.quotejson.paper2 == "" ||
                this.quotejson.paper2 == null ||
                this.quotejson.paper2 == " " ||
                this.quotejson.paper2 == undefined
            ) {
                this.quotejson.error.paper2 = true;
            } else {
                this.quotejson.error.paper2 = false;
            }
            if (
                this.quotejson.paper3 == "" ||
                this.quotejson.paper3 == null ||
                this.quotejson.paper3 == " " ||
                this.quotejson.paper3 == undefined
            ) {
                this.quotejson.error.paper3 = true;
            } else {
                this.quotejson.error.paper3 = false;
            }
            if (
                this.quotejson.paper4 == "" ||
                this.quotejson.paper4 == null ||
                this.quotejson.paper4 == " " ||
                this.quotejson.paper4 == undefined
            ) {
                this.quotejson.error.paper4 = true;
            } else {
                this.quotejson.error.paper4 = false;
            }
        } else if (
            this.quotejson.pk_sheet_id == 5 ||
            this.quotejson.pk_sheet_id == "5"
        ) {
            if (
                this.quotejson.paper2 == "" ||
                this.quotejson.paper2 == null ||
                this.quotejson.paper2 == " " ||
                this.quotejson.paper2 == undefined
            ) {
                this.quotejson.error.paper2 = true;
            } else {
                this.quotejson.error.paper2 = false;
            }
            if (
                this.quotejson.paper3 == "" ||
                this.quotejson.paper3 == null ||
                this.quotejson.paper3 == " " ||
                this.quotejson.paper3 == undefined
            ) {
                this.quotejson.error.paper3 = true;
            } else {
                this.quotejson.error.paper3 = false;
            }
            if (
                this.quotejson.paper4 == "" ||
                this.quotejson.paper4 == null ||
                this.quotejson.paper4 == " " ||
                this.quotejson.paper4 == undefined
            ) {
                this.quotejson.error.paper4 = true;
            } else {
                this.quotejson.error.paper4 = false;
            }
            if (
                this.quotejson.paper4 == "" ||
                this.quotejson.paper4 == null ||
                this.quotejson.paper4 == " " ||
                this.quotejson.paper4 == undefined
            ) {
                this.quotejson.error.paper4 = true;
            } else {
                this.quotejson.error.paper4 = false;
            }
            if (
                this.quotejson.paper5 == "" ||
                this.quotejson.paper5 == null ||
                this.quotejson.paper5 == " " ||
                this.quotejson.paper5 == undefined
            ) {
                this.quotejson.error.paper5 = true;
            } else {
                this.quotejson.error.paper5 = false;
            }
        }

        if (this.quotejson.numbering == "MA") {
            if (
                this.quotejson.is_numbering == "" ||
                this.quotejson.is_numbering == null ||
                this.quotejson.is_numbering == " " ||
                this.quotejson.is_numbering == undefined
            ) {
                this.quotejson.error.is_numbering = true;
            } else {
                this.quotejson.error.is_numbering = false;
            }
        }

        if (
            this.quotejson.paper == "" ||
            this.quotejson.paper == null ||
            this.quotejson.paper == " " ||
            this.quotejson.paper == undefined
        ) {
            this.quotejson.error.paper = true;
        } else {
            this.quotejson.error.paper = false;
        }

        if (
            this.quotejson.category_type == "BN" &&
            this.quotejson.bitnoticelamination
        ) {
            if (
                this.quotejson.lamination == "" ||
                this.quotejson.lamination == null ||
                this.quotejson.lamination == " " ||
                this.quotejson.lamination == undefined
            ) {
                this.quotejson.error.lamination = true;
            } else {
                this.quotejson.error.lamination = false;
                this.quotejson.error.lamination_side = false;
                this.quotejson.error.lamination_type = false;
                if (this.quotejson.lamination == "Y") {
                    if (
                        this.quotejson.lamination_side == "" ||
                        this.quotejson.lamination_side == null ||
                        this.quotejson.lamination_side == " " ||
                        this.quotejson.lamination_side == undefined
                    ) {
                        this.quotejson.error.lamination_side = true;
                    } else {
                        this.quotejson.error.lamination_side = false;
                    }
                    if (
                        this.quotejson.lamination_type == "" ||
                        this.quotejson.lamination_type == null ||
                        this.quotejson.lamination_type == " " ||
                        this.quotejson.lamination_type == undefined
                    ) {
                        this.quotejson.error.lamination_type = true;
                    } else {
                        this.quotejson.error.lamination_type = false;
                    }
                }
            }
        } else {
            this.quotejson.error.lamination = false;
            this.quotejson.error.lamination_side = false;
            this.quotejson.error.lamination_type = false;
        }

        if (
            this.quotejson.bitnotice_numbering == "" ||
            this.quotejson.bitnotice_numbering == null ||
            this.quotejson.bitnotice_numbering == " " ||
            this.quotejson.bitnotice_numbering == undefined
        ) {
            this.quotejson.error.bitnotice_numbering = true;
        } else {
            this.quotejson.error.bitnotice_numbering = false;
        }

        if (
            this.quotejson.category_type == "BN" &&
            this.quotejson.bitnoticeperforation
        ) {
            if (
                this.quotejson.bitnotice_perforation == "" ||
                this.quotejson.bitnotice_perforation == null ||
                this.quotejson.bitnotice_perforation == " " ||
                this.quotejson.bitnotice_perforation == undefined
            ) {
                this.quotejson.error.bitnotice_perforation = true;
            } else {
                this.quotejson.error.bitnotice_perforation = false;
            }
        } else {
            this.quotejson.error.bitnotice_perforation = false;
        }

        if (
            this.quotejson.category_type == "BN" &&
            this.quotejson.bitnoticefolding
        ) {
            if (
                this.quotejson.folding == "" ||
                this.quotejson.folding == null ||
                this.quotejson.folding == " " ||
                this.quotejson.folding == undefined
            ) {
                this.quotejson.error.folding = true;
            } else {
                this.quotejson.error.folding = false;
            }
        } else {
            this.quotejson.error.folding = false;
        }

        if (
            this.quotejson.pk_bitnotice_cal_id == "" ||
            this.quotejson.pk_bitnotice_cal_id == null ||
            this.quotejson.pk_bitnotice_cal_id == " " ||
            this.quotejson.pk_bitnotice_cal_id == undefined
        ) {
            this.quotejson.error.pk_bitnotice_cal_id = true;
        } else {
            this.quotejson.error.pk_bitnotice_cal_id = false;
        }

        if (
            this.quotejson.category_type == "LH" &&
            this.quotejson.letterpadfoil
        ) {
            if (
                this.quotejson.foil == "" ||
                this.quotejson.foil == null ||
                this.quotejson.foil == " " ||
                this.quotejson.foil == undefined
            ) {
                this.quotejson.error.foil = true;
            } else {
                this.quotejson.error.foil = false;
                if (this.quotejson.foil == "Y") {
                    if (
                        this.quotejson.foillength == "" ||
                        this.quotejson.foillength == null ||
                        this.quotejson.foillength == " " ||
                        this.quotejson.foillength == undefined
                    ) {
                        this.quotejson.error.foillength = true;
                    } else {
                        this.quotejson.error.foillength = false;
                    }
                    if (
                        this.quotejson.foilwidth == "" ||
                        this.quotejson.foilwidth == null ||
                        this.quotejson.foilwidth == " " ||
                        this.quotejson.foilwidth == undefined
                    ) {
                        this.quotejson.error.foilwidth = true;
                    } else {
                        this.quotejson.error.foilwidth = false;
                    }
                } else {
                    this.quotejson.error.foillength = false;
                    this.quotejson.error.foilwidth = false;
                }
            }
        } else {
            this.quotejson.error.foil = false;
            this.quotejson.error.foillength = false;
            this.quotejson.error.foilwidth = false;
        }

        if (
            this.quotejson.category_type == "LH" &&
            this.quotejson.letterpadembossing
        ) {
            if (
                this.quotejson.embossing == "" ||
                this.quotejson.embossing == null ||
                this.quotejson.embossing == " " ||
                this.quotejson.embossing == undefined
            ) {
                this.quotejson.error.embossing = true;
            } else {
                this.quotejson.error.embossing = false;
                if (this.quotejson.embossing == "Y") {
                    if (
                        this.quotejson.embossinglength == "" ||
                        this.quotejson.embossinglength == null ||
                        this.quotejson.embossinglength == " " ||
                        this.quotejson.embossinglength == undefined
                    ) {
                        this.quotejson.error.embossinglength = true;
                    } else {
                        this.quotejson.error.embossinglength = false;
                    }
                    if (
                        this.quotejson.embossingwidth == "" ||
                        this.quotejson.embossingwidth == null ||
                        this.quotejson.embossingwidth == " " ||
                        this.quotejson.embossingwidth == undefined
                    ) {
                        this.quotejson.error.embossingwidth = true;
                    } else {
                        this.quotejson.error.embossingwidth = false;
                    }
                } else {
                    this.quotejson.error.embossinglength = false;
                    this.quotejson.error.embossingwidth = false;
                }
            }
        } else {
            this.quotejson.error.embossing = false;
            this.quotejson.error.embossinglength = false;
            this.quotejson.error.embossingwidth = false;
        }

        if (
            this.quotejson.pk_letter_cal_id == "" ||
            this.quotejson.pk_letter_cal_id == null ||
            this.quotejson.pk_letter_cal_id == " " ||
            this.quotejson.pk_letter_cal_id == undefined
        ) {
            this.quotejson.error.pk_letter_cal_id = true;
        } else {
            this.quotejson.error.pk_letter_cal_id = false;
        }

        if (this.quotejson.category_type == "BB") {
            if (
                !this.quotejson.error.customer_id &&
                !this.quotejson.error.customer_name &&
                !this.quotejson.error.customer_mobile &&
                !this.quotejson.error.customer_email &&
                !this.quotejson.error.category_type &&
                !this.quotejson.error.job_name &&
                !this.quotejson.error.psize_id &&
                !this.quotejson.error.pk_bb_id &&
                !this.quotejson.error.pk_sheet_id &&
                !this.quotejson.error.paper1 &&
                !this.quotejson.error.paper2 &&
                !this.quotejson.error.paper3 &&
                !this.quotejson.error.paper4 &&
                !this.quotejson.error.paper5 &&
                !this.quotejson.error.frontcolour &&
                !this.quotejson.error.backcolour &&
                !this.quotejson.error.pk_bp_id &&
                !this.quotejson.error.pnumber_id &&
                !this.quotejson.error.is_numbering &&
                !this.quotejson.error.binding &&
                !this.quotejson.error.quantity
            ) {
                this.formError = true;
            } else {
                this.formError = false;
            }
        } else if (this.quotejson.category_type == "BN") {
            if (
                !this.quotejson.error.customer_id &&
                !this.quotejson.error.customer_name &&
                !this.quotejson.error.customer_mobile &&
                !this.quotejson.error.customer_email &&
                !this.quotejson.error.category_type &&
                !this.quotejson.error.job_name &&
                !this.quotejson.error.psize_id &&
                !this.quotejson.error.paper &&
                !this.quotejson.error.frontcolor &&
                !this.quotejson.error.backcolor &&
                !this.quotejson.error.lamination &&
                !this.quotejson.error.lamination_side &&
                !this.quotejson.error.lamination_type &&
                !this.quotejson.error.bitnotice_numbering &&
                !this.quotejson.error.pnumber_id &&
                !this.quotejson.error.bitnotice_perforation &&
                !this.quotejson.error.folding &&
                !this.quotejson.error.pk_bitnotice_cal_id &&
                !this.quotejson.error.quantity
            ) {
                this.formError = true;
            } else {
                this.formError = false;
            }
        } else if (this.quotejson.category_type == "LH") {
            if (
                !this.quotejson.error.customer_id &&
                !this.quotejson.error.customer_name &&
                !this.quotejson.error.customer_mobile &&
                !this.quotejson.error.customer_email &&
                !this.quotejson.error.category_type &&
                !this.quotejson.error.job_name &&
                !this.quotejson.error.psize_id &&
                !this.quotejson.error.paper &&
                !this.quotejson.error.frontcolor &&
                !this.quotejson.error.backcolor &&
                !this.quotejson.error.foil &&
                !this.quotejson.error.foillength &&
                !this.quotejson.error.foilwidth &&
                !this.quotejson.error.embossing &&
                !this.quotejson.error.embossinglength &&
                !this.quotejson.error.embossingwidth &&
                !this.quotejson.error.pk_letter_cal_id &&
                !this.quotejson.error.quantity
            ) {
                this.formError = true;
            } else {
                this.formError = false;
            }
        }
    }

    saveQuote() {
        this.submitted = true;

        if (this.submitted) {
            this.checkValidation();
        }

        if (this.formError) {
            let frontPrintingCOloursList: any = [];
            const lastFrontCOlours = this.printingFrontColours.filter(
                (data) => {
                    if (data.isChecked == "Y" && data.pcolour_id != 11) {
                        frontPrintingCOloursList.push(data.pcolour_id);
                    }
                }
            );
            let backPrintingCOloursList: any = [];
            const lastBackCOlours = this.printingBackColours.filter((data) => {
                if (
                    data.isChecked == "Y" &&
                    data.pcolour_id != 0 &&
                    data.pcolour_id != 11
                ) {
                    backPrintingCOloursList.push(data.pcolour_id);
                }
            });

            const formData = new FormData();
            formData.append("customer_id", this.quotejson.customer_id);
            formData.append("customer_name", this.quotejson.customer_name);
            formData.append("customer_mobile", this.quotejson.customer_mobile);
            formData.append("customer_email", this.quotejson.customer_email);
            formData.append("job_name", this.quotejson.job_name);
            formData.append("psize_id", this.quotejson.psize_id);
            formData.append("pk_bb_id", this.quotejson.pk_bb_id);
            formData.append("pk_bp_id", this.quotejson.pk_bp_id);
            formData.append("pnumber_id", this.quotejson.pnumber_id);
            formData.append("pk_sheet_id", this.quotejson.pk_sheet_id);
            formData.append("paper1", this.quotejson.paper1);
            formData.append("paper2", this.quotejson.paper2);
            formData.append("paper3", this.quotejson.paper3);
            formData.append("paper4", this.quotejson.paper4);
            formData.append("paper5", this.quotejson.paper5);
            formData.append("paper", this.quotejson.paper);
            formData.append(
                "checkpaper1",
                this.quotejson.checkpaper1 ? "Y" : "N"
            );
            formData.append(
                "checkpaper2",
                this.quotejson.checkpaper2 ? "Y" : "N"
            );
            formData.append(
                "checkpaper3",
                this.quotejson.checkpaper3 ? "Y" : "N"
            );
            formData.append(
                "checkpaper4",
                this.quotejson.checkpaper4 ? "Y" : "N"
            );
            formData.append(
                "checkpaper5",
                this.quotejson.checkpaper5 ? "Y" : "N"
            );
            formData.append(
                "variablePaper1",
                this.quotejson.variablePaper1 ? "Y" : "N"
            );
            formData.append(
                "variablePaper2",
                this.quotejson.variablePaper2 ? "Y" : "N"
            );
            formData.append(
                "variablePaper3",
                this.quotejson.variablePaper3 ? "Y" : "N"
            );
            formData.append(
                "variablePaper4",
                this.quotejson.variablePaper4 ? "Y" : "N"
            );
            formData.append(
                "variablePaper5",
                this.quotejson.variablePaper5 ? "Y" : "N"
            );
            formData.append(
                "is_variable_printing",
                this.quotejson.is_variable_printing ? "Y" : "N"
            );
            formData.append(
                "no_of_printing_colour_front",
                frontPrintingCOloursList.length
            );
            formData.append(
                "no_of_printing_colour_back",
                backPrintingCOloursList.length
            );
            formData.append("printing_colours_front", frontPrintingCOloursList);
            formData.append("printing_colours_back", backPrintingCOloursList);
            formData.append(
                "isBackColourNone",
                this.quotejson.isBackColourNone
                    ? this.quotejson.isBackColourNone
                    : ""
            );
            formData.append(
                "isFrontMultiColour",
                this.quotejson.isFrontMultiColour
                    ? this.quotejson.isFrontMultiColour
                    : ""
            );
            formData.append(
                "isBackMultiColour",
                this.quotejson.isBackMultiColour
                    ? this.quotejson.isBackMultiColour
                    : ""
            );
            formData.append(
                "is_same_content",
                this.quotejson.sameContent ? this.quotejson.sameContent : "N"
            );
            formData.append(
                "lamination",
                this.quotejson.lamination ? this.quotejson.lamination : "N"
            );
            formData.append(
                "lamination_type",
                this.quotejson.lamination_type
                    ? this.quotejson.lamination_type
                    : "N"
            );
            formData.append(
                "lamination_side",
                this.quotejson.lamination_side
                    ? this.quotejson.lamination_side
                    : ""
            );
            formData.append(
                "numbering",
                this.quotejson.numbering ? this.quotejson.numbering : "N"
            );
            formData.append(
                "perforation",
                this.quotejson.perforation ? this.quotejson.perforation : "N"
            );
            formData.append(
                "is_numbering",
                this.quotejson.is_numbering ? this.quotejson.is_numbering : "N"
            );
            formData.append(
                "bitnotice_numbering",
                this.quotejson.bitnotice_numbering
                    ? this.quotejson.bitnotice_numbering
                    : "N"
            );
            formData.append(
                "bitnotice_perforation",
                this.quotejson.bitnotice_perforation
                    ? this.quotejson.bitnotice_perforation
                    : "N"
            );
            formData.append(
                "folding",
                this.quotejson.folding ? this.quotejson.folding : "N"
            );
            formData.append(
                "package_details",
                this.quotejson.pk_bitnotice_cal_id
                    ? this.quotejson.pk_bitnotice_cal_id
                    : ""
            );
            formData.append(
                "binding",
                this.quotejson.binding ? this.quotejson.binding : ""
            );
            formData.append(
                "foil",
                this.quotejson.foil ? this.quotejson.foil : ""
            );
            formData.append(
                "foillength",
                this.quotejson.foillength ? this.quotejson.foillength : ""
            );
            formData.append(
                "foilwidth",
                this.quotejson.foilwidth ? this.quotejson.foilwidth : ""
            );
            formData.append(
                "embossing",
                this.quotejson.embossing ? this.quotejson.embossing : ""
            );
            formData.append(
                "embossinglength",
                this.quotejson.embossinglength
                    ? this.quotejson.embossinglength
                    : ""
            );
            formData.append(
                "embossingwidth",
                this.quotejson.embossingwidth
                    ? this.quotejson.embossingwidth
                    : ""
            );
            formData.append(
                "letter_package_details",
                this.quotejson.pk_letter_cal_id
                    ? this.quotejson.pk_letter_cal_id
                    : ""
            );
            formData.append(
                "quantity",
                this.quotejson.quantity ? this.quotejson.quantity : ""
            );
            formData.append(
                "category_id",
                this.sub_category_id ? this.sub_category_id : ""
            );
            formData.append(
                "category_type",
                this.quotejson.category_type ? this.quotejson.category_type : ""
            );
            formData.append("user_id", localStorage.getItem("userId"));
            formData.append("user_type", localStorage.getItem("user_type"));
            // $('.preloader').fadeIn('slow');
            this.commonService
                .saveBillBookQuote(formData)
                .subscribe((response: any) => {
                    if (response.status) {
                        this.router.navigate([
                            "/quote-summary/" + response.quote_id,
                        ]);
                    } else {
                        $(".preloader").fadeOut("slow");
                        if (response?.message !== "") {
                            alert(response?.message);
                        }
                    }
                });
        }
    }

    resetAll() {
        this.quotejson.category_type = "BB";
        this.quotejson.job_name = "";
        this.quotejson.psize_id = "";
        this.quotejson.pk_bb_id = "2";
        this.quotejson.bookTypeDuplicate = false;
        this.quotejson.bookTypeTroplicate = false;
        this.quotejson.bookTypeQuadruplicate = true;
        this.quotejson.pk_sheet_id = "1";
        this.quotejson.paper1 = "";
        this.quotejson.paper2 = "";
        this.quotejson.paper3 = "";
        this.quotejson.paper4 = "";
        this.quotejson.paper5 = "";
        this.quotejson.paper = "";
        this.quotejson.checkpaper1 = true;
        this.quotejson.checkpaper2 = false;
        this.quotejson.checkpaper3 = false;
        this.quotejson.checkpaper4 = false;
        this.quotejson.checkpaper5 = false;
        this.quotejson.variablePaper1 = true;
        this.quotejson.variablePaper2 = true;
        this.quotejson.variablePaper3 = true;
        this.quotejson.variablePaper4 = true;
        this.quotejson.variablePaper5 = true;
        this.quotejson.disablePaper2 = false;
        this.quotejson.disablePaper3 = false;
        this.quotejson.disablePaper4 = false;
        this.quotejson.disablePaper5 = false;
        this.quotejson.pk_bp_id = "1";
        this.quotejson.pnumber_id = "2";
        this.quotejson.sameContent = "N";
        this.quotejson.lamination = "N";
        this.quotejson.lamination_type = "N";
        this.quotejson.lamination_side = "";
        this.quotejson.bitnotice_numbering = "";
        this.quotejson.bitnotice_perforation = "";
        this.quotejson.pk_bit_notice_pack_id = "";
        this.quotejson.folding = "N";
        this.quotejson.numbering = "M";
        this.quotejson.perforation = "M";
        this.quotejson.binding = "";
        this.quotejson.quantity = "";
        this.quotejson.isFrontMultiColour = "N";
        this.quotejson.isBackMultiColour = "N";
        this.quotejson.isBackColourNone = "Y";
        this.getPrintingFrontColours();
        this.getPrintingBackColours();
    }

    onSelectChange(value: any) {
        console.log(value);
    }

    showCreateForm() {
        this.quotejson.showCustomerForm = true;
        this.quotejson.customer_id = "";
    }

    closeCreateForm() {
        this.quotejson.showCustomerForm = false;
    }
}
