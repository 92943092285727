import { CommonService } from "./../../common/services/common.service";
import { Router, ActivatedRoute } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { environment } from "../../../../environments/environment";
import { AuthService } from "../auth/auth.service";
import { OwlModule } from "ngx-owl-carousel";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
    selector: "app-products-details",
    templateUrl: "./products-details.component.html",
    styleUrls: ["./products-details.component.scss"],
})
export class ProductsDetailsComponent implements OnInit {
    public customer_id: any;
    public user_id = localStorage.getItem("userId");
    public user_type = localStorage.getItem("user_type");
    public galleryImg: any = [];
    public prod_detail: any = [];
    public billbooktypes: any = [];
    public product_sizes: any = "";
    public bills_per_book: any = "";
    public front_colour: any = "";
    public pro_front_colours: any = [];
    public pro_front_colours_length: number = 0;
    public back_colour: any = "";
    public pro_back_colours: any = [];
    public pro_back_colours_length: number = 0;
    public book_copy_print_plain: any = "";
    public bookcopystatus: any = "";
    public bookcopyArray: any = [];
    public bookcopystatusArray: any = [];
    public bookcopystatusArrayLength: any = [];
    public numbering: any = "";
    public numberings: any = [];
    public numberingArray: any = [];
    public numberingsLength: number = 0;
    public is_perforation: any = "";
    public is_perforations: any = [];
    public perforationArray: any = [];
    public is_perforationlength: number = 0;
    public pqualitys: any = "";
    public product_name: any = "";
    public product_id: any = [];
    public pk_product_id: any = [];
    public prod_cat_id: any = "";
    public category_code: any = "";
    public parent_category_id: any = "";
    public prod_price: any = "";
    public description: any = "";
    public prod_desc_detail: any = "";
    public imagelink: any = environment.imageUrl;
    public image_url: any = "";
    public total_amount: any = "";
    public image_url_prod: any = "";
    public plan_percentage = localStorage.getItem("pre_cus_percentage");

    dropdownSettings: IDropdownSettings;

    public sizes: any = [];
    public bookTypes: any = [];
    public billBooklength: number = 0;
    public sizeLength: number = 0;
    public noOfBillsPerBooklength: number = 0;
    public bindingTypes: any = [];
    public binding_type: any = "";
    public binding_types: any = [];
    public binding_type_length: number = 0;
    frontColourSelectedItems = [];
    backColourSelectedItems = [];
    public customerList: any = [];
    public printingFrontColours: any = [];
    public printingBackColours: any = [];
    public duplicateprintingFrontColours: any = [];
    public duplicateprintingBackColours: any = [];
    public contentBox: boolean = false;
    public originalBilltypes: any = [];
    public duptripBilltypes: any = [];
    public quadBilltypes: any = [];
    public bitNoticePacking: any = [];
    public letterHeadPacking: any = [];
    public no_of_bills_per_page: number = 0;
    public noOfBillsPerBook: any = [];
    public productjson: any = {};
    public display_clear_number: any = "";
    public display_perforation: any = "";
    public noOfPlacesOfNumbering: any = [];
    public no_of_places_for_numbering: number = 0;
    public display_lamination: any = "";
    public lamination_type: any = "";
    public lamination_types: any = [];
    public lamination_type_length: number = 0;
    public lamination_side: any = "";
    public lamination_sides: any = [];
    public lamination_side_length: number = 0;
    public display_gold_silver_foil: any = "";
    public display_embossing: any = "";
    public submitted: boolean = false;
    public formSubmit: boolean = false;
    public qualitylist: any = [];
    public laminationArray: any = [];
    public laminationsideArray: any = [];
    public foldingArray: any = [];
    public product_code: any = [];
    public iconClasses: object;
    public display_folding: any = "";
    public foldingstatus: any = "";
    public foldingstatusarray: any = [];
    public foldingstatusarraylength: number = 0;
    public variable_printing: any = "";

    constructor(
        private Router: Router,
        private route: ActivatedRoute,
        private commonService: CommonService,
        private authenticationService: AuthService,
        private toastr: ToastrService,
        private fb: FormBuilder
    ) {
        this.bookcopyArray = [
            { id: "Y", name: "Yes", active: true },
            { id: "N", name: "No", active: true },
        ];

        this.bookTypes = [
            {
                pk_sheet_id: "1",
                id: "A",
                lable: "All original",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "2",
                id: "D",
                lable: "Duplicate (1 + 1)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "3",
                id: "T",
                lable: "Triplicate (1 + 2)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "4",
                id: "Q",
                lable: "Quadriplicate (1 + 3)",
                active: true,
                book: true,
            },
            {
                pk_sheet_id: "5",
                id: "U",
                lable: "Quintuplicate (1 + 4)",
                active: true,
                book: true,
            },
        ];
        this.foldingArray = [
            { id: "Y", name: "Yes", active: true },
            { id: "N", name: "No", active: true },
        ];
        this.numberingArray = [
            { id: "M", name: "Manual", active: true },
            { id: "MA", name: "Machine", active: true },
            { id: "N", name: "None", active: true },
        ];
        this.perforationArray = [
            { id: "M", name: "Manual", active: true },
            { id: "MA", name: "Micro", active: true },
            { id: "N", name: "None", active: true },
        ];
        this.bindingTypes = [
            { id: "1", name: "Pad Binding", value: "PB", active: true },
            { id: "2", name: "Soft Binding", value: "SB", active: true },
            { id: "3", name: "Hard Binding", value: "HB", active: true },
        ];
        this.laminationArray = [
            { id: "M", name: "Matt", active: true },
            { id: "G", name: "Glossy", active: true },
            { id: "N", name: "None", active: true },
        ];
        this.laminationsideArray = [
            { id: "1", name: "1", active: true },
            { id: "2", name: "2", active: true },
        ];
        route.params.forEach((params) => {
            if (params.id) {
                this.productjson.product_id = params.id;
                // this.getProductDetails();
            }
        });
        this.productjson.customer_id = "";
        this.productjson.psize_id = "";
        this.productjson.pk_sheet_id = "";
        this.productjson.pk_bb_id = "";
        this.productjson.is_front_multicolour = "";
        this.productjson.is_back_multicolour = "";
        this.productjson.is_back_none = "";
        this.productjson.is_variable_printing = "";
        this.productjson.is_content_same = "";
        this.productjson.is_same_content = "";
        this.productjson.book_copy_status = "";
        this.productjson.numbering = "M";
        this.productjson.is_numbering = "C";
        this.productjson.perforation = "M";
        this.productjson.pnumber_id = "";
        this.productjson.lamination_type = "";
        this.productjson.foil = "";
        this.productjson.embossing = "";
        this.productjson.quantity = "";
        this.productjson.total_amount = "";
        this.productjson.error = {};
        this.productjson.error.frontColour = false;
        this.productjson.error.backColour = false;
        this.productjson.showCustomerForm = false;
    }

    removeNonZeros(n: any) {
        var nonzeros = parseFloat(n);
        return nonzeros;
    }

    changeImage(image_url: any) {
        this.image_url_prod = image_url;
        return this.image_url_prod;
    }

    changeBooktype(value: any) {
        const data = this.noOfBillsPerBook.filter((datas: any) => {
            if (datas.pk_bb_id == value) {
                let sampleArray: any = [];
                sampleArray = datas.book_type.split(",");
                if (sampleArray.includes("U")) {
                    const bookstype = this.bookTypes.filter((data: any) => {
                        data.book = true;
                    });
                } else if (sampleArray.includes("Q")) {
                    if (this.productjson.pk_sheet_id == 5) {
                        this.productjson.pk_sheet_id = 4;
                    }
                    const bookstype = this.bookTypes.filter((data: any) => {
                        if (data.pk_sheet_id <= 4) {
                            data.book = true;
                        } else {
                            data.book = false;
                        }
                    });
                } else if (sampleArray.includes("T")) {
                    if (this.productjson.pk_sheet_id == 4) {
                        this.productjson.pk_sheet_id = 3;
                    }
                    const bookstype = this.bookTypes.filter((data: any) => {
                        if (data.pk_sheet_id <= 3) {
                            data.book = true;
                        } else {
                            data.book = false;
                        }
                    });
                } else if (sampleArray.includes("D")) {
                    if (
                        this.productjson.pk_sheet_id == 4 ||
                        this.productjson.pk_sheet_id == 3
                    ) {
                        this.productjson.pk_sheet_id = 2;
                    }
                    const bookstype = this.bookTypes.filter((data: any) => {
                        if (data.pk_sheet_id <= 2) {
                            data.book = true;
                        } else {
                            data.book = false;
                        }
                    });
                } else if (sampleArray.includes("A")) {
                    const bookstype = this.bookTypes.filter((data: any) => {
                        if (data.pk_sheet_id == 1) {
                            data.book = true;
                        } else {
                            data.book = false;
                        }
                    });
                }
            }
        });
        if (this.submitted) {
            this.checkValidation();
        }
    }

    checkIsVariablePrinting(event: any) {
        if (event.target.checked) {
            this.productjson.is_variable_printing = "Y";
        } else {
            this.productjson.is_variable_printing = "N";
        }
    }

    onItemSelectBack(item: any) {
        if (item.pcolour_id == "11" || item.pcolour_id == 11) {
            this.backColourSelectedItems = this.backColourSelectedItems.filter(
                (item) => item.pcolour_id === "11" || item.pcolour_id === 11
            );
            this.productjson.is_back_multicolour = "Y";
            this.productjson.is_back_none = "N";
        } else if (item.pcolour_id == "0" || item.pcolour_id == 0) {
            this.backColourSelectedItems = this.backColourSelectedItems.filter(
                (item) => item.pcolour_id === "0" || item.pcolour_id === 0
            );
            this.productjson.is_back_multicolour = "N";
            this.productjson.is_back_none = "Y";
        } else {
            this.backColourSelectedItems = this.backColourSelectedItems.filter(
                (item) => item.pcolour_id !== "11"
            );
            this.backColourSelectedItems = this.backColourSelectedItems.filter(
                (item) => item.pcolour_id !== "0"
            );
            this.productjson.is_back_multicolour = "N";
            this.productjson.is_back_none = "N";
        }
        this.checksamecolour();
        this.checklaminationside();
    }

    onItemSelectFront(item: any) {
        if (item.pcolour_id == "11" || item.pcolour_id == 11) {
            this.frontColourSelectedItems =
                this.frontColourSelectedItems.filter(
                    (item) => item.pcolour_id === "11"
                );
            this.productjson.is_front_multicolour = "Y";
        } else {
            this.frontColourSelectedItems =
                this.frontColourSelectedItems.filter(
                    (item) => item.pcolour_id !== "11"
                );
            this.productjson.is_front_multicolour = "N";
        }
        this.checksamecolour();
        this.checklaminationside();
    }

    checklaminationside() {
        if (
            this.prod_cat_id == "BN" &&
            this.productjson.lamination_type != "N"
        ) {
            if (
                this.productjson.is_front_multicolour != "Y" &&
                this.productjson.is_back_none == "Y"
            ) {
                this.productjson.lamination_side = 1;
            } else if (
                this.productjson.is_front_multicolour != "Y" &&
                this.productjson.is_back_none == "N"
            ) {
                this.productjson.lamination_side = 2;
            } else if (
                this.productjson.is_front_multicolour == "Y" &&
                this.productjson.is_back_multicolour == "N"
            ) {
                this.productjson.lamination_side = 1;
            } else if (
                this.productjson.is_front_multicolour == "Y" &&
                this.productjson.is_back_multicolour == "Y"
            ) {
                this.productjson.lamination_side = 2;
            }
        }
    }

    checksamecolour() {
        const bookTypes = this.bookTypes.filter((data: any) => {
            if (data.pk_sheet_id == this.productjson.pk_sheet_id) {
                const noOfBillsPerBook = this.noOfBillsPerBook.filter(
                    (item: any) => {
                        let sampleArray: any = [];
                        sampleArray = item.book_type.split(",");
                        if (sampleArray.includes(data.id)) {
                            item.active = true;
                        } else {
                            item.active = false;
                        }
                    }
                );
            }
        });

        if (this.productjson.pk_sheet_id == 1) {
            this.book_copy_print_plain = "N";
            this.productjson.book_copy_status = "Y";
            if (this.category_code == "HB" || this.category_code == "SB") {
                this.is_perforation = this.prod_detail.is_perforation;
                if (this.is_perforation) {
                    let perforations = this.is_perforation.split(",");
                    this.is_perforations = perforations;
                    this.is_perforationlength = this.is_perforations.length;
                    if (this.is_perforationlength > 0) {
                        const perforationss = this.perforationArray.filter(
                            (data: any) => {
                                if (this.is_perforations.includes(data.id)) {
                                    data.active = true;
                                    if (this.is_perforations.includes("M")) {
                                        this.productjson.perforation = "M";
                                    } else {
                                        this.productjson.perforation = data.id;
                                    }
                                } else {
                                    data.active = false;
                                }
                            }
                        );
                    }
                } else {
                    this.is_perforations = [];
                }
                if (this.is_perforationlength == 1) {
                    this.productjson.perforation = this.is_perforations[0];
                }
            }
        } else {
            this.book_copy_print_plain = this.prod_detail.book_copy_print_plain;
            if (this.bookcopystatus) {
                let bookcopystatus = this.bookcopystatus.split(",");
                this.bookcopystatusArray = bookcopystatus;
                this.bookcopystatusArrayLength =
                    this.bookcopystatusArray.length;
                if (this.bookcopystatusArrayLength > 0) {
                    const bookCopies = this.bookcopyArray.filter(
                        (data: any) => {
                            if (this.bookcopystatusArray.includes(data.id)) {
                                data.active = true;
                                if (this.bookcopystatusArrayLength == 1) {
                                    this.productjson.book_copy_status = data.id;
                                }
                            } else {
                                data.active = false;
                            }
                        }
                    );
                }
            }
            if (this.category_code == "HB" || this.category_code == "SB") {
                const perforationss = this.perforationArray.filter(
                    (data: any) => {
                        if (data.id == "N") {
                            data.active = false;
                        }
                    }
                );
            }
        }

        let common = []; // Array to contain common elements
        for (var i = 0; i < this.frontColourSelectedItems.length; ++i) {
            for (var j = 0; j < this.backColourSelectedItems.length; ++j) {
                if (
                    this.frontColourSelectedItems[i].pcolour_id ==
                    this.backColourSelectedItems[j].pcolour_id
                ) {
                    common.push(this.frontColourSelectedItems[i]);
                }
            }
        }

        if (
            common.length != 0 &&
            this.frontColourSelectedItems.length == common.length &&
            this.backColourSelectedItems.length == common.length &&
            this.productjson.pk_sheet_id == 1
        ) {
            this.contentBox = true;
            this.productjson.is_content_same = "Y";
        } else {
            this.contentBox = false;
            this.productjson.is_content_same = "N";
        }

        if (this.submitted) {
            this.checkValidation();
        }
    }

    returnNumber(event: any) {
        let value = event.target.value;
        value = value.replace(/[^0-9 ]/g, "");
        this.productjson.quantity = value.replace(/ /g, ";");
        if (this.submitted) {
            this.checkValidation();
        }
        if (this.prod_cat_id == "NC") {
            this.productjson.rate = this.prod_price;
            this.productjson.total_amount =
                this.productjson.quantity * this.prod_price;
        }
    }

    ngOnInit(): void {
        if (this.user_type === "SA") {
            this.getCustomerList();
        }
        this.getPrintingColours();
        this.iconClasses = {
            error: "toast-error",
            info: "toast-info",
            success: "toast-success",
            warning: "toast-warning",
        };
        this.getGallery();
        this.dropdownSettings = {
            singleSelection: false,
            enableCheckAll: false,
            idField: "pcolour_id",
            textField: "colour_name",
            itemsShowLimit: 2,
            allowSearchFilter: true,
        };
    }

    saveToCartProduct() {
        const formData = new FormData();
        formData.append("user_id", this.user_id);
        formData.append("customer_id", this.productjson.customer_id);
        formData.append("prod_cat_id", this.prod_cat_id);
        formData.append("rate", this.prod_price);
        formData.append("quantity", this.productjson.quantity);
        formData.append("product_id", this.productjson.product_id);
        formData.append("total_amount", this.productjson.total_amount);
        $(".preloader").fadeIn("slow");
        this.commonService
            .saveToCartProduct(formData)
            .subscribe((response: any) => {
                if (response.status) {
                    this.Router.navigate(["/cart"]);
                }
            });
    }

    getProductDetails() {
        this.commonService
            .getProductDetails(this.productjson.product_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.prod_detail = response.data;
                    this.variable_printing = this.prod_detail.variable_printing;
                    this.prod_cat_id = this.prod_detail.category_id;
                    this.product_code = this.prod_detail.product_code;
                    this.category_code = this.prod_detail.category_code;
                    this.no_of_bills_per_page =
                        this.prod_detail.no_of_bills_per_page;
                    this.no_of_places_for_numbering =
                        this.prod_detail.no_of_places_for_numbering;
                    this.parent_category_id =
                        this.prod_detail.parent_category_id;
                    this.productjson.parent_category_id =
                        this.prod_detail.parent_category_id;
                    this.book_copy_print_plain =
                        this.prod_detail.book_copy_print_plain;
                    this.productjson.book_copy_print_plain =
                        this.prod_detail.book_copy_print_plain;
                    this.bookcopystatus = this.prod_detail.book_copy_status;

                    if (this.bookcopystatus) {
                        let bookcopystatus = this.bookcopystatus.split(",");
                        this.bookcopystatusArray = bookcopystatus;
                        this.bookcopystatusArrayLength =
                            this.bookcopystatusArray.length;
                        if (this.bookcopystatusArrayLength > 0) {
                            const bookCopies = this.bookcopyArray.filter(
                                (data: any) => {
                                    if (
                                        this.bookcopystatusArray.includes(
                                            data.id
                                        )
                                    ) {
                                        data.active = true;
                                        if (
                                            this.bookcopystatusArrayLength == 1
                                        ) {
                                            this.productjson.book_copy_status =
                                                data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.bookcopystatusArray = [];
                    }
                    const bookSelectCopies = this.bookcopyArray.filter(
                        (data: any) => {
                            if (data.active && data.id == "Y") {
                                this.productjson.book_copy_status = data.id;
                            }
                        }
                    );
                    this.product_name = this.prod_detail.product_name;
                    this.product_sizes = this.prod_detail.product_sizes;
                    this.bills_per_book = this.prod_detail.bills_per_book;
                    this.billbooktypes = this.prod_detail.billbooktype
                        ? this.prod_detail.billbooktype
                        : "";

                    if (this.billbooktypes) {
                        let toppingArr = this.billbooktypes.split(",");
                        this.billbooktypes = toppingArr;
                        this.billBooklength = this.billbooktypes.length;
                        if (this.billBooklength > 0) {
                            const bookTypes = this.bookTypes.filter(
                                (data: any) => {
                                    if (this.billbooktypes.includes(data.id)) {
                                        data.book = true;
                                        if (this.billBooklength == 1) {
                                            this.productjson.pk_sheet_id =
                                                data.pk_sheet_id;
                                        }
                                    } else {
                                        data.book = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.billbooktypes = [];
                    }

                    this.front_colour = this.prod_detail.front_colour;
                    if (this.front_colour) {
                        let front_colour = this.front_colour.split(",");
                        this.pro_front_colours = front_colour;
                        this.pro_front_colours_length =
                            this.pro_front_colours.length;
                        if (this.pro_front_colours_length > 0) {
                            const frontcolours =
                                this.printingFrontColours.filter(
                                    (data: any) => {
                                        if (
                                            this.pro_front_colours.includes(
                                                data.pcolour_id
                                            )
                                        ) {
                                            this.duplicateprintingFrontColours.push(
                                                data
                                            );
                                        }
                                    }
                                );
                            this.printingFrontColours =
                                this.duplicateprintingFrontColours;
                            if (this.printingFrontColours.length > 0) {
                                this.frontColourSelectedItems.push(
                                    this.printingFrontColours[0]
                                );
                                this.onItemSelectFront(
                                    this.printingFrontColours[0]
                                );
                            }
                        }
                    } else {
                        this.pro_front_colours = [];
                        this.duplicateprintingFrontColours =
                            this.printingFrontColours;
                        this.frontColourSelectedItems.push(
                            this.printingFrontColours[0]
                        );
                        this.onItemSelectFront(this.printingFrontColours[0]);
                    }

                    this.back_colour = this.prod_detail.back_colour;
                    if (this.back_colour) {
                        let back_colour = this.back_colour.split(",");
                        this.pro_back_colours = back_colour;
                        this.pro_back_colours_length =
                            this.pro_back_colours.length;
                        if (this.pro_back_colours_length > 0) {
                            this.duplicateprintingBackColours = [];
                            this.duplicateprintingBackColours.push();
                            // const backcolo = this.printingBackColours.filter((data: any) => {
                            //   if(data.pcolour_id == 0 || data.pcolour_id == '0'){
                            //     this.duplicateprintingBackColours.push(data);
                            //   }
                            // });
                            const backcolours = this.printingBackColours.filter(
                                (data: any) => {
                                    if (
                                        this.pro_back_colours.includes(
                                            data.pcolour_id
                                        )
                                    ) {
                                        this.duplicateprintingBackColours.push(
                                            data
                                        );
                                    }
                                }
                            );
                            this.printingBackColours =
                                this.duplicateprintingBackColours;
                            this.backColourSelectedItems.push(
                                this.printingBackColours[0]
                            );
                            this.onItemSelectBack(this.printingBackColours[0]);
                        }
                    } else {
                        this.pro_back_colours = [];
                        this.duplicateprintingBackColours =
                            this.printingBackColours;
                        this.backColourSelectedItems.push(
                            this.printingBackColours[0]
                        );
                        this.onItemSelectBack(this.printingBackColours[0]);
                    }

                    this.display_clear_number =
                        this.prod_detail.display_clear_number;
                    this.productjson.display_clear_number =
                        this.prod_detail.display_clear_number;
                    this.numbering = this.prod_detail.numbering
                        ? this.prod_detail.numbering
                        : "";
                    if (this.numbering) {
                        let numberingArr = this.numbering.split(",");
                        this.numberings = numberingArr;
                        this.numberingsLength = this.numberings.length;
                        if (this.numberingsLength > 0) {
                            const numberingss = this.numberingArray.filter(
                                (data: any) => {
                                    if (this.numberings.includes(data.id)) {
                                        data.active = true;
                                        if (this.numberings.includes("N")) {
                                            this.productjson.numbering = "N";
                                        } else if (
                                            this.numberings.includes("M")
                                        ) {
                                            this.productjson.numbering = "M";
                                        } else if (
                                            this.numberings.includes("MA")
                                        ) {
                                            this.productjson.numbering = "MA";
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.numberings = [];
                    }
                    // const numrings = this.numberingArray.filter((data: any) => {
                    //   if(data.active){
                    //     this.productjson.numbering = data.id;
                    //   }
                    // });
                    if (this.numberingsLength == 1) {
                        this.productjson.perforation = this.numberings[0];
                    }
                    // const numring = this.numberingArray.filter((data: any) => {
                    //   if(data.active && data.id == 'N'){
                    //     this.productjson.numbering = data.id;
                    //   }
                    // });
                    this.productjson.place_for_number =
                        this.prod_detail.place_for_number;
                    this.productjson.pnumber_id = this.prod_detail.pnumber_id;
                    this.display_perforation =
                        this.prod_detail.display_perforation;
                    this.productjson.display_perforation =
                        this.prod_detail.display_perforation;
                    this.is_perforation = this.prod_detail.is_perforation;
                    if (this.is_perforation) {
                        let perforations = this.is_perforation.split(",");
                        this.is_perforations = perforations;
                        this.is_perforationlength = this.is_perforations.length;
                        if (this.is_perforationlength > 0) {
                            const perforationss = this.perforationArray.filter(
                                (data: any) => {
                                    if (
                                        this.is_perforations.includes(data.id)
                                    ) {
                                        data.active = true;
                                        if (
                                            this.is_perforations.includes("M")
                                        ) {
                                            this.productjson.perforation = "M";
                                        } else {
                                            this.productjson.perforation =
                                                data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.is_perforations = [];
                    }
                    if (this.is_perforationlength == 1) {
                        this.productjson.perforation = this.is_perforations[0];
                    }
                    if (
                        this.category_code == "HB" ||
                        this.category_code == "SB" ||
                        this.category_code == "PB"
                    ) {
                        if (
                            this.no_of_bills_per_page > 1 ||
                            this.no_of_places_for_numbering > 1
                        ) {
                            const perforationss = this.perforationArray.filter(
                                (data: any) => {
                                    if (data.id == "N") {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    }
                    this.binding_type = this.prod_detail.binding_type;
                    if (this.binding_type) {
                        let binding_types = this.binding_type.split(",");
                        this.binding_types = binding_types;
                        this.binding_type_length = this.binding_types.length;
                        if (this.binding_type_length > 0) {
                            const bindingtypesss = this.bindingTypes.filter(
                                (data: any) => {
                                    if (this.binding_types.includes(data.id)) {
                                        data.active = true;
                                        if (this.binding_type_length == 1) {
                                            this.productjson.binding_type =
                                                data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.binding_types = [];
                    }
                    this.pqualitys = this.prod_detail.pquality;
                    this.display_lamination =
                        this.prod_detail.display_lamination;
                    this.productjson.display_lamination =
                        this.prod_detail.display_lamination;
                    this.lamination_type = this.prod_detail.lamination_type;

                    if (this.lamination_type) {
                        let lamination_types = this.lamination_type.split(",");
                        this.lamination_types = lamination_types;
                        this.lamination_type_length =
                            this.lamination_types.length;
                        if (this.lamination_type_length > 0) {
                            const lamination = this.laminationArray.filter(
                                (data: any) => {
                                    if (
                                        this.lamination_types.includes(data.id)
                                    ) {
                                        data.active = true;
                                        if (
                                            this.lamination_types.includes("N")
                                        ) {
                                            this.productjson.lamination_type =
                                                "N";
                                        } else {
                                            this.productjson.lamination_type =
                                                data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.lamination_types = [];
                    }

                    this.lamination_side = this.prod_detail.lamination_side;
                    if (this.lamination_side) {
                        let lamination_sides = this.lamination_side.split(",");
                        this.lamination_sides = lamination_sides;
                        this.lamination_side_length =
                            this.lamination_sides.length;
                        if (this.lamination_side_length > 0) {
                            const laminaside = this.laminationsideArray.filter(
                                (data: any) => {
                                    if (
                                        this.lamination_sides.includes(data.id)
                                    ) {
                                        data.active = true;
                                        this.productjson.lamination_side =
                                            data.id;
                                        if (
                                            this.lamination_sides.includes("1")
                                        ) {
                                            this.productjson.lamination_side =
                                                "1";
                                        } else {
                                            this.productjson.lamination_side =
                                                data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.lamination_sides = [];
                    }

                    this.display_folding = this.prod_detail.folding;
                    this.productjson.display_folding = this.prod_detail.folding;
                    this.foldingstatus = this.prod_detail.foldingstatus;
                    if (this.foldingstatus) {
                        let foldingstatusarray = this.foldingstatus.split(",");
                        this.foldingstatusarray = foldingstatusarray;
                        this.foldingstatusarraylength =
                            this.foldingstatusarray.length;
                        if (this.foldingstatusarraylength > 0) {
                            const foldingarry = this.foldingArray.filter(
                                (data: any) => {
                                    if (
                                        this.foldingstatusarray.includes(
                                            data.id
                                        )
                                    ) {
                                        data.active = true;
                                        if (
                                            this.foldingstatusarraylength == 1
                                        ) {
                                            this.productjson.folding = data.id;
                                        }
                                    } else {
                                        data.active = false;
                                    }
                                }
                            );
                        }
                    } else {
                        this.foldingstatusarray = [];
                    }
                    const fold = this.foldingArray.filter((data: any) => {
                        if (data.active && data.id == "N") {
                            this.productjson.folding = data.id;
                        }
                    });

                    this.display_gold_silver_foil =
                        this.prod_detail.gold_silver_foil;
                    this.display_embossing = this.prod_detail.embossing;
                    this.description = this.prod_detail.description;
                    this.prod_desc_detail = this.prod_detail.product_details;
                    this.image_url = this.prod_detail.image_large_details;
                    this.image_url_prod = this.prod_detail.image_large_details;
                    this.prod_price = this.prod_detail.productPrice;
                    this.getSizes();
                    this.getNoOfBillPerBook();
                }
            });
    }

    getGallery() {
        this.commonService
            .getGallery(this.productjson.product_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.galleryImg = response.data;
                    $(".service-dtls-slider").owlCarousel();
                }
            });
    }

    getCustomerList() {
        this.commonService.getCustomerList().subscribe((response: any) => {
            if (response.status) {
                this.customerList = response.data;
            }
        });
    }

    getPrintingColours() {
        this.commonService.getPrintingColours().subscribe((response: any) => {
            if (response.status) {
                this.printingFrontColours = response.data;
                this.printingBackColours = [
                    { pcolour_id: "0", colour_name: "None" },
                ];
                const backColourArray = response.data.filter((data: any) => {
                    this.printingBackColours.push(data);
                });
                this.getProductDetails();
            }
        });
    }

    getNoOfBillPerBook() {
        const formData = new FormData();
        formData.append(
            "bills_per_book",
            this.bills_per_book ? this.bills_per_book : ""
        );
        this.commonService
            .getNoOfBillPerBookForproduct(formData)
            .subscribe((response: any) => {
                if (response.status) {
                    this.noOfBillsPerBook = response.data;
                    this.noOfBillsPerBooklength = this.noOfBillsPerBook.length;
                    const noOfBills = this.noOfBillsPerBook.filter(
                        (data: any) => {
                            data.active = true;
                        }
                    );
                    this.productjson.pk_bb_id =
                        this.noOfBillsPerBook[0].pk_bb_id;
                }
            });
    }

    sizeChange() {
        if (this.prod_cat_id == "BN") {
            this.getBitNoticePacking();
        } else if (this.prod_cat_id == "LH") {
            this.getLetterHeadPacking();
        }
    }

    getBitNoticePacking() {
        this.commonService
            .getBitNoticePacking(this.productjson.psize_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.bitNoticePacking = response.data;
                }
            });
    }

    getLetterHeadPacking() {
        this.commonService
            .getLetterHeadPacking(this.productjson.psize_id)
            .subscribe((response: any) => {
                if (response.status) {
                    this.letterHeadPacking = response.data;
                    this.productjson.letter_package_details =
                        this.letterHeadPacking[0].pk_letter_cal_id;
                }
            });
    }

    getSizes() {
        const formDate = new FormData();
        formDate.append("sizes", this.product_sizes ? this.product_sizes : "");
        formDate.append("prod_cat_id", this.prod_cat_id);
        formDate.append("parent_category_id", this.parent_category_id);
        this.commonService
            .getSizesForProduct(formDate)
            .subscribe((response: any) => {
                if (response.status) {
                    this.sizes = response.data;
                    this.sizeLength = this.sizes.length;
                    this.productjson.psize_id = this.sizes[0].psize_id;
                    this.sizeChange();
                }
            });
    }

    checkValidation() {
        this.productjson.error.customer_id = false;
        this.productjson.error.customer_name = false;
        this.productjson.error.customer_mobile = false;
        this.productjson.error.customer_email = false;
        this.productjson.error.product_name = false;
        this.productjson.error.psize_id = false;
        this.productjson.error.frontColour = false;
        this.productjson.error.backColour = false;
        this.productjson.error.is_same_content = false;
        this.productjson.error.book_copy_status = false;
        this.productjson.error.pk_bb_id = false;
        this.productjson.error.pk_sheet_id = false;
        this.productjson.error.numbering = false;
        this.productjson.error.is_numbering = false;
        this.productjson.error.perforation = false;
        this.productjson.error.lamination_type = false;
        this.productjson.error.lamination_side = false;
        this.productjson.error.binding_type = false;
        this.productjson.error.letter_package_details = false;
        this.productjson.error.quantity = false;

        if (this.user_type === "SA") {
            if (this.productjson.showCustomerForm) {
                if (
                    this.productjson.customer_name == "" ||
                    this.productjson.customer_name == null ||
                    this.productjson.customer_name == undefined
                ) {
                    this.productjson.error.customer_name = true;
                }
                if (
                    this.productjson.customer_mobile == "" ||
                    this.productjson.customer_mobile == null ||
                    this.productjson.customer_mobile == undefined
                ) {
                    this.productjson.error.customer_mobile = true;
                }
                if (
                    this.productjson.customer_email == "" ||
                    this.productjson.customer_email == null ||
                    this.productjson.customer_email == undefined
                ) {
                    this.productjson.error.customer_email = true;
                } else {
                    const valid =
                        /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                            this.productjson.customer_email
                        );
                    if (valid) {
                        this.productjson.error.customer_email = false;
                    } else {
                        this.productjson.error.customer_email = true;
                    }
                }
            } else {
                if (
                    this.productjson.customer_id == "" ||
                    this.productjson.customer_id == null ||
                    this.productjson.customer_id == undefined
                ) {
                    this.productjson.error.customer_id = true;
                }
            }
        }

        if (
            this.productjson.product_name == "" ||
            this.productjson.product_name == null ||
            this.productjson.product_name == undefined
        ) {
            this.productjson.error.product_name = true;
        }

        if (this.productjson.psize_id == "") {
            this.productjson.error.psize_id = true;
        }

        if (this.frontColourSelectedItems.length == 0) {
            this.productjson.error.frontColour = true;
        }

        if (this.backColourSelectedItems.length == 0) {
            this.productjson.error.backColour = true;
        }

        if (this.contentBox) {
            if (this.productjson.is_same_content == "") {
                this.productjson.error.is_same_content = true;
            }
        }

        if (this.prod_cat_id == "BB") {
            if (this.productjson.book_copy_status == "") {
                this.productjson.error.book_copy_status = true;
            }

            if (this.productjson.pk_bb_id == "") {
                this.productjson.error.pk_bb_id = true;
            }

            if (this.productjson.pk_sheet_id == "") {
                this.productjson.error.pk_sheet_id = true;
            }

            if (this.productjson.numbering == "") {
                this.productjson.error.numbering = true;
            } else {
                if (this.productjson.numbering == "MA") {
                    if (this.productjson.is_numbering == "") {
                        this.productjson.error.is_numbering = true;
                    }
                }
            }

            if (this.productjson.perforation == "") {
                this.productjson.error.perforation = true;
            }

            if (
                this.productjson.binding_type == "" ||
                this.productjson.binding_type == null ||
                this.productjson.binding_type == undefined
            ) {
                this.productjson.error.binding_type = true;
            }
        } else if (this.prod_cat_id == "BN") {
            if (this.productjson.numbering == "") {
                this.productjson.error.numbering = true;
            }

            if (this.productjson.perforation == "") {
                this.productjson.error.perforation = true;
            }

            if (this.productjson.lamination_type == "") {
                this.productjson.error.lamination_type = true;
            }

            if (this.productjson.lamination_type != "N") {
                if (this.productjson.lamination_side == "") {
                    this.productjson.error.lamination_side = true;
                }
            }
        } else if (this.prod_cat_id == "LH") {
            this.productjson.book_copy_status = "N";
            this.productjson.numbering = "N";
            this.productjson.is_numbering = "N";
            this.productjson.perforation = "N";
            this.productjson.lamination_type = "N";
            this.productjson.lamination_side = "N";
            if (
                this.productjson.letter_package_details == "" ||
                this.productjson.letter_package_details == null ||
                this.productjson.letter_package_details == undefined
            ) {
                this.productjson.error.letter_package_details = true;
            }
        }

        if (this.productjson.quantity == "" || this.productjson.quantity < 1) {
            this.productjson.error.quantity = true;
        }

        if (
            !this.productjson.error.customer_id &&
            !this.productjson.error.customer_name &&
            !this.productjson.error.customer_mobile &&
            !this.productjson.error.customer_email &&
            !this.productjson.error.product_name &&
            !this.productjson.error.psize_id &&
            !this.productjson.error.frontColour &&
            !this.productjson.error.backColour &&
            !this.productjson.error.is_same_content &&
            !this.productjson.error.book_copy_status &&
            !this.productjson.error.pk_bb_id &&
            !this.productjson.error.pk_sheet_id &&
            !this.productjson.error.numbering &&
            !this.productjson.error.is_numbering &&
            !this.productjson.error.perforation &&
            !this.productjson.error.lamination_type &&
            !this.productjson.error.lamination_side &&
            !this.productjson.error.binding_type &&
            !this.productjson.error.letter_package_details &&
            !this.productjson.error.quantity
        ) {
            this.formSubmit = true;
        } else {
            this.formSubmit = false;
        }
    }

    showAmountList() {
        this.submitted = true;
        this.checkValidation();

        let printing_colours_front: any = [];
        const lastFrontCOlours = this.frontColourSelectedItems.filter(
            (data) => {
                if (data.pcolour_id != 11) {
                    printing_colours_front.push(data.pcolour_id);
                }
            }
        );
        let printing_colours_back: any = [];
        const lastBackCOlours = this.backColourSelectedItems.filter((data) => {
            if (data.pcolour_id != 0 && data.pcolour_id != 11) {
                printing_colours_back.push(data.pcolour_id);
            }
        });

        if (this.formSubmit) {
            const formData = new FormData();
            formData.append("user_id", this.user_id);
            formData.append("user_type", this.user_type);
            formData.append("customer_id", this.productjson.customer_id);
            formData.append("customer_name", this.productjson.customer_name);
            formData.append("customer_mobile", this.productjson.customer_mobile);
            formData.append("customer_email", this.productjson.customer_email);
            formData.append("pqualitys", this.pqualitys);
            formData.append("data", JSON.stringify(this.productjson));
            formData.append(
                "frontcolour",
                JSON.stringify(this.frontColourSelectedItems)
            );
            formData.append(
                "backcolour",
                JSON.stringify(this.backColourSelectedItems)
            );
            formData.append("printing_colours_front", printing_colours_front);
            formData.append("printing_colours_back", printing_colours_back);
            // $(".preloader").fadeIn("slow");
            this.commonService
                .getAmount(formData)
                .subscribe((response: any) => {
                    if (this.user_type === "SA") {
                        this.getCustomerList();
                    }
                    if (response.status) {
                        this.productjson.customer_id = response.customer_id;
                        // $(".preloader").fadeOut("slow");
                        this.submitted = false;
                        if (response.qualities == "") {
                            this.showerror("No qualities found");
                        } else {
                            this.qualitylist = response.qualities;
                        }
                    } else {
                        // $(".preloader").fadeOut("slow");
                        this.qualitylist = [];
                        if(response?.message !== ''){
                          alert(response?.message);
                        }
                    }
                });
        }
    }

    showerror(msg: any) {
        this.toastr.warning(msg);
    }

    selectQuality(pquality_id: any) {
        const bookstype = this.qualitylist.filter((data: any) => {
            if (data.pquality_id == pquality_id) {
                if (data.active) {
                    data.active = false;
                } else {
                    data.active = true;
                }
            } else {
                data.active = false;
            }
        });
    }

    addtocustomerproduct() {
        let customerproduct = {};
        this.formSubmit = false;
        const customerProducts = this.qualitylist.filter((data: any) => {
            if (data.active) {
                customerproduct = data;
                this.formSubmit = true;
            }
        });

        if (this.authenticationService.isLoggedin()) {
            if (this.formSubmit) {
                const formData = new FormData();
                formData.append("productList", JSON.stringify(customerproduct));
                formData.append("user_id", localStorage.getItem("userId"));
                formData.append("user_type", localStorage.getItem("user_type"));
                formData.append("customer_id", this.productjson.customer_id);
                formData.append(
                    "showCustomerForm",
                    this.productjson.showCustomerForm
                );
                formData.append(
                    "customer_name",
                    this.productjson.customer_name
                );
                formData.append(
                    "customer_mobile",
                    this.productjson.customer_mobile
                );
                formData.append(
                    "customer_email",
                    this.productjson.customer_email
                );
                $(".preloader").fadeIn("slow");
                this.commonService
                    .saveCustomerproducts(formData)
                    .subscribe((response: any) => {
                        if (response.status) {
                            this.Router.navigate([
                                "/product-summary/" + response.pk_product_id,
                            ]);
                        } else {
                            $(".preloader").fadeOut("slow");
                            if (response.logged_out) {
                                this.authenticationService.logout();
                                localStorage.setItem("db_entry", "Y");
                                localStorage.setItem(
                                    "current_url",
                                    "product-details"
                                );
                                localStorage.setItem(
                                    "productList",
                                    JSON.stringify(customerproduct)
                                );
                                this.Router.navigate(["/sign-in"]);
                            }
                        }
                    });
            } else {
                alert("please choose atleast one quality");
            }
        } else {
            localStorage.setItem("db_entry", "Y");
            localStorage.setItem("current_url", "product-details");
            localStorage.setItem(
                "productList",
                JSON.stringify(customerproduct)
            );
            this.Router.navigate(["/sign-in"]);
        }
    }

    removeFromAt(name: any) {
        if (name.includes("(")) {
            var name = name.substr(0, name.indexOf("("));
        }
        if (name.includes("@")) {
            var name = name.substr(0, name.indexOf("@"));
        }
        return name;
    }

    onSelectChange(value: any) {
        console.log(value);
    }

    showCreateForm() {
        this.productjson.showCustomerForm = true;
        this.productjson.customer_id = "";
    }

    closeCreateForm() {
        this.productjson.showCustomerForm = false;
    }
}
