<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg12">
    <div class="container">
        <div class="inner-title">
            <h3>Contact Us</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>

<div class="contact-area ptb-100">
    <div class="scetion-title text-center">
        <span>Contact Us</span>
        <h2>+91 99414 29634</h2>
        <br>
        <h3>No.1/1, Dr. Niyamadullah Street, Triplicane, Chennai-600005</h3>
    </div>

    <div class="container">
        <div class="contact-wrap pt-45">
            <div class="contact-wrap-form">
                 <form id="contactForm" [formGroup]="contactForm">
                     <div class="row">
                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="name" id="name" [ngClass]="{ 'is-invalid': submitted && p.name.errors }" formControlName="name" class="form-control"  placeholder="Your Name">
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="email" name="email" id="email" [ngClass]="{ 'is-invalid': submitted && p.email.errors }" formControlName="email" class="form-control" 
                                pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" placeholder="Your Email">
                                <div *ngIf="(p.email.invalid && p.email.touched) || p.email.dirty">
                                    <small *ngIf="p.email.errors?.required" class="text-danger">Email is required</small>
                                    <small *ngIf="p.email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                                </div>
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="phone_number" id="phone_number" [ngClass]="{ 'is-invalid': submitted && p.phone_number.errors }" formControlName="phone_number"  class="form-control" placeholder="Your Phone">
                            </div>
                         </div>

                         <div class="col-lg-6 col-sm-6">
                            <div class="form-group">
                                <input type="text" name="msg_subject" id="msg_subject" [ngClass]="{ 'is-invalid': submitted && p.msg_subject.errors }" formControlName="msg_subject" class="form-control"  placeholder="Your Subject">
                            </div>
                         </div>

                         <div class="col-lg-12 col-md-12">
                            <div class="form-group">
                                <textarea name="message" class="form-control" id="message" [ngClass]="{ 'is-invalid': submitted && p.message.errors }" formControlName="message" cols="30" rows="8"  placeholder="Your Message"></textarea>
                            </div>
                         </div>

                         <div class="col-lg-12 col-md-12 text-center">
                            <button (click)="sendContact()" class="default-btn page-btn text-center">Send Message</button>
                         </div>
                     </div>
                 </form>
            </div>
         </div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
