import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  sessionInfo: any = {};
  constructor(private httpClient: HttpClient) { }

  register(params) {
    return this.httpClient.post(environment.apiUrl + 'common/register', params);
  }

  login(params) {
    return this.httpClient.post(environment.apiUrl + 'common/login', params);
  }

  logout() {
    localStorage.removeItem('userId');
    localStorage.removeItem('user_type');
    localStorage.removeItem('userLogin');
    localStorage.removeItem('token');
    localStorage.removeItem('userDisplayName');
    localStorage.removeItem('roleName');
    localStorage.removeItem('subjectsList');
    localStorage.removeItem('db_entry');
    localStorage.removeItem('current_url');
    localStorage.removeItem('productList');
    localStorage.removeItem('pre_cus_percentage');
    localStorage.removeItem('reference_code');
  }

  isLoggedin() {
    if(localStorage.getItem('userId')){
      return this.httpClient.post(environment.apiUrl + 'common/isUserValidByUserId', localStorage.getItem('userId'));
    }else{
      localStorage.removeItem('userId');
      return localStorage.getItem('userId');
    }
    
  }

  setSessionInfo(response) {
    localStorage.setItem('userId', response.customer_id);
    localStorage.setItem('userDisplayName', response.customer_name);
    localStorage.setItem('user_type', response.user_type);
    localStorage.setItem('plan_tag', response.plan_tag);
    localStorage.setItem('pre_cus_percentage', response.pre_cus_percentage);
    localStorage.setItem('reference_code', response.reference_code);
  }

  getSessionInfo() {
    this.sessionInfo = {};
    this.sessionInfo.userId = localStorage.getItem('userId');
    this.sessionInfo.user_type = localStorage.getItem('user_type');
    this.sessionInfo.userLogin = localStorage.getItem('userLogin');
    this.sessionInfo.token = localStorage.getItem('token');
    this.sessionInfo.userDisplayName = localStorage.getItem('userDisplayName');
    this.sessionInfo.roleName = localStorage.getItem('roleName');
    return this.sessionInfo;
  }

  checkUserNotTaken(username) {
    return this.httpClient.get(environment.apiUrl + 'auth/validate_username/' + username);
  }

  getLoggedinuserDetails(user_id){
    return this.httpClient.get(environment.apiUrl + 'common/getUserDetails/' + user_id);
  }

}