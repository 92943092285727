<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner inner-bg1">
    <div class="container">
        <div class="inner-title">
            <h3>Pricing</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>

<div class="price-area pt-100 pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Pricing</span>
            <h2>Our Monthly Services Package</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="single-price">
                    <span>Basic Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>30<sub>/month</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li class="color-gray"><del>Tshirt Design</del></li>
                        <li class="color-gray"><del>Vector Art</del></li>
                        <li class="color-gray"><del>Print Ready Source</del></li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-price current">
                    <span>Standard Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>60<sub>/month</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li>Tshirt Design</li>
                        <li class="color-gray"><del>Vector Art</del></li>
                        <li class="color-gray"><del>Print Ready Source</del></li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-price">
                    <span>Premium Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>90<sub>/month</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li>Tshirt Design</li>
                        <li>Vector Art</li>
                        <li> Print Ready Source</li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="price-area-two pb-70">
    <div class="container">
        <div class="scetion-title text-center">
            <span>Pricing</span>
            <h2>Our Yearly Services Package</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="single-price border-radius-5">
                    <span>Basic Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>70<sub>/Year</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li class="color-gray"><del>Tshirt Design</del></li>
                        <li class="color-gray"><del>Vector Art</del></li>
                        <li class="color-gray"><del>Print Ready Source</del></li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-price current border-radius-5">
                    <span>Standard Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>120<sub>/Year</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li>Tshirt Design</li>
                        <li class="color-gray"><del>Vector Art</del></li>
                        <li class="color-gray"><del>Print Ready Source</del></li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-price border-radius-5">
                    <span>Premium Plan</span>

                    <div class="single-price-title">
                        <h2><sup>$</sup>170<sub>/Year</sub></h2>
                    </div>

                    <ul>
                        <li>Brand Identy</li>
                        <li>Package Design</li>
                        <li>Web Application</li>
                        <li>Bill Board</li>
                        <li>Tshirt Design</li>
                        <li>Vector Art</li>
                        <li> Print Ready Source</li>
                    </ul>

                    <a routerLink="/" class="get-btn">Get Stated</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>