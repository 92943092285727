import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']
})
export class WalletComponent implements OnInit {
  public user_id =  localStorage.getItem('userId');
  public user_type =  localStorage.getItem('user_type');
  public user_name: any = '';
  public user_detail: any = []; 
  public wallet_detail: any = [];
  public referrer_name: any = [];

  constructor(private Router: Router, 
    private route: ActivatedRoute, 
    private commonService: CommonService) { }

  ngOnInit(): void {
    this.getUserDetails();
    this.getWalletDetails();
  }

  getUserDetails(){
    this.commonService.getUserDetails(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        this.user_detail = response.data;
        this.user_name = this.user_detail.user_name;
      }
    });
  }
  getWalletDetails(){
    this.commonService.getWalletDetails(this.user_id, this.user_type).subscribe((response: any)=> {
      if (response.status) {
        this.wallet_detail = response.data;
      }else{
        this.wallet_detail = [];
      }
    });
  }

}
