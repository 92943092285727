import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DesignerComponent } from './components/pages/designer/designer.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { HomePageTwoComponent } from './components/pages/home-page-two/home-page-two.component';
import { HomePageThreeComponent } from './components/pages/home-page-three/home-page-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { GalleryComponent } from './components/pages/gallery/gallery.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { RecoverPasswordComponent } from './components/pages/recover-password/recover-password.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { ProductsComponent } from './components/pages/products/products.component';
import { ProductsSummaryViewComponent } from './components/pages/products-summary-view/products-summary-view.component';
import { CartComponent } from './components/pages/cart/cart.component';
import { CheckoutComponent } from './components/pages/checkout/checkout.component';
import { ProductsDetailsComponent } from './components/pages/products-details/products-details.component';
import { ProductsSummaryComponent } from './components/pages/products-summary/products-summary.component';
import { CustomerproductsComponent } from './components/pages/customerproducts/customerproducts.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CareersComponent } from './components/pages/careers/careers.component';
import { PostJobsComponent } from './components/pages/post-jobs/post-jobs.component';
import { ListComponent } from './components/pages/quotes/list/list.component';
import { CreateComponent } from './components/pages/quotes/create/create.component';
import { ViewComponent } from './components/pages/quotes/view/view.component';
import { DashboardComponent } from './components/pages/dashboard/dashboard.component';
import { AccountComponent } from './components/pages/account/account.component';
import { AddressComponent } from './components/pages/address/address.component';
import { OrdersComponent } from './components/pages/orders/orders.component';
import { OrderViewComponent } from './components/pages/order-view/order-view.component';
import { WalletComponent } from './components/pages/wallet/wallet.component';
import { AuthGuard } from './components/pages/auth/auth.guard';
import { QuoteSummaryComponent } from './components/pages/quote-summary/quote-summary.component';
import { QuoteSummaryViewComponent } from './components/pages/quote-summary-view/quote-summary-view.component';

const routes: Routes = [
    {path: '', component: HomePageOneComponent},
    {path: 'home-two', component: HomePageTwoComponent},
    {path: 'home-three', component: HomePageThreeComponent},
    {path: 'about', component: AboutComponent},
    {path: 'gallery', component: GalleryComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'designer', component: DesignerComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'recover-password', component: RecoverPasswordComponent},
    {path: 'recover-password/:id', component: RecoverPasswordComponent},
    {path: 'terms-condition', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'service-details/:id', component: ServicesDetailsComponent},
    {path: 'product', component: ProductsComponent},
    {path: 'product/:id', component: ProductsComponent},
    {path: 'customerproducts', component: CustomerproductsComponent, canActivate: [AuthGuard]},
    {path: 'cart', component: CartComponent,canActivate: [AuthGuard]},
    {path: 'checkout', component: CheckoutComponent,canActivate: [AuthGuard]},
    {path: 'checkout/:id', component: CheckoutComponent,canActivate: [AuthGuard]},
    {path: 'product-details/:id', component: ProductsDetailsComponent},
    {path: 'product-summary/:id', component: ProductsSummaryComponent},
    {path: 'product-summary-view/:id', component: ProductsSummaryViewComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'careers', component: CareersComponent},
    {path: 'post-jobs', component: PostJobsComponent, canActivate: [AuthGuard]},
    {path: 'quote', component: ListComponent, canActivate: [AuthGuard]},
    {path: 'createquote', component: CreateComponent, canActivate: [AuthGuard]},
    {path: 'viewquote/:id', component: ViewComponent, canActivate: [AuthGuard]},
    {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
    {path: 'account', component: AccountComponent, canActivate: [AuthGuard]},
    {path: 'address', component: AddressComponent, canActivate: [AuthGuard]},
    {path: 'orders', component: OrdersComponent, canActivate: [AuthGuard]},
    {path: 'ordersView/:id', component: OrderViewComponent, canActivate: [AuthGuard]},
    {path: 'wallet', component: WalletComponent, canActivate: [AuthGuard]},
    {path: 'quote-summary/:id', component: QuoteSummaryComponent, canActivate: [AuthGuard]},
    {path: 'quote-summary-view/:id', component: QuoteSummaryViewComponent, canActivate: [AuthGuard]},
    {path: '**', component: ErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }