<app-navbar-style-one></app-navbar-style-one>
<!-- <div class="inner-banner inner-bg2">
    <div class="container">
        <div class="inner-title">
            <h3>Products Details</h3>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><i class='bx bxs-chevrons-right'></i></li>
                <li>Products Details</li>
            </ul>
        </div>
    </div>
</div> -->
<div class="product-desc text-center mt-10">
    <h3 class="uppercase">
        {{ product_name }} {{ product_code ? "(" + product_code + ")" : "" }}
    </h3>
    <h3 *ngIf="prod_cat_id == 'NC'">Rs {{ prod_price }}</h3>
</div>
<section class="product-detls ptb-25 mobviv">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row">
                    <div class="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                        <div class="service-dtls-item prod_detail_image">
                            <span
                                ><img
                                    width="50px"
                                    height="50px"
                                    style="margin: 20px"
                                    (click)="changeImage(image_url)"
                                    src="{{ imagelink + image_url }}"
                                    alt="Images"
                                />
                            </span>
                            <span *ngFor="let image of galleryImg"
                                ><img
                                    style="margin: 20px"
                                    width="50px"
                                    height="50px"
                                    (click)="changeImage(image.image_url)"
                                    src="{{ imagelink + image.image_url }}"
                                    alt="Images"
                            /></span>
                        </div>
                    </div>
                    <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                        <div class="height350px text-center">
                            <img
                                class="fullShow"
                                src="{{ imagelink + image_url_prod }}"
                                alt="Image"
                            />
                        </div>
                    </div>
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <h6 *ngIf="prod_desc_detail != ''" class="header">
                            Product Description
                        </h6>
                        <div
                            *ngIf="prod_desc_detail != ''"
                            [innerHtml]="prod_desc_detail"
                        ></div>
                    </div>
                </div>
            </div>
            <!-- sample -->
            <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="service-dtls-slider owl-carousel owl-theme">
                        
                </div>
            </div> -->
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                <div class="product-desc">
                    <!-- <h3 class="uppercase">{{product_name}} {{product_code?'('+product_code+')':''}}</h3>
                    <h3 *ngIf = "prod_cat_id == 'NC'">Rs {{prod_price}}</h3> -->
                    <section>
                        <div class="container">
                            <!-- <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " *ngIf = "description !== ''">
                                    <h6 class="header">Product Description</h6>
                                    <p class="Product-Special-Features" [innerHtml]="description"></p>
                                     <p *ngIf = "prod_cat_id == 'NC'">{{prod_price}}</p>
                                </div>
                            </div> -->
                            <br />
                            <div
                                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row"
                            >
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 padding0px"
                                    *ngIf="user_type == 'SA'"
                                >
                                    <div
                                        id="customerSearch"
                                        class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    >
                                        <label
                                            *ngIf="
                                                !productjson.showCustomerForm
                                            "
                                            >Customer Name
                                            <span class="error">*</span>
                                        </label>
                                        <ng-select
                                            *ngIf="
                                                !productjson.showCustomerForm
                                            "
                                            [(ngModel)]="
                                                productjson.customer_id
                                            "
                                            [searchable]="true"
                                            placeholder=""
                                            (change)="checkValidation()"
                                            class="ng-select"
                                            [ngClass]="{
                                                'ng-select-error':
                                                    submitted &&
                                                    productjson.error
                                                        .customer_id
                                            }"
                                        >
                                            <ng-option [value]=""
                                                >Select Customer</ng-option
                                            >
                                            <ng-option
                                                *ngFor="
                                                    let customer of customerList
                                                "
                                                [value]="customer.customer_id"
                                                >{{ customer.customer_mobile }}
                                                ,
                                                {{
                                                    customer.customer_name
                                                }}</ng-option
                                            >
                                        </ng-select>
                                        <button
                                            *ngIf="
                                                !productjson.showCustomerForm
                                            "
                                            class="createBtn"
                                            (click)="showCreateForm()"
                                        >
                                            Create Customer
                                        </button>
                                        <button
                                            *ngIf="productjson.showCustomerForm"
                                            class="createBtn"
                                            (click)="closeCreateForm()"
                                        >
                                            cancel create Customer
                                        </button>
                                    </div>
                                    <div
                                        *ngIf="productjson.showCustomerForm"
                                        class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    >
                                        <label
                                            >Customer Name<span class="error"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            class="form-control"
                                            [ngClass]="{
                                                'is-invalid':
                                                    submitted &&
                                                    productjson.error
                                                        .customer_name
                                            }"
                                            [(ngModel)]="
                                                productjson.customer_name
                                            "
                                            (change)="checkValidation()"
                                        />
                                    </div>
                                    <div
                                        *ngIf="productjson.showCustomerForm"
                                        class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    >
                                        <label
                                            >Customer Mobile<span class="error"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            class="form-control"
                                            [ngClass]="{
                                                'is-invalid':
                                                    submitted &&
                                                    productjson.error
                                                        .customer_mobile
                                            }"
                                            [(ngModel)]="
                                                productjson.customer_mobile
                                            "
                                            (change)="checkValidation()"
                                        />
                                    </div>
                                    <div
                                        *ngIf="productjson.showCustomerForm"
                                        class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    >
                                        <label
                                            >Customer Email<span class="error"
                                                >*</span
                                            ></label
                                        >
                                        <input
                                            class="form-control"
                                            type="text"
                                            [ngClass]="{
                                                'is-invalid':
                                                    submitted &&
                                                    productjson.error
                                                        .customer_email
                                            }"
                                            [(ngModel)]="
                                                productjson.customer_email
                                            "
                                            (change)="checkValidation()"
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id != 'NC'"
                                >
                                    <label
                                        >Job Name<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <input
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.product_name
                                        }"
                                        [(ngModel)]="productjson.product_name"
                                        (change)="checkValidation()"
                                    />
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id != 'NC'"
                                >
                                    <label
                                        >Size<span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.psize_id
                                        }"
                                        [(ngModel)]="productjson.psize_id"
                                        (change)="sizeChange()"
                                    >
                                        <option value="">Select a size</option>
                                        <option
                                            value="{{ billsize.psize_id }}"
                                            *ngFor="let billsize of sizes"
                                        >
                                            {{ billsize.size_name }} - (
                                            {{
                                                removeNonZeros(
                                                    billsize.size_cms_left
                                                )
                                            }}
                                            *
                                            {{
                                                removeNonZeros(
                                                    billsize.size_cms_right
                                                )
                                            }}
                                            cm )
                                        </option>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id == 'BB'"
                                >
                                    <label
                                        >Bill Book Type
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.pk_sheet_id
                                        }"
                                        [(ngModel)]="productjson.pk_sheet_id"
                                        (change)="checksamecolour()"
                                    >
                                        <option value="">
                                            Select Book Type
                                        </option>
                                        <ng-container
                                            *ngFor="let book of bookTypes"
                                        >
                                            <option
                                                value="{{ book.pk_sheet_id }}"
                                                *ngIf="book.book"
                                            >
                                                {{ book.lable }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group row col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        variable_printing == 'Y' &&
                                        prod_cat_id == 'BB' &&
                                        productjson.pk_sheet_id != '' &&
                                        productjson.pk_sheet_id != '1'
                                    "
                                >
                                    <label class="col-xl-4 col-md-4"
                                        >Variable Printing</label
                                    >
                                    <div
                                        class="col-xl-2 col-md-2 paddingTop10px"
                                    >
                                        <input
                                            type="checkbox"
                                            [checked]="
                                                productjson.is_variable_printing ===
                                                'Y'
                                            "
                                            [(ngModel)]="
                                                productjson.is_variable_printing
                                            "
                                            class="col-xl-12 col-md-12"
                                            (change)="
                                                checkIsVariablePrinting($event)
                                            "
                                        />
                                    </div>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id == 'BB'"
                                >
                                    <label
                                        >No of Bills per Book
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [(ngModel)]="productjson.pk_bb_id"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.pk_bb_id
                                        }"
                                    >
                                        <option value="">
                                            Select No of Bills per Book
                                        </option>
                                        <ng-container
                                            *ngFor="
                                                let place of noOfBillsPerBook
                                            "
                                        >
                                            <option
                                                value="{{ place.pk_bb_id }}"
                                                *ngIf="place.active"
                                            >
                                                {{ place.no_of_bills }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id != 'NC'"
                                >
                                    <label>Front Printing Colour</label>
                                    <ng-multiselect-dropdown
                                        [placeholder]="
                                            'Select Front Printing Colours'
                                        "
                                        [data]="printingFrontColours"
                                        [(ngModel)]="frontColourSelectedItems"
                                        [settings]="dropdownSettings"
                                        (onSelect)="onItemSelectFront($event)"
                                    >
                                    </ng-multiselect-dropdown>
                                    <small
                                        class="is-invalid error"
                                        *ngIf="
                                            submitted &&
                                            productjson.error.frontColour
                                        "
                                        >Please Select Atleast One Option</small
                                    >
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id != 'NC'"
                                >
                                    <label>Back Printing Colour</label>
                                    <ng-multiselect-dropdown
                                        [placeholder]="
                                            'Select Back Printing Colours'
                                        "
                                        [data]="printingBackColours"
                                        [(ngModel)]="backColourSelectedItems"
                                        [settings]="dropdownSettings"
                                        (onSelect)="onItemSelectBack($event)"
                                    >
                                    </ng-multiselect-dropdown>
                                    <small
                                        class="is-invalid error"
                                        *ngIf="
                                            submitted &&
                                            productjson.error.backColour
                                        "
                                        >Please Select Atleast One Option</small
                                    >
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        book_copy_print_plain == 'Y' &&
                                        prod_cat_id == 'BB'
                                    "
                                >
                                    <label
                                        >Book Copy Print<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error
                                                    .book_copy_status
                                        }"
                                        [(ngModel)]="
                                            productjson.book_copy_status
                                        "
                                        class="form-control"
                                        (change)="checkValidation()"
                                    >
                                        <option value="">
                                            Select Book Copy Print
                                        </option>
                                        <ng-container
                                            *ngFor="
                                                let bookcpy of bookcopyArray
                                            "
                                        >
                                            <option
                                                value="{{ bookcpy.id }}"
                                                *ngIf="bookcpy.active"
                                            >
                                                {{ bookcpy.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="contentBox && prod_cat_id != 'NC'"
                                >
                                    <label
                                        >Front & Back Same Printing Content<span
                                            class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control col-xl-4"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error
                                                    .is_same_content
                                        }"
                                        [(ngModel)]="
                                            productjson.is_same_content
                                        "
                                        class="form-control"
                                        (change)="checkValidation()"
                                    >
                                        <option>Select</option>
                                        <option value="Y">Yes</option>
                                        <option value="N">No</option>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        prod_cat_id == 'BN' &&
                                        display_lamination == 'Y'
                                    "
                                >
                                    <label
                                        >Lamination Type<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control"
                                        [(ngModel)]="
                                            productjson.lamination_type
                                        "
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error
                                                    .lamination_type
                                        }"
                                        (change)="checkValidation()"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let laminate of laminationArray
                                            "
                                        >
                                            <option
                                                *ngIf="laminate.active"
                                                value="{{ laminate.id }}"
                                            >
                                                {{ laminate.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        prod_cat_id == 'BN' &&
                                        display_lamination == 'Y' &&
                                        productjson.lamination_type != 'N'
                                    "
                                >
                                    <label
                                        >Lamination Sides<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control"
                                        [(ngModel)]="
                                            productjson.lamination_side
                                        "
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error
                                                    .lamination_side
                                        }"
                                        (change)="checkValidation()"
                                    >
                                        <ng-container
                                            *ngFor="
                                                let side of laminationsideArray
                                            "
                                        >
                                            <option
                                                *ngIf="side.active"
                                                value="{{ side.id }}"
                                            >
                                                {{ side.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        (prod_cat_id == 'BB' ||
                                            prod_cat_id == 'BN') &&
                                        display_clear_number == 'Y'
                                    "
                                >
                                    <label
                                        >Numbering<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.numbering
                                        }"
                                        [(ngModel)]="productjson.numbering"
                                        (change)="checkValidation()"
                                    >
                                        <ng-container
                                            *ngFor="let num of numberingArray"
                                        >
                                            <option
                                                *ngIf="num.active"
                                                value="{{ num.id }}"
                                            >
                                                {{ num.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        prod_cat_id == 'BB' &&
                                        productjson.numbering == 'MA'
                                    "
                                >
                                    <label
                                        >Numbering Type<span class="error"
                                            >*</span
                                        ></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.is_numbering
                                        }"
                                        [(ngModel)]="productjson.is_numbering"
                                        (change)="checkValidation()"
                                    >
                                        <option value="C">Continuous</option>
                                        <option value="R">Repeated</option>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        (prod_cat_id == 'BB' ||
                                            prod_cat_id == 'BN') &&
                                        display_perforation == 'Y'
                                    "
                                >
                                    <label
                                        >Perforation
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.perforation
                                        }"
                                        [(ngModel)]="productjson.perforation"
                                        (change)="checkValidation()"
                                    >
                                        <ng-container
                                            *ngFor="let per of perforationArray"
                                        >
                                            <option
                                                *ngIf="per.active"
                                                value="{{ per.id }}"
                                            >
                                                {{ per.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        prod_cat_id == 'BN' &&
                                        display_folding == 'Y'
                                    "
                                >
                                    <label
                                        >Folding
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.folding
                                        }"
                                        [(ngModel)]="productjson.folding"
                                        (change)="checkValidation()"
                                    >
                                        <ng-container
                                            *ngFor="let per of foldingArray"
                                        >
                                            <option
                                                *ngIf="per.active"
                                                value="{{ per.id }}"
                                            >
                                                {{ per.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="
                                        prod_cat_id == 'LH' &&
                                        productjson.psize_id != ''
                                    "
                                >
                                    <label
                                        >Packing / Finishing
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error
                                                    .letter_package_details
                                        }"
                                        [(ngModel)]="
                                            productjson.letter_package_details
                                        "
                                        (change)="checkValidation()"
                                    >
                                        <option value="">
                                            Select packing Quantity
                                        </option>
                                        <option
                                            value="{{
                                                letterPacking.pk_letter_cal_id
                                            }}"
                                            *ngFor="
                                                let letterPacking of letterHeadPacking
                                            "
                                        >
                                            {{ letterPacking.packing }}
                                        </option>
                                    </select>
                                </div>
                                <!-- <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " *ngIf="prod_cat_id == 'LH' && display_gold_silver_foil == 'Y'">
                                    <label>Gold/Silver Foil Size in Sq.Cms <span class="error">*</span></label>
                                    <input type="number" [ngClass]="{ 'is-invalid': submitted && productjson.error.foil}" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
                                    step="0.01" (change)="checkValidation()" [(ngModel)]="productjson.foil" class="form-control">
                                </div>
                                <div class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 " *ngIf="prod_cat_id == 'LH' && display_embossing == 'Y'">
                                    <label>Embossing Size in Sq.Cms <span class="error">*</span></label>
                                    <input type="number" [ngClass]="{ 'is-invalid': submitted && productjson.error.embossing}" ng-pattern="/^[0-9]+(\.[0-9]{1,2})?$/"
                                    step="0.01" (change)="checkValidation()" [(ngModel)]="productjson.embossing" class="form-control">
                                </div> -->
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                    *ngIf="prod_cat_id == 'BB'"
                                >
                                    <label
                                        >Binding Type
                                        <span class="error">*</span></label
                                    >
                                    <select
                                        class="form-control"
                                        [(ngModel)]="productjson.binding_type"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.binding_type
                                        }"
                                        (change)="checkValidation()"
                                    >
                                        >
                                        <ng-container
                                            *ngFor="
                                                let bindings of bindingTypes
                                            "
                                        >
                                            <option
                                                value="{{ bindings.id }}"
                                                *ngIf="bindings.active"
                                            >
                                                {{ bindings.name }}
                                            </option>
                                        </ng-container>
                                    </select>
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                >
                                    <label
                                        >Quantity
                                        <span class="error">*</span></label
                                    >
                                    <input
                                        type="text"
                                        [ngClass]="{
                                            'is-invalid':
                                                submitted &&
                                                productjson.error.quantity
                                        }"
                                        (keyup)="returnNumber($event)"
                                        [(ngModel)]="productjson.quantity"
                                        class="form-control"
                                    />
                                </div>
                                <div
                                    class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center text-center"
                                    *ngIf="prod_cat_id != 'NC'"
                                >
                                    <button
                                        class="default-btn justify-content-center"
                                        (click)="showAmountList()"
                                    >
                                        <i class="fas fa-cart-plus"></i>
                                        Generate Amount
                                    </button>
                                </div>
                            </div>
                            <div
                                class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                                *ngIf="prod_cat_id == 'NC'"
                            >
                                <label>Total Amount</label>
                                <input
                                    type="text"
                                    class="form-control"
                                    [(ngModel)]="productjson.total_amount"
                                    disabled
                                />
                            </div>
                            <div
                                class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 justify-content-center text-center"
                                *ngIf="prod_cat_id == 'NC'"
                            >
                                <button
                                    class="default-btn justify-content-center"
                                    (click)="saveToCartProduct()"
                                >
                                    <i class="fas fa-cart-plus"></i> Add To Cart
                                </button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div
                class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                *ngIf="qualitylist != ''"
            >
                <table class="table">
                    <thead>
                        <tr>
                            <th>Quality Name</th>
                            <th class="text-right">Price Per Unit (Rs)</th>
                            <th class="text-right" *ngIf="user_type == 'P'">
                                Sub Total Amount (Rs)
                            </th>
                            <th class="text-right" *ngIf="user_type == 'P'">
                                Discount Amt({{ plan_percentage }} %)
                            </th>
                            <th class="text-right">Total Amount (Rs)</th>
                            <th class="text-center">Select Quality</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container
                            *ngFor="let qua of qualitylist; let i = index"
                        >
                            <tr *ngIf="qua.showQua">
                                <ng-template #tipContent>
                                    <span *ngIf="qua.pk_sheet_id == 5">
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_three_name
                                                ? "paper 3 : " +
                                                  removeFromAt(
                                                      qua.paper_three_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_four_name
                                                ? "paper 4 : " +
                                                  removeFromAt(
                                                      qua.paper_four_name
                                                  )
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_five_name
                                                ? "paper 5 : " +
                                                  removeFromAt(
                                                      qua.paper_five_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 4 &&
                                            qua.is_carbon == 'N'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_three_name
                                                ? "paper 3 : " +
                                                  removeFromAt(
                                                      qua.paper_three_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_four_name
                                                ? "paper 4 : " +
                                                  removeFromAt(
                                                      qua.paper_four_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 4 &&
                                            qua.is_carbon == 'Y'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_three_name
                                                ? "paper 3 : " +
                                                  removeFromAt(
                                                      qua.paper_three_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_five_name
                                                ? "paper 4 : " +
                                                  removeFromAt(
                                                      qua.paper_five_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 3 &&
                                            qua.is_carbon == 'N'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_three_name
                                                ? "paper 3 : " +
                                                  removeFromAt(
                                                      qua.paper_three_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 3 &&
                                            qua.is_carbon == 'Y'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}<br />
                                        {{
                                            qua.paper_four_name
                                                ? "paper 3 : " +
                                                  removeFromAt(
                                                      qua.paper_four_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 2 &&
                                            qua.is_carbon == 'N'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}
                                        <br />{{
                                            qua.paper_two_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_two_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="
                                            qua.pk_sheet_id == 2 &&
                                            qua.is_carbon == 'Y'
                                        "
                                    >
                                        {{
                                            qua.paper_one_name
                                                ? "paper 1 : " +
                                                  removeFromAt(
                                                      qua.paper_one_name
                                                  ) +
                                                  ","
                                                : ""
                                        }}
                                        <br />{{
                                            qua.paper_four_name
                                                ? "paper 2 : " +
                                                  removeFromAt(
                                                      qua.paper_four_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span *ngIf="qua.pk_sheet_id == 1">
                                        {{
                                            qua.paper_one_name
                                                ? removeFromAt(
                                                      qua.paper_one_name
                                                  )
                                                : ""
                                        }}<br />
                                    </span>
                                    <span
                                        *ngIf="qua.paper > 0 || qua.paper > '0'"
                                    >
                                        {{
                                            qua.paper_name
                                                ? removeFromAt(qua.paper_name)
                                                : ""
                                        }}<br />
                                    </span>
                                </ng-template>
                                <td>
                                    <span
                                        [ngbTooltip]="tipContent"
                                        tooltipClass="my-custom-class"
                                        >{{ qua.quality_name }}</span
                                    >
                                </td>
                                <td class="text-right">Rs {{ qua.rate }}</td>
                                <td class="text-right" *ngIf="user_type == 'P'">
                                    Rs {{ qua.taxable_amount }}
                                </td>
                                <td class="text-right" *ngIf="user_type == 'P'">
                                    Rs {{ qua.discount_amount }}
                                </td>
                                <td class="text-right">
                                    Rs {{ qua.sub_total_amount }} +{{
                                        qua.gst ? qua.gst : 0
                                    }}% GST
                                </td>
                                <td class="text-center">
                                    <input
                                        type="radio"
                                        name="products"
                                        [checked]="qua.active"
                                        (change)="
                                            selectQuality(qua.pquality_id)
                                        "
                                        value="{{ qua.pquality_id }}"
                                    />
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center justify-content-center"
                >
                    <button
                        class="default-btn justify-content-center"
                        (click)="addtocustomerproduct()"
                    >
                        <i class="fas fa-cart-plus"></i> Next
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-style-two></app-footer-style-two>
