<footer class="footer-area">
    <div class="container pt-70">
        <div class="footer-contact">
            <div class="row">
                <!-- <div class="col-lg-4 col-md-4">
                    <div class="footer-card">
                        <i class='bx bx-time'></i>
                        <h3>Sun - Thu : 10:00 AM - 06:00 PM</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="footer-card">
                        <i class='bx bxs-map'></i>
                        <h3>New No. 106, Old No. 124, Dr. Besant Road, Royapettah,

                            Chennai- 600 014.</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="footer-card">
                        <i class='bx bxs-phone-call'></i>
                        <h3 class="media-tel">
                            <a href="tel:+19876543210">+91 98416 62256</a> 
                        </h3>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="footer-top-list pb-70">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="footer-list">
                        <h3>Categories</h3>
                        <ul>
                            <ng-container *ngFor="let cat of subCategories">
                                <li *ngIf="cat.value == 'BB' || cat.value == 'BN' || cat.value == 'LH'">
                                    <i class='bx bxs-chevron-right'></i> <a routerLink="/product/{{cat.category_id}}">{{cat.category_name}}</a></li>
                            </ng-container>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="footer-list">

                        <h3>Quick Links</h3>
                        <ul>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="">Home</a></li>
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/services">Categories</a></li>
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/about">About Us</a></li> -->
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/testimonial">Testimonial</a></li> -->
                            <!-- <li><i class='bx bxs-chevron-right'></i><a routerLink="/blog">Blog</a></li> -->
                            <li><i class='bx bxs-chevron-right'></i><a routerLink="/contact">Contact</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-4">
                    <div class="footer-side-list">
                        <h3>Contact Us</h3>

                        <ul>
                            <li><i class='bx bxs-phone'></i> <a href="tel:+919941429634">+91 99414 29634</a></li>
                            <li><i class='bx bxl-telegram'></i> <a href="mailto:achagamonline@gmail.com">achagamonline@gmail.com</a></li>
                            <!-- <li><i class='bx bxs-map'></i>New No. 106, Old No. 124, Dr. Besant Road, Royapettah, Chennai- 600 014.</li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-3">
                    <div class="footer-logo">
                        <img width="150px" src="assets/img/footerlogo.svg" alt="Footer Logo">
                    </div>
                </div>

                <div class="col-lg-8 col-md-9">
                    <div class="bottom-text pt-20">
                        <p>©2020 Achagam</p>
                        <!-- <ul class="social-bottom">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>