<div class="navbar-area mb-10">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img width="70px" height="35px" src="assets/img/achagam_logo.svg" alt="Logo"></a>
        <a *ngIf="user_id" href="javascript:void(0)" class="logo user_code_mobile"><b>{{user_code}}</b></a>
        <a class="logo logo_wishlist" *ngIf="user_id" routerLink="/customerproducts">
            <span width="150px" class="cartNumberSpan_mob">
                <i class='bx bx-heart'></i></span></a>
        <a routerLink="/cart" class="logo logo_cart" *ngIf="user_id"  >
            <span width="150px" class="cartNumberSpan_mob" *ngIf="globals.cart_count > 0">
                <i class='bx bx-cart'></i>{{globals.cart_count}}</span>
        </a>
    </div>
    <div class="main-nav border-radius">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img width="100px" height="100px"  src="assets/img/achagam-14.svg" alt="Logo"></a>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item">
                            <a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Category</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/product" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Products</a>
                        </li>
                        <li class="nav-item" *ngIf="user_id">
                            <a routerLink="/quote" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Quote</a>
                        </li>
                        <!-- <li class="nav-item" *ngIf="user_id">
                            <a routerLink="/post-jobs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Post Jobs</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a routerLink="/careers" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Careers</a>
                        </li> -->
                        <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                        <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                        <li class="nav-item" *ngIf="!user_id">
                            <a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a>
                        </li>
                        <li class="nav-item" *ngIf="!user_id">
                            <a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a>
                        </li>
                        <li class="nav-item" *ngIf="user_id">
                            <a href="javascript:void(0)" class="nav-link">My Accounts</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dashboard</a></li>
                                <li class="nav-item"><a routerLink="/account" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Account Info</a></li>
                                <li class="nav-item"><a routerLink="/address" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Address Book</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                                <li class="nav-item"><a routerLink="/orders" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My orders</a></li>
                                <li class="nav-item" *ngIf="user_id"><a routerLink="/sign-in" class="nav-link" >Sign out</a></li>
                            </ul>
                        </li>
                    </ul>
                    <div class="cart-area">
                        <a class="mr-15" *ngIf="user_id" routerLink="/customerproducts"> <i class='bx bx-heart'></i></a>
                        <a *ngIf="globals.cart_count > 0 && user_id !=''" routerLink="/cart" >
                            <div class="cartnumber" >
                                <span class="cartNumberSpan">{{globals.cart_count}}</span>
                            </div>
                            <div><i class='bx bx-cart'></i></div>
                        </a>
                        <a *ngIf="globals.cart_count == 0 && user_id !=''">
                            <div class="cartnumber" >
                                <span class="cartNumberSpan">{{globals.cart_count}}</span>
                            </div>
                            <div><i class='bx bx-cart'></i></div>
                        </a>
                    </div>
                    <div class="menu-btn">
                        <div *ngIf="user_id" class="user_code"><b>{{user_code}}</b></div>
                        <a href="tel:+919941429634" class="phone-btn"><i class='bx bxs-phone'></i>+91 99414 29634</a>
                    </div>
                </div>
            </nav>
        </div>
    </div>
</div>