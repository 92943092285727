import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from './../../../common/services/common.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss']
})

export class ViewComponent implements OnInit {

  public user_id =  localStorage.getItem('userId');
  public quote_id = '';
  public quote_detail: any = [];
  constructor(private router: Router,private route: ActivatedRoute,private commonService: CommonService) { 
    route.params.forEach(params => {
      if (params.id) {
        this.quote_id = params.id;
        this.getQuoteDetails();
      }
    });
  }

  getQuoteDetails(){
    this.commonService.getQuoteDetails(this.quote_id).subscribe((response: any) => {
      if (response.status) {
        this.quote_detail = response.data;
      }
    });
  }

  ngOnInit(): void {
  }

}