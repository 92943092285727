import { CommonService } from '../../common/services/common.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  public careertitlelist: any = [];
  public postjoblist: any = [];
  public joboffered: any = [];
  public jobwanted: any = [];
  public pk_career_id: any = '';

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    private commonService: CommonService,
    private router: Router) { 

    }

  ngOnInit(): void {
    this.getCareerList_get();
  }

  getCareerList_get() {
    this.commonService.getCareerList_get().subscribe((response: any) => {
        if (response.status) {
          this.careertitlelist = response.data;
          this.filteredpostjobs_get(this.careertitlelist[0].pk_career_id);
          this.pk_career_id = (this.careertitlelist[0].pk_career_id);
        }
    });
  }

  filteredpostjobs_get(value: any){
    this.commonService.filteredpostjobs_get(value).subscribe((response: any) => {
      if (response.status) {
        this.postjoblist = response.data;
        this.joboffered = [];
          this.jobwanted = [];
          const jobdetails = this.postjoblist.filter((data: any) => {
            if( data.new_job == 'O' )
            {
              this.joboffered.push(data);
            } 
            if( data.new_job == 'W' )
            {
              this.jobwanted.push(data);
            }
        });
      }else{
        this.postjoblist = [];
      }
    });
  }

}
