import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CommonService } from '../../common/services/common.service';


@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {

  public recoverpasswordForm: FormGroup;
  public changePasswordForm: FormGroup;
  public submitted: boolean = false;
  public token: any = '';
  public customer_email: any = '';
  public error:boolean = false;
  public trueerror:boolean = false;
  public message:any = '';
  public commonerror: any = '';

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
  ) { 
    route.params.forEach(params => {
      if(params.id) {
        this.token = params.id;
        this.getTokenDetails();
      }
    });
  }

  getTokenDetails(){
    this.commonService.getUserDetailsWithToken(this.token).subscribe((response: any) => {
      if(response.status){
        this.customer_email = response.data.customer_email;
      }else{
        this.message = 'Your link has been expired';
      }
    });
  }

  get p() { return this.recoverpasswordForm.controls; }
  get g() { return this.changePasswordForm.controls; }

  resetPassword(){
    this.error = false;
    this.trueerror = false;
    this.commonerror = '';
    this.message = '';
    if(this.recoverpasswordForm.valid){
      const formData = new FormData();
      formData.append('email' , this.recoverpasswordForm.value.email);
      this.commonService.resetPassword(formData).subscribe((response: any) => {
        if (response.status) {
            this.trueerror = true;
            this.recoverpasswordForm.get('email').setValue("");
            this.commonerror = response.message;
            this.router.routeReuseStrategy.shouldReuseRoute = function () {
              return false;
            };
        }else{
          this.error = true;
          this.commonerror = response.message;
        }
      });
    }else{
      this.error = true;
      this.commonerror = 'Enter a Valid Mail Id';
    }
  }

  changePassword(){
    this.message = "";
    this.error = false;
    this.submitted = true;
    if(this.changePasswordForm.value.password != this.changePasswordForm.value.confirm_password){
      this.error = true;
      this.message = "Password Not Match";
      return false;
    }
    if (this.changePasswordForm.invalid) {
      return false;
    }
    if(this.changePasswordForm.valid){
      const formData = new FormData();
      formData.append('email' , this.customer_email);
      formData.append('token' , this.token);
      formData.append('password' , this.changePasswordForm.value.password);
      formData.append('confirm_password' , this.changePasswordForm.value.confirm_password);
      this.commonService.updatePassword(formData).subscribe((response: any) => {
        if (response.status) {
            this.changePasswordForm.get('password').setValue("");
            this.changePasswordForm.get('confirm_password').setValue("");
            this.router.navigate(['/sign-in']);
        }else{
          this.error = true;
          this.message = response.message;
        }
      });
    }
  }

  ngOnInit(): void {
    this.recoverpasswordForm = this.formBuilder.group({
      email: [null, Validators.required],
    });
    this.changePasswordForm = this.formBuilder.group({
      password: [null, Validators.required],
      confirm_password: [null, Validators.required],
    });
  }

}
