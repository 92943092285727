import { Component, OnInit } from '@angular/core';
import { CommonService } from './../../common/services/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { Globals } from '../../common/providers/global';
import { exit } from 'process';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';

declare let $: any;

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  providers: [DatePipe]

})
export class CartComponent implements OnInit {
  public user_id = localStorage.getItem('userId');
  public plan_tag = localStorage.getItem('plan_tag');
  public plan_percentage = localStorage.getItem('pre_cus_percentage');
  public user_type = localStorage.getItem('user_type');
  public imagelink: any = environment.imageUrl;
  public order: any = {};
  public carts: any = [];
  public total_amount: any = 0;
  public sub_total_amount: any = 0;
  public taxable_amount: any = 0;
  public discount_amount: any = 0;
  public category_type: any = '';
  public iconClasses: object;
  public final_day: any = '';
  public is_form: any = '';
  public isShown: boolean = true;

  constructor(
    private Router: Router, 
    private route: ActivatedRoute, 
    private commonService: CommonService,
    public globals: Globals,
    private toastr: ToastrService,
    private datePipe: DatePipe

    ) { }

  ngOnInit(): void {
    this.getCartProducts();
    this.iconClasses = {
      error: 'toast-error',
      info: 'toast-info',
      success: 'toast-success',
      warning: 'toast-warning',
    };
  }

  getCartProducts(){
    this.commonService.getCartProducts(this.user_id, this.user_type).subscribe((response: any) => {
      if (response.status) {
        if(response.data.length > 0){
          this.carts = response.data;
          this.globals.cart_count = this.carts.length;
        }else{
          this.carts = [];
          this.globals.cart_count = 0;
          this.Router.navigate(['/product']);
        }
        this.getTotals();
      }else{
        this.carts = [];
        this.globals.cart_count = 0;
        this.getTotals();
        this.Router.navigate(['/product']);
      }
    });
  }
  daysFinder(datefrom,dateto){
    const myDate = new Date();
    this.final_day = this.datePipe.transform(myDate, 'yyyy-MM-dd');
    return Math.floor(( Date.parse(dateto) - Date.parse(this.final_day) ) / 86400000); 
  }
  getTotals(){
    this.sub_total_amount = 0;
    const carts = this.carts.filter((data: any) => {
      this.sub_total_amount += parseFloat(data.total_amount);
    });
    this.sub_total_amount = (this.sub_total_amount).toFixed(2);
    this.total_amount = this.sub_total_amount;
    this.total_amount = (Math.ceil(this.total_amount)).toFixed(2);
    this.isShown = true;
  }

  getLatestAmount(array: any , id: any){

    this.isShown = false;
    
    if(id == "plus"){
      array.quantity = parseInt(array.quantity) + 1;
    }else if(id == "minus"){
      array.quantity = parseInt(array.quantity) - 1;
    }else{
      array.quantity = parseInt(array.quantity);
    }
    if(parseInt(array.quantity) < 1 || array.quantity == '' || array.quantity == null || array.quantity == undefined){
      this.showerror('Quantity should not be lessthan 1');
      array.quantity = 1;
      return false;
    }else{
      if(array.category_type == 'NC'){
        array.total_amount = array.productPrice * array.quantity;
        this.isShown = true;
      }else{
        const formData = new FormData();
        formData.append('user_id' , this.user_id);
        formData.append('data', JSON.stringify(array));
        this.commonService.getlatestAmount(formData).subscribe((response: any) => {
          if (response.status) {
            array.binding_calc_no = response.data.binding_calc_no;
            array.binding_rate = response.data.binding_rate;
            array.collating_amount = response.data.collating_amount;
            array.j1 = response.data.j1;
            array.j2 = response.data.j2;
            array.j3 = response.data.j3;
            array.j4 = response.data.j4;
            array.j5 = response.data.j5;
            array.j6 = response.data.j6;
            array.j7 = response.data.j7;
            array.j8 = response.data.j8;
            array.j9 = response.data.j9;
            array.j10 = response.data.j10;
            array.j11 = response.data.j11;
            array.j12 = response.data.j12;
            array.margin_amount = response.data.margin_amount;
            array.numbering_amount = response.data.numbering_amount;
            array.paper_consumption_amount = response.data.paper_consumption_amount;
            array.percentage_amount = response.data.percentage_amount;
            array.perforation_amount = response.data.perforation_amount;
            array.printing_amount = response.data.printing_amount;
            array.percentage_amount = response.data.percentage_amount;
            array.perforation_amount = response.data.perforation_amount;
            array.sub_total_amount = response.data.sub_total_amount?response.data.sub_total_amount:0;
            array.rate = response.data.rate?response.data.rate:0;
            array.discount_amount = response.data.discount_amount?response.data.discount_amount:0;
            array.total_amount = response.data.total_amount?response.data.total_amount:0;
            array.tax_amount = response.data.tax_amount?response.data.tax_amount:0;
            array.taxable_amount = response.data.taxable_amount?response.data.taxable_amount:0;
            array.original_sub_total_amount = response.data.original_sub_total_amount?response.data.original_sub_total_amount:0;
            array.original_total_amount = response.data.original_total_amount?response.data.original_total_amount:0;
            array.original_tax_amount = response.data.original_tax_amount?response.data.original_tax_amount:0;
            this.getTotals();
          }
        });
      }
    }
  }

  showSuccess() {
    this.toastr.success('Products Successfully added to checkout!');
  }

  showerror(msg: any){
    this.toastr.warning(msg);
  }

  addToOrder(){
    this.order.user_id = this.user_id;
    this.order.plan_tag = this.plan_tag;
    this.order.plan_percentage = this.plan_percentage;
    this.order.user_type = this.user_type;
    this.order.total_amount = this.total_amount;
    this.order.sub_total_amount = this.sub_total_amount;
    this.order.taxable_amount = this.taxable_amount;
    this.order.cart = this.carts;
    if(this.order.cart.length > 4){
      this.showerror('Please order maximum of 4 products!');
      return false;
    }else{
      const formData = new FormData();
      formData.append('data' , JSON.stringify(this.order));
      $('.preloader').fadeIn('slow');
      this.commonService.addToOrder(formData).subscribe((response: any) => {
        if (response.status) {
          this.showSuccess();
          this.Router.navigate(['/checkout']);
        }
      });
    }
  }

  removeCart(cart_id){
    const formData = new FormData();
    formData.append('data' , JSON.stringify(this.carts));
    formData.append('cart_id' , cart_id);
    $('.preloader').fadeIn('slow');
    this.commonService.removeUpdateCart(formData).subscribe((response: any) => {
      $('.preloader').fadeOut('slow');
      this.getCartProducts();
    });
  }

  clearCart(){
    const formData = new FormData();
    formData.append('data' , JSON.stringify(this.carts));
    $('.preloader').fadeIn('slow');
    this.commonService.clearCart(formData).subscribe((response: any) => {
      $('.preloader').fadeOut('slow');
      this.getCartProducts();
    });
  }

  updateCart(){
    const formData = new FormData();
    formData.append('data' , JSON.stringify(this.carts));
    $('.preloader').fadeIn('slow');
    this.commonService.updateCart(formData).subscribe((response: any) => {
      $('.preloader').fadeOut('slow');
      this.getCartProducts();
    });
  }
}