<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    </style>
    <app-navbar-style-one></app-navbar-style-one>
<section class="register-page pt-50">
    <div class="container">
        <div class="row paddingBottom10px">
            <div class="col-lg-12">
                <button class="default-btn-post_jobs" (click)="open(content)">Add New Job Offered</button>
                <ng-template #content let-modal >
                    <div class="col-lg-12 billing-details">
                        <div class="row marginTop10">
                            <div class="col-6">
                                <h3>Post Jobs</h3>
                            </div>
                            <div class="col-6">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div class="theme-card">
                            <div class="card-body tab2-card">
                                <div class="paddingTop20px" *ngIf="jobCareerForm">
                                    <form [formGroup]="jobCareerForm" class="needs-validation" novalidate="" >
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 form_lable">Title</label>
                                            <select class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.job_type.errors }" formControlName="job_type" (change)="titleshow_get($event)">
                                                <option value="disabled">Select</option>
                                                <option value="{{title.pk_career_id}}" *ngFor="let title of careertitlelist">
                                                    {{title.career_title}}
                                                </option>
                                                <option value="0">Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group row" *ngIf= "showofferedtittlebox">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Title Name</label>
                                            <input class="form-control col-xl-8 col-md-5"  formControlName="titlename"
                                                type="text" autocomplete="off" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Company Name</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.companyname.errors }" formControlName="companyname"
                                                type="text" autocomplete="off" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Designation</label>
                                            <input class="form-control col-xl-8 col-md-5"[ngClass]="{ 'is-invalid': submitted && p.designation.errors }" formControlName="designation"
                                                type="text" autocomplete="off" autocomplete="off">
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Description</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.description.errors }" formControlName="description"
                                                type="text" autocomplete="off">
                                        </div> -->
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Address</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.address.errors }" formControlName="address" type="text"
                                                autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Mobile</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.mobile.errors }" formControlName="mobile"
                                                type="number" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Email</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.email_id.errors }" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email_id" type="text"
                                                autocomplete="off">
                                                <div *ngIf="(p.email_id.invalid && p.email_id.touched) || p.email_id.dirty">
                                                    <small *ngIf="p.email_id.errors?.required" class="text-danger margin_left_150">Email is required</small>
                                                    <small *ngIf="p.email_id.errors?.pattern" class="text-danger margin_left_150">Please provide a valid email address</small>
                                                </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Required Experience<br>(in years)</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.req_exp.errors }" formControlName="req_exp" min="0" (keyup)="checkValue($event)" type="number"
                                                autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Salary Offered</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && p.sal_off.errors }" formControlName="sal_off" min="0" (keyup)="checkValue($event)" type="number"
                                                autocomplete="off">
                                        </div>
                                        <!-- <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Image</label>
                                            <input type="file" class="profile_upload cursor_pointer" ngFileSelect [options]="options" (uploadOutput)="onUploadOutput($event)" [uploadInput]="uploadInput" multiple>
                                                <button class="btn btn-primary cursor_pointer">Choose file to upload</button>
                                                    <div *ngIf="uploadImage" class="col-xl-12 font-11px">Filename : {{uploadImage}}</div>
                                                    <div *ngIf="uploadError" class="col-xl-12 font-11px error">{{uploadError}}</div>
                                        </div> -->
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="default-btn-post_jobs" (click)="saveJob_post();">Save</button>
                            <button type="button" class="default-btn-post_jobs" (click)="modal.dismiss('Cross click');">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</section>

<section class="register-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Company Name</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Address</th>
                            <th scope="col">Required Experience<br>(in years)</th>
                            <th scope="col">Salary Offered</th>
                            <th scope="col" class="paddingleft30px">Edit</th>
                            <th scope="col" class="paddingleft30px">Delete</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="postjoblist != '' ">
                        <tr *ngFor="let careerdetails of joboffered;">
                            <td>{{careerdetails.career_title}}<span *ngIf= "careerdetails.job_type == 0 ">{{careerdetails.titlename}}</span></td>
                            <td>{{careerdetails.name}}</td>
                            <td>{{careerdetails.designation}}</td>                            
                            <td>{{careerdetails.mobile}}</td>
                            <td>{{careerdetails.email_id}}</td>
                            <td>{{careerdetails.address}}</td>
                            <td class="text-center">{{careerdetails.requiredexp}}</td>
                            <td>{{careerdetails.salary}}</td>
                            <td><button class="optionBtnedit three"
                                    (click)="editpostjob(careerdetails.job_id);open(content);">Edit</button></td>
                            <td><button class="optionBtndelete three"
                                    (click)="deletejob(careerdetails.job_id)">Delete</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>

<section class="register-page">
    <div class="container">
        <div class="row paddingBottom10px">
            <div class="col-sm-12">
                <button class="default-btn-post_jobs" (click)="open_popup(wanted)">Add New Job Wanted</button>
                <ng-template #wanted let-modal >
                    <div class="col-lg-12">
                        <div class="row marginTop10">
                            <div class="col-6">
                                <h3>Job Wanted</h3>
                            </div>
                            <div class="col-6">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div class="theme-card wanted_label">
                            <div class="card-body tab2-card">
                                <div class="paddingTop20px" *ngIf="jobWantedForm">
                                    <form [formGroup]="jobWantedForm" class="needs-validation" novalidate="" >
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 form_lable">Title</label>
                                            <select class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.job_type.errors }" formControlName="job_type" (change)="wantedtitleshow_get($event)" >
                                                <option value="disabled">Select</option>
                                                <option value="{{title.pk_career_id}}" *ngFor="let title of careertitlelist">
                                                    {{title.career_title}}
                                                </option>
                                                <option value="0">Others</option>
                                            </select>
                                        </div>
                                        <div class="form-group row" *ngIf= "showWantedtittlebox">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Title Name</label>
                                            <input class="form-control col-xl-8 col-md-5"  formControlName="titlename"
                                                type="text" autocomplete="off" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Name</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.name.errors }" formControlName="name"
                                                type="text" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Mobile Number</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.mobile.errors }" formControlName="mobile"
                                                type="number" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Email Id</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.email_id.errors }" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" formControlName="email_id" type="text"
                                                autocomplete="off">
                                                <div *ngIf="(j.email_id.invalid && j.email_id.touched) || j.email_id.dirty">
                                                    <small *ngIf="j.email_id.errors?.required" class="text-danger margin_left_150">Email is required</small>
                                                    <small *ngIf="j.email_id.errors?.pattern" class="text-danger margin_left_150">Please provide a valid email address</small>
                                                </div>
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Experience<br>(in years)</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.experience.errors }" formControlName="experience"
                                                type="text" autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Address</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.address.errors }" formControlName="address" type="text"
                                                autocomplete="off">
                                        </div>
                                        <div class="form-group row">
                                            <label class="col-xl-4 col-md-3 paddingTop10px"><span> </span>Salary Expected</label>
                                            <input class="form-control col-xl-8 col-md-5" [ngClass]="{ 'is-invalid': submitted && j.sal_exp.errors }" formControlName="sal_exp" min="0" (keyup)="checkValue($event)" type="text"
                                                autocomplete="off">
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="default-btn-post_jobs" (click)="saveJobwanted_post();">Save</button>
                            <button type="button" class="default-btn-post_jobs" (click)="modal.dismiss('Cross click');">Cancel</button>
                        </div>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</section>

<section class="register-page">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <table class="table" >
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile Number</th>
                            <th scope="col">Email Id</th>
                            <th scope="col">Experience<br>(in years)</th>
                            <th scope="col">Address</th>
                            <th scope="col">Salary Expected</th>
                            <th scope="col" class="paddingleft30px">Edit</th>
                            <th scope="col" class="paddingleft30px">Delete</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="postjoblist != '' ">
                        
                        <tr *ngFor="let careerdetails of jobwanted;">
                            <td>{{careerdetails.career_title}}<span *ngIf= "careerdetails.job_type == 0 ">{{careerdetails.titlename}}</span></td>
                            <td>{{careerdetails.name}}</td>
                            <td>{{careerdetails.mobile}}</td>
                            <td>{{careerdetails.email_id}}</td>
                            <td class="text-center">{{careerdetails.requiredexp}}</td>
                            <td>{{careerdetails.address}}</td>
                            <td>{{careerdetails.salary}}</td>
                            <td><button class="optionBtnedit three"
                                    (click)="editjobwanted(careerdetails.job_id);open_popup(wanted);">Edit</button></td>
                            <td><button class="optionBtndelete three"
                                    (click)="deletejob(careerdetails.job_id)">Delete</button></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
<app-footer-style-one></app-footer-style-one>
